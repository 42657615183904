import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { EnquiryNeedDetail } from '../../../models/EnquiryNeed';
import ReactCountryFlag from 'react-country-flag';
import {
    CloseIconWrapper,
    DialogTitleContainer,
    TypographyBold,
    TypographyPreWhiteSpace,
} from './EnquiryDetailDialog.styles';
import { HighlightOff } from '@material-ui/icons';
import { TK } from '../../../store/Translations/translationKeys';
import { useTranslations } from '../../../store/Translations/hooks';

interface IEnquiryDetailDialogProps {
    needOpen?: EnquiryNeedDetail;
    handleDialogClose: () => void;
}

export const EnquiryDetailClientDialog: React.FC<IEnquiryDetailDialogProps> = ({ needOpen, handleDialogClose }) => {
    const t = useTranslations();

    return (
        <Dialog open={!!needOpen?.enquiryId} onClose={handleDialogClose} maxWidth="lg">
            <DialogTitle style={{ padding: 0 }}>
                <DialogTitleContainer>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                            <ReactCountryFlag
                                style={{ height: 20, width: 20 }}
                                svg
                                countryCode={needOpen?.countryCode}
                            />
                            <Typography style={{ marginLeft: '5px' }} variant="h5">
                                {needOpen?.needName || ''}
                            </Typography>
                        </div>
                    </div>
                    <CloseIconWrapper>
                        <IconButton onClick={handleDialogClose} style={{ outline: 'none' }}>
                            <HighlightOff color="primary" fontSize="large" />
                        </IconButton>
                    </CloseIconWrapper>
                </DialogTitleContainer>

                <hr style={{ margin: 0 }} />
            </DialogTitle>
            <DialogContent style={{ width: '100%' }}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TypographyBold variant="h6">
                                {t(TK.productCode) + ':\t' + needOpen?.productCode}
                            </TypographyBold>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TypographyBold variant="h6">{'Need quantity:\t' + needOpen?.needQuantity}</TypographyBold>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TypographyBold variant="h6">{'More details about request:'}</TypographyBold>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TypographyPreWhiteSpace>
                                {'Accept Alternative\t' + (needOpen?.acceptAlternative === true ? 'Yes' : 'No')}
                            </TypographyPreWhiteSpace>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TypographyPreWhiteSpace>
                                {'Accept other pack sizes\t' + (needOpen?.acceptOtherPackSize === true ? 'Yes' : 'No')}
                            </TypographyPreWhiteSpace>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TypographyPreWhiteSpace>
                                {'Accept generics\t' + (needOpen?.acceptGeneric === true ? 'Yes' : 'No')}
                            </TypographyPreWhiteSpace>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TypographyPreWhiteSpace>
                                {'Accept different countries\t' +
                                    (needOpen?.acceptDifferentCountries === true
                                        ? 'Yes (' + needOpen?.otherAcceptedCountries!! + ')'
                                        : 'No')}
                            </TypographyPreWhiteSpace>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TypographyPreWhiteSpace>
                                {'Single lot\t' + (needOpen?.isSingleLot === true ? 'Yes' : 'No')}
                            </TypographyPreWhiteSpace>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                            <TypographyPreWhiteSpace>
                                {'Additional documents\t' +
                                    (needOpen?.otherDocuments === true
                                        ? 'Yes (' + needOpen?.otherDocumentsNeeded!! + ')'
                                        : 'No')}
                            </TypographyPreWhiteSpace>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TypographyPreWhiteSpace>{'Comments\t' + needOpen?.comments!!}</TypographyPreWhiteSpace>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
