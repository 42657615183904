import * as React from 'react';
import Page from '../../components/Page';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/AGPaginatedTable';
import { columnsArray } from './columns';
import { EnquiryNeedStatus, OrderStatus, ProposalStatus } from '../../models/EnquiryNeed';
import Panel from '../../components/Panel';
import TextInput from '../../components/inputs/TextInput';
import { Button, Grid } from '@material-ui/core';
import SingleSelectInput from '../../components/inputs/SingleSelectInput';
import { EnquiryFiltersClient } from './EnquiriesListClients.types';

import {
    getItems as getEnquiries,
    isLoading as isLoadingEnquiry,
    getTotal,
    getDetail,
    getEnquiryNeedStatusDetail,
} from '../../store/Enquiry/selectors';
import {
    fetchEnquiries,
    fetchEnquiryDetail,
    fetchEnquiryDetailUnload,
    fetchEnquiryNeedStatusDetail,
    unloadEnquiryDetailStatusDetail,
} from '../../store/Enquiry/actions';
import { goToEnquireProducts } from '../../store/Router/actions';
import { EnquiryDetailClientDialog } from './EnquiryDetailDialog/EnquiryDetailDialog';
import StatusDetailDialog from '../../components/StatusDetailDialog/StatusDetailDialog';
import { MapOf } from '../../utils/Types';
const orderBy = {
    Enquiry: 'EnquiryNumber',
    Status: 'Status',
    Need: 'Name',
    'Last Update': 'UpdatedAt',
} as MapOf<string>;

const EnquiriesList: React.FC = () => {
    const dispatch = useDispatch();
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const t = useTranslations();

    const [filters, setFilters] = React.useState<EnquiryFiltersClient>({
        pageSize: 10,
        offset: 0,
    });
    const items = useSelector(getEnquiries);
    const needDetail = useSelector(getDetail);
    const total = useSelector(getTotal);
    const isLoading = useSelector(isLoadingEnquiry);
    const statusDetail = useSelector(getEnquiryNeedStatusDetail);
    const [cPage, setCpage] = React.useState(0);

    React.useEffect(() => {
            dispatch(fetchEnquiries(filters));
        //eslint-disable-next-line
        }, []); 

    React.useEffect(() => {
        setHeaderName(t(TK.enquiriesList));
    }, [t, setHeaderName]);

    React.useEffect(() => {
        setCpage(filters.offset! / filters.pageSize);
    }, [filters.offset, filters.pageSize]);

    const handlePageChange = React.useCallback(
        (page: number) => {
            filters.offset = page * filters.pageSize;
            setFilters({ ...filters });
            dispatch(fetchEnquiries(filters));
        },
        [filters, dispatch],
    );

    const getNeedDetail = (id: string) => {
        dispatch(fetchEnquiryDetail(id));
    };

    const getStatusDetail = (id: string) => {
        dispatch(fetchEnquiryNeedStatusDetail(id));
    };
    const colDef = columnsArray(getNeedDetail, getStatusDetail);

    const handlePageSizeChange = React.useCallback(
        (pageSize: number) => {
            filters.offset = 0;
            filters.pageSize = pageSize;
            setFilters({ ...filters });
            dispatch(fetchEnquiries(filters));
        },
        [filters, dispatch],
    );

    const handleSearch = React.useCallback(() => {
        filters.offset = 0;
        dispatch(fetchEnquiries(filters));
    }, [filters, dispatch]);

    const updatedStartSearch = React.useCallback(
        (filters: EnquiryFiltersClient) => {
            dispatch(fetchEnquiries(filters));
        },
        [dispatch],
    );

    const handleDetailDialogClose = () => {
        dispatch(fetchEnquiryDetailUnload());
    };

    const handleStatusDetailDialogClose = () => {
        dispatch(unloadEnquiryDetailStatusDetail());
    };

    const handleEnquiryNeedStatus = React.useCallback(
        (status: string) => {
            filters.enquiryStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    const onSortChanged = React.useCallback(
        // The type of prefFilters
        (sortField: string, sortOrder: string, prefFilters: EnquiryFiltersClient) => {
            let newFilter = JSON.parse(JSON.stringify(prefFilters));
            if (sortField && sortOrder) {
                newFilter.orderBy = orderBy[sortField] + ' ' + sortOrder;
                setFilters(newFilter);
                updatedStartSearch(newFilter);
                return;
            }
            newFilter.orderBy = undefined;
            setFilters(newFilter);
            updatedStartSearch(newFilter);
            return;
        },
        [updatedStartSearch],
    );
    const handleOrderStatus = React.useCallback(
        (status: string) => {
            filters.orderStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    const handleProposalStatus = React.useCallback(
        (status: string) => {
            filters.proposalStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    let filterCleared: EnquiryFiltersClient = JSON.parse(JSON.stringify(filters));

    const clearFilters = React.useCallback(() => {
        filterCleared.search = undefined;
        filterCleared.enquiryStatus = undefined;
        filterCleared.proposalStatus = undefined;
        filterCleared.orderStatus = undefined;
        filterCleared.offset = 0;

        setFilters(filterCleared);

        updatedStartSearch(filterCleared);
    }, [updatedStartSearch, filterCleared]);

    return (
        <Page
            title={t(TK.enquiriesList)}
            actionPanel={
                <Panel>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled={isLoading}
                                options={Object.values(EnquiryNeedStatus)}
                                value={filters.enquiryStatus}
                                placeholder={t(TK.enquiryStatus)}
                                onChange={handleEnquiryNeedStatus}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>
                            <TextInput
                                disabled={isLoading}
                                fullWidth={true}
                                value={filters.search}
                                onChange={(v) => {
                                    filters.search = v ?? undefined;
                                    setFilters({ ...filters });
                                }}
                                placeholder={t(TK.search)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled
                                options={Object.values(ProposalStatus)}
                                placeholder={t(TK.proposalStatus)}
                                value={filters.proposalStatus}
                                onChange={handleProposalStatus}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled
                                options={Object.values(OrderStatus)}
                                placeholder={t(TK.orderStatus)}
                                value={filters.orderStatus}
                                onChange={handleOrderStatus}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={1}>
                            <Button variant="contained" color="primary" onClick={handleSearch} fullWidth>
                                {t(TK.search)}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                size="small"
                                style={{ marginTop: '2.5px', outline: 'none' }}
                                variant="outlined"
                                color="secondary"
                                onClick={clearFilters}
                                disabled={isLoading}
                            >
                                {t(TK.clearFilters)}
                            </Button>
                        </Grid>
                    </Grid>
                </Panel>
            }
            style={{ marginTop: '10rem' }}
        >
            <EnquiryDetailClientDialog handleDialogClose={handleDetailDialogClose} needOpen={needDetail} />
            <StatusDetailDialog
                statusDetail={statusDetail}
                handleStatusDetailDialogClose={handleStatusDetailDialogClose}
            />
            <Panel>
                <Grid container spacing={1} justify="flex-end">
                    <Grid item xs={6} md={4} lg={2}>
                        <Button disabled variant="contained" color="secondary" fullWidth>
                            {t(TK.acceptSelectedProposals)}
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={4} lg={2}>
                        <Button disabled variant="contained" color="primary" fullWidth>
                            {t(TK.createOrderforSelectedItems)}
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={4} lg={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => dispatch(goToEnquireProducts())}
                            fullWidth
                        >
                            {t(TK.createNewEnquiry)}
                        </Button>
                    </Grid>
                </Grid>
                <Table
                    columnsDefinition={colDef}
                    data={items}
                    currentPage={cPage}
                    enablePagination={true}
                    pagination={true}
                    pageSize={filters.pageSize}
                    totalElements={total}
                    totalPages={Math.ceil(total / filters.pageSize)}
                    isExportable={false}
                    isLoading={isLoading}
                    onChangeSorting={onSortChanged}
                    onPageChange={handlePageChange}
                    onFilterChanged={() => {}}
                    onPageSizeChange={handlePageSizeChange}
                    onFreeTextChange={() => {}}
                    filters={filters}
                />
            </Panel>
        </Page>
    );
};

export default EnquiriesList;
