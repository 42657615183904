import { Action } from 'redux';
import { CompanyInfo } from '../../models/CompanyInfo';
import { CompanyInfoErrorAction, CompanyInfoLoadedAction } from './types';
import { AppThunkAction } from '..';
import { isLoadingCompanyInfo } from './selectors';
import { alertError, alertGenericError, alertSuccess, requestServer } from '../Session/actions';
import { getCompanyInfoAsync, putCompanyInfoAsync } from '../../fetch/requests';
import { CompanyInfoError } from '../../models/CompanyInfoError';
import { getTranslation } from '../Translations/selectors';
import { TK } from '../Translations/translationKeys';
import { removeSpaceCharacters } from '../../utils/utils';

export const Actions = {
    companyInfoLoading: '@@whichpharma.companyInfo.loading',
    companyInfoLoadError: '@@whichpharma.companyInfo.loadError',
    companyInfoLoaded: '@@whichpharma.companyInfo.loaded',
    companyInfoUpdate: '@@whichpharma.companyInfo.update',
    companyInfoUpdated: '@@whichpharma.companyInfo.updated',
    companyInfoShowValidationErrors: '@@whichpharma.companyInfo.showValidationErrors',
};

const companyInfoLoading = (): Action => ({
    type: Actions.companyInfoLoading,
});

const companyInfoLoaded = (result: CompanyInfo): CompanyInfoLoadedAction => ({
    type: Actions.companyInfoLoaded,
    result,
});

export const updateCompanyInfo = (newInfo: CompanyInfo) => ({
    type: Actions.companyInfoUpdate,
    result: newInfo,
});

export const companyInfoLoadError = (fields: CompanyInfoError, error?: string): CompanyInfoErrorAction => {
    return {
        type: Actions.companyInfoLoadError,
        error: error,
        fields: fields,
    };
};

export const companyInfoShowValidationErrors = (): Action => ({
    type: Actions.companyInfoShowValidationErrors,
});

export const fetchCompanyInfo = (id: string | null): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingCompanyInfo(getState())) {
                return;
            }
            if (!id) {
                dispatch(companyInfoLoadError({} as CompanyInfoError, 'No company id provided'));
                return;
            }
            dispatch(companyInfoLoading());
            const result = await dispatch(
                requestServer((token, csrfToken) => getCompanyInfoAsync(parseInt(id), token, csrfToken)),
            );

            dispatch(companyInfoLoaded(result));
        } catch (error) {
            console.log(error);
            dispatch(companyInfoLoadError({} as CompanyInfoError, 'An error occurred while fetching company info'));
            dispatch(alertGenericError());
        }
    };
};

export const saveCompanyInfo = (info: CompanyInfo): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingCompanyInfo(getState())) {
                return;
            }
            dispatch(companyInfoLoading());
            await dispatch(
                requestServer((token, csrfToken) =>
                    putCompanyInfoAsync({ ...info, status: removeSpaceCharacters(info.status) }, token, csrfToken),
                ),
            );
            const message = getTranslation(getState(), TK.submitCompanyInfoSuccessMessage);
            dispatch(alertSuccess(message));
            dispatch(companyInfoLoaded(info));
        } catch (error) {
            dispatch(alertError(getTranslation(getState(), TK.OopsSomethingWentWrong)));
            dispatch(companyInfoLoadError({} as CompanyInfoError));
        }
    };
};
