import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    IconButton,
    TableHead,
    Box,
    Switch,
    Checkbox,
    Dialog,
    DialogContent,
    Button,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckIcon from '@material-ui/icons/Check';
import UndoIcon from '@material-ui/icons/Undo';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslations } from '../../../../store/Translations/hooks';
import { TK } from '../../../../store/Translations/translationKeys';
import { ActiveIngredientsTableV3Props } from './ActiveIngredientsTable.types';
import { ActiveIngMappingTableContainer } from './ActiveIngredientsTable.styles';
import PlatformFilters from '../../../../components/Filter';
import { FilterFieldNames } from '../ProcessingSettingsPanel.types';
import { getMyUser } from '../../../../store/Users/selectors';
import { ProcessingPformsSettings } from '../../../../models/ProcessingPformsSettings';
import { ProcessingDciFullSettings } from '../../../../models/ProcessingDciFullSettings';
import { DEFAULT_PAGE_SIZE, NO_INDEX } from '../ProcessingSettingsPanel';
import { savePlatformV3NewItem } from '../../../../store/Platform/actions';
import { MappingName } from '../../../../models/ProcessingSettingsV3';
import MultipleSelectInput from '../../../../components/inputs/MultipleSelectInput';
import { CloseIconWrapper, DialogTitleContainer } from '../ProcessingSettingsPanel.styles';
import TextInput from '../../../../components/inputs/TextInput';

const EMPTY_CATEGORY = { activeSubs: '' };

const ActiveIngredientsTable: React.FC<ActiveIngredientsTableV3Props> = ({
    activeIngredients,
    activeIngredientsFinal,
    originCountry,
    setChanged,
    setTotalNumberOfPages,
    indexToEdit,
    setIndexToEdit,
    onFilterChange,
    setDataToSave,
    setIsApproved,
    isApproved,
}) => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const user = useSelector(getMyUser);

    const [actIngs, setActIngs] = React.useState<ProcessingPformsSettings[]>([]);
    const [originalActIngs, setOriginalActIngs] = React.useState<ProcessingPformsSettings[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [newCategory, setNewCategory] = React.useState<ProcessingDciFullSettings>(EMPTY_CATEGORY);
    const [categoriesArrays, setCategoriesArrays] = React.useState<{ [key: string]: string[] }>();

    React.useEffect(() => {
        setActIngs(activeIngredients.items);
        setOriginalActIngs(activeIngredients.items);
        setTotalNumberOfPages(Math.ceil(activeIngredients.total / DEFAULT_PAGE_SIZE));
    }, [activeIngredients, setTotalNumberOfPages]);

    React.useEffect(() => {
        const actSubs = activeIngredientsFinal?.items?.reduce<string[]>((acc, val) => {
            !acc.includes(val.activeSubs) && acc.push(val.activeSubs);
            return acc;
        }, []);
        setCategoriesArrays({ actSubs });
    }, [activeIngredientsFinal]);

    React.useEffect(() => {
        const data = actIngs.filter((actIng, index) => {
            return actIng?.final?.toString() !== originalActIngs[index]?.final?.toString();
        });
        if (data.length > 0) {
            setChanged(true);
            setDataToSave(data);
        } else {
            setChanged(false);
        }
    }, [actIngs, originalActIngs, setChanged, setDataToSave]);

    const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsApproved(event.target.checked);
    };

    const onClickEditable = (index: number) => {
        setIndexToEdit(index);
    };

    const onClickToAppove = () => {
        setIndexToEdit(NO_INDEX);
    };

    const onClickToUndo = React.useCallback(
        (index: number) => {
            const originalActIng = { ...originalActIngs[index] };
            const actIngsCopy = [...actIngs];
            const newActIngssArr = [...actIngsCopy.slice(0, index), originalActIng, ...actIngsCopy.slice(index + 1)];
            setActIngs(newActIngssArr);
        },
        [actIngs, originalActIngs],
    );

    const handleChange = React.useCallback(
        (index: number) => (newValues: string[]) => {
            const itemIndex = newValues.findIndex((i) => i === 'Add new');
            if (itemIndex >= 0) {
                setOpen(true);
                return;
            }

            const newActIng = { ...actIngs[index], final: newValues };
            const actIngsCopy = [...actIngs];
            const newActIngssArr = [...actIngsCopy.slice(0, index), newActIng, ...actIngsCopy.slice(index + 1)];
            setActIngs(newActIngssArr);
        },
        [actIngs],
    );

    const showUndo = React.useCallback(
        (index: number): boolean => {
            if (actIngs[index].final?.toString() !== originalActIngs[index].final?.toString()) {
                return true;
            } else {
                return false;
            }
        },
        [actIngs, originalActIngs],
    );

    const handleClose = () => {
        setNewCategory(EMPTY_CATEGORY);
        setOpen(false);
    };

    const handleDialogChange = React.useCallback(
        (newProp: { [key: string]: string }) => {
            setNewCategory({ ...newCategory, ...newProp });
        },
        [newCategory],
    );

    const createNew = React.useCallback(() => {
        if (!user?.userName) {
            return;
        }

        const data = { activeIngredient: newCategory.activeSubs, user: user.userName };

        dispatch(
            savePlatformV3NewItem({
                origin: originCountry,
                mappingName: MappingName.ActiveIngredientsFinal,
                data,
            }),
        );

        setNewCategory(EMPTY_CATEGORY);
        setOpen(false);
    }, [newCategory, user, originCountry, dispatch]);

    return (
        <>
            <ActiveIngMappingTableContainer>
                <Typography variant="caption">* {t(TK.specifyTheKeywordsForTheCorrespondingValues)}.</Typography>
                <Box display="flex" justifyContent="end">
                    <Box mr={9} display="flex" alignItems="center">
                        <Typography>Show only new terms</Typography>
                        <Switch
                            color="primary"
                            checked={isApproved}
                            onChange={handleSwitchChange}
                            name="notCommercial"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />

                        <Typography>Total: {activeIngredients.total} records</Typography>
                    </Box>
                </Box>

                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell width="500px">
                                Original
                                <PlatformFilters
                                    elementId="original-popover"
                                    onFilterChange={onFilterChange(FilterFieldNames.Original)}
                                />
                            </TableCell>
                            <TableCell>
                                Translated
                                <PlatformFilters
                                    elementId="translated-popover"
                                    onFilterChange={onFilterChange(FilterFieldNames.Translated)}
                                />
                            </TableCell>
                            <TableCell>
                                Final
                                <PlatformFilters
                                    elementId="final-popover"
                                    onFilterChange={onFilterChange(FilterFieldNames.Final)}
                                />
                            </TableCell>
                            <TableCell>
                                Details
                                <PlatformFilters
                                    elementId="details-popover"
                                    onFilterChange={onFilterChange(FilterFieldNames.User)}
                                />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {actIngs?.map((value, index) => {
                            return (
                                <TableRow key={value.original}>
                                    <TableCell>{value.original}</TableCell>
                                    <TableCell>{value?.translated}</TableCell>
                                    <TableCell>
                                        <Box display="flex">
                                            <MultipleSelectInput
                                                values={value?.final}
                                                options={categoriesArrays?.actSubs || []}
                                                disabled={index !== indexToEdit}
                                                renderOption={(option, { selected }) => {
                                                    if (option === 'Add new') {
                                                        return (
                                                            <div>
                                                                <hr style={{ width: '200px' }} />
                                                                {option}
                                                            </div>
                                                        );
                                                    }
                                                    return (
                                                        <React.Fragment>
                                                            <Checkbox
                                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                                style={{ marginRight: 8 }}
                                                                checked={selected}
                                                            />
                                                            {option}
                                                        </React.Fragment>
                                                    );
                                                }}
                                                showAdd
                                                freeSolo
                                                onChange={handleChange(index)}
                                                style={{
                                                    width: '80%',
                                                    backgroundColor: value.edited
                                                        ? '#c8e1cc'
                                                        : value.closed
                                                        ? '#8080800d'
                                                        : '#ffff0066',
                                                }}
                                            />
                                            {index !== indexToEdit && (
                                                <IconButton
                                                    style={{ marginTop: '6px' }}
                                                    aria-label="edit"
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => {
                                                        onClickEditable(index);
                                                    }}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            )}
                                            {index === indexToEdit && (
                                                <IconButton
                                                    style={{ marginTop: '6px' }}
                                                    aria-label="approve"
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => {
                                                        onClickToAppove();
                                                    }}
                                                >
                                                    <CheckIcon fontSize="small" />
                                                </IconButton>
                                            )}
                                            {index === indexToEdit && showUndo(index) && (
                                                <IconButton
                                                    style={{ marginTop: '6px' }}
                                                    aria-label="approve"
                                                    size="small"
                                                    color="primary"
                                                    onClick={() => {
                                                        onClickToUndo(index);
                                                    }}
                                                >
                                                    <UndoIcon fontSize="small" />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        Last updated by:
                                        <br />
                                        {value?.user}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <Dialog open={open} onClose={handleClose} style={{ width: '100%' }}>
                    <DialogTitleContainer>
                        <CloseIconWrapper>
                            <IconButton onClick={handleClose} style={{ outline: 'none' }}>
                                <HighlightOffIcon color="primary" fontSize="large" />
                            </IconButton>
                        </CloseIconWrapper>
                    </DialogTitleContainer>
                    <DialogContent style={{ width: '500px' }}>
                        <div>
                            <b>Active Ingredient</b>
                            <TextInput
                                style={{ width: '425px' }}
                                value={newCategory.activeSubs}
                                onChange={(val: string) => handleDialogChange({ activeSubs: val })}
                            />
                        </div>

                        <Box display="flex" justifyContent="right">
                            <Box marginTop="10px" marginBottom="10px">
                                <Button
                                    disabled={!newCategory.activeSubs.length}
                                    onClick={createNew}
                                    color="primary"
                                    variant="contained"
                                >
                                    Create
                                </Button>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            </ActiveIngMappingTableContainer>
        </>
    );
};

export default ActiveIngredientsTable;
