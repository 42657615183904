import * as React from 'react';
import Page from '../../components/Page';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import Panel from '../../components/Panel';
import UserForm from '../../components/UserForm';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser, updateUser } from '../../store/Users/actions';
import { getMyUser, isUpdatingUsers } from '../../store/Users/selectors';
import { CreateUser } from '../../models/CreateUser';
import UserAvatar from '../../components/UserAvatar';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';

const Profile: React.FC = () => {
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const t = useTranslations();
    const dispatch = useDispatch();
    const user = useSelector(getMyUser);
    const isUpdating = useSelector(isUpdatingUsers);
    const [hasFetched, setHasFetched] = React.useState<boolean>(false);

    // eslint-disable-next-line
    React.useEffect(() => {
        if (hasFetched && user) {
            dispatch(fetchUser(user.username));
            setHeaderName(t(TK.profile));
            setHasFetched(true);
        }
    }, [dispatch, setHeaderName, t, user, hasFetched]);

    // eslint-disable-next-line
    const handleUserChange = React.useCallback(
        (user: CreateUser) => {
            dispatch(updateUser(user));
        },
        [dispatch],
    );

    return (
        <Page title={t(TK.profile)} style={{ marginTop: '10rem' }}>
            <Panel title={user?.username ? <UserAvatar showName username={user.username} size="large" /> : ''}>
                <UserForm
                    value={{ ...user, password: '' } as CreateUser}
                    isLoading={isUpdating}
                    onChange={handleUserChange}
                    type="edit"
                />
            </Panel>
        </Page>
    );
};

export default Profile;
