export enum TK {
    enquiryCreated = 'Enquiry Created',
    enquiryCreatedMessage = 'Enquiry Number 0000 was created successfully.',
    goTo = 'Go to ',
    myEnquiries = 'My Enquiries',
    or = 'or',
    from = 'from',
    enquiry = 'Enquiry',
    until = 'until',
    enquiryStatus = 'Enquiry Status',
    additionalNotes = 'additionalNotes',
    next = 'next',
    createOrderforSelectedItems = 'Create Order for Selected Items',
    createNewEnquiry = 'Create New Enquiry',
    authorised = 'authorised',
    marketed = 'marketed',
    monitor = 'Monitor',
    unavailable = 'unavailable',
    discontinued = 'discontinued',
    authorisedeu = 'authorisedeu',
    parallelimport = 'parallelimport',
    previous = 'previous',
    loading = 'loading',
    offers = 'offers',
    shortages = 'shortages',
    exchangeRate = 'exchangeRate',
    country = 'Origin',
    countryV2 = 'Country',
    companyName = 'Company Name',
    accessType = 'Access Type',
    refineCountry = 'Filter Origin',
    errorRequest = 'errorRequest',
    countries = 'countries',
    scrapingOrigin = 'Scraping Origin',
    scrapingOriginId = 'Scraping OriginId',
    lastUpdate = 'lastUpdate',
    name = 'Name',
    nameV2 = 'name',
    documents = 'Documents',
    otherDocuments = 'Other Documents',
    supplierReply = 'Supplier reply',
    activeSubstances = 'Active Ingredient',
    atc = 'ATC',
    manufacturer = 'Manufacturer',
    drugForm = 'drugForm',
    pharmaceuticalForm = 'Pharmaceutical Form',
    administration = 'administration',
    administrationForm = 'Administration Route',
    drugFormCategories = 'drugFormCategories',
    administrationFormCategories = 'administrationFormCategories',
    strength = 'Strength',
    package = 'Pack size',
    maNumber = 'maNumber',
    registerConfirmMessage = 'By proceeding, I declare that I am duly authorized by the entity I represent to open an account with wixmed on its behalf',
    maHolder = 'MA holder',
    status = 'Status',
    state = 'state',
    spcFileUrl = 'spcFileUrl',
    pilFileUrl = 'pilFileUrl',
    retailPrice = 'retailPrice',
    reimbursementPrice = 'reimbursementPrice',
    wholesalePrice = 'wholesalePrice',
    factoryPrice = 'factoryPrice',
    exFactoryPrice = 'exFactoryPrice',
    currencyCode = 'currencyCode',
    all = 'all',
    filters = 'filters',
    none = 'None',
    selectedProducts = 'selectedProducts',
    noProductSelected = 'noProductSelected',
    productsSelection = 'productsSelection',
    proceedToRFQCreation = 'proceedToRFQCreation',
    proceedToEnquiryCreation = 'Create Enquiry',
    marketedProducts = 'marketedProducts',
    euProducts = 'euProducts',
    nonEuProducts = 'nonEuProducts',
    proceedToSuppliersSelection = 'proceedToSuppliersSelection',
    proceedToEmailConfiguration = 'proceedToEmailConfiguration',
    productCode = 'productCode',
    packSize = 'packSize',
    packSizeOrig = 'packSizeOrig',
    packSizeLabel = 'Pack Size Label',
    packSizeValue = 'Pack Size Value',
    welcome = 'welcome',
    username = 'username',
    password = 'password',
    confirmPassword = 'confirmPassword',
    haveYouForgottenYourPassword = 'haveYouForgottenYourPassword',
    enter = 'enter',
    areYouSure = 'areYouSure',
    newPasswordWillBeSent = 'newPasswordWillBeSent',
    resetPassword = 'resetPassword',
    cancel = 'cancel',
    somethingWentWrong = 'somethingWentWrong',
    invalidCredentials = 'invalidCredentials',
    passwordEmptyOrInvalid = 'Password empty or invalid',
    emailEmptyOrNotValid = 'Email empty or not valid',
    products = 'products',
    productsProdV = 'Products (stable)',
    productsProdV1 = 'Products (stable V1.0)',
    productsV3 = 'Products (v3.0)',
    logout = 'logout',
    login = 'login',
    users = 'users',
    roles = 'roles',
    userUpdatedSuccessfully = 'userUpdatedSuccessfully',
    userCreatedSuccessfully = 'userCreatedSuccessfully',
    weakPassword = 'weakPassword',
    usernameInUse = 'usernameInUse',
    emailInUse = 'emailInUse',
    invalidUsername = 'invalidUsername',
    invalidEmail = 'invalidEmail',
    invalidPassword = 'invalidPassword',
    addNew = 'addNew',
    update = 'update',
    create = 'create',
    accessRestricted = 'accessRestricted',
    loggedOut = 'loggedOut',
    suppliersSelection = 'suppliersSelection',
    emailsSelection = 'emailsSelection',
    pleaseSelectSupplierForCountry = 'pleaseSelectSupplierForCountry',
    pleaseSelectContactEmailForTheSupplier = 'pleaseSelectContactEmailForTheSupplier',
    id = 'id',
    contacts = 'Contacts',
    classification = 'Classification',
    type = 'Type',
    isEurope = 'isEurope',
    notes = 'Notes',
    expiryDate = 'expiryDate',
    expiryDateSupplier = 'Expiry date',
    creationDateSupplier = 'Creation date',
    lastVerification = 'lastVerification',
    creationDate = 'creationDate',
    selectedSuppliers = 'selectedSuppliers',
    noSupplierSelected = 'noSupplierSelected',
    configurations = 'configurations',
    confirmation = 'Confirmation',
    deselectAll = 'deselectAll',
    rfqs = 'rfqs',

    rfqsV2 = 'RFQs (v2)',
    productsList = 'productsList',
    createRequestForQuote = 'createRequestForQuote',
    rfqsList = 'rfqsList',
    profile = 'profile',
    dontHaveRfqsYetCreateYourFirst = 'dontHaveRfqsYetCreateYourFirst',
    suppliers = 'suppliers',
    active = 'active',
    inactive = 'inactive',
    closed = 'closed',
    noQuote = 'noQuote',
    unviewedReplies = 'unviewedReplies',
    emailContent = 'emailContent',
    user = 'user',
    supplier = 'Supplier',
    product = 'product',
    others = 'others',
    other = 'Other',
    configureEmailContentMessage = 'configureEmailContentMessage',
    price = 'price',
    prices = 'Prices',
    quantity = 'quantity',
    quantityRequested = 'quantityRequested',
    availableAt = 'availableAt',
    lotNumber = 'lotNumber',
    quoteReplyForm = 'quoteReplyForm',
    configureQuoteReplyForm = 'configureQuoteReplyForm',
    mandatoryFields = 'mandatoryFields',
    optionalFields = 'optionalFields',
    noOptions = 'noOptions',
    add = 'add',
    send = 'send',
    createRfqAndSendTheEmails = 'createRfqAndSendTheEmails',
    sendUsingOtherAccount = 'sendUsingOtherAccount',
    rfqCreated = 'rfqCreated',
    original = 'original',
    withVAT = 'withVAT',
    withoutVAT = 'withoutVAT',
    noData = 'noData',
    noColumns = 'noColumns',
    hideColumns = 'hideColumns',
    fromToOf = 'fromToOf',
    rowsPerPage = 'rowsPerPage',
    showAll = 'showAll',
    export = 'export',
    exportVisibleItems = 'exportVisibleItems',
    exportSelectedItems = 'exportSelectedItems',
    dragColumnToGroup = 'dragColumnToGroup',
    sort = 'sort',
    search = 'search',
    advancedSearch = 'advancedSearch',
    emailResetSent = 'If this email is registered you will receive an e-mail to complete the process. Please check your inbox.',
    emailResetSentPart1 = 'A message has been sent to ',
    emailResetSentPart2 = '. Please check your inbox to continue the process and set a new password.',
    clearAllFilters = 'clearAllFilters',
    clearFilters = 'clear',
    yes = 'yes',
    no = 'no',
    productNotes = 'productNotes',
    start = 'start',
    end = 'end',
    minStart = 'minStart',
    maxStart = 'maxStart',
    minEnd = 'minEnd',
    maxEnd = 'maxEnd',
    onlyActive = 'onlyActive',
    partialshortage = 'partialshortage',
    showHideTablePageFilters = 'showHideTablePageFilters',
    contains = 'contains',
    notContains = 'notContains',
    startsWith = 'startsWith',
    endsWith = 'endsWith',
    equal = 'equal',
    notEqual = 'notEqual',
    greaterThan = 'greaterThan',
    greaterThanOrEqual = 'greaterThanOrEqual',
    lessThan = 'lessThan',
    lessThanOrEqual = 'lessThanOrEqual',
    categories = 'categories',
    pil = 'pil',
    spc = 'spc',
    label = 'label',
    file = 'file',
    resetTableSettings = 'resetTableSettings',
    freeTextSearch = ' ',
    freeTextSearchDescription = 'freeTextSearchDescription',
    productCodeOrMANumber = 'productCodeOrMANumber',
    manufacturerOrMAHolder = 'manufacturerOrMAHolder',
    empty = 'empty',
    close = 'close',
    ok = 'ok',
    defaultBehavior = 'defaultBehavior',
    freeTextSearchDefaultBehaviorInfo = 'freeTextSearchDefaultBehaviorInfo',
    mandatoryWords = 'mandatoryWords',
    freeTextSearchMandatoryWordsInfo = 'freeTextSearchMandatoryWordsInfo',
    exactPhrase = 'exactPhrase',
    freeTextSearchExactPhraseInfo = 'freeTextSearchExactPhraseInfo',
    excludeWords = 'excludeWords',
    freeTextSearchExcludeWordsInfo = 'freeTextSearchExcludeWordsInfo',
    email = 'email',
    qualificationFormTabId = 'qualification-form-tabs',
    accountSettings = 'accountSettings',
    rfqNr = 'rfqNr',
    unitQuant = 'unitQuant',
    unUsed = 'unUsed',
    totalUnits = 'totalUnits',
    exwNetPriceEuro = 'exwNetPriceEuro',
    netPriceEuro = 'netPriceEuro',
    priceEuro = 'priceEuro',
    availabilityPacks = 'availabilityPacks',
    availabilityVisuals = 'availabilityVisuals',
    leadTimeToDeliver = 'leadTimeToDeliver',
    expDate = 'expDate',
    countryOfOrigin = 'countryOfOrigin',
    precForStorage = 'precForStorage',
    comments = 'comments',
    productsTables = 'productsTables',
    seeAndConfigureTheTablesInEmails = 'seeAndConfigureTheTablesInEmails',
    sendingTo = 'sendingTo',
    markAsValidated = 'markAsValidated',
    productsToAssociate = 'productsToAssociate',
    pleaseMarkAllProductsTablesAsVerified = 'pleaseMarkAllProductsTablesAsVerified',
    pleaseMakeSureAllRFQsHaveAValidNumber = 'pleaseMakeSureAllRFQsHaveAValidNumber',
    pleaseMakeSureAllRFQsHaveAValidDescription = 'pleaseMakeSureAllRFQsHaveAValidDescription',
    pleaseAssociateAllProductsToAnRFQ = 'pleaseAssociateAllProductsToAnRFQ',
    dragAndDropToAssignProductsToARFQ = 'dragAndDropToAssignProductsToARFQ',
    noProductsSelectedError = 'noProductsSelectedError',
    rfqAssociation = 'rfqAssociation',
    allProductsAreAssociated = 'allProductsAreAssociated',
    theEmailBodyCannotBeEmpty = 'theEmailBodyCannotBeEmpty',
    subject = 'subject',
    default = 'default',
    remove = 'remove',
    saveThisTemplate = 'saveThisTemplate',
    removeThisTemplate = 'removeThisTemplate',
    reset = 'reset',
    autoFillFields = 'autoFillFields',
    selectAccount = 'selectAccount',
    addAccount = 'addAccount',
    firstName = 'firstName',
    lastName = 'lastName',
    creatingRFQsAndSendingEmails = 'creatingRFQsAndSendingEmails',
    rfqsCreatedAndEmailsSent = 'rfqsCreatedAndEmailsSent',
    pleaseVerifyYourOutbox = 'pleaseVerifyYourOutbox',
    mailbox = 'mailbox',
    title = 'title',
    supplierFirstNameMissing = 'supplierFirstNameMissing',
    supplierLastNameMissing = 'supplierLastNameMissing',
    userFirstNameMissing = 'userFirstNameMissing',
    userLastNameMissing = 'userLastNameMissing',
    userEmailMissing = 'userEmailMissing',
    userTitleMissing = 'userTitleMissing',
    productsTableMissing = 'productsTableMissing',
    supplierNotFoundInDatabase = 'supplierNotFoundInDatabase',
    recipientNotFoundInSupplierData = 'recipientNotFoundInSupplierData',
    userNotFoundInDatabase = 'userNotFoundInDatabase',
    emailBodyContainsUnknownPlaceholder = 'emailBodyContainsUnknownPlaceholder',
    unableToConnectToYourEmailAccount = 'unableToConnectToYourEmailAccount',
    platform = 'platform',
    platformV3 = 'platform',
    savePlatformSettings = 'savePlatformSettings',
    saving = 'saving',
    processingSettings = 'processingSettings',
    fields = 'fields',
    specifyProductFieldsWhereInfoCanBeFound = 'specifyProductFieldsWhereInfoCanBeFound',
    specifyTheKeywordsForTheCorrespondingValues = 'specifyTheKeywordsForTheCorrespondingValues',
    units = 'units',
    save = 'save',
    culture = 'culture',
    decimalSeparator = 'decimalSeparator',
    numberGroupSeparator = 'numberGroupSeparator',
    general = 'general',
    copied = 'copied',
    minimumCharacters = 'minimumCharacters',
    reprocessProducts = 'reprocessProducts',
    backupPlatform = 'Save to Backup',
    backupRestorePlatform = 'Restore from Backup',
    reprocessRequestSubmitted = 'reprocessRequestSubmitted',
    pFormCreated = 'pFormCreated',

    streakApiKey = 'streakApiKey',
    open = 'Open',
    supplierWaitingReply = 'supplierWaitingReply',
    openQuotes = 'openQuotes',
    closedQuotes = 'closedQuotes',

    onlyOpen = 'onlyOpen',
    onlyMine = 'onlyMine',
    userIsNotACollaborator = 'userIsNotACollaborator',
    assigneeSuccessfullyUpdated = 'assigneeSuccessfullyUpdated',
    conversations = 'conversations',
    quotes = 'quotes',
    saveChanges = 'saveChanges',
    userIsNotAllowed = 'userIsNotAllowed',
    threadNotFound = 'threadNotFound',
    tableDataSuccessfullyUpdated = 'tableDataSuccessfullyUpdated',
    rfqUpdated = 'rfqUpdated',
    emailsSentButErrorIntegrationWithStreak = 'emailsSentButErrorIntegrationWithStreak',
    emailsSentButErrorSavingInDatabase = 'emailsSentButErrorSavingInDatabase',
    errorAccessingDatabase = 'errorAccessingDatabase',
    errorSendingEmails = 'errorSendingEmails',
    copyFromOther = 'copyFromOther',
    fullList = 'Full List',
    rfqReplyForm = 'rfqReplyForm',
    requestsForQuote = 'requestsForQuote',
    thisReplyFormIsNoLongerAvailable = 'thisReplyFormIsNoLongerAvailable',
    submit = 'submit',
    successfullySubmited = 'successfullySubmited',
    contact = 'contact',
    days = 'days',
    externalLinkForSupplierReplyForm = 'externalLinkForSupplierReplyForm',
    description = 'description',
    desc = 'desc',
    rfqDescription = 'rfqDescription',
    unitPrice = 'unitPrice',
    totalPrice = 'totalPrice',
    packsTotal = 'packsTotal',
    numberOfPacks = 'numberOfPacks',
    requestedPacks = 'requestedPacks',
    addFieldsToBeAutomaticallyFilledInProductsTables = 'addFieldsToBeAutomaticallyFilledInProductsTables',
    fillProductTables = 'fillProductTables',
    codes = 'codes',
    code = 'code',
    pleaseMakeSureAllContactsHaveAValidEmail = 'pleaseMakeSureAllContactsHaveAValidEmail',
    pleaseMakeSureVerifiedAllProducts = 'pleaseMakeSureVerifiedAllProducts',
    youAreSelectingASupplierForACountryWithoutSelectedProducts = 'youAreSelectingASupplierForACountryWithoutSelectedProducts',
    linkWithGoogle = 'linkWithGoogle',
    availableUntil = 'availableUntil',
    imInterested = 'imInterested',
    skip = 'Skip',
    skipped = 'Declined',
    details = 'details',
    offerAlternative = 'offerAlternative',
    offerAlternativeOnDecline = 'offerAlternativeOnDecline',
    declineQuote = 'declineQuote',
    submitOffer = 'submitOffer',
    decline = 'decline',
    iveChangedMyMindAndImInterested = 'iveChangedMyMindAndImInterested',
    changeQuote = 'changeQuote',
    editQuote = 'editQuote',
    accept = 'accept',
    termsAndConditions = 'platTermsAndConditions',
    receiveACopyOfMyOfferByEmail = 'receiveACopyOfMyOfferByEmail',
    toQuote = 'toQuote',
    v1 = 'v1',
    v2 = 'v2',
    interested = 'Open',
    kanbanInterested = 'Interested',
    inProgress = 'inProgress',
    quoted = 'Quoted',
    declined = 'declined',
    Category_Solid = 'solid',
    Category_SemiSolid = 'semiSolid',
    Category_Liquid = 'liquid',
    Category_Gas = 'gas',
    createdBy = 'createdBy',
    updatedBy = 'updatedBy',
    expires = 'expires',
    seeAllYourRFQs = 'seeAllYourRFQs',
    viewAll = 'viewAll',
    dueDate = 'dueDate',
    dueDateMissing = 'dueDateMissing',
    alternative = 'alternative',
    assignToExistingRfq = 'assignToExistingRfq',
    youreTryingToAssignToAnExistingRfqWhichWasntFound = 'youreTryingToAssignToAnExistingRfqWhichWasntFound',
    someoneHasCreatedRfqWithTheSameNumberPleaseTryAgain = 'someoneHasCreatedRfqWithTheSameNumberPleaseTryAgain',
    requestAnAccessLink = 'requestAnAccessLink',
    accessLinkWasSentToYourInbox = 'accessLinkWasSentToYourInbox',
    yourRequestHasBeenRegistered = 'yourRequestHasBeenRegistered',
    thisLinkIsNoLongerAvailable = 'thisLinkIsNoLongerAvailable',
    language = 'language',
    noResults = 'noResults',
    quoteLater = 'quoteLater',
    yourQuote = 'yourQuote',
    whatWeNeed = 'whatWeNeed',
    why = 'why',
    duration = 'duration',
    ifApplicable = 'ifApplicable',
    hide = 'hide',
    includeExprice = 'includeExprice',
    viewOpen = 'viewOpen',
    notInOurRange = 'Not in our range',
    onShortage = 'On shortage',
    notCommercialized = 'Not commercialized',
    authorizationCeased = 'Authorization ceased',
    exportBan = 'Export ban',
    otherPleaseIndicate = 'otherPleaseIndicate',
    ToQualify = 'To Qualify',
    suspended = 'suspended',
    qualify = 'qualify',
    archive = 'Archive',
    generic = 'Generic',
    psychotropic = 'Psychotropic',
    biological = 'Biological',
    additionalMonitoring = 'Additional Monitoring',
    prescription = 'Prescription',
    hospitalar = 'Hospitalar',
    precautionsForStorage = 'Precautions For Storage',
    reason = 'Reason',
    register = 'Register',
    registration = 'REGISTRATION',
    statusNotes = 'Status Notes',

    notAuthorised = 'Not Authorised',

    commercialised = 'Commercialised',
    notCommercialised = 'Not Commercialised',

    unknown = 'Unknown',
    NotAuthorised = 'NotAuthorised', // for color codes
    NotMarketed = 'NotMarketed',
    Marketed = 'Marketed',
    acceptSelectedProposals = 'Accept Selected Proposals',
    shortage = 'Shortage',
    availability = 'Availability',
    additionalInformation = 'Additional Information',
    shareLink = 'Copy link',
    searchAnyColumn = 'Search any column',
    productDescription = 'productDescription',
    additionalCost = 'additionalCost',
    weightedPrice = 'weightedPrice',
    comparison = 'comparison',
    TotalQuotes = 'Total quotes',
    TotalResult = 'Total results',
    pleaseSelectProducts = 'pleaseSelectProducts',
    errorMessageInvalidDate = 'Invalid expDate, should be MM/YYYY or month less than 12',
    errorMessageInvalidYear = 'Invalid date',
    selectSuppliersMessage = 'Please select suppliers for all products!',
    confirmDialogMessage = 'Are you sure you want to delete this?',
    confirm = 'Confirm',
    weAcceptAlt = 'We accept alternative',
    showTableView = 'Table view',
    showKanbanView = 'Kanban view',
    searchKanban = 'Search rfq, pack size, user ...',
    unSavedChanges = 'You have unsaved changes, please save them',
    RFQEnquiry = 'RFQ Enquiry',
    enquiryCreationConfirmationMessage = 'Are you sure you want to submit this enquiry?',
    enquiriesList = 'Enquiries List',
    need = 'Need',
    proposal = 'Proposal',
    proposalStatus = 'Proposal Status',
    order = 'Order',
    orderStatus = 'Order Status',
    createdDate = 'Created Date',
    RFQQuantity = 'Quantity',
    RFQProductCode = 'Product Code',
    RFQOfferInfo = 'Offer Info',
    RFQOrigin = 'Origin',
    RFQPackPrice = 'Pack Price',
    RFQLeadTime = 'Lead Time',
    RFQUnitPriceEuro = 'Unit Price (€)',
    EuroSimbol = '€',
    RFQExpDate = 'Exp. Date',
    RFQComments = 'Comments',
    RFQLastUpdatedBy = 'Last updated by',
    on = 'on',
    RFQAvailableQTT = 'Available QTT',
    Trasporter = 'Trasporter',
    RFQAdditionalCost = 'Additional Cost',
    RFQWeightedPrice = 'Weighted Price',
    SellingPackPrice = ' Selling Pack Price',
    TotalSellingPrice = ' Total Selling Price',
    Margin = 'Margin',
    packs = 'packs',
    priceSimulation = 'Price Simulation',
    approved = 'Approved',
    draft = 'Draft',
    forApproval = 'For Approval',
    createEnquiry = 'Create Enquiry',
    needed = 'Needed',
    moreDetailsAbout = 'More details about the request',
    acceptAlternative = 'Accept Alternative',
    acceptGenerics = 'Accept generics',
    acceptOtherPackSizes = 'Accept other pack sizes',
    singleLot = 'Single lot',
    acceptDifferentCountries = 'Accept different countries',
    additionalDocuments = 'Additional documents',
    selectAnyCountry = 'Select any country',
    selectDocuments = 'Select documents',
    select = 'Select',
    client = 'Client',
    rfqStatus = 'RFQ Status',
    rfq = 'RFQ',
    enquiriesListPharma = 'Enquiries List (Wixmed)',
    acceptDecline = 'Accept/Decline',
    onlyCreatedbyMe = 'Only Created by Me',
    statusDetailOpenMessage = 'Your request is under review.',
    statusDetailAcceptedMessage = 'You will be contacted with a proposal for this product',
    acceptNeed = 'Accept Need',
    declineNeed = 'Decline Need',
    reasonWhy = 'Reason why',
    notesToClient = 'Notes to Client',
    submitEnquiry = 'Submit Enquiry',
    findMedicines = 'Find',
    getQuotes = 'Quote',
    trackStock = 'Buy',
    connectWithGlobalSuppliers = 'Connect with Global Suppliers',
    allInOnePlatform = 'All in One Platform',
    customer = 'Customer',
    notFound = 'Page not found',
    goToHome = 'Go to Home',
    noOffers = 'There are no offers to show at the moment.',
    noCompanies = 'There are no companies associated with Internal Wixmed users.',
    welcomeWixmed = 'Welcome to Wixmed',
    definePassword = 'Please define a Password',
    registrationCompletedSuccess = 'Your account is now enabled',
    resetPasswordSuccess = 'Your password is now changed',
    passwordRole = 'Must contain a capital letter, a number and at least 8 characters',
    excludingCharactersRole = `Excluding (&,",',<,>, *)`,
    confLinkNotValid = 'Your confirmation link is no longer valid',
    resLinkNotValid = 'Your reset link is no longer valid',
    passwordError = 'Password empty or invalid',
    passwordMustMatch = 'Password must match',
    countryNeedToBeFilled = 'Country need to be filled',
    companyNameNeedToBeFilled = 'Company Name need to be filled',
    tooltipTabCapVials = 'e.g.: tablets/capsules/vials',
    accountDetails = 'Account Details',
    companyDetails = 'Company Details',
    characterization = 'Characterization',
    financialInformation = 'Financial Information',
    companyInfo = 'Company Info',
    registrationNumberVAT = 'VAT Registration Number',
    phone = 'Phone',
    officeAddress = 'Office Address',
    postalCode = 'Postal Code',
    website = 'Website',
    companyContactPerson = 'Company Contact Person',
    position = 'Position',
    saveCompanyInformation = 'Save Company Information',
    saveCompanyInformationMessage = 'Are you sure you want to save the added information?',
    mandatoryField = 'This field is required',
    comingSoon = 'Coming soon',
    registrationComplete = 'Registration Complete',
    thankYouForSigningUpOnWixmed = 'Thank you for signing up on wixmed!',
    youWillReceiveAConfirmationEmailShortlyPleaseFollowTheInstructionsInTheEmailToFinalizeYourRegistration = 'You will receive a confirmation email shortly. Please follow the instructions in the email to finalize your registration.',
    financialContactPerson = 'Financial Contact Person',
    bankDetails = 'Bank Details',
    bankName = 'Bank Name',
    bankAddress = 'Bank Address',
    iban = 'IBAN',
    swiftCodeBic = 'Swift Code / Bic',
    ibanNotValid = 'IBAN not valid',
    paymentTerms = 'Payment Terms',
    specifyPaymentTerms = 'Please specify payment terms',
    companiesList = 'Companies List',
    accountType = 'Account Type',
    vatNumber = 'VAT Number',
    totalCompanies = 'Companies',
    NotQualified = 'Not Qualified',
    New = 'New',
    Qualified = 'Qualified',
    invoicingAddress = 'Invoicing Address',
    companyType = 'Company Type',
    specifyCompanyType = 'Please specify company type',
    licenses = 'Licenses',
    responsiblePersonTitle = 'Responsible Person (RP) or Quality Manager',
    deliveryInfo = 'Delivery Info',
    pharmaCovigilance = 'Pharmacovigilance',
    art23Question = 'Is your company under the scope of  Art.23 of the Delegated Regulation 2016/161 regarding the Falsified Medicines Directive?',
    art23QuestionIfYes = 'If you select "Yes", the serial numbers of the medicines will be decomissioned (inactivated) by Wixmed',
    art23QuestionAnswerYes = 'Yes - Wixmed deactivates',
    art23QuestionAnswerNo = 'No - Wixmed does not deactivates',
    art23Details = 'Art.23 Details',
    sameAsInvoincingAddress = 'Same address as invoincing address',
    sameAsWarehouseAddress = 'Same address as warehouse address',
    openingHours = 'Opening Hours',
    closingHours = 'Closing Hours',
    remarksLunch = 'Remarks (eq. lunch time,...)',
    sameAsRP = 'Same as Responsible Person (RP) or Quality manager',
    warehouseInfo = 'Warehouse Info',
    deliveryAddress = 'Delivery address',
    warehouseAddress = 'Warehouse address',
    internalInfo = 'Internal Information',
    summary = 'Summary',
    wixmedInternalInfo = 'Wixmed Internal Info',
    paymentTermsAs = 'Payment terms as',
    both = 'Supplier & Customer',
    transportResponsibility = 'Transport Responsibility',
    reasonForStatusChange = 'Enter reason for status change',
    overduePayments = 'Overdue Payments',
    internalId = 'Internal ID',
    rating = 'Rating',
    oddoId = 'Oddo ID',
    entityType = 'Entity Type',
    selectEntityType = 'Select Entity Type',
    deliveryTerms = 'Delivery Terms',
    selectDeliveryTerms = 'Select Delivery Terms',
    vatTable = 'VAT Table',
    selectVatTable = 'Select VAT Table Option',
    paymentTermsConditions = 'Payment Terms Conditions',
    selectPaymentTerms = 'Select Payment Terms',
    submitCompanyDetailsSuccessMessage = 'Your information has been successfully submitted! Our quality team will review it, and you will be notified once the process is complete.',
    noDataFromServer = 'No data returned from the server.',
    OopsSomethingWentWrong = 'Oops, something went wrong.',
    submitCompanyInfoSuccessMessage = 'Company info saved',

    // Qualification Form
    qualificationForm = 'Qualification Form',
    na = 'N/A',
    carrierName = 'Name of the Carrier',
    enterCarrierName = 'Please Enter Name of the Carrier',
    carrierCertificates = "Carrier's Certificates",
    enterCertificate = 'Please enter Certificate',
    inpectionDate = 'Last inspection',
    selectTR = 'Select Transport Responsibility',
    noQuestions = 'No questions available',
    carrierCertificateDesc = 'Please enter Certificates',

    // Qualification Form Sections
    QMS = 'Quality Management System',
    TCQ = 'Transport Company Qualification',
    Procedures = 'Procedures',
    HR = 'Human Resources',
    Facilities = 'Facilities',
    DM = 'Document Management',
    Operations = 'Operations',
    Complaints = 'Complaints',
    SA = 'Subcontracted activities',
    ASI = 'Audits and Self-inspections',
    TR = 'Transport Responsibility',

    // Qualification Form Questions
    // Section - Quality Management System (QMS)
    'QMS_ISO9001' = 'Do you have a QMS implemented in accordance with the ISO 9001 standard?',
    'QMS_QualityManual' = 'Does the company have a Quality Manual or equivalent document?',
    'QMS_QualityIntegrity' = 'Does the QMS establish clear procedures to ensure that the product supplied maintains its quality and integrity during storage and / or transportation?',
    'QMS_AnalysisEvaluationCI' = 'Are the processes subject to analysis, evaluation and continuous improvement?',
    'QMS_AuthorityResponsibilities' = 'Does the company have a quality manager with authority and responsibilities to ensure the maintenance of the QMS?',
    'QMS_GDPFMD' = 'Are the reception, storage and distribution or export of medicines carried out in a manner compatible with the requirements of good distribution practices (GDP) in force and in compliance with the FMD directive (if applicable)?',
    'QMS_NonConformities' = 'Are non-conformities documented and their causes evaluated and corrected?',
    'QMS_RiskManagement' = 'Does the company take appropriate corrective actions to amend deviations and prevent them in line with the principles of risk management?',
    'QMS_SubcontractingQualification' = 'In the case of resorting to subcontracting, is there prior qualification of the entity contracted to perform the activity?',
    'QMS_RiskAssessment' = 'Does the company carry out a risk assessment plan through a systematic process of assessing, controlling, communicating and reviewing risks to the quality of medicines and taking into account patient protection?',
    'QMS_SubcontractedEntities' = 'Does the company monitor the performance of subcontracted entities?',
    'QMS_FormalProcess' = 'Does the company have a formal process to review and monitor the QMS?',
    'QMS_BCP' = 'Does the company have a Business Continuity Plan (BCP)?',
    'QMS_ChangeControl' = 'Does the company have a change control procedure?',
    'QMS_MedicineRecall' = 'Is there a procedure for managing recalls of medicines?',

    // Section - Transport Company Qualification (TCQ)
    'TCQ_CarrierName' = 'Name of the Carrier',
    'TCQ_CarrierCertificateType' = "Carrier's certificates type",
    'TCQ_CarrierCertificateDesc' = "Carrier's certificates Desc",
    'TCQ_InspectionDate' = 'Date of last inspection',

    //  SubSection - Facilities
    'TCQ_F_ISO9001' = 'Does the company have a Quality Management System implemented in accordance with the ISO 9001 standard?',
    'TCQ_F_QualityManual' = 'Does the company have a Quality Manual or equivalent document?',
    'TCQ_F_Facilities' = 'Does the company have facilities and vehicles to carry out contracted activities, preventing products from being exposed to conditions that could affect their quality?',
    'TCQ_F_TemperatureControl' = 'Does the company have temperature-controlled vehicles?',
    'TCQ_F_RBPharmaGoods' = 'Does the company have staff with competence and experience to ensure the correct handling, safety and integrity of RB PHARMA goods?',
    'TCQ_F_TransportPlatform' = 'When the transport route includes unloading and reloading in transit on a transport platform, do you control the temperature and cleaning and pest control in intermediate storage facilities?',

    //  SubSection - Procedures
    'TCQ_P_TransportQuality' = 'Does the company have internal procedures to assure that the transported product maintain the quality and integrity during the entire period of transport?',
    'TCQ_P_TrainingPlan' = 'Does the company have an employee training plan and keep records of the training carried out?',
    'TCQ_P_DistributionVehicles' = 'Does the company have maintenance records for distribution vehicles, including cleanliness and safety precautions?',
    'TCQ_P_EquipmentCalibration' = 'Does the company perform annual calibration and maintenance of equipment for monitoring the temperature of transport vehicles?',
    'TCQ_P_Temperature72Hours' = 'Does the company provide temperature and humidity records for transporting RB PHARMA goods up to 72 hours?',
    'TCQ_P_WorkingDays' = 'Does the company respond to complaints within five working days (after receiving the complaint) with a resolution proposal?',
    'TCQ_P_PeriodicAudits' = 'Does the company provide, when requested, records of employee training, improvement and corrective actions, pest control, cleaning and other documents, for periodic audits?',

    // Section - Human Resources (HR)
    'HR_GDPHumanUSer' = 'Does the Responsible Person have the appropriate qualifications and experience in the field of GDP for Medicines for Human Use?',
    'HR_RPAlwaysReachable' = 'Is the Responsible Person always reachable?',
    'HR_AuthorityAutonomy' = 'Does the Responsible Person have the authority and autonomy to make decisions regarding his responsibilities?',
    'HR_DefinitionOfRoles' = 'Does the company have an organizational chart with a written definition of roles, responsibilities and interconnection between all personnel (including replacements)?',
    'HR_TrainingPlan' = 'Does the company have a training plan documented and adequate to the functions performed?',
    'HR_Products' = 'Is there a job description for personnel who handle and work directly with products and / or who play a strategic role?',
    'HR_DistributionGDP' = 'Does the staff involved in distribution activities have training and experience on GDP?',
    'HR_TrainingProduct' = 'Does the staff have training on product identification and prevention of falify medicines into supply chain?',
    'HR_SpecialConditions' = 'Does the staff have training on special handling conditions (dangerous, radioactive, narcotic, psychotropic and cold products)?',

    // Section - Facilities
    'Facilities_EnvParameters' = 'Is the company able to keep the facilities clean and within the proper environmental parameters?',
    'Facilities_ControlAccess' = 'Is the storage area separate and has controlled access to authorized personnel only?',
    'Facilities_IntrusionAlarm' = 'Is there an intrusion alarm system?',
    'Facilities_AwaitingDecision' = 'Are the products awaiting a decision as to their destination or those removed from saleable stock physically and informatically separated?',
    'Facilities_ThirdCountry' = 'Are medicines from a third country that are not destined for the EU physically separated?',
    'Facilities_RejectMedicines' = 'Are falsified, expired, recalled and rejected medicines physically separated in clearly identified areas?',
    'Facilities_SeparatedAreas' = 'Are the reception, shipping and storage areas clearly separated?',
    'Facilities_IncomingOutgoing' = 'Are there procedures for controlling incoming and outgoing goods?',
    'Facilities_CleaningPrograms' = 'Are there cleaning programs, instructions and records?',
    'Facilities_Contamination' = 'Are equipment and cleaning agents suitable so that they do not represent a source of contamination?',
    'Facilities_PestPlague' = 'Is there a preventive pest and plague control program?',
    'Facilities_LockerRooms' = 'Are the locker rooms, changing rooms and rest areas clearly and adequately separated from the storage areas?',
    'Facilities_StorageTemperature' = 'Is there adequate temperature monitoring equipment to check environmental storage conditions?',
    'Facilities_ExtremesTemperatures' = 'Is the temperature monitoring equipment located in the areas where the temperature extremes were recorded?',
    'Facilities_CalibratedEquipment' = 'Is the equipment used to monitor the environmental conditios calibrated at defined intervals?',
    'Facilities_AlertSystems' = 'Are there adequate alarm systems to alert whenever there are deviations from pre-defined storage conditions?',
    'Facilities_ColdTemperature' = 'In case of deviation of temperature or mouist in the cold rooms, when the company is closed, is there a remote warning system (eg sms, etc.)?',
    'Facilities_ComputerDetail' = 'Is there a detailed description (diagrams, principles, objectives, security measures, scope of the system and main characteristics) of the computer system?',
    'Facilities_OwnerOperation' = 'If the facilities are not directly operated by the owner, is there a quality (written) agreement?',
    'Facilities_ComputerAccess' = 'Are access levels defined for the use of the computer system?',
    'Facilities_WarehouseSystem' = 'Were the warehouse management systems validated?',
    'Facilities_ComputerBackup' = 'Is the computer data safe through an adequate and perfectly defined back-up methodology?',
    'Facilities_Backup5Years' = 'Are the backups kept for a minimum of five years, in a separate and safe place?',
    'Facilities_ComputerFailure' = 'Are procedures defined to be followed in case of failure or malfunction of the computer system?',

    // Section - Document Management (DM)
    'DM_SystemApproval' = 'Does the existing document management system ensure that documents are approved or re-approved, by whom is entitled, as to their adequacy before being issued?Are the locker rooms, changing rooms and rest areas clearly and adequately separated from the storage areas?',
    'DM_Availability' = 'Does document management system ensure that the documents enforce are available on site and for the people who need them?',
    'DM_Identifiable' = 'Does document management system ensure that documents remain legible and identifiable?',
    'DM_Obsolete' = 'Does document management system ensure that obsolete documents are not used?',
    'DM_AllTransactions' = 'Are records kept for all transactions of entry, exit or intermediation of medicines?',
    'DM_RecordInfo' = 'Do the records contain date, name of the medicine, quantity received / supplied, name and address of the supplier / customer / recipient, batch number and expiration date?',
    'DM_Medicine5Years' = 'Are records related to medicines kept for at least 5 years?',

    // Section - Operations
    'Operations_Methodology' = 'Does the company have a defined methodology that ensures that product suppliers, before being resourced, are subject to a qualification and approval process?',
    'Operations_ReviewedQualification' = 'Is the qualification and approval of suppliers periodically reviewed?',
    'Operations_Irregularities' = 'Does the company monitor and investigate any irregularities in the sales patterns of controlled substances?',
    'Operations_DamageMedicines' = 'Do the reception procedures ensure that the medicines received come from authorized suppliers and that they were not damaged during transport?',
    'Operations_SpecialStorage' = 'Reception procedures give priority to medicines that require special storage or safety measures, i.e. cold products?',
    'Operations_StockRotation' = 'Does the warehouse management system ensure that stock rotation takes place according to “first expire, first out”?',
    'Operations_ContactFloor' = 'Does the storage system used ensure that medicines are not stored in direct contact with the floor?',
    'Operations_2MonthExpiration' = 'Are medicines that have less than two months of expiry date immediately removed from saleable stocks (both physical and informatic)?',
    'Operations_InventoryCounts' = 'Does the company perform inventory counts and investigate differences, if any?',
    'Operations_MedicineDestruction' = 'Does the company have a written procedure to identify, separate and treat the medicines that are going to be destroyed?',
    'Operations_Shipments' = "All shipments are accompanied by a document indicating the date, the name and the pharmaceutical form of the medicine, the batch number, quantity supplied, name and address of the supplier, name and address of the recipient's place of delivery and applicable conditions of transport and storage?",

    // Section - Complaints
    'Complaints_Methodology' = 'Does the company a defined complaints management system and handling methodology?',
    'Complaints_MAH' = 'Do complaints related to the quality of a medicine give rise to a warning / information to the manufacturer and / or the MAH?',
    'Complaints_Responsible' = 'Is it defined who is responsible for managing and handling complaints?',
    'Complaints_CorrectiveActions' = 'Following complaints, are corrective and / or preventive actions triggered?',
    'Complaints_ProductTreatment' = 'Does the treatment given to products that have been returned take into account the type of medicine, the specific storage conditions and the period of time since the medicine was shipped?',
    'Complaints_ReturnPolicy' = 'Are medicines that left the premises only returned to saleable stocks if they are in their unopened and unaltered secondary packaging (unbroken ATD) and are in good condition, within the expiration date and have not been withdrawn from the market?',
    'Complaints_ReturnHandling' = 'Are return medicines only placed in saleable stocks if it has been demonstrated by the customer that the medicines have been transported, stored and handled in accordance with the storage requirements that apply to them?',
    'Complaints_Evaluation' = 'Are return medicines only only placed in saleable stocks if they have been examined and evaluated by the Responsible Person or by a person designated by him, with the appropriate training and competence?',
    'Complaints_Verification' = 'Are return medicines only only placed in saleable saleable stocks if the distributor has reasonable evidence that the product has been supplied to that customer, that the batch number of the medicines and the respective quantities corresponds to that supplied and that there is no reason to believe that the product has been falsified?',
    'Complaints_ColdChain' = 'Are cold chain medicines only returned to saleable stocks if there is documented evidence that they were stored in suitable storage conditions throughout this period?',
    'Complaints_FalsifiedStorage' = 'The procedure defined for the treatment of falsified medicines provides that the company must immediately inform the health authorities and the marketing authorization holder of medicines that identify or suspect they are falsified medicines?',
    'Complaints_FalsifiedMedicines' = 'Are falsified medicines detected in the supply chain immediately separated physically from all other medicines and stored in a specific area?',
    'Complaints_StartQuickly' = 'Can recall operations start quickly and at any time?',
    'Complaints_RecallRecords' = 'Are recall operations recorded at the moment they are carried out?',
    'Complaints_FinalReport' = 'Does the recall process give rise to a final report?',

    // Section - Subcontracted Activities (SA)
    'SA_Subcontract' = 'The company subcontracts some activities',
    'SA_GDP' = "Does the company assess the subcontractor's competence to perform the required work and to comply with GDP principles and guidelines?",
    'SA_Audits' = 'Is the subcontractor subject to an audit before starting the subcontracted activities?',
    'SA_WrittenContracts' = 'Are there written contracts between the contracting entity and the contractor?',

    // Section - Audits and Self-Inspections (ASI)
    'ASI_GDP' = 'Has the company defined an audit / self-inspection plan, which covers aspects of GDP, compliance with regulations, guidelines and procedures?',
    'ASI_ImpartiallyAudit' = 'Are audits / self-inspections carried out impartially and in detail by competent company employees appointed for this purpose?',
    'ASI_AuditReports' = 'Do the audits / self-inspections give rise to a report with the observations detected during the performance?',
    'ASI_CorrectiveActions' = 'Following audits / self-inspections, are there corrective and / or preventive actions triggered?',

    // Section - Trasnport Responsability
    'TR_TRName' = 'Transport Responsibility Name',
    'TR_Methodology' = 'Does the methodology defined for transport ensure that medicines are protected against breakage, tampering and theft, and is it ensured that temperature conditions are kept within acceptable limits during transport?',
    'TR_TemperatureDeviation' = 'If there is a temperature deviation during transport, is it investigated and documented and communicated to the marketing authorization holder, in order to assess the potential impact on product quality?If there is a temperature deviation during transport, is it investigated and documented and communicated to the marketing authorization holder, in order to assess the potential impact on product quality?',
    'TR_QualityCommunication' = 'If you suspect that the quality of the product may have been affected, is this information communicated by the distributor to the MAH?',
    'TR_VehicleEquipment' = 'Does the company ensure that the vehicles and equipment used to distribute, store or handle the medicines are suitable for their use and are properly equipped to prevent products from being exposed to conditions that could affect their quality and the integrity of the packaging?',
    'TR_Maintenance' = 'Are there written procedures in place for the operation and maintenance of vehicles and equipment involved in the distribution process, including cleaning and safety precautions?',
    'TR_TemperatureMonitoring' = 'Is the equipment used to monitor the temperature during transport in vehicles and / or containers subjected to maintenance and calibrated regularly at least once a year?',
    'TR_NonSpecificVehicles' = 'When vehicles and equipment not specifically intended for this purpose are used, are there procedures to ensure that the quality of the medicine will not be compromised?',
    'TR_IntermediateStorage' = 'When the transport route includes unloading and reloading or storage in transit on a transport platform, is special attention paid to temperature control, cleaning, pest control and the safety of all intermediate storage facilities?',
    'TR_AdverseEffects' = 'Are medicines transported in containers that have no adverse effects on product quality and that offer adequate protection against external factors, including contamination?',
    'TR_LabelInformation' = 'Do the containers and packaging boxes contain labels that provide sufficient information about handling and storage requirements and precautions to ensure that products are always handled correctly and are always safe?',
    'TR_IdentificationBoxes' = 'Does the packaging boxes allow identification of their contents and origin?',
    'TR_SpecialConditions' = 'In deliveries containing medicines that require special conditions, such as controlled substances, does the company maintain a secure supply chain for these products, in accordance with the requirements of the legislation in force?',
    'TR_DeliverySpecialConditions' = 'Are there any additional control systems for the delivery of the medicines that required special conditions? And if so is there a protocol in place to deal with the possibility of any theft?',
    'TR_RadioactiveMaterials' = 'Are medicines made up of very active or radioactive materials transported in safe containers and vehicles specially dedicated to this effect?',
    'TR_TemperatureSensitive' = 'In the case of temperature-sensitive medicines, is qualified equipment (thermal packaging, containers or vehicles with controlled temperature) used to ensure that the correct transport conditions between the manufacturer, the company and the customer are maintained?',
}
