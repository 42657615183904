import * as React from 'react';
import {
    CertificateOptions,
    FreeValueIds,
    Question,
    SectionOptions,
    TransportResponsibilityOptions,
} from '../../../models/QualificationForm';
import { TextField } from '@material-ui/core';
import { BoxFlexBetween } from './styles';
import { useTranslations } from '../../../store/Translations/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getQualificationFormToUpdate, getShowValidationErrors } from '../../../store/QualificationForm/selectors';
import { qualificationFormLoadError, updateQualificationForm } from '../../../store/QualificationForm/actions';
import { TK } from '../../../store/Translations/translationKeys';
import TextInput from '../../inputs/TextInput';
import SingleSelectInput from '../../inputs/SingleSelectInput';
import { BoxWidth } from '../Sections/styles';
import ErrorSpan from '../../ErrorSpan';
import { removeSpaceCharacters } from '../../../utils/utils';
interface FreeValueQuestionProps {
    question: Question;
    readonly?: boolean;
    errors: string[];
    listRef?: any;
}

const FreeValueQuestion: React.FC<FreeValueQuestionProps> = ({ question, readonly, errors, listRef }) => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const questionsToUpdate = useSelector(getQualificationFormToUpdate);
    const showErrors = useSelector(getShowValidationErrors);

    const carrieNameValue = questionsToUpdate.find((q) => q.questionId === FreeValueIds.CarrierName)?.value;
    const carrierCertificateTypeValue = questionsToUpdate.find(
        (q) => q.questionId === FreeValueIds.CarrierCertificateType,
    )?.value;
    const certificateDescValue = questionsToUpdate.find((q) => q.questionId === FreeValueIds.CarrierCertificateDesc)
        ?.value;
    const trValue = questionsToUpdate.find((q) => q.questionId === FreeValueIds.TRName)?.value;
    const inspectionDateValue = questionsToUpdate.find((q) => q.questionId === FreeValueIds.InspectionDate)?.value;

    // Scroll to the last question
    React.useEffect(() => {
        if (trValue === TransportResponsibilityOptions.supplier && listRef.current) {
            if (window.scrollY === 0 || listRef.current.state.scrollDirection === 'backward') return;
            listRef.current.scrollToItem(questionsToUpdate.length - 1);
        }
    }, [trValue, listRef, questionsToUpdate]);

    const onQuestionChange = (valueOrEvent: string | React.ChangeEvent<HTMLInputElement>, questionId: string) => {
        if (!valueOrEvent) valueOrEvent = '';
        const value = typeof valueOrEvent === 'string' ? valueOrEvent : (valueOrEvent.target as HTMLInputElement).value;

        // Clear the error for this question if the value is updated
        if (errors.includes(question.id)) {
            dispatch(qualificationFormLoadError(errors.filter((id) => id !== questionId)));
        }

        const updatedQuestions = [
            ...questionsToUpdate.filter((q) => q.questionId !== questionId),
            { questionId: questionId, value: valueOrEvent === '' ? null : value, section: question.section },
        ];

        dispatch(updateQualificationForm(updatedQuestions));
    };

    const onCarrirerNameChange = (value: string) => {
        onQuestionChange(value, FreeValueIds.CarrierName);
    };

    const onCertificateChange = (value: string) => {
        onQuestionChange(removeSpaceCharacters(value), FreeValueIds.CarrierCertificateType);
    };

    const onInspectionDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onQuestionChange(event.target.value, FreeValueIds.InspectionDate);
    };

    const onTransportResponsibilityChange = (value: string) => {
        onQuestionChange(value, FreeValueIds.TRName);
    };

    const onDescriptionChange = (value: string) => {
        if (carrierCertificateTypeValue !== CertificateOptions.other) value = '';
        onQuestionChange(value, FreeValueIds.CarrierCertificateDesc);
    };

    return (
        <>
            {question.section === SectionOptions.TCQ && (
                <BoxFlexBetween>
                    <BoxWidth width="30%">
                        <TextInput
                            value={carrieNameValue || ''}
                            label={t(TK.carrierName)}
                            placeholder={t(TK.enterCarrierName)}
                            onChange={onCarrirerNameChange}
                            disabled={readonly}
                            fullWidth
                        />
                    </BoxWidth>

                    <BoxWidth width="25%">
                        <SingleSelectInput
                            options={Object.values(CertificateOptions)}
                            value={carrierCertificateTypeValue || ''}
                            label={t(TK.carrierCertificates)}
                            placeholder={t(TK.enterCertificate)}
                            onChange={onCertificateChange}
                            disabled={readonly}
                        />
                    </BoxWidth>

                    {carrierCertificateTypeValue === CertificateOptions.other && (
                        <BoxWidth width="25%">
                            <TextInput
                                value={certificateDescValue || ''}
                                placeholder={t(TK.carrierCertificateDesc)}
                                onChange={onDescriptionChange}
                                fullWidth
                                disabled={readonly}
                            />
                        </BoxWidth>
                    )}
                    <BoxWidth width="25%">
                        <TextField
                            id="date"
                            label={t(TK.inpectionDate)}
                            type="date"
                            onChange={onInspectionDateChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disabled={readonly}
                            defaultValue={inspectionDateValue || ''}
                        />
                    </BoxWidth>
                </BoxFlexBetween>
            )}

            {question.id === FreeValueIds.TRName && question.section === SectionOptions.TR && (
                <BoxWidth width="25%">
                    <SingleSelectInput
                        options={Object.values(TransportResponsibilityOptions)}
                        value={trValue}
                        label={t(TK.transportResponsibility) + '*'}
                        placeholder={t(TK.selectTR)}
                        onChange={onTransportResponsibilityChange}
                        disabled={readonly}
                        required
                    />
                    {showErrors && errors.includes(FreeValueIds.TRName) && <ErrorSpan label={t(TK.mandatoryField)} />}
                </BoxWidth>
            )}
        </>
    );
};

export default FreeValueQuestion;
