import * as React from 'react';
import { getMyRoles } from '../../store/Users/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { UserRole } from '../../models/UserRole';
import { goTo, goToHome, goToLogin } from '../../store/Router/actions';
import { isLoggedOutOrSessionExpired } from '../../store/Session/selectors';
import { routes } from '../../store/Router/routes';

export interface RestrictedComponentProps {
    Component: React.ReactType;
    requiresLogin?: boolean;
    requiresLogout?: boolean;
    requiresRoles?: UserRole[];
    notAllowedElement?: React.ReactElement;
}

const RestrictedComponent: React.FC<RestrictedComponentProps> = ({
    requiresLogin,
    requiresLogout,
    requiresRoles,
    notAllowedElement,
    Component,
    ...props
}) => {
    const dispatch = useDispatch();
    const roles = useSelector(getMyRoles);
    const loggedOutOrSessionExpired = useSelector(isLoggedOutOrSessionExpired);

    React.useEffect(() => {
        if (requiresLogin && loggedOutOrSessionExpired) {
            dispatch(goToLogin());
        } else if (requiresLogout && !loggedOutOrSessionExpired) {
            dispatch(goToHome());
        }
    }, [dispatch, loggedOutOrSessionExpired, requiresLogin, requiresLogout]);

    const isAllowed = () => {
        if (!requiresRoles) return true;
        return roles.some((role) => role === UserRole.Administrator || requiresRoles.includes(role));
    };
    /* if user is allowed to see the page due to user roles, redirect user to 404 page*/
    if (!isAllowed()) {
        dispatch(goTo(routes.notFound));
    }
    /*Only will load component if user was permissions*/
    return <Component {...props} />;
};

export default RestrictedComponent;
