import { Box, BoxProps, Card, Typography } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import styled from 'styled-components';

export const StikyDiv = styled.div`
    position: sticky;
    top: 0;
`;
export const Label = styled(Typography)`
    color: 'black';
    font-weight: bold;
`;
export const BoxFlex = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1%;
`;
export const BoxFlexStart = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1%;
`;

export const BoxWidth = styled(Box)<BoxProps & { width: string }>`
    width: ${(p) => p.width};
    padding: 0 1%;
`;

export const CardStyled = styled(Card)`
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    margin: 1%;
`;

export const BoldSpan = styled.span`
    font-weight: bolder;
`;

export const DivTab = styled.div`
    display: flex;
    align-items: center;
`;

export const CloseOutlinedErrorIcon = styled(CloseOutlined)`
    color: ${({ theme }) => theme.colors.error};
`;
