import * as React from 'react';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import { ActiveIngredientsFinalTableV3Props } from './ActiveIngredientsFinalTable.types';
import { ActiveSubFullListContainer } from './ActiveIngredientsFinalTable.styles';
import PlatformFilters from '../../../../components/Filter';
import { FilterFieldNames } from '../ProcessingSettingsPanel.types';
import { DEFAULT_PAGE_SIZE } from '../ProcessingSettingsPanel';

const ActiveIngredientsFinalTable: React.FC<ActiveIngredientsFinalTableV3Props> = ({
    activeIngredientsFinal,
    onFilterChange,
    setTotalNumberOfPages,
}) => {
    React.useEffect(() => {
        setTotalNumberOfPages(Math.ceil(activeIngredientsFinal.total / DEFAULT_PAGE_SIZE));
    }, [activeIngredientsFinal, setTotalNumberOfPages]);

    return (
        <ActiveSubFullListContainer>
            <div>Total: {activeIngredientsFinal.total}</div>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Active Sub
                            <PlatformFilters
                                elementId="activeSub-popover"
                                onFilterChange={onFilterChange(FilterFieldNames.ActiveSubs)}
                            />
                        </TableCell>
                        <TableCell>
                            Updated by
                            <PlatformFilters
                                elementId="user-popover"
                                onFilterChange={onFilterChange(FilterFieldNames.User)}
                            />
                        </TableCell>
                        <TableCell>Updated</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {activeIngredientsFinal.items.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.activeSubs}</TableCell>
                            <TableCell>{item.user}</TableCell>
                            <TableCell>
                                {' '}
                                {item.updated !== undefined
                                    ? new Date(Date.parse(item.updated?.toString() || '')).toLocaleString()
                                    : ''}{' '}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </ActiveSubFullListContainer>
    );
};

export default ActiveIngredientsFinalTable;
