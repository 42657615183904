export const dateFilterOptions: string[] = ['equals', 'notEqual', 'lessThanOrEqual', 'greaterThanOrEqual', 'inRange'];

export const textFilterOptions: string[] = ['contains', 'notContains', 'equals', 'notEqual', 'startsWith', 'endsWith'];

export const altTextFilterOptions: string[] = ['contains'];

export const numberFilterOptions: string[] = ['equals', 'notEqual', 'lessThanOrEqual', 'greaterThanOrEqual', 'inRange'];

export function buildSort(filterModel: { [key: string]: any }): { query: string; countries?: string } {
    const rsqlQueryArr: string[] = [];
    const returnObj = { query: '', countries: '' };
    Object.keys(filterModel).forEach((columnName: string) => {
        const filterObject = filterModel[columnName];
        let operator = '';

        operator = getOperator(filterObject['type']);

        // Multiple conditions
        if (filterObject['operator']) {
            const multiCondition: string[] = [];
            Object.keys(filterModel[columnName])
                .filter((propName: string) => propName.startsWith('condition'))
                .forEach((conditionName: string) => {
                    const filterCondition = filterModel[columnName][conditionName];

                    if (filterCondition['filter']) {
                        operator = getOperator(filterCondition['type']);
                        multiCondition.push(`${columnName} ${operator} '${filterCondition['filter']}'`);
                    }
                });
            rsqlQueryArr.push(multiCondition.join(` ${filterObject['operator'].toLowerCase()} `));
            return;
        }

        // Column has only one condition
        if (filterObject['filterType'] === 'text' || filterObject['filterType'] === 'number') {
            rsqlQueryArr.push(`${columnName} ${operator} '${filterObject['filter']}'`);
        }

        if (filterObject['filterType'] === 'date') {
            if (filterObject['dateFrom']) {
                const op = filterObject['type'] === 'inRange' ? 'gte' : operator;
                rsqlQueryArr.push(`${columnName} ${op} '${filterObject['dateFrom']}'`);
            }

            if (filterObject['dateTo']) {
                const op = filterObject['type'] === 'inRange' ? 'lte' : operator;
                rsqlQueryArr.push(`${columnName} ${op} '${filterObject['dateTo'].split(' ')[0]} 23:59:59'`);
            }
        }

        if (filterObject['filterType'] === 'set') {
            const refCountries: string[] = [];

            // Special case for the Origin column
            if (columnName === 'Country') {
                filterObject['values'].forEach((value: string) => {
                    refCountries.push(`${value}`);
                });
                returnObj.countries = refCountries.join(',');
            } else {
                const setQuery: string[] = [];
                filterObject['values'].forEach((value: string) => {
                    setQuery.push(`${columnName} eq '${value}'`);
                });
                rsqlQueryArr.push(`(${setQuery.join(' or ')})'`);
                returnObj.query = rsqlQueryArr.join(' and ');
            }

            return returnObj;
        }
    });

    returnObj.query = rsqlQueryArr.join(' and ');
    return returnObj;
}

function getOperator(type: string): string {
    let operator = '';
    switch (type) {
        case 'contains':
            operator = 'in';
            break;
        case 'notContains':
            operator = 'nin';
            break;
        case 'equals':
            operator = 'eq';
            break;
        case 'notEqual':
            operator = 'ne';
            break;
        case 'startsWith':
            operator = 'sw';
            break;
        case 'endsWith':
            operator = 'ew';
            break;
        case 'greaterThanOrEqual':
        case 'greaterThan':
            operator = 'gte';
            break;
        case 'lessThanOrEqual':
        case 'lessThan':
            operator = 'lte';
            break;
    }

    return operator;
}
