import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import RequireRole from '../../components/RequireRole';
import { Box, List, Avatar, Drawer, CssBaseline, AppBar, Toolbar, Divider, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { MenuContainer, Item, IconWrapper, Text, Expander, useStyles } from './styled';
import { UserRole } from '../../models/UserRole';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BusinessCircleIcon from '@material-ui/icons/Business';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import StoreIcon from '@material-ui/icons/Store';
import { push } from 'connected-react-router';
import { routes } from '../../store/Router/routes';
import { getLoggedUser } from '../../store/Session/selectors';
import { Logo, LogoBetaLogged } from '../Layout/styled';
import { AppContext } from '../App';
import { AppContextType } from '../../context/@types/types';
import appSettings from '../../appSettings';
import { useUrl } from '../../utils/hooks/url';
import { RFQAvatar } from '../../components/RFQAvatar';
import { isUserInternal } from '../../utils/utils';

interface ListItemWithIconProps {
    title: string;
    icon: React.ReactNode;
    onClick: () => void;
}

export const ListItemWithIcon: React.FC<ListItemWithIconProps> = (props: ListItemWithIconProps) => (
    <Item onClick={props.onClick}>
        <IconWrapper>{props.icon}</IconWrapper>
        <Text>{props.title}</Text>
    </Item>
);

interface INavMenu {
    isOpen: boolean;
    handleDrawerToggle: () => void;
}

const NavMenu: React.FC<INavMenu> = ({ isOpen, handleDrawerToggle }) => {
    const { headerName } = React.useContext(AppContext) as AppContextType;

    const classes = useStyles();
    const theme = useTheme();

    const path = useUrl();

    const dispatch = useDispatch();
    const t = useTranslations();

    const user = useSelector(getLoggedUser);

    const goTo = (url: string) => (): void => {
        handleDrawerToggle();
        dispatch(push({ pathname: url }));
    };
    return (
        <>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: isOpen,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            edge="start"
                            className={clsx(classes.menuButton, isOpen && classes.hide)}
                        >
                            <MenuIcon />
                        </IconButton>

                        <Typography variant="h4" noWrap>
                            <Box display="flex">
                                <RFQAvatar />
                                <Box fontWeight={600}>
                                    {path.includes(appSettings.developmentUrl + '/products/') ||
                                    path.includes(appSettings.localDevelopmentUrl + '/products/')
                                        ? t(TK.productsProdV)
                                        : headerName}
                                </Box>
                            </Box>
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={isOpen}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <div style={{ width: '40%' }}>
                            <Logo isLogged={true} /> <LogoBetaLogged />
                        </div>
                        <IconButton onClick={handleDrawerToggle}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    <MenuContainer>
                        <List>
                            <RequireRole roles={[UserRole.Collaborator]}>
                                <ListItemWithIcon
                                    onClick={goTo(routes.rfqProducts)}
                                    icon={<StoreIcon />}
                                    title={`${t(TK.products)}`}
                                />
                            </RequireRole>
                            <RequireRole roles={[UserRole.Collaborator, UserRole.Customer]}>
                                <ListItemWithIcon
                                    onClick={goTo(routes.enquiryProducts)}
                                    icon={<StoreIcon />}
                                    title={
                                        t(TK.products) +
                                        (!user?.roles.includes(UserRole.Customer) ? ` (${t(TK.customer)})` : '')
                                    }
                                />
                            </RequireRole>
                            <RequireRole roles={[UserRole.Collaborator]}>
                                <ListItemWithIcon
                                    onClick={goTo(routes.rfqList)}
                                    icon={<YoutubeSearchedForIcon />}
                                    title={'RFQs'}
                                />
                            </RequireRole>
                            <RequireRole roles={[UserRole.Supplier, UserRole.External]}>
                                <ListItemWithIcon
                                    onClick={goTo(routes.rfqReply)}
                                    icon={<YoutubeSearchedForIcon />}
                                    title={'Offers'}
                                />
                            </RequireRole>
                            <RequireRole roles={[UserRole.Customer]}>
                                <ListItemWithIcon
                                    onClick={goTo(routes.enquiryList)}
                                    icon={<YoutubeSearchedForIcon />}
                                    title={t(TK.enquiriesList)}
                                />
                            </RequireRole>
                            <RequireRole roles={[UserRole.Collaborator]}>
                                <ListItemWithIcon
                                    onClick={goTo(routes.enquiryListInternal)}
                                    icon={<YoutubeSearchedForIcon />}
                                    title={t(TK.enquiriesListPharma)}
                                />
                            </RequireRole>
                            <RequireRole roles={[UserRole.Administrator]}>
                                <ListItemWithIcon
                                    onClick={goTo(routes.users)}
                                    icon={<PeopleAltIcon />}
                                    title={t(TK.users)}
                                />
                            </RequireRole>
                            <RequireRole roles={[UserRole.PlatformContributor]}>
                                <ListItemWithIcon
                                    onClick={goTo(routes.platform)}
                                    icon={<BeachAccessIcon />}
                                    title={`${t(TK.platformV3)}`}
                                />
                            </RequireRole>
                            <Expander />
                            <RequireRole roles={[UserRole.Administrator, UserRole.Collaborator]}>
                                <ListItemWithIcon
                                    onClick={goTo(routes.companiesList)}
                                    icon={<BusinessCircleIcon />}
                                    title={t(TK.companiesList)}
                                />
                            </RequireRole>
                            {process.env.REACT_APP_SHOW_BETA_FEATURES === 'TRUE' &&
                                user !== undefined &&
                                !isUserInternal(user.roles) && (
                                    <RequireRole roles={[UserRole.Administrator, UserRole.Supplier, UserRole.Customer]}>
                                        <ListItemWithIcon
                                            onClick={goTo(routes.companyDetails)}
                                            icon={<BusinessCircleIcon />}
                                            title={t(TK.companyDetails)}
                                        />
                                    </RequireRole>
                                )}
                            <ListItemWithIcon
                                onClick={goTo(routes.profile)}
                                icon={
                                    user?.imageUrl?.length ? (
                                        <Avatar src={user.imageUrl} style={{ width: '24px', height: '24px' }} />
                                    ) : (
                                        <AccountCircleIcon />
                                    )
                                }
                                title={t(TK.profile)}
                            />
                            <ListItemWithIcon
                                onClick={goTo(routes.logout)}
                                icon={<ExitToAppIcon />}
                                title={t(TK.logout)}
                            />
                        </List>
                    </MenuContainer>
                </Drawer>
            </div>
        </>
    );
};

export default NavMenu;
