import styled from 'styled-components';
import { Button, Chip, ChipProps } from '@material-ui/core';
import { MapOf } from '../../utils/Types';
import { CellStyle } from 'ag-grid-community';

const statusColor = {
    Qualified: { backgroundColor: '#40c057', color: 'white' },
    New: { backgroundColor: '#3bc9db', color: 'white' },
    NotQualified: { backgroundColor: '#e03131', color: 'white' },
    ToQualify: { backgroundColor: '#fab005', color: 'white' },
} as MapOf<any>;

export const ChipStatus = styled(Chip)<ChipProps & { status: any }>`
    ${(p) => statusColor[p.status]}
`;

// Button that looks like a link
export const LinkButton = styled(Button)`
    color: #0078d4;
    cursor: pointer;
    &:hover {
        background-color: transparent;
    }
    &:focus {
        outline: none;
    }
`;

export const defaultCellStyle: CellStyle = { paddingRight: '20px', display: 'flex', alignItems: 'center' };
