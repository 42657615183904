import { Action, Reducer } from 'redux';
import { QualificationFormState } from './state';
import { QualificationFormErrorAction, QualificationFormLoadedAction } from './types';
import { Actions } from './actions';
import { normalizeYesNoNA } from '../../utils/utils';

const unloadedState: QualificationFormState = {
    isLoading: false,
    updating: false,
    questionsInfo: [],
    showValidationErrors: false,
    questionsInfoToUpdate: [],
    questionsWithErrors: [],
};

export const persistor = (state: QualificationFormState): QualificationFormState => ({
    ...unloadedState,
});

export const reconciler = (state: QualificationFormState): QualificationFormState => ({
    ...state,
    isLoading: false,
});

const handleQualificationFormLoading = (state: QualificationFormState): QualificationFormState => ({
    ...state,
    isLoading: true,
});

const handleQualificationFormLoadError = (
    state: QualificationFormState,
    action: QualificationFormErrorAction,
): QualificationFormState => ({
    ...state,
    isLoading: false,
    questionsWithErrors: action.questions,
});

const handleQualificationFormLoaded = (
    state: QualificationFormState,
    action: QualificationFormLoadedAction,
): QualificationFormState => ({
    ...state,
    isLoading: false,
    questionsInfo: action.result.map((q) => ({ ...q, value: normalizeYesNoNA(q.value || '') || q.value })),
    questionsInfoToUpdate: action.result.map((q) => ({ ...q, value: normalizeYesNoNA(q.value || '') || q.value })),
    questionsWithErrors: [],
});

const handleQualificationFormUpdate = (
    state: QualificationFormState,
    action: QualificationFormLoadedAction,
): QualificationFormState => ({
    ...state,
    questionsInfoToUpdate: action.result,
});

const handleShowValidationErrors = (state: QualificationFormState): QualificationFormState => ({
    ...state,
    showValidationErrors: true,
});

export const reducer: Reducer<QualificationFormState> = (
    state: QualificationFormState = unloadedState,
    action: Action,
): QualificationFormState => {
    switch (action.type) {
        case Actions.qualificationFormLoading:
            return handleQualificationFormLoading(state);
        case Actions.qualificationFormLoadError:
            return handleQualificationFormLoadError(state, action as QualificationFormErrorAction);
        case Actions.qualificationFormLoaded:
            return handleQualificationFormLoaded(state, action as QualificationFormLoadedAction);
        case Actions.qualificationFormUpdate:
            return handleQualificationFormUpdate(state, action as QualificationFormLoadedAction);
        case Actions.qualificationFormShowValidationErrors:
            return handleShowValidationErrors(state);
        default:
            return state;
    }
};
