import { routes } from './routes';
import { SearchResult } from '../models/SearchResult';
import { ProcessingPformsSettings } from '../models/ProcessingPformsSettings';
import { PlatformV3ProcessingSettings } from '../models/ProcessingSettings';
import { AuthenticatedUser } from '../models/AuthenticatedUser';
import { CreateUser, RegisterCompany } from '../models/CreateUser';
import { UpdateUser } from '../models/UpdateUser';
import { User } from '../models/User';
import { Supplier } from '../models/Supplier';
import { RFQSummary } from '../models/RFQSummary';
import { IRFQDueRminderRequest, IRFQStateReasonRequest, RFQRequestV3 } from '../models/RFQRequest';
import { RFQDetails } from '../models/RFQDetails';
import { MapOf } from '../utils/Types';
import { RFQSupplierDetails } from '../models/RFQSupplierDetails';
import { ProductV2 } from '../models/ProductV2';
import { Login } from '../models/Login';
import { RFQQuote } from '../models/RFQQuote';
import { SupplierRFQQuotesChange } from '../models/SupplierRFQQuotesChange';
import { RFQQuoteTableItem } from '../pages/RFQDetails/RFQDetails';
import { Country } from '../models/Country';
import { ProcessingDciFullSettings } from '../models/ProcessingDciFullSettings';
import { ProcessingCatSettings } from '../models/ProcessingCatSettings';
import { RFQEnquire } from '../store/RFQEnquire/state';
import { RFQSimulationTransporterDto } from '../models/RFQSimulationTransporterDto';
import { AdditionalDocuments } from '../models/AdditionalDocuments';
import { EnquiryData } from '../models/EnquiryData';
import { EnquiryNeed, EnquiryNeedComment, EnquiryNeedDetail, EnquiryNeedStatusDetail } from '../models/EnquiryNeed';
import { EnquiryFiltersClient } from '../pages/EnquiriesListClients/EnquiriesListClients.types';
import { EnquiryFiltersInternal } from '../pages/EnquiriesListInternal/EnquiriesListInternal.types';
import { AddEnquiryNeedComment, UpdateEnquiryNeedStatus } from '../pages/EnquiriesListInternal/EnquiriesListInternal';
import { EnableUser } from '../models/EnableUser';
import { PaymentTerm } from '../models/PaymentTerms';
import { CompanyDetails } from '../models/CompanyDetails';
import { CompanyListResult } from '../models/CompaniesList';
import { IRFQStateReasonResponse } from '../models/RFQResponse';
import { CompanyType } from '../models/CompanyType';
import { CompanyInfo } from '../models/CompanyInfo';
import { DeliveryTerm } from '../models/DeliveryTerm';
import { TaxRate } from '../models/TaxRate';
import { ProductType } from '../models/ProductType';

// Utils

export class RequestError extends Error {
    statusCode: number;
    body: any;

    constructor(statusCode: number, body: any) {
        super(`Request error: ${statusCode}`);
        this.statusCode = statusCode;
        this.body = body;
    }
}

const getHeaders = (token?: string, csrfToken?: string): Headers => {
    const headers = new Headers();

    headers.append('content-type', 'application/json');
    headers.append('headerName', 'RequestVerificationToken');
    if (token) headers.append('Authorization', `Bearer ${token}`);
    if (csrfToken) headers.append('X-CSRF-Token', csrfToken);
    return headers;
};

const responseHandleMiddleware = async (r: Response): Promise<any> => {
    if (!r.ok) {
        let bodyObj = {};
        try {
            bodyObj = await r.json();
        } catch (e) {}
        throw new RequestError(r.status, bodyObj);
    }
    var isJson = r.headers.get('content-type')?.includes('application/json');
    return isJson ? r.json() : null;
};

function getParams(params?: { [name: string]: any }) {
    if (!params) return '';

    const paramsString = Object.keys(params)
        .filter((k) => params[k] !== undefined)
        .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k].toString()))
        .join('&');

    return paramsString.length === 0 ? '' : '?'.concat(paramsString);
}

const post = (uri: string, body: any, token?: string, csrfToken?: string): Promise<any> =>
    fetch(uri, {
        method: 'post',
        headers: getHeaders(token, csrfToken),
        body: JSON.stringify(body),
    }).then(responseHandleMiddleware);

const put = (uri: string, body: any, token?: string, csrfToken?: string): Promise<any> =>
    fetch(uri, {
        method: 'put',
        headers: getHeaders(token, csrfToken),
        body: JSON.stringify(body),
    }).then(responseHandleMiddleware);

export const getDownloadFile = (uri: string, token?: string, csrfToken?: string): Promise<Response> =>
    fetch(uri, {
        method: 'get',
        headers: getHeaders(token, csrfToken),
    });

const get = (uri: string, token?: string, csrfToken?: string, params?: { [name: string]: any }): Promise<any> =>
    fetch(uri + getParams(params), {
        method: 'get',
        headers: getHeaders(token, csrfToken),
    }).then(responseHandleMiddleware);

// CLOUD

// Users

export const postLoginAsync = (login: Login): Promise<AuthenticatedUser> => post(routes.login(), login);

export const getUserAsync = (username: string, token?: string, csrfToken?: any): Promise<User> =>
    get(routes.getUser(), token, csrfToken, { userName: username });

export const putUserAsync = (user: UpdateUser, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.updateUser(), user, token, csrfToken);

export const getUsersAsync = (token?: string, csrfToken?: string): Promise<User[]> =>
    get(routes.getAllUsers(), token, csrfToken);

export const postUserAsync = (user: CreateUser, token?: string, csrfToken?: string): Promise<void> =>
    post(routes.createUser(), user, token, csrfToken);

export const postCompanyAsync = (user: RegisterCompany, token?: string, csrfToken?: string): Promise<void> =>
    post(routes.createAccount(), user, token, csrfToken);

// Products

export const getPaginatedProductsAsync = ({
    isMarketing,
    isEuropean,
    countries,
    refCountries,
    offset,
    pageSize,
    orderBy,
    free,
    filters,
    token,
    csrfToken,
}: {
    isMarketing: boolean;
    isEuropean: boolean | undefined;
    countries?: string[];
    refCountries?: string[];
    offset?: number;
    pageSize?: number;
    orderBy?: string;
    free?: string;
    filters?: string;
    token?: string;
    csrfToken?: string;
}): Promise<SearchResult<ProductV2>> =>
    get(routes.paginatedProducts(), token, csrfToken, {
        isMarketing,
        isEuropean,
        countries,
        offset,
        refCountries,
        pageSize,
        orderBy,
        free,
        filters,
    });

export const getProductDetailsAsync = ({
    productId,
    token,
    csrfToken,
}: {
    productId: string;
    token?: string;
    csrfToken?: string;
}): Promise<ProductV2> =>
    get(routes.getProductDetails(), token, csrfToken, {
        productId,
    });
export const getProductsPingAsync = (token?: string, csrfToken?: string): Promise<any> =>
    get(routes.pingProducts(), token, csrfToken);

export const getProductsPingAsync2 = (token?: string, csrfToken?: string): Promise<any> =>
    get(routes.pingProducts2(), token, csrfToken);

export const getProductsPingAsync3 = (token?: string, csrfToken?: string): Promise<any> =>
    get(routes.pingProducts3(), token, csrfToken);

export const getProductsPingAsync4 = (token?: string, csrfToken?: string): Promise<any> =>
    get(routes.pingProducts4(), token, csrfToken);

// Sets

export const getCountriesAsync = (token?: string, csrfToken?: string): Promise<Country[]> =>
    get(routes.getCountries(), token, csrfToken);

export const getAllCountriesAsync = (token?: string, csrfToken?: string): Promise<Country[]> =>
    get(routes.getAllCountries(), token, csrfToken);

export const getPaymentTermsAsync = (token?: string, csrfToken?: string): Promise<PaymentTerm[]> =>
    get(routes.getPaymentTerms(), token, csrfToken);

export const getDeliveryTermsAsync = (token?: string, csrfToken?: string): Promise<DeliveryTerm[]> =>
    get(routes.getDeliveryTerms(), token, csrfToken);

export const getTaxRatesAsync = (token?: string, csrfToken?: string): Promise<TaxRate[]> =>
    get(routes.getTaxRates(), token, csrfToken);

export const getTransportersAsync = (token?: string, csrfToken?: string): Promise<RFQSimulationTransporterDto[]> =>
    get(routes.getTransporters(), token, csrfToken);

export const getAdditionalDocumentsAsync = (token?: string, csrfToken?: string): Promise<AdditionalDocuments[]> =>
    get(routes.getAdditionalDocuments(), token, csrfToken);

export const getCompanyTypesAsync = (token?: string, csrfToken?: string): Promise<CompanyType[]> =>
    get(routes.getCompanyTypes(), token, csrfToken);

// Suppliers

export const getSuppliersAsync = ({
    offset,
    pageSize,
    filters,
    orderBy,
    token,
    csrfToken,
}: {
    offset: number;
    pageSize: number;
    filters: { [property: string]: string | string[] | undefined };
    orderBy?: string;
    token?: string;
    csrfToken?: string;
}): Promise<SearchResult<Supplier>> =>
    get(routes.getSuppliers(), token, csrfToken, { offset, pageSize, ...filters, orderBy });

// Rfqs

export const getRFQsAsyncPaginated = (
    onlyMine: boolean,
    search: string | undefined,
    expiredIn: string | undefined,
    createdBy: string | undefined,
    filters: string | undefined,
    offset: number,
    pageSize: number,
    orderBy?: string,
    token?: string,
    csrfToken?: string,
): Promise<SearchResult<RFQSummary>> => {
    return fetch(
        routes.rfqsV3() +
            getParams({
                offset,
                search,
                expiredIn,
                createdBy,
                pageSize,
                onlyMine,
                orderBy,
                filters,
            }),
        { method: 'get', headers: getHeaders(token, csrfToken) },
    ).then(responseHandleMiddleware);
};

export const getRFQsDetailsAsyncV3 = (
    rfqNumbers: string[],
    token?: string,
    csrfToken?: string,
): Promise<RFQDetails[]> => get(routes.rfqsDetailsV3(), token, csrfToken, { rfqNumber: rfqNumbers });

export const getRFQDetailsAsyncV3 = (rfqNumber: string, token?: string, csrfToken?: string): Promise<RFQDetails> =>
    get(routes.rfqDetailsV3(rfqNumber), token, csrfToken);

export const putRFQDueReminderRequestAsyncV3 = (
    request: IRFQDueRminderRequest,
    token?: string,
    csrfToken?: string,
): Promise<void> => put(routes.rfqsDueDateV3(), request, token, csrfToken);

export const putRFQStateReasonAsyncV3 = (
    request: IRFQStateReasonRequest,
    token?: string,
    csrfToken?: string,
): Promise<IRFQStateReasonResponse> => put(routes.rfqsReasonStateV3(), request, token, csrfToken);

export const putRFQDataAsyncV3 = (
    rfqNumber: string,
    dataByThreadId: MapOf<RFQQuote[]>,
    token?: string,
    csrfToken?: string,
): Promise<void> => put(routes.rfqTableDataV3(rfqNumber), dataByThreadId, token, csrfToken);

export const putRFQCardsDataAsyncV3 = (
    rfqNumber: string,
    cardsData: RFQQuoteTableItem[],
    token?: string,
    csrfToken?: string,
): Promise<RFQSummary> => put(routes.rfqCardsDataV3(rfqNumber), cardsData, token, csrfToken);

export const getRFQNextNumberAsyncV3 = (token?: string, csrfToken?: string): Promise<{ nextRfqNumber: string }> =>
    get(routes.rfqNextNumberV3(), token, csrfToken);

export const postRFQRequestAsyncV3 = (
    request: RFQRequestV3,
    token?: string,
    csrfToken?: string,
): Promise<{ number: string }[]> => post(routes.postRfqsV3(), request, token, csrfToken);

export const putRFQAssigneeAsyncV3 = (
    rfqNumber: string,
    username?: string,
    token?: string,
    csrfToken?: string,
): Promise<void> => put(routes.rfqAssigneeV3(rfqNumber), username, token, csrfToken);

export const getRFQEnquireAsyncV3 = (rfqNumber: string, token?: string, csrfToken?: string): Promise<RFQEnquire> =>
    get(routes.rfqEnquire(rfqNumber), token, csrfToken);

// Enquiry

export const postEnquiryAsync = ({
    data,
    token,
    csrfToken,
}: {
    data: EnquiryData;
    token?: string;
    csrfToken?: string;
}): Promise<{ number: string }> => post(routes.postEnquiry(), data, token, csrfToken);

export const getEnquiriesClientsListAsync = (
    token?: string,
    csrfToken?: string,
    params?: EnquiryFiltersClient,
): Promise<SearchResult<EnquiryNeed>> => get(routes.getEnquiriesClient(), token, csrfToken, { ...params });

export const getEnquiriesPharmaListAsync = (
    token?: string,
    csrfToken?: string,
    params?: EnquiryFiltersInternal,
): Promise<SearchResult<EnquiryNeed>> => get(routes.getEnquiriesPharma(), token, csrfToken, { ...params });

// Enquiry Need

export const getEnquiryNeedDetailAsync = (
    token?: string,
    csrfToken?: string,
    id?: string,
): Promise<EnquiryNeedDetail> => get(routes.getEnquiryNeedDetail(), token, csrfToken, { needId: id });

export const getEnquiryNeedCommentsAsync = (
    token?: string,
    csrfToken?: string,
    id?: string,
): Promise<EnquiryNeedComment[]> => get(routes.getComments(), token, csrfToken, { needId: id });

export const getEnquiryNeedStatusDetailAsync = (
    token?: string,
    csrfToken?: string,
    id?: string,
): Promise<EnquiryNeedStatusDetail> => get(routes.getEnquiryNeedStatusDetail(), token, csrfToken, { needId: id });

export const putEnquiryNeedPutAprovalAsync = (
    id: string,
    token?: string,
    csrfToken?: string,
    updateStatus?: UpdateEnquiryNeedStatus,
): Promise<EnquiryNeedDetail> => put(routes.putEnquiryAproval(id), updateStatus, token, csrfToken);

export const postEnquiryNeedCommentAsync = (
    token?: string,
    csrfToken?: string,
    updateStatus?: AddEnquiryNeedComment,
): Promise<EnquiryNeedDetail> => post(routes.postComment(), updateStatus, token, csrfToken);

// External

export const getExternalSupplierCollaboratorsAsync = (
    rfqsNumbers?: string[],
    token?: string,
    csrfToken?: string,
): Promise<User[]> => get(routes.getExternalCollaborators(), token, csrfToken, { rfqsNumbers });

export const getExternalSupplierProductsSearchAsync = (
    term: string,
    token?: string,
    csrfToken?: string,
): Promise<ProductV2[]> => get(routes.getExternalProductsSearch(), token, csrfToken, { name: term });

export const getExternalSupplierRFQsResposiblesAsync = (
    rfqsNumbers: string[],
    token?: string,
    csrfToken?: string,
): Promise<MapOf<string>> => get(routes.getExternalResponsibles(), token, csrfToken, { rfqsNumbers });

export const getExternalRFQsSupplierDataAsync = (
    rfqsNumbers?: string[],
    token?: string,
    csrfToken?: string,
): Promise<RFQSupplierDetails> => get(routes.getExternalRfqsData(), token, csrfToken, { rfqsNumbers });

export const putExternalSupplierRFQsDataAsync = (
    data: SupplierRFQQuotesChange,
    token?: string,
    csrfToken?: string,
): Promise<void> => put(routes.putExternalRfqsData(), data, token, csrfToken);

// Platform

export const getPlatformV3PharmaceuticalFormsAsync = ({
    origin,
    offset,
    pageSize,
    orderBy,
    filters,
    isNewTerm,
    token,
    csrfToken,
}: {
    origin?: string;
    offset?: number;
    pageSize?: number;
    orderBy?: string;
    filters?: string;
    isNewTerm?: boolean;
    token?: string;
    csrfToken?: string;
}): Promise<SearchResult<PlatformV3ProcessingSettings>> =>
    get(routes.getPharmaceuticalForms(), token, csrfToken, { origin, offset, pageSize, orderBy, filters, isNewTerm });

export const putPlatformV3PharmaceuticalFormsAsync = ({
    data,
    token,
    csrfToken,
}: {
    data: ProcessingPformsSettings[];
    token?: string;
    csrfToken?: string;
}): Promise<void> => put(routes.putPharmaceuticalForms(), data, token, csrfToken);

export const getPlatformV3CategoriesListAsync = ({
    origin,
    offset,
    pageSize,
    orderBy,
    filters,
    token,
    csrfToken,
}: {
    origin?: string;
    offset?: number;
    pageSize?: number;
    orderBy?: string;
    filters?: string;
    token?: string;
    csrfToken?: string;
}): Promise<SearchResult<PlatformV3ProcessingSettings>> =>
    get(routes.getCategoriesList(), token, csrfToken, { origin, offset, pageSize, orderBy, filters });

export const postPlatformV3CreateCategoryAsync = ({
    data,
    token,
    csrfToken,
}: {
    data: ProcessingCatSettings;
    token?: string;
    csrfToken?: string;
}): Promise<ProcessingCatSettings> => post(routes.createCategory(), data, token, csrfToken);

export const getPlatformV3ActiveIngredientsAsync = ({
    origin,
    offset,
    pageSize,
    orderBy,
    filters,
    isNewTerm,
    token,
    csrfToken,
}: {
    origin?: string;
    offset?: number;
    pageSize?: number;
    orderBy?: string;
    filters?: string;
    isNewTerm?: boolean;
    token?: string;
    csrfToken?: string;
}): Promise<SearchResult<PlatformV3ProcessingSettings>> =>
    get(routes.getActiveIngredients(), token, csrfToken, { origin, offset, pageSize, orderBy, filters, isNewTerm });

export const putPlatformV3ActiveIngredientsAsync = ({
    data,
    token,
    csrfToken,
}: {
    data: ProcessingPformsSettings[];
    token?: string;
    csrfToken?: string;
}): Promise<void> => put(routes.putActiveIngredients(), data, token, csrfToken);

export const getPlatformV3ActiveIngredientsFinalAsync = ({
    origin,
    offset,
    pageSize,
    orderBy,
    filters,
    token,
    csrfToken,
}: {
    origin?: string;
    offset?: number;
    pageSize?: number;
    orderBy?: string;
    filters?: string;
    token?: string;
    csrfToken?: string;
}): Promise<SearchResult<PlatformV3ProcessingSettings>> =>
    get(routes.getActiveIngredientsFinal(), token, csrfToken, { origin, offset, pageSize, orderBy, filters });

export const postPlatformV3ActiveIngredientsFinalAsync = ({
    data,
    token,
    csrfToken,
}: {
    data: { activeIngredient: string; user: string };
    token?: string;
    csrfToken?: string;
}): Promise<ProcessingDciFullSettings> => post(routes.postActiveIngredientsFinal(), data, token, csrfToken);

export const getPlatformV3ATCsAsync = ({
    origin,
    offset,
    pageSize,
    orderBy,
    filters,
    token,
    csrfToken,
}: {
    origin?: string;
    offset?: number;
    pageSize?: number;
    orderBy?: string;
    filters?: string;
    token?: string;
    csrfToken?: string;
}): Promise<SearchResult<PlatformV3ProcessingSettings>> =>
    get(routes.getATCs(), token, csrfToken, { origin, offset, pageSize, orderBy, filters });

export const verifyConfirmationCodeAsync = (code: string): Promise<boolean> =>
    get(routes.verifyConfirmationCodeAsync(code));

export const accountActivationAsync = (code: string): Promise<void> =>
    put(routes.putAccountActivationAsync(), { confirmationCode: code });

export const verifyResetCodeAsync = (code: string): Promise<boolean> => get(routes.verifyResetCodeAsync(code));

export const putRegistCodePasswordAsync = (enableUser: EnableUser): Promise<void> =>
    put(routes.putRegistCodePasswordAsync(), enableUser);

export const postResetPasswordAsync = (enableUser: EnableUser): Promise<void> =>
    post(routes.postResetPasswordAsync(), enableUser);

export const putStartResetPasswordAsync = (body: { email: string }): Promise<void> =>
    put(routes.putStartResetPasswordAsync(), body);

// export const postUserAsync = (user: CreateUser, token?: string, csrfToken?: string): Promise<void> =>
//     post(routes.createUser(), user, token, csrfToken);

//CompanyDetails  getCompanyDetailsAsync

export const getCompanyDetailsAsync = (id?: number, token?: string, csrfToken?: string): Promise<CompanyDetails> =>
    get(routes.getCompanyDetails(), token, csrfToken, { id });

export const getProductTypesAsync = (token?: string, csrfToken?: string): Promise<ProductType[]> =>
    get(routes.getProductTypes(), token, csrfToken);

export const getCompaniesAsync = ({
    token,
    csrfToken,
    offset,
    pageSize,
    orderBy,
    text,
    countries,
    accountType,
    statuses,
    companyTypes,
}: {
    token?: string;
    csrfToken?: string;
    offset?: number;
    pageSize?: number;
    orderBy?: string;
    text?: string | null;
    statuses?: string[];
    countries?: string[];
    accountType?: string;
    companyTypes?: string[];
}): Promise<CompanyListResult> =>
    get(routes.getCompanies(), token, csrfToken, {
        offset,
        pageSize,
        orderBy,
        text,
        statuses,
        countries,
        accountType,
        companyTypes,
    });

export const getCompanyInfoAsync = (id: number, token?: string, csrfToken?: string): Promise<CompanyInfo> =>
    get(routes.getCompanyInfo(id), token, csrfToken);

export const postCompanyDetailsAsync = (company: CompanyDetails, token?: string): Promise<void> =>
    post(routes.postCompanyDetailsAsync(), company, token);

export const putCompanyInfoAsync = (company: CompanyInfo, token?: string, csrfToken?: string): Promise<void> =>
    put(routes.putCompanyInfo(), company, token, csrfToken);
