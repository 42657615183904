import * as ProductsV2 from './RFQProducts';
import * as Suppliers from './Suppliers';
import * as Translations from './Translations';
import * as Session from './Session';
import * as Users from './Users';
import * as RFQsV3 from './RFQ';
import * as Enquiry from './Enquiry';
import * as RFQEnquire from './RFQEnquire';
import * as PlatformV3 from './Platform';
import * as EnquireProducts from './EnquiryProducts';
import * as DomainInfo from './DomainInfo';
import * as Transporters from './Transporters';
import * as Countries from './Countries';

import * as PaymentTerms from './PaymentTerms';
import * as CompanyDetails from './CompanyDetails';
import * as CompaniesList from './CompaniesList';
import * as CompanyInfo from './CompanyInfo';
import * as QualificationForm from './QualificationForm';

import * as AdditionalDocuments from './AdditionalDocuments';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { AnyAction, Reducer, Action } from 'redux';
import { RouterState } from 'connected-react-router';

export type Reconciler<S> = (state: S) => S;

export interface ApplicationState {
    router: RouterState;
    session: Session.State;
    translations: Translations.State;
    productsV2: ProductsV2.State;
    suppliers: Suppliers.State;
    users: Users.State;
    rfqsV3: RFQsV3.State;
    enquiry: Enquiry.State;
    rfqEnquire: RFQEnquire.State;
    platformV3: PlatformV3.State;
    domainInfo: DomainInfo.State;
    additionalDocuments: AdditionalDocuments.State;
    enquireProducts: EnquireProducts.State;
    transporters: Transporters.State;
    countries: Countries.State;
    paymentTerms: PaymentTerms.State;
    companyDetails: CompanyDetails.State;
    companiesList: CompaniesList.State;
    companyInfo: CompanyInfo.State;
    qualificationForm: QualificationForm.State;
}

export interface ApplicationReducer {
    router: Reducer<RouterState>;
    session: Reducer<Session.State>;
    translations: Reducer<Translations.State>;
    productsV2: Reducer<ProductsV2.State>;
    suppliers: Reducer<Suppliers.State>;
    users: Reducer<Users.State>;
    rfqsV3: Reducer<RFQsV3.State>;
    enquiry: Reducer<Enquiry.State>;
    rfqEnquire: Reducer<RFQEnquire.State>;
    platformV3: Reducer<PlatformV3.State>;
    domainInfo: Reducer<DomainInfo.State>;
    enquireProducts: Reducer<EnquireProducts.State>;
    transporters: Reducer<Transporters.State>;
    countries: Reducer<Countries.State>;
    additionalDocuments: Reducer<AdditionalDocuments.State>;
    paymentTerms: Reducer<PaymentTerms.State>;
    companyDetails: Reducer<CompanyDetails.State>;
    companiesList: Reducer<CompaniesList.State>;
    companyInfo: Reducer<CompanyInfo.State>;
    qualificationForm: Reducer<QualificationForm.State>;
}

export interface ApplicationReconciler {
    router: Reconciler<RouterState>;
    session: Reconciler<Session.State>;
    translations: Reconciler<Translations.State>;
    productsV2: Reconciler<ProductsV2.State>;
    suppliers: Reconciler<Suppliers.State>;
    users: Reconciler<Users.State>;
    rfqsV3: Reconciler<RFQsV3.State>;
    rfqEnquire: Reconciler<RFQEnquire.State>;
    enquiry: Reconciler<Enquiry.State>;
    platformV3: Reconciler<PlatformV3.State>;
    domainInfo: Reconciler<DomainInfo.State>;
    enquireProducts: Reconciler<EnquireProducts.State>;
    transporters: Reconciler<Transporters.State>;
    countries: Reconciler<Countries.State>;
    additionalDocuments: Reconciler<AdditionalDocuments.State>;
    paymentTerms: Reconciler<PaymentTerms.State>;
    companyDetails: Reconciler<CompanyDetails.State>;
    companiesList: Reconciler<CompaniesList.State>;
    companyInfo: Reconciler<CompanyInfo.State>;
    qualificationForm: Reconciler<QualificationForm.State>;
    [key: string]: Reconciler<any> | undefined;
}

export const emptyReducer: <S>() => Reducer<S> = <S>() => (state: S | undefined, _: Action): S => state as S;
export const emptyReconciler: <S>() => Reconciler<S> = <S>() => (state: S): S => state as S;

export const reducers: ApplicationReducer = {
    router: emptyReducer<RouterState>(),
    session: Session.reducer,
    translations: Translations.reducer,
    productsV2: ProductsV2.reducer,
    suppliers: Suppliers.reducer,
    users: Users.reducer,
    rfqsV3: RFQsV3.reducer,
    rfqEnquire: RFQEnquire.reducer,
    platformV3: PlatformV3.reducer,
    domainInfo: DomainInfo.reducer,
    enquireProducts: EnquireProducts.reducer,
    enquiry: Enquiry.reducer,
    transporters: Transporters.reducer,
    countries: Countries.reducer,
    additionalDocuments: AdditionalDocuments.reducer,
    paymentTerms: PaymentTerms.reducer,
    companyDetails: CompanyDetails.reducer,
    companiesList: CompaniesList.reducer,
    companyInfo: CompanyInfo.reducer,
    qualificationForm: QualificationForm.reducer,
};

export const applicationStateRehydrateReconcilers: ApplicationReconciler = {
    router: emptyReconciler<RouterState>(),
    session: Session.reconciler,
    translations: Translations.reconciler,
    productsV2: ProductsV2.reconciler,
    suppliers: Suppliers.reconciler,
    users: Users.reconciler,
    rfqsV3: RFQsV3.reconciler,
    enquiry: Enquiry.reconciler,
    rfqEnquire: RFQEnquire.reconciler,
    platformV3: PlatformV3.reconciler,
    domainInfo: DomainInfo.reconciler,
    enquireProducts: EnquireProducts.reconciler,
    transporters: Transporters.reconciler,
    countries: Countries.reconciler,
    additionalDocuments: AdditionalDocuments.reconciler,
    paymentTerms: PaymentTerms.reconciler,
    companyDetails: CompanyDetails.reconciler,
    companiesList: CompaniesList.reconciler,
    companyInfo: CompanyInfo.reconciler,
    qualificationForm: QualificationForm.reconciler,
};

export const applicationStatePersistReconcilers: ApplicationReconciler = {
    router: emptyReconciler<RouterState>(),
    session: Session.persistor,
    translations: Translations.persistor,
    productsV2: ProductsV2.persistor,
    suppliers: Suppliers.persistor,
    users: Users.persistor,
    rfqsV3: RFQsV3.persistor,
    enquiry: Enquiry.persistor,
    rfqEnquire: RFQEnquire.persistor,
    platformV3: PlatformV3.persistor,
    domainInfo: DomainInfo.persistor,
    enquireProducts: EnquireProducts.persistor,
    transporters: Transporters.persistor,
    countries: Countries.persistor,
    additionalDocuments: AdditionalDocuments.persistor,
    paymentTerms: PaymentTerms.persistor,
    companyDetails: CompanyDetails.persistor,
    companiesList: CompaniesList.persistor,
    companyInfo: CompanyInfo.persistor,
    qualificationForm: QualificationForm.persistor,
};

// Type helpers
export type AppThunkAction<R> = ThunkAction<R, ApplicationState, {}, AnyAction>;
export type AppThunkDispatch = ThunkDispatch<ApplicationState, {}, AnyAction>;
