import { ColDef } from 'ag-grid-community';
import { EnquiryNeed, EnquiryNeedStatus } from '../../models/EnquiryNeed';
import { TK } from '../../store/Translations/translationKeys';
import { Link, Typography } from '@material-ui/core';

import * as React from 'react';
import moment from 'moment';
import { DateTimeFormatWithotSeconds } from '../../components/Table/DataTypeFormatter';
import { ITranslate } from '../../store/Translations/hooks';
import { ChatBubbleOutlineOutlined, ThumbDown, ThumbUp } from '@material-ui/icons';
import { ChipNeedStatus, ChipOrderStatus, ChipPropStatus, ChipRFQStatus } from './EnquiriesListInternal.styles';

export function columnsArray(
    t: ITranslate<TK>,
    goToRFQ: any,
    handleAcceptRejectClick: any,
    onClickCommentsIcon: any,
    getNeedDetail: any,
): ColDef<EnquiryNeed>[] {
    return [
        {
            field: 'Enquiry',
            headerName: TK.enquiry,
            wrapText: true,
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            autoHeight: true,
            menuTabs: [],
            sortable: true,
            valueGetter: (p) => p.data?.enquiryNumber,
            cellRenderer: (p: any) => {
                if (!p.data.enquiryNumber) return <>-</>;
                return <Typography>{'Enq. ' + p.data.enquiryNumber}</Typography>;
            },
        },
        {
            field: 'Status',
            headerName: TK.status,
            wrapText: true,
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            autoHeight: true,
            menuTabs: [],
            sortable: true,
            valueGetter: (p) => p.data?.needStatus,
            cellRenderer: (p: any) => {
                if (!p.data.needStatus) return <></>;
                return <ChipNeedStatus status={p.data.needStatus} size="small" label={p.data.needStatus} />;
            },
        },
        {
            field: 'Client',
            headerName: TK.client,
            maxWidth: 120,
            cellStyle: {
                whiteSpace: 'nowrap',
                paddingRight: '20px',
                width: '120px',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
            },
            autoHeight: true,
            menuTabs: [],
            sortable: true,
            valueGetter: (p) => p.data?.clientName,
            cellRenderer: (p: any) => {
                if (!p.data.clientName) return <></>;
                return <p style={{ textOverflow: 'ellipsis' }}>{p.data.clientName}</p>;
            },
        },
        {
            field: 'Need',
            headerName: TK.need,
            wrapText: true,
            cellStyle: {
                paddingRight: '20px',
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
            },
            autoHeight: true,
            menuTabs: [],
            sortable: true,
            valueGetter: (p) => p.data?.needName,
            cellRenderer: (p: any) => {
                if (!p.data.needName) return <></>;
                return <Link onClick={() => getNeedDetail(p.data.id)}>{p.data.needName}</Link>;
            },
        },
        {
            field: 'Accept/Decline',
            headerName: t(TK.acceptDecline),
            maxWidth: 120,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            sortable: true,

            cellRenderer: (p: any) => {
                if (p.data.needStatus === EnquiryNeedStatus.Open) {
                    return (
                        <>
                            <ThumbUp
                                onClick={() => handleAcceptRejectClick(p.data, true)}
                                style={{ marginRight: '4px' }}
                                htmlColor="green"
                            />
                            <ThumbDown
                                onClick={() => handleAcceptRejectClick(p.data, false)}
                                style={{ marginLeft: '4px' }}
                                htmlColor="red"
                            />
                        </>
                    );
                } else if (p.data.needStatus === EnquiryNeedStatus.Declined) {
                    return (
                        <>
                            <ThumbDown htmlColor="darkslategrey" />
                        </>
                    );
                }
                // TODO uncomment when no proposal status become available
                //  else if (p.data.needStatus === EnquireNeedStatus.Noproposal){
                //     return <><ThumbDown htmlColor='darkslategrey'/></>;
                //  }
                return (
                    <>
                        <ThumbUp htmlColor="darkslategrey" />
                    </>
                );
            },
        },
        {
            field: 'RFQ',
            headerName: t(TK.rfq),
            maxWidth: 80,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            valueGetter: (p) => p.data?.rfqNumber,
            cellRenderer: (p: any) => {
                if (!p.data.rfqNumber) return <>-</>;
                return <Link onClick={() => goToRFQ(p.data.rfqNumber)}>{'RFQ ' + p.data.rfqNumber}</Link>;
            },
        },
        {
            field: 'RFQ Status',
            headerName: t(TK.rfqStatus),
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            valueGetter: (p) => p.data?.rfqStatus,
            cellRenderer: (p: any) => {
                if (!p.data.rfqStatus) return <></>;
                return <ChipRFQStatus status={p.data.rfqStatus} size="small" label={p.data.rfqStatus} />;
            },
        },
        {
            field: 'Proposal',
            headerName: TK.proposal,
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            valueGetter: (p) => p.data?.proposalNumber,
            cellRenderer: (p: any) => {
                if (!p.data.proposalNumber) return <>-</>;
                return <>{'P. ' + p.data.proposalNumber}</>;
            },
        },
        {
            field: 'Proposal Status',
            headerName: TK.proposalStatus,
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            valueGetter: (p) => p.data?.proposalStatus,
            cellRenderer: (p: any) => {
                if (!p.data.proposalStatus) return <></>;
                return <ChipPropStatus status={p.data.proposalStatus} size="small" label={p.data.proposalStatus} />;
            },
        },
        {
            field: 'Order',
            headerName: TK.order,
            maxWidth: 120,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            valueGetter: (p) => p.data?.orderNumber,
            cellRenderer: (p: any) => {
                if (!p.data.orderNumber) return <>-</>;
                return <>{'O. ' + p.data.orderNumber}</>;
            },
        },
        {
            field: 'Order Status',
            headerName: TK.orderStatus,
            maxWidth: 180,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            valueGetter: (p) => p.data?.orderStatus,
            cellRenderer: (p: any) => {
                if (!p.data.orderStatus) return <></>;
                return <ChipOrderStatus status={p.data.orderStatus} size="small" label={p.data.orderStatus} />;
            },
        },
        {
            field: 'Comments',
            headerName: t(TK.comments),
            maxWidth: 80,
            cellStyle: { paddingRight: '20px', display: 'flex', alignItems: 'center' },
            menuTabs: [],
            cellRenderer: (p: any) => {
                return (
                    <ChatBubbleOutlineOutlined
                        onClick={() => {
                            onClickCommentsIcon(p.data);
                        }}
                        color="primary"
                    />
                );
            },
        },
        {
            field: 'Last Update',
            headerName: 'Last Update',
            autoHeight: true,
            wrapText: true,
            maxWidth: 210,
            cellStyle: {
                paddingRight: '20px',
                wordBreak: 'break-word',
                display: 'flex',
                alignItems: 'center',
            },
            menuTabs: [],
            sortable: true,
            valueGetter: (p) => p.data?.updatedAt,
            cellRenderer: (p: any) => {
                if (!p.data.updatedAt) return <></>;
                return <>{moment(p.data.updatedAt).format(DateTimeFormatWithotSeconds) + ', by ' + p.data.updatedBy}</>;
            },
        },
    ];
}
