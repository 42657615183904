import * as React from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';


import { accountActivationAsync } from '../../fetch/requests';
import { goToLogin } from '../../store/Router/actions';

const RegistrationActivation: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const [codeIsValid, setCodeIsValid] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const params = new URLSearchParams(window.location.search);
    const code = params.get('confirmationCode');

    const accountActivation = React.useCallback(async (code) => {
        try{
            setIsLoading(true)
            await accountActivationAsync(code);
            setCodeIsValid(true);

        }catch(ex){
            setCodeIsValid(false);
        }
        setIsLoading(false);
    }, []);

    React.useEffect(() => {
        code && accountActivation(code);
    }, [accountActivation, code]);

    const handleGoLogin = () => {
        dispatch(goToLogin());
    };
    return (
        <div style={{ width: '100%', textAlign: 'center', marginTop: '50px' }}>
            <h1>{t(TK.welcomeWixmed)}</h1>            
            {!isLoading && !codeIsValid && <p>{t(TK.confLinkNotValid)}.</p>}

            {!isLoading && codeIsValid && (
                <>
                    <p>{t(TK.registrationCompletedSuccess)}!</p>
                    <Button style={{ cursor: 'pointer' }} onClick={handleGoLogin} variant="contained" color="primary">
                        {t(TK.login)}
                    </Button>
                </>
            )}
        </div>
    );
};

export default RegistrationActivation;
