export interface QualificationForm {
    id: number;
    formId: number;
    formVersion: string | null;
    questions: Question[];
}

export interface Question {
    id: string;
    value: YesNoNAOptions | string | null;
    section: SectionOptions;
    type?: QuestionTypeOptions;
    sectionOrder?: number;
    order: number;
}

export enum QuestionTypeOptions {
    YesNoNA = 'YesNoNA',
    FreeValue = 'FreeValue',
}

export enum YesNoNAOptions {
    Yes = 'Yes',
    No = 'No',
    NA = 'NA',
}

export enum FreeValueIds {
    CarrierName = 'TCQ_CarrierName',
    CarrierCertificateType = 'TCQ_CarrierCertificateType',
    InspectionDate = 'TCQ_InspectionDate',
    CarrierCertificateDesc = 'TCQ_CarrierCertificateDesc',
    TRName = 'TR_TRName',
}

export enum SectionOptions {
    QMS = 'QMS',
    TCQ = 'TCQ',
    HR = 'HR',
    Facilities = 'Facilities',
    Procedures = 'Procedures',
    DM = 'DM',
    Operations = 'Operations',
    Complaints = 'Complaints',
    SA = 'SA',
    ASI = 'ASI',
    TR = 'TR',
}

export enum CertificateOptions {
    gdp = 'GDP',
    iso9001 = 'ISO 9001',
    none = 'No Certificates',
    notApplicable = 'Not Applicable',
    other = 'other',
}

export enum TransportResponsibilityOptions {
    supplier = 'Supplier',
    customer = 'Customer',
}
