import * as React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TK } from '../../store/Translations/translationKeys';
import { Product } from '../../models/Product';
import CountryFlag from '../CountryFlag';
import { DocCell, PharmaCell, PriceCell, Cell, NameCell, ActiveSubtanceCell } from '../AGTable/CellFormatter';
import { excerptText } from '../../utils/utils';
import { ColDef } from 'ag-grid-community';
import { altTextFilterOptions } from '../AGPaginatedTable/Filters';

const textFilterParams = {
    filterOptions: altTextFilterOptions,
    suppressAndOrCondition: true,
};

export function columnsArray(): ColDef<Product>[] {
    return [
        {
            field: 'Country',
            headerName: TK.country,
            filter: 'agSetColumnFilter',
            initialWidth: 120,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            cellStyle: { paddingRight: '20px', justifyContent: 'center' },
            wrapText: true,
            autoHeight: true,
            menuTabs: ['filterMenuTab'],
            headerCheckboxSelectionFilteredOnly: true,
            filterParams: {
                cellRenderer: (p: any) => {
                    if (!p.value || p.value === '(Select All)') return <>All</>;
                    return <CountryFlag country={p.value.toUpperCase()} showCode={false} countryCode={p.value} />;
                },
            },
            valueGetter: (p: any) => p.data.countryCode.toUpperCase(),
            cellRenderer: (p: any) => {
                if (!p.data.countryName || !p.data.countryCode) return <></>;
                return <CountryFlag country={p.data.countryName} showCode={true} countryCode={p.data.countryCode} />;
            },
        },
        {
            headerName: TK.name,
            field: 'Name',
            filter: 'agTextColumnFilter',
            sort: 'asc',
            initialWidth: 210,
            cellStyle: { paddingLeft: '2px' },
            menuTabs: ['filterMenuTab'],
            wrapText: true,
            headerClass: 'agrid-column-name_pl',
            filterParams: textFilterParams,
            valueGetter: (p: any) => p.data.name,
            cellRenderer: (p: any) => {
                if (!p) return <></>;
                return <NameCell rowData={p} />;
            },
        },

        {
            headerName: TK.strength,
            field: 'Strength',
            menuTabs: ['filterMenuTab'],
            filter: 'agTextColumnFilter',
            filterParams: textFilterParams,
            initialWidth: 120,
            wrapText: true,
            autoHeight: true,
            sortable: true,
            valueGetter: (p: any) => p.data.strength + ' ' + p.data.strengthDetailed,
            cellRenderer: (p: any) => {
                const { strength, id } = p.data;
                let { strengthDetailed } = p.data;

                if (!strength || !id || !strengthDetailed) return <></>;
                let showMore = false;
                if (strengthDetailed && strengthDetailed.length > 10) {
                    showMore = true;
                    strengthDetailed = excerptText(10, strengthDetailed);
                }
                return (
                    <Cell subValue={strength} lineHeight="1.2rem" marginTop="0.7rem">
                        {strengthDetailed || '-'}
                        {showMore && <Link to={(location) => ({ ...location, hash: '#' + id })}>...</Link>}
                    </Cell>
                );
            },
        },
        {
            headerName: TK.pharmaceuticalForm,
            headerTooltip: TK.pharmaceuticalForm,
            field: 'PharmaceuticalForm',
            filter: 'agTextColumnFilter',
            menuTabs: ['filterMenuTab'],
            initialWidth: 160,
            wrapText: true,
            autoHeight: true,
            filterParams: textFilterParams,
            sortable: true,
            valueGetter: (p: any) =>
                p.data.pharmaceuticalFormCategories?.join('') + '' + p.data.pharmaceuticalForm ||
                p.data.pharmaceuticalForm ||
                '',
            cellRenderer: (p: any) => {
                if (!p) return <></>;
                return <PharmaCell rowData={p} />;
            },
        },
        {
            headerName: TK.package,
            field: 'Package',
            filter: 'agTextColumnFilter',
            menuTabs: ['filterMenuTab'],
            initialWidth: 140,
            wrapText: true,
            autoHeight: true,
            filterParams: textFilterParams,
            sortable: true,
            valueGetter: (p: any) => p.data.packageDetailed + '' + p.data.package || 0,
            cellRenderer: (p: any) => {
                if (!p.data.package || !p.data.packageDetailed) return <></>;
                return (
                    <Cell lineHeight="1.6rem" subValue={p.data.package}>
                        {p.data.packageDetailed || '-'}
                    </Cell>
                );
            },
        },
        {
            headerName: TK.activeSubstances,
            field: 'ActiveSubstances',
            filter: 'agTextColumnFilter',
            headerTooltip: TK.activeSubstances,
            menuTabs: ['filterMenuTab'],
            initialWidth: 140,
            wrapText: true,
            autoHeight: true,
            filterParams: textFilterParams,
            sortable: true,
            valueGetter: (p: any) => (p.data.activeSubstances?.length && p.data.activeSubstances.join('')) || '',
            cellRenderer: (p: any) => {
                if (!p) return <></>;
                return <ActiveSubtanceCell limit={2} rowData={p} />;
            },
        },
        {
            headerName: TK.atc,
            field: 'ATC',
            filter: 'agTextColumnFilter',
            menuTabs: ['filterMenuTab'],
            initialWidth: 90,
            wrapText: true,
            autoHeight: true,
            filterParams: textFilterParams,
            sortable: true,
            valueGetter: (p: any) => p.data.atc,
            cellRenderer: (p: any) => {
                const { atc } = p.data;
                if (!atc) return <></>;
                const atcs = atc.split('\n');
                return (
                    <>
                        <Cell lineHeight="1.2rem" marginTop="0.5rem">
                            {(atcs?.length &&
                                atcs.map((a: any) => {
                                    return (
                                        <Tooltip key={a} title={<h6>{a}</h6>}>
                                            <Chip size="small" key={a} label={a} />
                                        </Tooltip>
                                    );
                                })) ||
                                ''}
                        </Cell>
                    </>
                );
            },
        },
        {
            headerName: 'Retail Price',
            field: 'retailPrice',
            filter: false,
            sortable: false,
            suppressMenu: true,
            initialWidth: 120,
            wrapText: true,
            autoHeight: true,
            cellRenderer: (p: any) => {
                if (!p) return <></>;
                return <PriceCell rowData={p} />;
            },
        },
        {
            headerName: TK.maHolder,
            field: 'MAHolder',
            filter: 'agTextColumnFilter',
            menuTabs: ['filterMenuTab'],
            filterParams: textFilterParams,
            initialWidth: 120,
            wrapText: true,
            autoHeight: true,
            cellRenderer: (p: any) => {
                if (!p.data.maHolder) return <></>;
                return (
                    <Cell lineHeight="1.2rem" marginTop="0.67rem">
                        {' '}
                        {p.data.maHolder}
                    </Cell>
                );
            },
            valueGetter: (p: any) => p.data.maHolder || '',
        },
        {
            headerName: TK.documents,
            field: 'documentsUrls',
            filter: false,
            sortable: false,
            suppressMenu: true,
            initialWidth: 130,
            wrapText: true,
            autoHeight: true,
            cellRenderer: (p: any) => {
                if (!p) return <></>;
                return <DocCell rowData={p} />;
            },
        },
    ];
}
