import { TransportersState } from './state';
import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import { TransportersLoadedAction } from './types';

const unloadedState: TransportersState = {
    transportersInfo: [],
    isLoading: false,
};

export const persistor = (state: TransportersState): TransportersState => ({
    ...unloadedState,
});

export const reconciler = (stored: TransportersState): TransportersState => ({
    ...stored,
    isLoading: false,
});

const handleTransporterLoading = (state: TransportersState): TransportersState => ({
    ...state,
    isLoading: true,
}); 

const handleTransporterLoaded = (state: TransportersState, action: TransportersLoadedAction): TransportersState => ({
    ...state,
    isLoading: false,
    transportersInfo: action.result,
});

export const reducer: Reducer<TransportersState> = (
    state: TransportersState = unloadedState,
    action: Action,
): TransportersState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return unloadedState;
        case Actions.transportersLoading:
            return handleTransporterLoading(state);
        case Actions.transportersLoaded:
            return handleTransporterLoaded(state, action as TransportersLoadedAction);
        default:
            return state;
    }
};
