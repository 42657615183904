import { AppThunkAction } from '..';
import { Action } from 'redux';
import {
    getPaginatedProductsAsync,
    getProductDetailsAsync,
    getProductsPingAsync,
    getProductsPingAsync2,
    getProductsPingAsync3,
    getProductsPingAsync4,
} from '../../fetch/requests';
import {
    ProductsLoadedAction,
    ProductItemAction,
    ProductAction,
    ProductDetailsLoadedAction,
    ChangeCriteriaAction,
} from './types';
import { SearchResult } from '../../models/SearchResult';
import { alertGenericError, requestServer } from '../Session/actions';
import { ProductV2 } from '../../models/ProductV2';
import { ProductsSearchFilters } from '../../components/ProductsSearchList/ProductsSearchList.types';

export const Actions = {
    productsLoading: '@@whichpharma.productsV2.loading',
    productsLoaded: '@@whichpharma.productsV2.loaded',
    productDetailsLoaded: '@@whichpharma.productsV2.productDetailsloaded',
    productsLoadError: '@@whichpharma.productsV2.loadError',
    productsSelected: '@@whichpharma.productsV2.productsSelected',
    productsDeselected: '@@whichpharma.productsV2.productsDeselected',
    productsDeselectAll: '@@whichpharma.productsV2.productsDeselectAll',
    addOriginByProductId: '@@whichpharma.productsV2.addOriginByProductId',
    productsCriteriaChange: '@@whichpharma.productsV2.productsCriteriaChange',
    productsResetFacets: '@@whichpharma.productsV2.productsResetFacets',
};

const productsLoading = (): Action => ({
    type: Actions.productsLoading,
});

export const productsLoaded = (result: SearchResult<ProductV2>): ProductsLoadedAction => ({
    type: Actions.productsLoaded,
    result,
});

export const productDetailsLoaded = (result: ProductV2): ProductDetailsLoadedAction => ({
    type: Actions.productDetailsLoaded,
    result,
});

const productsLoadError = (): Action => ({
    type: Actions.productsLoadError,
});

export const productsDeselectAll = (): Action => ({
    type: Actions.productsDeselectAll,
});

export const productsSelected = (products: ProductV2[]): ProductAction => ({
    type: Actions.productsSelected,
    products,
});

export const productsDeselected = (products: ProductV2[]): ProductAction => ({
    type: Actions.productsDeselected,
    products,
});

export const addOriginByProductId = (productId: string, origins: (string | undefined)[]): ProductItemAction => ({
    type: Actions.addOriginByProductId,
    productId,
    origins,
});

export const changeCriteria = (criteria: boolean): ChangeCriteriaAction => ({
    type: Actions.productsCriteriaChange,
    criteria,
});

export const resetFacets = (): Action => ({
    type: Actions.productsResetFacets,
});

export const fetchPaginatedProducts = (
    filters: ProductsSearchFilters,
    offset: number,
): AppThunkAction<Promise<void>> => {
    return async (dispatch): Promise<void> => {
        try {
            if (!filters.countries && !filters.free) {
                dispatch(productsLoaded({ items: [], total: 0, timeInSeconds: 0, facets: {} }));
                return;
            }

            dispatch(productsLoading());

            const startTime = new Date().getTime();

            const result = await dispatch(
                requestServer((token, csrfToken) =>
                    getPaginatedProductsAsync({
                        isMarketing: filters.isMarketed!,
                        isEuropean:
                            filters.isEuropean === undefined || filters.isEuropean === 0
                                ? undefined
                                : filters.isEuropean === 1
                                ? true
                                : false,
                        countries: filters.countries,
                        refCountries: filters.refCountries,
                        offset,
                        pageSize: filters.pageSize,
                        orderBy: filters.orderBy,
                        free: filters.free || '',
                        filters: filters.filters,
                        token,
                        csrfToken,
                    }),
                ),
            );

            const endsTime = new Date().getTime();
            let timeInSeconds = 0;
            if (result.total > 0) {
                timeInSeconds = Math.round((endsTime - startTime) / 1000);
                result.timeInSeconds = timeInSeconds;
            }

            dispatch(productsLoaded(result));
        } catch (e) {
            console.log(e);
            dispatch(productsLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const fetchProductDetails = ({ productId }: { productId: string }): AppThunkAction<Promise<void>> => {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(productsLoading());
            const result = await dispatch(
                requestServer((token, csrfToken) =>
                    getProductDetailsAsync({
                        productId,
                        token,
                        csrfToken,
                    }),
                ),
            );
            dispatch(productDetailsLoaded(result));
        } catch (e) {
            dispatch(productsLoadError());
            dispatch(alertGenericError());
        }
    };
};
export const fetchPing = (): AppThunkAction<Promise<void>> => {
    return async (dispatch): Promise<void> => {
        try {
            await dispatch(requestServer((token, csrfToken) => getProductsPingAsync(token, csrfToken)));
        } catch (e) {
            dispatch(alertGenericError());
        }
    };
};

export const fetchPing2 = (): AppThunkAction<Promise<void>> => {
    return async (dispatch): Promise<void> => {
        try {
            await dispatch(requestServer((token, csrfToken) => getProductsPingAsync2(token, csrfToken)));
        } catch (e) {
            dispatch(alertGenericError());
        }
    };
};

export const fetchPing3 = (): AppThunkAction<Promise<void>> => {
    return async (dispatch): Promise<void> => {
        try {
            await dispatch(requestServer((token, csrfToken) => getProductsPingAsync3(token, csrfToken)));
        } catch (e) {
            dispatch(alertGenericError());
        }
    };
};

export const fetchPing4 = (): AppThunkAction<Promise<void>> => {
    return async (dispatch): Promise<void> => {
        try {
            await dispatch(requestServer((token, csrfToken) => getProductsPingAsync4(token, csrfToken)));
        } catch (e) {
            dispatch(alertGenericError());
        }
    };
};
