import React from 'react';
import { GridAnalyticsWrapper } from './styled';

interface IGridAnalytics {
    totalRecords: number;
    timeInSeconds: number;
    caption?: string | undefined;
    showRecords: boolean;
}

export const GridAnalystic: React.FC<IGridAnalytics> = ({
    caption,
    showRecords,
    totalRecords,
    timeInSeconds,
}) => {
    return (
        <GridAnalyticsWrapper>
            <div>
                {caption && (
                    <span>
                        <b>{caption}:</b>
                    </span>
                )}
                <span>
                    {showRecords && totalRecords > 0 ? ' ' + totalRecords : ''}
                    {timeInSeconds > 0 ? ' (' + timeInSeconds + ' seconds)' : ''}
                </span>
            </div>
        </GridAnalyticsWrapper>
    );
};