import * as React from 'react';
import moment from 'moment';
import { DateFormat } from '../../components/Table/DataTypeFormatter';
import { Cell } from '../../components/AGTable/CellFormatter';
import { RFQStateChip } from '../../components/RFQStateChip';
import UserAvatar from '../../components/UserAvatar';
import { Badge, Box, Link } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { RFQState } from '../../models/RFQState';
import CountryFlag from '../../components/CountryFlag';
import { RFQRightPanel } from './RFQRightPanel/RFQRightPanel';
import { ColDef } from 'ag-grid-community';
import { RFQSummary } from '../../models/RFQSummary';
import { dateFilterOptions, textFilterOptions } from '../../components/AGPaginatedTable/Filters';
import { TK } from '../../store/Translations/translationKeys';

export function columnsArray(goToRFQ: any, goToRFQEnquire: any, onClickStateHandler: any): ColDef<RFQSummary>[] {
    const handleClick = (event: any, id: any) => {
        event.preventDefault();
        if (event.ctrlKey) {
            goToRFQEnquire(id);
        }
    };

    return [
        {
            headerName: 'Rfq',
            field: 'Title',
            sortable: true,
            headerClass: 'agrid-column-country-pr',
            initialWidth: 300,
            cellStyle: { justifyContent: 'center' },
            wrapText: true,
            autoHeight: true,
            menuTabs: ['filterMenuTab'],
            filter: 'agTextColumnFilter',
            filterParams: { filterOptions: textFilterOptions },
            valueGetter: (item: any) => item.data!.title,
            cellRenderer: (item: any) => {
                if (!item.data.id || !item.data.title) return <></>;
                return (
                    <Cell>
                        <Link style={{ cursor: 'pointer' }} onClick={() => goToRFQ(item.data.id)}>
                            {item.data.title}
                        </Link>
                    </Cell>
                );
            },
        },
        {
            headerName: 'Date Created',
            colId: 'CreationDate',
            field: 'CreationDate',
            menuTabs: ['filterMenuTab'],
            filter: 'agDateColumnFilter',
            filterParams: { filterOptions: dateFilterOptions },
            initialWidth: 70,
            wrapText: true,
            autoHeight: true,
            headerClass: 'agrid-column-name_pl',
            valueGetter: (item: any) => {
                return moment(item.data!.creationDate).format(DateFormat);
            },
            cellRenderer: (item: any) => {
                if (!item.data!.creationDate) return <></>;
                return (
                    <>
                        <Cell>
                            <p onClick={(event) => handleClick(event, item.data.id)}>
                                {moment(item.data!.creationDate).format(DateFormat)}
                            </p>
                        </Cell>
                    </>
                );
            },
        },
        {
            headerName: 'Country',
            initialWidth: 120,
            filter: false,
            sortable: false,
            suppressMenu: true,
            wrapText: true,
            autoHeight: true,
            valueGetter: (item: any) => 0,
            cellRenderer: (item: any) => {
                if (!item.data.byCountry) return <></>;
                return Object.keys(item.data.byCountry).map((country) => (
                    <Badge
                        badgeContent={item.data.byCountry[country].length}
                        color="primary"
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom',
                        }}
                    >
                        <CountryFlag
                            hideName
                            country={item.data.byCountry[country][0].country}
                            countryCode={item.data.byCountry[country][0].countryCode}
                        />
                    </Badge>
                ));
            },
        },
        {
            headerName: TK.status,
            field: 'State',
            menuTabs: ['filterMenuTab'],
            filter: 'agSetColumnFilter',
            filterParams: {
                values: [...Object.values(RFQState)],
            },
            initialWidth: 160,
            wrapText: true,
            autoHeight: true,
            valueGetter: (item: any) => item.data.state,
            cellRenderer: (item: any) => {
                if (!item.data.state) return <></>;
                return <RFQStateChip state={item.data.state} size="medium" />;
            },
        },
        {
            headerName: 'User',
            field: 'AssigneeUsername',
            menuTabs: ['filterMenuTab'],
            filter: 'agTextColumnFilter',
            filterParams: { filterOptions: textFilterOptions },
            initialWidth: 140,
            wrapText: true,
            autoHeight: true,
            cellStyle: { justifyContent: 'center' },
            valueGetter: (item: any) => item.data.assigneeUsername,
            cellRenderer: (item: any) => {
                if (!item.data.assigneeUsername?.length) return <></>;
                return (
                    <Box>
                        <UserAvatar username={item.data.assigneeUsername} />
                    </Box>
                );
            },
        },
        {
            headerName: 'Due date',
            field: 'EndingDate',
            menuTabs: ['filterMenuTab'],
            filter: 'agDateColumnFilter',
            filterParams: { filterOptions: dateFilterOptions },
            initialWidth: 140,
            wrapText: true,
            autoHeight: true,
            valueGetter: (item: any) => item.data.endingDate,
            cellRenderer: (item: any) => {
                if (!item.data.endingDate) return <></>;
                return <Box>{moment(item.data.endingDate).format(DateFormat)}</Box>;
            },
        },
        {
            headerName: 'Time to close',
            field: 'EndingDate',
            filter: false,
            sortable: false,
            suppressMenu: true,
            initialWidth: 90,
            wrapText: true,
            autoHeight: true,
            valueGetter: (item: any) => item.data.endingDate,
            cellRenderer: (item: any) => {
                if (!item.data.endingDate) return <></>;
                return moment(item.data.endingDate).fromNow();
            },
        },
        {
            headerName: '',
            initialWidth: 120,
            wrapText: true,
            autoHeight: true,
            menuTabs: [],
            valueGetter: (item: any) => 0,
            cellRenderer: (item: any) => {
                return item.data.rfqDetail ? <RFQRightPanel details={item.data.rfqDetail} /> : '';
            },
        },
        {
            headerName: '',
            initialWidth: 120,
            wrapText: true,
            autoHeight: true,
            menuTabs: [],
            valueGetter: (item: any) => 0,
            cellRenderer: (item: any) => {
                if (!item.data.state) return <></>;
                return (
                    <Box>
                        <Link
                            component="button"
                            variant="body2"
                            color="secondary"
                            style={{ fontSize: 'medium' }}
                            onClick={() => onClickStateHandler(item.data)}
                        >
                            {item.data.state === RFQState.Open ? (
                                <span>
                                    <CancelIcon /> Close
                                </span>
                            ) : (
                                <span>
                                    <CheckCircleIcon />
                                    Open
                                </span>
                            )}
                        </Link>
                    </Box>
                );
            },
        },
    ];
}
