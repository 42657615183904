import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import { EnquiryNeedDeclineType, EnquiryNeedDetail } from '../../../models/EnquiryNeed';
import {
    ButtonColor,
    CloseIconWrapper,
    DialogTitleContainer,
    ColorHighlightOff,
    SingleSelectInputDialog,
    StyledTextField,
    TypographyBold,
    TypographyPreWhiteSpace,
} from './EnquiryAcceptDeclineDialog.styles';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';

interface IEnquiryAcceptDeclineDialogProps {
    needOpen?: EnquiryNeedDetail;
    toAccept?: boolean;
    handleDialogClose: () => void;
    handleClick: (
        id: string,
        toAccept: boolean,
        reason?: EnquiryNeedDeclineType,
        comment?: string,
        internal?: string,
    ) => void;
}

export const EnquiryAcceptDeclineDialog: React.FC<IEnquiryAcceptDeclineDialogProps> = ({
    needOpen,
    toAccept,
    handleDialogClose,
    handleClick,
}) => {
    const t = useTranslations();
    const [reason, setReason] = React.useState<EnquiryNeedDeclineType>();
    const [comment, setComment] = React.useState<string>();
    const [needComment, setNeedComment] = React.useState(false);
    const [needReason, setNeedReason] = React.useState(false);
    const [internal, setInternal] = React.useState<string>();

    const handleClose = React.useCallback(() => {
        setComment(undefined);
        setInternal(undefined);
        setReason(undefined);
        setNeedComment(false);
        handleDialogClose();
    }, [handleDialogClose]);

    const acceptOrDeclineClick = React.useCallback(() => {
        if (!toAccept && reason === undefined) {
            setNeedReason(true);
            return;
        }
        if (reason === EnquiryNeedDeclineType.Other && !comment) {
            setNeedComment(true);
            return;
        }
        handleClick(needOpen?.id!, toAccept!, reason, comment, internal);
        handleClose();
    }, [reason, comment, internal, toAccept, needOpen, handleClick, handleClose]);

    return (
        <Dialog open={!!needOpen?.id} onClose={handleClose} maxWidth="lg">
            <DialogTitle style={{ padding: 0 }}>
                <DialogTitleContainer>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                            <Typography style={{ marginLeft: '5px', color: 'white' }} variant="h5">
                                {toAccept ? t(TK.acceptNeed) : t(TK.declineNeed)}
                            </Typography>
                        </div>
                    </div>
                    <CloseIconWrapper>
                        <IconButton onClick={handleClose} style={{ outline: 'none' }}>
                            <ColorHighlightOff colors="white" fontSize="large" />
                        </IconButton>
                    </CloseIconWrapper>
                </DialogTitleContainer>
                <hr style={{ margin: 0 }} />
            </DialogTitle>
            <DialogContent style={{ width: '100%' }}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TypographyBold color="primary" variant="h6">
                                {needOpen?.needName}
                            </TypographyBold>
                        </Grid>
                        {!toAccept && (
                            <Grid item xs={12} md={12} lg={3}>
                                <TypographyPreWhiteSpace color={needReason ? 'error' : 'inherit'} bold>
                                    {'Reason'}
                                </TypographyPreWhiteSpace>
                                <SingleSelectInputDialog
                                    options={Object.values(EnquiryNeedDeclineType)}
                                    getOptionLabel={(reason: EnquiryNeedDeclineType) => t(TK[reason])}
                                    placeholder={t(TK.reasonWhy)}
                                    value={reason}
                                    onChange={(reason: EnquiryNeedDeclineType) => {
                                        setReason(reason);
                                        setNeedReason(false);
                                        needComment &&
                                            setNeedComment(reason === EnquiryNeedDeclineType.Other && !comment);
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12} md={12} lg={12}>
                            <TypographyPreWhiteSpace color={needComment ? 'error' : 'inherit'} bold>
                                {t(TK.notesToClient)}
                            </TypographyPreWhiteSpace>
                            <StyledTextField
                                onChange={(value: any) => {
                                    setComment(value.target.value);
                                    setNeedComment(reason === EnquiryNeedDeclineType.Other && !value.target.value);
                                }}
                                value={comment}
                                margins="0px 16px"
                                resize="vertical"
                                width={'-webkit-fill-available'}
                                multiline
                                rows={2}
                                rowsMax={25}
                                variant="outlined"
                                size="medium"
                            ></StyledTextField>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <TypographyPreWhiteSpace bold>{t(TK.comments)}</TypographyPreWhiteSpace>
                            <StyledTextField
                                onChange={(value) => setInternal(value.target.value)}
                                value={internal}
                                margins="0px 16px"
                                resize="vertical"
                                width={'-webkit-fill-available'}
                                multiline
                                rows={2}
                                rowsMax={25}
                                variant="outlined"
                                size="medium"
                            ></StyledTextField>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Box style={{ display: 'flex', justifyContent: 'center', marginBottom: '16px' }}>
                                {(needComment || needReason) && (
                                    <Grid item xs={8} md={4} lg={2}>
                                        <Typography color="error">
                                            {needReason ? 'Please select reason' : 'Please fill clients notes'}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={8} md={4} lg={2}>
                                    <ButtonColor
                                        onClick={() => acceptOrDeclineClick()}
                                        disabled={needComment || needReason}
                                        colorRGB={toAccept}
                                        fullWidth
                                        variant={!toAccept ? 'contained' : 'outlined'}
                                    >
                                        {toAccept ? t(TK.accept) : t(TK.decline)}
                                    </ButtonColor>
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
