import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1%;
    font-size: 1.5rem;
`;

export const Text = styled.span`
    margin-right: 0.8rem;
`;

export const CenteredBox = styled(Box)`
    display: flex;
    justifycontent: center;
    alignitems: center;
    height: 100%;
`;
