import * as React from 'react';
import { Box, Grid, Select, Typography } from '@material-ui/core';
import TextInput from '../../../components/inputs/TextInput';
import { useTranslations } from '../../../store/Translations/hooks';
import { RFQQuote } from '../../../models/RFQQuote';
import { TK } from '../../../store/Translations/translationKeys';

const SupplierInfoForm: React.FC<{
    value: RFQQuote;
    fieldsWithError?: string[];
    readOnly?: boolean;
    setValue: (value: RFQQuote) => void;
}> = ({ value, fieldsWithError = [], readOnly, setValue }) => {
    const t = useTranslations();

    const [isAvailError, setIsAvailError] = React.useState(false);
    const [isPacksError, setIsPacksError] = React.useState(false);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5">{t(TK.yourQuote)}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex">
                    <TextInput
                        style={{ backgroundColor: readOnly ? '#f2f2f2' : '#ffffff', width: '150px' }}
                        type="number"
                        readOnly={readOnly}
                        label={t(TK.netPriceEuro) + '*'}
                        error={fieldsWithError.includes(TK.netPriceEuro)}
                        value={value.exwNetPriceEuro}
                        onKeyUp={(e: { target: { value: any } }) => {
                            if (e.target.value === '') {
                                setIsPacksError(true);
                            } else {
                                setIsPacksError(false);
                            }
                        }}
                        onChange={(exwNetPriceEuro) => {
                            setValue({
                                ...value,
                                exwNetPriceEuro: exwNetPriceEuro.match(/^\d{1,}(\.\d{0,4})?$/) ? exwNetPriceEuro : '',
                            });
                        }}
                    />
                    <Select
                        native
                        value={value.currency}
                        style={{
                            backgroundColor: readOnly ? '#f2f2f2' : '#ffffff',
                            height: '39px',
                            marginLeft: '10px',
                            marginRight: '10px',
                        }}
                        variant="outlined"
                        onChange={(evt) => setValue({ ...value, currency: evt.target.value as string })}
                        name="currency"
                        disabled={readOnly}
                    >
                        <option value="EUR">EURO</option>
                        <option value="USD">USD</option>
                        <option value="CAD">CAD</option>
                    </Select>
                    <TextInput
                        style={{ backgroundColor: readOnly ? '#f2f2f2' : '#ffffff', width: '150px' }}
                        type="number"
                        readOnly={readOnly}
                        label={t(TK.availabilityPacks) + '*'}
                        error={fieldsWithError.includes(TK.availabilityPacks)}
                        value={value.availabilityPacks?.toString()}
                        onKeyUp={(e: { target: { value: any } }) => {
                            if (e.target.value === '') {
                                setIsAvailError(true);
                            } else {
                                setIsAvailError(false);
                            }
                        }}
                        onChange={(availabilityPacks) => {
                            setValue({
                                ...value,
                                availabilityPacks: availabilityPacks.match(/^\d{1,}(\.\d{0,4})?$/)
                                    ? availabilityPacks
                                    : '',
                            });
                        }}
                    />
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Box>
                        {isPacksError ? (
                            <p>
                                <Typography style={{ fontSize: '10px', color: '#ff0000' }}>
                                    Only numbers are allowed
                                </Typography>
                            </p>
                        ) : (
                            ''
                        )}
                    </Box>
                    <Box style={{ marginRight: '200px' }}>
                        {isAvailError ? (
                            <p>
                                <Typography style={{ fontSize: '10px', color: '#ff0000' }}>
                                    Only numbers are allowed
                                </Typography>
                            </p>
                        ) : (
                            ''
                        )}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex">
                    <TextInput
                        style={{ backgroundColor: readOnly ? '#f2f2f2' : '#ffffff', width: '150px' }}
                        type="month"
                        readOnly={readOnly}
                        label={t(TK.expiryDate)}
                        error={fieldsWithError.includes(TK.expiryDate)}
                        value={value.expDate}
                        onChange={(expDate) => setValue({ ...value, expDate })}
                    />
                    <TextInput
                        style={{
                            backgroundColor: readOnly ? '#f2f2f2' : '#ffffff',
                            width: '149px',
                            marginLeft: '108px',
                        }}
                        type="number"
                        endAdorment={t(TK.days)}
                        readOnly={readOnly}
                        label={t(TK.leadTimeToDeliver) + '*'}
                        error={fieldsWithError.includes(TK.leadTimeToDeliver)}
                        value={value.leadTimeToDeliver}
                        onChange={(leadTimeToDeliver) => setValue({ ...value, leadTimeToDeliver })}
                    />
                </Box>
            </Grid>

            <Grid item xs={12}>
                <TextInput
                    style={{ backgroundColor: readOnly ? '#f2f2f2' : '#ffffff', width: '100%' }}
                    multiline
                    readOnly={readOnly}
                    label={t(TK.comments)}
                    error={fieldsWithError.includes(TK.comments)}
                    value={value.comments}
                    onChange={(comments) => setValue({ ...value, comments })}
                />
            </Grid>
        </Grid>
    );
};

export default SupplierInfoForm;
