import { ApplicationState } from '..';
import { Facets } from '../../models/Facets';
import { ProductV2 } from '../../models/ProductV2';

export const getAllProducts = (state: ApplicationState): { [id: string]: ProductV2 } => state.productsV2.items || {};
export const getProductDetails = (state: ApplicationState, productId: string): ProductV2 => state.productsV2.items[productId];
export const getSearchResult = (state: ApplicationState): string[] => state.productsV2.searchResult;
export const getSelectedProducts = (state: ApplicationState): ProductV2[] => state.productsV2.selectedProducts || [];
export const getSearchTotalIncludingNotLoaded = (state: ApplicationState): number => state.productsV2.searchTotal || 0;
export const getRequestTimeInSeconds = (state: ApplicationState): number => state.productsV2.timeInSeconds;
export const isLoadingProducts = (state: ApplicationState): boolean => state.productsV2.isLoading;
export const getOriginsByProductId = (state: ApplicationState): { [id: string]: (string | string[] | undefined)[] } =>
    state.productsV2.origins;
export const getProductsSearchFacets = (state: ApplicationState): Facets => state.productsV2.facets || {};
export const hasCriteriaChanged = (state: ApplicationState): boolean => state.productsV2.hasCriteriaChanged;
