import React, { useRef } from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import { currencyFormatter } from '../../utils/utils';
import { Chip, Tooltip } from '@material-ui/core';
import { PriceTitle, PriceList, PriceListItem, PriceCaption } from './styled';
import { TK } from '../../store/Translations/translationKeys';
import { Spinner } from 'reactstrap';

interface PriceProps {
    title: string;
    priceWithoutVat?: number;
    priceWithVat?: number;
    priceWithVatOrig?: number;
    priceWithoutVatOrig?: number;
    value?: number;
    exchangeRate?: number;
    currencyCode?: string;
    targetCurrencyCode?: string;
    label?: string;
}

const Price: React.FC<PriceProps> = ({
    title,
    exchangeRate,
    priceWithoutVat,
    priceWithVat,
    priceWithoutVatOrig,
    priceWithVatOrig,
    value,
    label,
    currencyCode = 'EUR',
    targetCurrencyCode = 'EUR',
}: PriceProps) => {
    const t = useTranslations();

    const componentIsMounted = useRef(true);

    React.useEffect(() => {
        return () => {
            componentIsMounted.current = false;
        };
    }, []);

    const [price, setPrice] = React.useState<React.ReactNode>(<Spinner size="sm" />);
    const [priceOrigVat, setPriceOrigVat] = React.useState<React.ReactNode>(<Spinner size="sm" />);
    const [priceOrig, setPriceOrig] = React.useState<React.ReactNode>(<Spinner size="sm" />);

    React.useMemo(
        (): void => {
            value &&
                currencyFormatter(value, targetCurrencyCode).then(
                    (val) => componentIsMounted && setPrice(<div style={{ fontSize: '0.9rem' }}>{val}</div>),
                );
            priceWithVatOrig &&
                currencyFormatter(priceWithVatOrig, currencyCode).then(
                    (val) => componentIsMounted && setPriceOrigVat(<div style={{ fontSize: '0.9rem' }}>{val}</div>),
                );
            priceWithoutVatOrig &&
                currencyFormatter(priceWithoutVatOrig, currencyCode).then(
                    (val) => componentIsMounted && setPriceOrig(<div style={{ fontSize: '0.9rem' }}>{val}</div>),
                );
        },
        // eslint-disable-next-line
        [],
    );

    if (!value) {
        return <span>-</span>;
    }

    if (priceWithVat || priceWithVatOrig) {
        return (
            <Tooltip
                title={
                    <>
                        <PriceList>
                            {priceWithVatOrig && (
                                <PriceListItem>
                                    <PriceCaption>Original Price</PriceCaption>
                                    {priceOrigVat}
                                </PriceListItem>
                            )}
                            {priceWithVat && priceWithoutVat && (
                                <PriceListItem>
                                    <PriceCaption>{t(TK.withoutVAT)}</PriceCaption>
                                    {new Intl.NumberFormat('pt-PT', {
                                        style: 'currency',
                                        currency: targetCurrencyCode,
                                    }).format(priceWithoutVat)}
                                </PriceListItem>
                            )}
                            {priceWithoutVatOrig && (
                                <PriceListItem>
                                    <PriceCaption>{t(TK.withoutVAT) + ' (' + t(TK.original) + ')'}</PriceCaption>
                                    {priceOrig}
                                </PriceListItem>
                            )}
                            {exchangeRate && (
                                <PriceListItem>
                                    <PriceCaption>
                                        {t(TK.exchangeRate)}: {Number(exchangeRate.toFixed(2))}
                                    </PriceCaption>
                                </PriceListItem>
                            )}
                        </PriceList>
                    </>
                }
            >
                <span>
                    <Chip size="small" label={price} style={{ fontSize: '0.9rem' }} />
                </span>
            </Tooltip>
        );
    }
    return (
        <Tooltip
            title={
                <>
                    <PriceTitle>{title}</PriceTitle>
                    <PriceList>
                        {value && (
                            <PriceListItem>
                                <PriceCaption>{label}</PriceCaption>
                                {price}
                            </PriceListItem>
                        )}
                        {exchangeRate && (
                            <PriceListItem>
                                <PriceCaption>{t(TK.exchangeRate)}</PriceCaption>
                                {Number(exchangeRate.toFixed(2))}
                            </PriceListItem>
                        )}
                    </PriceList>
                </>
            }
        >
            <span>
                <Chip size="small" label={price} />
            </span>
        </Tooltip>
    );
};

export default Price;
