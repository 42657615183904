import * as React from 'react';
import Page from '../../components/Page';
import { Typography } from '@material-ui/core';

import { SuccessIcon, SuccessPanel, SuccessParagraph } from './RegisterComplete.styles';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';

const RegisterComplete: React.FC = () => {
    const t = useTranslations();
    return (
        <Page>
            <SuccessPanel>
                <SuccessParagraph>
                    <SuccessIcon />
                    <Typography color="primary" variant="h5">
                        {t(TK.registrationComplete)}
                    </Typography>
                </SuccessParagraph>
                <Typography style={{ margin: '2rem' }} variant="h5">
                    {t(TK.thankYouForSigningUpOnWixmed)}
                </Typography>
                <Typography align="center" style={{ width: 600 }}>
                    {t(TK.youWillReceiveAConfirmationEmailShortlyPleaseFollowTheInstructionsInTheEmailToFinalizeYourRegistration)}
                </Typography>
            </SuccessPanel>
        </Page>
    );
};

export default RegisterComplete;
