import { Action, Reducer } from 'redux';
import { CompaniesListState } from './state';
import { CompaniesListLoadedAction, SearchCompaniesListLoadedAction } from './types';
import { Actions as SessionActions } from '../Session/actions';
import { Actions } from './actions';

const unloadState: CompaniesListState = {
    companies: {},
    isLoading: false,
    searchTotal: 0,
    searchResult: [],
    facets: {},
};

export const persistor = (state: CompaniesListState): CompaniesListState => ({
    ...unloadState,
});

export const reconciler = (stored: CompaniesListState): CompaniesListState => ({
    ...stored,
    isLoading: false,
});

const handleCompaniesListLoading = (state: CompaniesListState): CompaniesListState => ({
    ...state,
    isLoading: true,
});

const handleCompaniesListLoadError = (state: CompaniesListState): CompaniesListState => ({
    ...state,
    isLoading: false,
});

// Really needed?
const handleCompaniesListLoaded = (
    state: CompaniesListState,
    action: CompaniesListLoadedAction,
): CompaniesListState => ({
    ...state,
    isLoading: false,
    companies: Object.assign({}, ...action.result.items.map((c: any) => ({ [c.id]: c }))),
    searchTotal: action.result.total,
});

const handleCompaniesListSearchLoaded = (
    state: CompaniesListState,
    action: SearchCompaniesListLoadedAction,
): CompaniesListState => ({
    ...state,
    isLoading: false,
    searchResult: action.result.items.map((c: any) => c.id),
    searchTotal: action.result.total,
    companies: Object.assign({}, ...action.result.items.map((c: any) => ({ [c.id]: c }))),
    facets: action.result.facets || {},
});

export const reducer: Reducer<CompaniesListState> = (
    state: CompaniesListState = unloadState,
    action: Action,
): CompaniesListState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return reconciler(state);
        case Actions.companiesLoading:
            return handleCompaniesListLoading(state);
        case Actions.companiesLoadError:
            return handleCompaniesListLoadError(state);
        case Actions.companiesLoaded:
            return handleCompaniesListLoaded(state, action as CompaniesListLoadedAction);
        case Actions.searchCompaniesLoaded:
            return handleCompaniesListSearchLoaded(state, action as SearchCompaniesListLoadedAction);
        default:
            return state;
    }
};
