import { AppThunkAction } from '..';
import { Action } from 'redux';
import { getAdditionalDocumentsAsync } from '../../fetch/requests';
import { AdditionalDocumentsLoadedAction } from './types';
import { requestServer } from '../Session/actions';
import { isLoadingAdditionalDocuments } from './selectors';
import { AdditionalDocuments } from '../../models/AdditionalDocuments';

export const Actions = {
    additionalDocumentsLoading: '@@whichpharma.additionalDocuments.loading',
    additionalDocumentsLoaded: '@@whichpharma.additionalDocuments.loaded',
};

const additionalDocumentsLoading = (): Action => ({
    type: Actions.additionalDocumentsLoading,
});

const additionalDocumentsLoaded = (result: AdditionalDocuments[]): AdditionalDocumentsLoadedAction => {
    console.log(result);
    return {
        type: Actions.additionalDocumentsLoaded,
        result,
    };
};

export const fetchAdditionalDocuments = (): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingAdditionalDocuments(getState())) {
                return;
            }
            dispatch(additionalDocumentsLoading());
            const result = await dispatch(requestServer((token, csrfToken) => getAdditionalDocumentsAsync(token, csrfToken)));
            dispatch(additionalDocumentsLoaded(result));
        } catch (e) {
            console.log(e);
        }
    };
};
