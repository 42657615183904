import { IconButton, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useState } from 'react';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';
import TextInput from '../../inputs/TextInput';

interface IAGSearchProps {
    onHandleChange: (val: string) => void;
}

export const AGSearch: React.FC<IAGSearchProps> = ({ onHandleChange }) => {
    const t = useTranslations();

    const [text, setText] = useState('');

    const onChange = useCallback(
        (val) => {
            setText(val);
            onHandleChange(val);
        },

        [onHandleChange, setText],
    );

    return (
        <TextInput
            placeholder={t(TK.searchAnyColumn)}
            fullWidth={true}
            type="text"
            value={text}
            onChange={onChange}
            formStyle={{ marginLeft: '12px', marginTop: '6px', marginRight: '12px' }}
            endAdorment={
                <InputAdornment position="end">
                    <IconButton style={{ outline: 'none' }} aria-label="free text filter info" edge="start">
                        <SearchIcon color="primary" />
                    </IconButton>
                </InputAdornment>
            }
        />
    );
};
