import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import { DomainInfoState, DomainInfoData, DomainInfoName } from './state';
import { DomainInfoUpdatedAction, DomainInfoAction } from './types';

const unloadedDomainInfoData: DomainInfoData<any> = {
    items: [],
    isUpdating: false,
    lastUpdate: new Date(1990),
};

const unloadedState: DomainInfoState = {
    [DomainInfoName.Countries]: { ...unloadedDomainInfoData },
    [DomainInfoName.Transporters]: { ...unloadedDomainInfoData },
    [DomainInfoName.AdditionalDocuments]: { ...unloadedDomainInfoData },
    [DomainInfoName.CompanyTypes]: { ...unloadedDomainInfoData },
    [DomainInfoName.PaymentTerms]: { ...unloadedDomainInfoData },
    [DomainInfoName.DeliveryTerms]: { ...unloadedDomainInfoData },
    [DomainInfoName.TaxRates]: { ...unloadedDomainInfoData },
    [DomainInfoName.ProductTypes]: { ...unloadedDomainInfoData },
};

export const persistor = (state: DomainInfoState): DomainInfoState => ({
    [DomainInfoName.Countries]: { ...state?.[DomainInfoName.Countries], isUpdating: false },
    [DomainInfoName.Transporters]: { ...state?.[DomainInfoName.Transporters], isUpdating: false },
    [DomainInfoName.AdditionalDocuments]: { ...state?.[DomainInfoName.AdditionalDocuments], isUpdating: false },
    [DomainInfoName.CompanyTypes]: { ...state?.[DomainInfoName.CompanyTypes], isUpdating: false },
    [DomainInfoName.PaymentTerms]: { ...state?.[DomainInfoName.PaymentTerms], isUpdating: false },
    [DomainInfoName.DeliveryTerms]: { ...state?.[DomainInfoName.DeliveryTerms], isUpdating: false },
    [DomainInfoName.TaxRates]: { ...state?.[DomainInfoName.TaxRates], isUpdating: false },
    [DomainInfoName.ProductTypes]: { ...state?.[DomainInfoName.ProductTypes], isUpdating: false },
});

export const reconciler = persistor;

export const reducer: Reducer<DomainInfoState> = (
    state: DomainInfoState = unloadedState,
    action: Action,
): DomainInfoState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return unloadedState;
        case Actions.domainInfoUpdated:
            return handleDomainInfoUpdated(state, action as DomainInfoUpdatedAction);
        case Actions.domainInfoUpdating:
            return handleDomainInfoUpdating(state, action as DomainInfoAction);
        case Actions.domainInfoUpdateError:
            return handleDomainInfoUpdateError(state, action as DomainInfoAction);
        default:
            return state;
    }
};

const handleDomainInfoUpdated = (state: DomainInfoState, action: DomainInfoUpdatedAction): DomainInfoState => ({
    ...state,
    [action.domainInfoName]: {
        items: action.values,
        isUpdating: false,
        lastUpdate: new Date(),
    },
});

const handleDomainInfoUpdating = (state: DomainInfoState, action: DomainInfoAction): DomainInfoState => ({
    ...state,
    [action.domainInfoName]: {
        ...state[action.domainInfoName],
        isUpdating: true,
        lastUpdate: new Date(),
    },
});

const handleDomainInfoUpdateError = (state: DomainInfoState, action: DomainInfoAction): DomainInfoState => ({
    ...state,
    [action.domainInfoName]: {
        ...state[action.domainInfoName],
        isUpdating: false,
        lastUpdate: new Date(),
    },
});
