import { ApplicationState, AppThunkAction } from '..';
import { RFQSummary } from '../../models/RFQSummary';
import { Action } from 'redux';
import {
    postRFQRequestAsyncV3,
    RequestError,
    getRFQsDetailsAsyncV3,
    getRFQDetailsAsyncV3,
    putRFQAssigneeAsyncV3,
    putRFQDataAsyncV3,
    getRFQNextNumberAsyncV3,
    putRFQCardsDataAsyncV3,
    getRFQsAsyncPaginated,
    putRFQStateReasonAsyncV3,
} from '../../fetch/requests';
import {
    RFQsSummariesLoadedAction,
    RFQsSubmittedAction,
    RFQsDetailsLoadedAction,
    RFQDetailsLoadedAction,
    RFQUpdatedAction,
    RFQUpdatingAction,
    RFQTableQuotesDataUpdateAction,
    RFQAction,
    RFQsCreatedAction,
} from './types';
import { SearchResult } from '../../models/SearchResult';
import { alertGenericError, requestServer, alertError, alertSuccess } from '../Session/actions';
import { IRFQDueRminderRequest, IRFQStateReasonRequest, RFQRequestV3 } from '../../models/RFQRequest';
import { isLoadingRFQs, isSubmittingRFQs, getRFQSummaries, isUpdatingRFQ, getRFQDetails } from './selectors';
import { suppliersDeselectAll } from '../Suppliers/actions';
import { productsDeselectAll } from '../RFQProducts/actions';
import { goToRFQCreated } from '../Router/actions';
import { TK } from '../Translations/translationKeys';
import { RFQCreationError } from '../../models/RFQCreationError';
import { HTTP_BAD_REQUEST } from '../../fetch/statusCodes';
import { getTranslation } from '../Translations/selectors';
import { RFQDetails, RFQDetailsResponse } from '../../models/RFQDetails';
import { RFQUpdateError } from '../../models/RFQUpdateError';
import { MapOf } from '../../utils/Types';
import { RFQQuoteInfo } from '../../models/RFQQuoteInfo';
import { RFQQuoteTableItem } from '../../pages/RFQDetails/RFQDetails';
import { IRFQStateReasonResponse } from '../../models/RFQResponse';

export const Actions = {
    rfqsLoading: '@@whichpharma.rfqsV3.loading',
    rfqsLoaded: '@@whichpharma.rfqsV3.loaded',
    rfqsDetailsLoading: '@@whichpharma.rfqsV3.detailsLoading',
    rfqsDetailsLoaded: '@@whichpharma.rfqsV3.detailsLoaded',
    rfqDetailsLoaded: '@@whichpharma.rfqsV3.detailsLoaded',
    rfqHistoryLoaded: '@@whichpharma.rfqsV3.historyLoaded',
    rfqsLoadError: '@@whichpharma.rfqsV3.loadError',
    rfqsSubmitting: '@@whichpharma.rfqsV3.submitting',
    rfqsSubmitted: '@@whichpharma.rfqsV3.submitted',
    rfqsSubmitError: '@@whichpharma.rfqsV3.submitError',
    rfqsUpdating: '@@whichpharma.rfqsV3.updating',
    rfqUpdate: '@@whichpharma.rfqsV3.update',
    rfqCreatedNrs: '@@whichpharma.rfqsV3.createdNrs',
    rfqsUpdated: '@@whichpharma.rfqsV3.updated',
    rfqsUpdateError: '@@whichpharma.rfqsV3.updateError',
    rfqTableDataUpdated: '@@whichpharma.rfqsV3.tableDataUpdated',
    rfqQuotesTableDataUpdated: '@@whichpharma.rfqsV3.quotes.tableDataUpdated',
    rfqNextRfqNumberUpdated: '@@whichpharma.rfqsV3.rfqNextRfqNumberUpdated',
};

const rfqsLoading = (): Action => ({
    type: Actions.rfqsLoading,
});
const rfqsCreated = (createdNrs: string[]): RFQsCreatedAction => ({
    type: Actions.rfqCreatedNrs,
    numbers: createdNrs,
});
const rfqsLoaded = (result: SearchResult<RFQSummary>): RFQsSummariesLoadedAction => ({
    type: Actions.rfqsLoaded,
    result,
});

const rfqsDetailsLoading = (): Action => ({
    type: Actions.rfqsDetailsLoading,
});

const rfqsDetailsLoaded = (response: RFQDetailsResponse): RFQsDetailsLoadedAction => ({
    type: Actions.rfqsDetailsLoaded,
    ...response,
});

const rfqDetailsLoaded = (rfqNr: string, result: RFQDetails): RFQDetailsLoadedAction => ({
    type: Actions.rfqDetailsLoaded,
    rfqNr,
    result,
});

const rfqsLoadError = (): Action => ({
    type: Actions.rfqsLoadError,
});

const rfqsSubmitting = (): Action => ({
    type: Actions.rfqsSubmitting,
});

const rfqsSubmitted = (request: RFQRequestV3): RFQsSubmittedAction => ({
    type: Actions.rfqsSubmitted,
    request,
});

const rfqsUpdating = (rfqNumber: string): RFQUpdatingAction => ({
    type: Actions.rfqsUpdating,
    rfqNumber,
});

const rfqUpdated = (result: RFQSummary): RFQUpdatedAction => ({
    type: Actions.rfqsUpdated,
    result,
});

const rfqsSubmitError = (): Action => ({
    type: Actions.rfqsSubmitError,
});

const rfqsUpdateError = (rfqNumber: string): RFQUpdatingAction => ({
    type: Actions.rfqsUpdateError,
    rfqNumber,
});

const rfqQuotesTableDataUpdated = (
    rfqNumber: string,
    dataByThreadId: MapOf<RFQQuoteInfo[]>,
): RFQTableQuotesDataUpdateAction => ({
    type: Actions.rfqQuotesTableDataUpdated,
    rfqNumber,
    dataByThreadId,
});

const nextRfqNumberUpdated = (nextRfqNumber: string): RFQAction => ({
    type: Actions.rfqNextRfqNumberUpdated,
    rfqId: nextRfqNumber,
});

export const fetchRFQsV3 = (
    onlyMine: boolean,
    search?: string,
    expiredIn?: string,
    createdBy?: string,
    filters?: string,
    orderBy?: string,
    offset = 0,
    pageSize = 50,
): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingRFQs(getState())) {
                console.log('rfqs are already loading.....');
                return;
            }
            dispatch(rfqsLoading());
            const startTime = new Date().getTime();

            const result = await dispatch(
                requestServer((token, csrfToken) =>
                    getRFQsAsyncPaginated(
                        onlyMine,
                        search,
                        expiredIn,
                        createdBy,
                        filters,
                        offset,
                        pageSize,
                        orderBy,
                        token,
                        csrfToken,
                    ),
                ),
            );

            const endsTime = new Date().getTime();
            let timeInSeconds = 0;
            if (result.total > 0) {
                timeInSeconds = Math.round((endsTime - startTime) / 1000);
                result.timeInSeconds = timeInSeconds;
            }

            dispatch(rfqsLoaded(result || []));
            dispatch(fetchRFQsDetails(true));
        } catch (e) {
            console.log(e);
            dispatch(rfqsLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const fetchRFQsDetails = (onlyMissing?: boolean): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            const state = getState();
            const rfqs = getRFQSummaries(state);
            const details = getRFQDetails(state);
            const rfqsNrs = onlyMissing
                ? Object.values(rfqs)
                      .filter((rfq) => !details[rfq.number])
                      .map((rfq) => rfq.number)
                : Object.values(rfqs).map((rfq) => rfq.number);
            dispatch(rfqsDetailsLoading());

            if (!(rfqsNrs && rfqsNrs.length > 0)) {
                console.log('no rfq details to load');
                dispatch(rfqsDetailsLoaded({ result: [], timeInSeconds: 0 }));
                return;
            }

            const startTime = new Date().getTime();
            const result = await dispatch(
                requestServer((token, csrfToken) => getRFQsDetailsAsyncV3(rfqsNrs, token, csrfToken)),
            );
            const endsTime = new Date().getTime();
            let timeInSeconds = 0;
            if (result.length > 0) {
                timeInSeconds = Math.round((endsTime - startTime) / 1000);
            }
            dispatch(rfqsDetailsLoaded({ result, timeInSeconds: timeInSeconds }));
        } catch (e) {
            console.log(e);
        }
    };
};

export const fetchRFQDetails = (rfqNr: string): AppThunkAction<Promise<void>> => {
    return async (dispatch): Promise<void> => {
        try {
            dispatch(rfqsLoading());
            const result = await dispatch(
                requestServer((token, csrfToken) => getRFQDetailsAsyncV3(rfqNr, token, csrfToken)),
            );
            dispatch(rfqDetailsLoaded(rfqNr, result));
        } catch (e) {
            console.log(e);
        }
    };
};

const getRFQCreationErrorTranslationKey = (error: RFQCreationError): TK => {
    switch (error) {
        case RFQCreationError.SupplierFirstNameMissing:
            return TK.supplierFirstNameMissing;
        case RFQCreationError.SupplierLastNameMissing:
            return TK.supplierLastNameMissing;
        case RFQCreationError.UserFirstNameMissing:
            return TK.userFirstNameMissing;
        case RFQCreationError.UserLastNameMissing:
            return TK.userLastNameMissing;
        case RFQCreationError.UserEmailMissing:
            return TK.userEmailMissing;
        case RFQCreationError.UserTitleMissing:
            return TK.userTitleMissing;
        case RFQCreationError.ProductsTableMissing:
            return TK.productsTableMissing;
        case RFQCreationError.SupplierNotFoundInDatabase:
            return TK.supplierNotFoundInDatabase;
        case RFQCreationError.RecipientNotFoundInSupplierData:
            return TK.recipientNotFoundInSupplierData;
        case RFQCreationError.UserNotFoundInDatabase:
            return TK.userNotFoundInDatabase;
        case RFQCreationError.EmailBodyContainsUnknownPlaceholder:
            return TK.emailBodyContainsUnknownPlaceholder;
        case RFQCreationError.UnableToConnectToYourEmailAccount:
            return TK.unableToConnectToYourEmailAccount;
        case RFQCreationError.DueDateMissing:
            return TK.dueDateMissing;
        default:
            return TK.somethingWentWrong;
    }
};

const getRFQCreationErrorMessage = (error: any, state: ApplicationState): string => {
    const result: RFQCreationError | undefined =
        (error instanceof RequestError &&
            (error as RequestError).statusCode === HTTP_BAD_REQUEST &&
            ((error as RequestError).body?.error as RFQCreationError)) ||
        undefined;

    if (result !== undefined) {
        return getTranslation(state, getRFQCreationErrorTranslationKey(result));
    }

    const stateModelResult =
        (error instanceof RequestError &&
            (error as RequestError).statusCode === HTTP_BAD_REQUEST &&
            ((error as RequestError).body?.errors as MapOf<string[]>)) ||
        undefined;

    if (stateModelResult !== undefined) {
        return `Invalid request: ${Object.keys(stateModelResult).map(
            (field) => `${field} - ${stateModelResult[field].join(' ')}`,
        )}`;
    }

    return getTranslation(state, TK.somethingWentWrong);
};

const getRFQUpdateErrorMessage = (error: any): TK => {
    const result: RFQUpdateError | undefined =
        (error instanceof RequestError &&
            (error as RequestError).statusCode === HTTP_BAD_REQUEST &&
            ((error as RequestError).body?.error as RFQUpdateError)) ||
        undefined;

    switch (result) {
        case RFQUpdateError.UserNotFound:
            return TK.userNotFoundInDatabase;
        case RFQUpdateError.UserIsNotACollaborator:
            return TK.userIsNotACollaborator;
        case RFQUpdateError.UserIsNotAllowed:
            return TK.userIsNotAllowed;
        case RFQUpdateError.ThreadNotFound:
            return TK.threadNotFound;
        default:
            return TK.somethingWentWrong;
    }
};

export const submitRFQs = (request: RFQRequestV3): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isSubmittingRFQs(getState())) {
                return;
            }
            dispatch(rfqsSubmitting());

            const result = await dispatch(
                requestServer((token, csrfToken) => postRFQRequestAsyncV3(request, token, csrfToken)),
            );

            dispatch(rfqsSubmitted(request));
            dispatch(rfqsCreated(result.map((I) => I.number)));
            dispatch(goToRFQCreated());
            dispatch(productsDeselectAll());
            dispatch(suppliersDeselectAll());
        } catch (e) {
            console.log(e);
            dispatch(rfqsSubmitError());
            dispatch(alertError(getRFQCreationErrorMessage(e, getState())));
        }
    };
};

export const changeRFQAssignment = (rfqNumber: string, username?: string): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isUpdatingRFQ(getState(), rfqNumber)) {
                return;
            }

            dispatch(rfqsUpdating(rfqNumber));

            await dispatch(
                requestServer((token, csrfToken) => putRFQAssigneeAsyncV3(rfqNumber, username, token, csrfToken)),
            );

            var rfq: RFQSummary = {
                ...getRFQSummaries(getState())[rfqNumber],
                assigneeUsername: username,
            };
            dispatch(rfqUpdated(rfq));
            dispatch(alertSuccess(getTranslation(getState(), TK.assigneeSuccessfullyUpdated)));
        } catch (e) {
            console.log(e);
            dispatch(rfqsUpdateError(rfqNumber));
            dispatch(alertError(getTranslation(getState(), getRFQUpdateErrorMessage(e))));
        }
    };
};

export const changeRFQQuotesData = (
    rfqNumber: string,
    dataByThreadId: MapOf<RFQQuoteInfo[]>,
): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isUpdatingRFQ(getState(), rfqNumber)) {
                return;
            }

            dispatch(rfqsUpdating(rfqNumber));

            await dispatch(
                requestServer((token, csrfToken) => putRFQDataAsyncV3(rfqNumber, dataByThreadId, token, csrfToken)),
            );

            dispatch(rfqQuotesTableDataUpdated(rfqNumber, dataByThreadId));

            dispatch(alertSuccess(getTranslation(getState(), TK.tableDataSuccessfullyUpdated)));
        } catch (e) {
            console.log(e);
            dispatch(rfqsUpdateError(rfqNumber));
            dispatch(alertError(getTranslation(getState(), getRFQUpdateErrorMessage(e))));
        }
    };
};

export const submitRFQDueDateAndReminder = (
    rfqNumber: string,
    request: IRFQDueRminderRequest,
): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isUpdatingRFQ(getState(), rfqNumber)) {
                return;
            }

            dispatch(rfqsUpdating(rfqNumber));

            var rfq: RFQSummary = {
                ...getRFQSummaries(getState())[rfqNumber],
                endingDate: request.dueDate,
                reminder: request.reminder,
            };
            dispatch(rfqUpdated(rfq));

            dispatch(alertSuccess(getTranslation(getState(), TK.rfqUpdated)));
        } catch (e) {
            console.log(e);
            dispatch(rfqsUpdateError(rfqNumber));
            dispatch(alertError(getTranslation(getState(), getRFQUpdateErrorMessage(e))));
        }
    };
};

export const submitRFQStateReason = (request: IRFQStateReasonRequest): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        const rfqNumber = request.rfqNumber || '';
        try {
            if (isUpdatingRFQ(getState(), rfqNumber)) {
                return;
            }

            const response: IRFQStateReasonResponse = await dispatch(
                requestServer((token, csrfToken) => putRFQStateReasonAsyncV3(request, token, csrfToken)),
            );

            var rfq: RFQSummary = {
                ...getRFQSummaries(getState())[rfqNumber],
                reason: response.reason,
                state: response.state,
                endingDate: response.dueDate,
            };
            dispatch(rfqUpdated(rfq));
            dispatch(alertSuccess(getTranslation(getState(), TK.rfqUpdated)));
        } catch (e) {
            console.log(e);
            dispatch(rfqsUpdateError(rfqNumber));
            dispatch(alertError(getTranslation(getState(), getRFQUpdateErrorMessage(e))));
        }
    };
};

export const updateRFQQuotesCardsData = (
    rfqNumber: string,
    cardsData: RFQQuoteTableItem[],
): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            dispatch(rfqsUpdating(rfqNumber));

            await dispatch(
                requestServer((token, csrfToken) => putRFQCardsDataAsyncV3(rfqNumber, cardsData, token, csrfToken)),
            );
            dispatch(alertSuccess(getTranslation(getState(), TK.tableDataSuccessfullyUpdated)));
        } catch (e) {
            console.log(e);
            dispatch(rfqsUpdateError(rfqNumber));
            dispatch(alertError(getTranslation(getState(), getRFQUpdateErrorMessage(e))));
        }
    };
};

export const updateNextRFQNumber = (): AppThunkAction<Promise<void>> => {
    return async (dispatch): Promise<void> => {
        try {
            const response = await dispatch(
                requestServer((token, csrfToken) => {
                    return getRFQNextNumberAsyncV3(token, csrfToken);
                }),
            );
            dispatch(nextRfqNumberUpdated(response.nextRfqNumber));
        } catch (e) {
            console.log(e);
        }
    };
};
