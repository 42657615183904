import React, { CSSProperties } from 'react';
import { Error } from '../Styled/FormElements';

interface ErrorSpanProps {
    label?: React.ReactNode;
    onTextFilterChange?: (value: string) => void;
    onSelectFilterChange?: (value: string) => void;
    onFilterChange?: ({ filterType, textValue }: { filterType: string; textValue: string }) => void;
    style?: CSSProperties;
}

const ErrorSpan: React.FC<ErrorSpanProps> = ({ label, style }) => {
    return <Error style={style}>{label}</Error>;
};

export default ErrorSpan;
