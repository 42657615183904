import { AppThunkAction } from '..';
import { Action } from 'redux';
import { getTransportersAsync } from '../../fetch/requests';
import { TransportersLoadedAction } from './types';
import { requestServer } from '../Session/actions';
import { isLoadingTransporters } from './selectors';
import { RFQSimulationTransporterDto } from '../../models/RFQSimulationTransporterDto';

export const Actions = {
    transportersLoading: '@@whichpharma.transporters.loading',
    transportersLoaded: '@@whichpharma.transporters.loaded',
};

const transportersLoading = (): Action => ({
    type: Actions.transportersLoading,
});

const transportersLoaded = (result: RFQSimulationTransporterDto[]): TransportersLoadedAction => {
    return {
        type: Actions.transportersLoaded,
        result,
    };
};

export const fetchTransporters = (): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingTransporters(getState())) {
                return;
            }
            dispatch(transportersLoading());
            const result = await dispatch(requestServer((token, csrfToken) => getTransportersAsync(token, csrfToken)));
            dispatch(transportersLoaded(result));
        } catch (e) {
            console.log(e);
        }
    };
};
