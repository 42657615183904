import {
    Box,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    OutlinedInput,
    Switch,
    TextField,
} from '@material-ui/core';
import React, { ChangeEventHandler, useCallback, useEffect } from 'react';
import { EnquireBoxFlexStart, EnquireH, EnquireSpan, EnquireCardStyled } from './EnquireCard.styles';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import MultipleSelectInput from '../inputs/MultipleSelectInput';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { Country } from '../../models/Country';
import CountryFlag from '../CountryFlag';
import { AdditionalDocuments } from '../../models/AdditionalDocuments';
import { IEnquireCardProps } from './EnquireCard.types';
import { Enquiry } from '../../models/Enquiry';
import { RBTooltip } from '../RBTooltip';

const EnquireCard: React.FC<IEnquireCardProps> = ({
    additionalDocuments,
    countries,
    product,
    setEnquiryNeeds,
    enquiryNeeds,
    index,
}) => {
    const t = useTranslations();
    const [showAdditionalDocuments, setShowAdditionalDocuments] = React.useState(false);
    const [selectedCountries, setSelectedCountries] = React.useState<Country[]>([]);
    const [selectedDocuments, setSelectedDocuments] = React.useState<AdditionalDocuments[]>([]);
    const [values, setValues] = React.useState<Enquiry>({} as Enquiry);

    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    useEffect(() => {
        setValues({
            productCode: product.productCode,
            countryCode: product.countryCode,
            name: product.name,
            unitQuant: 0,
            acceptAlternative: false,
            acceptOtherPackSizes: false,
            acceptGenerics: false,
            acceptDifferentCountries: false,
            isSingleLot: false,
            hasAdditionalDocuments: false,
            comments: '',
        });
    }, [product]);

    useEffect(() => {
        const updatedEnquiries = [...enquiryNeeds];
        updatedEnquiries.splice(index, 1, values);
        setEnquiryNeeds(updatedEnquiries);
        // eslint-disable-next-line
    }, [values, product]);

    const handleSelectedCountry = React.useCallback(
        (selectedCountries: Country[]) => {
            const arr = selectedCountries.map((country: Country) => ({
                countryCode: country.alpha2Code.toLowerCase(),
            }));
            setSelectedCountries(selectedCountries);
            setValues({ ...values, differentCountries: arr });
        },
        [values],
    );

    const handleSelectedDocuments = React.useCallback(
        (documents: AdditionalDocuments[]) => {
            setShowAdditionalDocuments(false);
            const arr = documents.map((doc: AdditionalDocuments) => {
                if (doc.id === 10) setShowAdditionalDocuments(true);
                return { id: doc.id };
            });
            setSelectedDocuments(documents);
            setValues({ ...values, additionalDocuments: arr });
        },
        [values],
    );

    const handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
        (e) => {
            const { name, value } = e.target;
            setValues({ ...values, [name]: value });
        },
        [values],
    );

    const handleSwitchChange = useCallback(
        (e) => {
            const { name, checked } = e.target;
            setValues({ ...values, [name]: checked });
        },
        [values],
    );

    return (
        <EnquireCardStyled>
            <CardContent>
                <EnquireBoxFlexStart>
                    <EnquireH variant="h5" $color="black">
                        <CountryFlag countryCode={product.countryCode} showCode={false} country={product.countryName} />
                    </EnquireH>
                    <EnquireH variant="h5" $color="black">
                        {` - `}
                    </EnquireH>
                    <EnquireH variant="h5" $color="green">
                        {product.name}
                    </EnquireH>
                </EnquireBoxFlexStart>
                <EnquireBoxFlexStart>
                    <EnquireSpan>
                        {[
                            product.atc,
                            product.activeSubstances.join(', '),
                            product.pharmaceuticalForm,
                            product.strength,
                            product.package,
                        ]
                            .filter((item) => item?.length)
                            .join(' - ')}
                    </EnquireSpan>
                </EnquireBoxFlexStart>
                <EnquireBoxFlexStart>
                    <FormControl
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                        variant="outlined"
                        error={!!!values.unitQuant || values.unitQuant < 1}
                    >
                        <EnquireSpan>{t(TK.need)}:&nbsp;</EnquireSpan>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <OutlinedInput
                                name="unitQuant"
                                value={values.unitQuant}
                                type={'number'}
                                onChange={handleInputChange}
                            />
                        </div>
                        <EnquireSpan>&nbsp;{t(TK.units)}*</EnquireSpan>
                        <RBTooltip text={t(TK.tooltipTabCapVials)} />
                    </FormControl>
                </EnquireBoxFlexStart>
                <EnquireBoxFlexStart>
                    <EnquireH variant="h6" $color="black">
                        {t(TK.moreDetailsAbout)}
                    </EnquireH>
                </EnquireBoxFlexStart>
                <EnquireBoxFlexStart>
                    <FormControlLabel
                        style={{ width: '33%' }}
                        control={
                            <Switch
                                name="acceptAlternative"
                                onChange={handleSwitchChange}
                                color="primary"
                                value={values.acceptAlternative}
                            />
                        }
                        label={t(TK.acceptAlternative)}
                    />
                    <FormControlLabel
                        value={values.acceptGenerics}
                        style={{ width: '33%' }}
                        control={<Switch color="primary" name="acceptGenerics" onChange={handleSwitchChange} />}
                        label={t(TK.acceptGenerics)}
                    />
                    <FormControlLabel
                        value={values.acceptOtherPackSizes}
                        style={{ width: '33%' }}
                        control={<Switch color="primary" name="acceptOtherPackSizes" onChange={handleSwitchChange} />}
                        label={t(TK.acceptOtherPackSizes)}
                    />
                </EnquireBoxFlexStart>
                <EnquireBoxFlexStart>
                    <FormControlLabel
                        value={values.isSingleLot}
                        style={{ width: '33%' }}
                        control={<Switch color="primary" name="isSingleLot" onChange={handleSwitchChange} />}
                        label={t(TK.singleLot)}
                    />
                    <FormControlLabel
                        value={values.acceptDifferentCountries}
                        style={{ width: '33%' }}
                        control={
                            <Switch
                                name="acceptDifferentCountries"
                                /* onChange={toggleShowSelectCountries} */
                                /*  checked={showSelectCountries} */
                                color="primary"
                                onChange={handleSwitchChange}
                            />
                        }
                        label={t(TK.acceptDifferentCountries)}
                    />
                    {values.acceptDifferentCountries && (
                        <MultipleSelectInput
                            label={t(TK.country)}
                            placeholder={t(TK.all)}
                            options={countries}
                            limitTags={2}
                            isCustomFilter={true}
                            style={{ width: '33%' }}
                            orderBy={countries.sort((c, d) => c.name.localeCompare(d.name))}
                            values={selectedCountries}
                            onChange={handleSelectedCountry}
                            getOptionLabel={(option: Country): string => option?.name || ''}
                            renderOption={(option: Country, { selected }): React.ReactNode => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    <CountryFlag
                                        countryCode={option.alpha2Code}
                                        showCode={false}
                                        country={`${option.name} (${option.alpha2Code})`}
                                    />
                                </React.Fragment>
                            )}
                        />
                    )}
                </EnquireBoxFlexStart>
                <EnquireBoxFlexStart>
                    <FormControlLabel
                        value={values.hasAdditionalDocuments}
                        style={{ width: '33%' }}
                        control={<Switch color="primary" name="hasAdditionalDocuments" onChange={handleSwitchChange} />}
                        label={t(TK.additionalDocuments)}
                    />
                    {values.hasAdditionalDocuments && (
                        <>
                            <MultipleSelectInput
                                label={t(TK.additionalDocuments)}
                                options={additionalDocuments}
                                limitTags={2}
                                isCustomFilter={true}
                                style={{ width: '33%' }}
                                orderBy={additionalDocuments}
                                values={selectedDocuments}
                                error={!!values.hasAdditionalDocuments && !!!selectedDocuments.length}
                                onChange={handleSelectedDocuments}
                                getOptionLabel={(option: AdditionalDocuments): string => option?.name || ''}
                                renderOption={(option: AdditionalDocuments, { selected }): React.ReactNode => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </React.Fragment>
                                )}
                            />
                            <EnquireSpan>*</EnquireSpan>
                            {showAdditionalDocuments && (
                                <Box
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: '33%',
                                        marginLeft: '1%',
                                    }}
                                >
                                    <TextField
                                        error={
                                            !!!(selectedDocuments.findIndex((doc) => doc.name === 'Other') < 0) &&
                                            !!!values.otherDocumentDescription
                                        }
                                        value={values.otherDocumentDescription}
                                        variant={'outlined'}
                                        type={'text'}
                                        label={t(TK.otherDocuments)}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        autoComplete="off"
                                        size="small"
                                        onChange={handleInputChange}
                                        name="otherDocumentDescription"
                                    ></TextField>
                                    <EnquireSpan>*</EnquireSpan>
                                </Box>
                            )}
                        </>
                    )}
                </EnquireBoxFlexStart>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '1%',
                    }}
                >
                    <EnquireSpan>{t(TK.comments)}:&nbsp;</EnquireSpan>
                    <TextField
                        value={values.comments}
                        variant={'outlined'}
                        type={'text'}
                        multiline
                        rows={3}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        autoComplete="off"
                        onChange={handleInputChange}
                        name="comments"
                    ></TextField>
                </Box>
            </CardContent>
        </EnquireCardStyled>
    );
};

export default EnquireCard;
