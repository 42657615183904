import { AppThunkAction } from '..';
import { Action } from 'redux';
import { getAllCountriesAsync } from '../../fetch/requests';
import { CountriesLoadedAction } from './types';
import { requestServer } from '../Session/actions';
import { isLoadingCountries } from './selectors';
import { Country } from '../../models/Country';

export const Actions = {
    countriesLoading: '@@whichpharma.countries.loading',
    countriesLoaded: '@@whichpharma.countries.loaded',
};

const countriesLoading = (): Action => ({
    type: Actions.countriesLoading,
});

const countriesLoaded = (result: Country[]): CountriesLoadedAction => {
    return {
        type: Actions.countriesLoaded,
        result,
    };
};

export const fetchCountries = (): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingCountries(getState())) {
                return;
            }
            dispatch(countriesLoading());
            const result = await dispatch(requestServer((token, csrfToken) => getAllCountriesAsync(token, csrfToken)));
            dispatch(countriesLoaded(result));
        } catch (e) {
            console.log(e);
        }
    };
};
