import * as React from 'react';
import { Grid, Button, Typography, TextField, Box, Switch } from '@material-ui/core';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import Panel from '../../../components/Panel';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';
import DragAndDrop, { DragAndDropItemProps } from './DragAndDrop/DragAndDrop';
import { getSelectedProducts } from '../../../store/RFQProducts/selectors';
import { useSelector, useDispatch } from 'react-redux';
import { RFQ } from '../CommunicationSetup.types';
import { MapOf } from '../../../utils/Types';
import { productsDeselected } from '../../../store/RFQProducts/actions';
import FormDialog from '../../../components/FormDialog';
import { RFQHeader, RFQLabel, StikyDiv } from './ProductsRFQsAssociation.styles';
import CountryFlag from '../../../components/CountryFlag';
import { reorder, tryGetNameFromOriginal } from '../../../utils/utils';
import moment from 'moment-business-days';
import { ProductV2 } from '../../../models/ProductV2';

const toAssociateDropableId = 'toAssociate';

const countriesPreferenceOrder = ['AT', 'GB', 'CA', 'CZ'];

const itemQualityScore = (item: ProductV2): number => {
    const fieldsCount =
        (item.activeSubstances?.length ? 1 : 0) +
        (item.name?.length ? 1 : 0) +
        (item.strength?.length ? 1 : 0) +
        (item.pharmaceuticalForm?.length ? 1 : 0) +
        (item.atc?.length ? 1 : 0);
    const countryIndex = countriesPreferenceOrder.indexOf(item.countryCode);
    const countryScore = countryIndex < 0 ? 0 : countriesPreferenceOrder.length - countryIndex;
    return fieldsCount * 100 + countryScore;
};

const getDefaultRFQDescription = (items: ProductV2[] = []): string => {
    const p = items.sort((a, b) => itemQualityScore(a) - itemQualityScore(b)).reverse()[0];
    const name = tryGetNameFromOriginal(p.name);
    const includeName = !items.some((i) => i.name.toLowerCase().indexOf(name.toLowerCase()) === -1);
    return includeName
        ? `${p.activeSubstances?.join(', ') ?? ''} (${name}) ${p.strength ?? ''} ${p.pharmaceuticalForm ?? ''} (${
              p.atc ?? ''
          })`
        : `${p.activeSubstances?.join(', ') ?? ''} ${p.strength ?? ''} ${p.pharmaceuticalForm ?? ''} (${p.atc ?? ''})`;
};

const getDefaultRFQ = (rid?: string, items: ProductV2[] = []): RFQ => ({
    id: rid != null ? rid : '',
    autoId: '',
    useExistingRfq: false,
    description: items.length ? getDefaultRFQDescription(items) : '',
    dueDate: moment().businessAdd(3).utc().format(),
    packSize: 0,
    unitQuant: '',
    isAlternative: false,
    items: items.map((i) => i.id),
});

const getProductClusterId = (p: ProductV2): string => {
    const strength = p.strength?.toLocaleLowerCase().split(' ').join('');
    const pharmaceuticalForm = p.pharmaceuticalFormCategories?.join('');

    return `${p.atc ? p.atc + ' ' + pharmaceuticalForm?.toLocaleLowerCase() + ' ' + strength : ''}`;
};

const createProductsClusters = (products: ProductV2[]): RFQ[] => {
    var clusters = products
        .filter((p) => p.atc?.length)
        .reduce(
            (map: { [clusterId: string]: ProductV2[] }, p) => ({
                ...map,
                [getProductClusterId(p)]: [...(map[getProductClusterId(p)] || []), p],
            }),
            {},
        );

    return Object.values(clusters).map((items) => getDefaultRFQ('', items));
};

export interface ProductsRFQsAssociationProps {
    rfqs: RFQ[];
    setRfqs: React.Dispatch<React.SetStateAction<RFQ[]>>;
    onAllProductsAssociatedChanged: (complete: boolean) => void;
    onAutoFillField: (rfqId: string, fieldName: string, value: string) => void;
}

const ProductsRFQsAssociation: React.FC<ProductsRFQsAssociationProps> = ({
    rfqs,
    setRfqs,
    onAllProductsAssociatedChanged,
}) => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const selectedProducts = useSelector(getSelectedProducts);
    const [nextRfqNumber, setNextRfqNumber] = React.useState(1);
    const [productToConfirmDeletion, setProductToConfirmDeletion] = React.useState<ProductV2 | null>(null);

    // DEFAULT VALUE
    React.useEffect(() => {
        let next = nextRfqNumber;
        const rfqs = createProductsClusters(selectedProducts).map((rfq, idx) => {
            rfq.autoId = next + '/new';
            next++;
            return rfq;
        });
        setNextRfqNumber(next);
        setRfqs(rfqs);
        // eslint-disable-next-line
    }, []);

    const itemsToAssociate = React.useMemo<ProductV2[]>(
        () => selectedProducts.filter((product) => !rfqs.some((rfq) => rfq.items.includes(product.id))),
        [selectedProducts, rfqs],
    );

    React.useEffect(() => onAllProductsAssociatedChanged(!itemsToAssociate.length), [
        onAllProductsAssociatedChanged,
        itemsToAssociate,
    ]);

    const onDragEnd = React.useCallback(
        (result: DropResult) => {
            if (!result.destination) return;

            setRfqs((prev) =>
                prev.map((rfq, index) => {
                    if (
                        index.toString() === result.source.droppableId &&
                        result.source.droppableId === result.destination?.droppableId
                    ) {
                        return { ...rfq, items: reorder(rfq.items, result.source.index, result.destination.index) };
                    } else if (index.toString() === result.source.droppableId) {
                        return { ...rfq, items: rfq.items.filter((id) => id !== result.draggableId) };
                    } else if (index.toString() === result.destination?.droppableId) {
                        return {
                            ...rfq,
                            items: reorder(
                                [...rfq.items, result.draggableId],
                                rfq.items.length,
                                result.destination.index,
                            ),
                        };
                    }
                    return rfq;
                }),
            );
        },
        [setRfqs],
    );

    const handleRFQIdChange = React.useCallback(
        (index: number, newId: string) => {
            newId = newId.replace(/\s|\n|\r|&nbsp;|<br>/g, '');
            setRfqs((prev) => prev.map((rfq, i) => (index !== i ? rfq : { ...rfq, id: newId })));
        },
        [setRfqs],
    );

    const handleRFQDescriptionChange = React.useCallback(
        (index: number, description: string) => {
            setRfqs((prev) => prev.map((rfq, i) => (index !== i ? rfq : { ...rfq, description })));
        },
        [setRfqs],
    );

    const handleDueDateChange = React.useCallback(
        (index: number, dueDate: string) => {
            setRfqs((prev) => prev.map((rfq, i) => (index !== i ? rfq : { ...rfq, dueDate })));
        },
        [setRfqs],
    );

    const handleUnitChange = React.useCallback(
        (index: number, unitQuant: string) => {
            setRfqs((prev) => prev.map((rfq, i) => (index !== i ? rfq : { ...rfq, unitQuant: unitQuant })));
        },
        [setRfqs],
    );

    const handleIsAlertnateChange = React.useCallback(
        (index: number, checked: boolean) => {
            setRfqs((prev) => prev.map((rfq, i) => (index !== i ? rfq : { ...rfq, isAlternative: checked })));
        },
        [setRfqs],
    );

    const handleCloseClick = React.useCallback(
        (rfq: RFQ) => {
            setNextRfqNumber(nextRfqNumber - 1);
            let next = 1;
            setRfqs(
                rfqs
                    .filter((prev) => prev !== rfq)
                    .map((rfq, idx) => {
                        rfq.autoId = next + '/new';
                        rfq.id = rfq.id ?? next + '/new';
                        next++;
                        return rfq;
                    }),
            );
        },
        [rfqs, nextRfqNumber, setRfqs],
    );

    const items = React.useMemo<MapOf<{ id: string; label: React.ReactNode }>>(() => {
        const vals = selectedProducts.map<MapOf<DragAndDropItemProps>>((p) => ({
            [p.id]: {
                id: p.id,
                onCloseClick: () => {
                    if (itemsToAssociate.some((item) => item.id === p.id)) {
                        setProductToConfirmDeletion(p);
                    } else {
                        onDragEnd({
                            draggableId: p.id,
                            mode: 'FLUID',
                            reason: 'DROP',
                            type: '',
                            source: {
                                index: -1,
                                droppableId: rfqs.findIndex((rfq) => rfq.items.includes(p.id)).toString(),
                            },
                            destination: { index: -1, droppableId: toAssociateDropableId },
                            combine: null, // adicionado após update de package ver se é impactante
                        });
                    }
                },
                label: (
                    <span>
                        <CountryFlag hideName country={p.country || ''} countryCode={p.countryCode} />
                        {p.name} (
                        {[p.strength, p.pharmaceuticalForm, p.administrationRoute, p.package]
                            .filter((x) => x)
                            .join(', ')}
                        )
                    </span>
                ),
            },
        }));
        return vals.reduce((prev, curr) => Object.assign(prev, curr), {});
        // eslint-disable-next-line
    }, [selectedProducts, itemsToAssociate]);

    return (
        <Panel title={t(TK.rfqAssociation)} subtitle={t(TK.dragAndDropToAssignProductsToARFQ)}>
            <FormDialog
                open={!!productToConfirmDeletion}
                title="Are you sure you want to remove this product from selected list?"
                onClose={() => setProductToConfirmDeletion(null)}
                onSubmit={() => {
                    if (productToConfirmDeletion) {
                        dispatch(productsDeselected([productToConfirmDeletion]));
                        setProductToConfirmDeletion(null);
                    }
                }}
            />
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={5}>
                        <StikyDiv>
                            <DragAndDrop
                                id={toAssociateDropableId}
                                items={itemsToAssociate.map((product) => items[product.id]) as any}
                                title={
                                    <Typography>
                                        <b>{t(TK.productsToAssociate)}:</b>
                                    </Typography>
                                }
                                texts={{
                                    none: t(TK.allProductsAreAssociated),
                                }}
                            />
                        </StikyDiv>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        {rfqs.map((rfq, index) => (
                            <DragAndDrop
                                key={index}
                                id={index.toString()}
                                items={rfq.items.map((id) => items[id]) as any}
                                onCloseClick={() => {
                                    handleCloseClick(rfq);
                                }}
                                texts={{
                                    none: t(TK.none),
                                }}
                                title={
                                    <RFQHeader>
                                        <span style={{ display: 'flex' }}>
                                            <Typography>
                                                <Box>
                                                    <RFQLabel>{t(TK.rfqNr)}:</RFQLabel>
                                                    <TextField
                                                        size="small"
                                                        style={{ width: 100 }}
                                                        value={rfq.id}
                                                        placeholder={rfq.autoId}
                                                        onChange={(event) =>
                                                            handleRFQIdChange(index, event.target.value)
                                                        }
                                                    />
                                                </Box>
                                            </Typography>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <Typography color={rfq.dueDate?.length ? 'secondary' : 'error'}>
                                                <Box display="flex">
                                                    <Box>
                                                        <RFQLabel>{t(TK.dueDate)}:</RFQLabel>
                                                        <TextField
                                                            size="small"
                                                            type="datetime-local"
                                                            placeholder={t(TK.dueDate)}
                                                            value={moment(rfq.dueDate).format().substr(0, 16)}
                                                            onChange={(event) =>
                                                                handleDueDateChange(
                                                                    index,
                                                                    new Date(event.target.value).toISOString(),
                                                                )
                                                            }
                                                        />
                                                    </Box>
                                                    <Box>
                                                        <TextField
                                                            size="small"
                                                            type="text"
                                                            label="Total units:"
                                                            style={{
                                                                marginLeft: '12px',
                                                                width: '80px',
                                                                marginTop: '-15px',
                                                            }}
                                                            value={rfq.unitQuant}
                                                            placeholder="Total units"
                                                            onChange={(event) => {
                                                                handleUnitChange(index, event.target.value);
                                                            }}
                                                        />

                                                        <Switch
                                                            color="primary"
                                                            checked={rfq.isAlternative || false}
                                                            onChange={(event: { target: { checked: boolean } }) => {
                                                                handleIsAlertnateChange(index, event.target.checked);
                                                            }}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                        <span>Accept alternative</span>
                                                    </Box>
                                                </Box>
                                            </Typography>
                                        </span>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            value={rfq.description}
                                            placeholder={t(TK.description)}
                                            onChange={(event) => handleRFQDescriptionChange(index, event.target.value)}
                                        />
                                    </RFQHeader>
                                }
                            />
                        ))}
                        <Button
                            variant="outlined"
                            style={{ width: '100%' }}
                            onClick={() => {
                                var rfq = getDefaultRFQ('');
                                rfq.autoId = nextRfqNumber + '/new';
                                setRfqs((prev) => prev.concat(rfq));
                                setNextRfqNumber(nextRfqNumber + 1);
                            }}
                        >
                            {t(TK.addNew)}
                        </Button>
                    </Grid>
                </Grid>
            </DragDropContext>
        </Panel>
    );
};
export default ProductsRFQsAssociation;
