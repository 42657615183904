import { Box, BoxProps } from '@material-ui/core';
import styled from 'styled-components';

export const Text = styled.span`
    margin-right: 0.8rem;
`;

export const BoxFlexBetween = styled(Box)<BoxProps & { maxWidth?: string }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1%;
    max-width: ${(p) => p.maxWidth || '100%'};
`;

export const Row = styled(Box)`
    display: flex;
    flex-direction: row;
`;

export const BoxWidth = styled(Box)<BoxProps & { width: string }>`
    width: ${(p) => p.width};
    height: 100%;
    padding: 0 1%;
`;

export const DivMargin = styled.div<{ margin?: string }>`
    margin-left: ${(p) => p.margin || '1%'};
`;
