import * as React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useParams } from 'react-router';
import Page from '../../components/Page';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { useSelector, useDispatch } from 'react-redux';
import { getRFQSummaries, getRFQDetails, isLoadingRFQs } from '../../store/RFQ/selectors';
import Loading from '../../components/Loading';
import { fetchRFQsV3, changeRFQAssignment, changeRFQQuotesData } from '../../store/RFQ/actions';
import { Box, Snackbar, LinearProgress } from '@material-ui/core';
import { RFQEntryState } from '../../models/RFQEntryState';
import { goToRFQsList } from '../../store/Router/actions';
import UserSelector from '../../components/UserSelector';
import { PageActionPanel } from '../../components/Page/styled';
import { Alert, ToggleButton } from '@material-ui/lab';
import { getMyUser } from '../../store/Users/selectors';
import Table from '../../components/AGTable';
import { tableColsDefinition } from './columns';
import { Item } from '../../components/Table/types';
import { MapOf } from '../../utils/Types';
import { UserRole } from '../../models/UserRole';
import { RFQQuoteInfo } from '../../models/RFQQuoteInfo';
import { ColDef, ColGroupDef, RowSelectedEvent } from 'ag-grid-community';
import { getMaxMinAndMeanByField, parseNumber } from '../../utils/utils';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import { RFQQuoteState } from '../../models/RFQQuoteState';

export interface RFQQuoteTableItem extends RFQQuoteInfo {
    threadId: string;
    country: { name: string; code: string };
    supplier: string;
    supplierId: string;
    contacts: string;
    sellingPrice?: number;
    packPrice?: number;
}

const RFQDetails: React.FC = () => {
    const { rfqNrEncoded } = useParams<{ rfqNrEncoded: string }>();
    const dispatch = useDispatch();
    const t = useTranslations();
    const count = React.useRef(0);

    const { setHeaderName } = React.useContext(AppContext) as AppContextType;

    const rfqSummaries = useSelector(getRFQSummaries) || {};
    const rfqsDetails = useSelector(getRFQDetails);
    const myUser = useSelector(getMyUser);
    const isLoading = useSelector(isLoadingRFQs);

    const [onlyOpen, setOnlyOpen] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [selectedQuotes, setSelectedQuotes] = React.useState<RFQQuoteTableItem[]>([]);

    const rfqNr = React.useMemo(() => decodeURIComponent(rfqNrEncoded), [rfqNrEncoded]);
    const rfqSummary = React.useMemo(() => rfqSummaries[rfqNr], [rfqSummaries, rfqNr]);
    const rfqDetails = React.useMemo(() => rfqsDetails[rfqNr], [rfqsDetails, rfqNr]);

    const [rfqDate, setRfqDate] = React.useState(new Date());

    const isDeSelectItem = '';

    const getSearhColumns = () => {
        const searchColumns: string[] = [];
        tableColsDefinition(myUser!).forEach((item) => {
            searchColumns.push(item.field || '');
        });

        return searchColumns;
    };

    const defaultColDef = React.useMemo(
        () => ({
            sortable: true,
            wrapHeaderText: true,
            resizable: true,
            autoHeaderHeight: true,
            suppressMovable: true,
            editable: false,
            filterParams: {
                textMatcher: () => true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                suppressAndOrCondition: true,
            },
        }),
        [],
    );

    React.useEffect(() => {
        if (!rfqSummary) dispatch(fetchRFQsV3(false, rfqNr));
        // eslint-disable-next-line
    }, [rfqNr]);

    React.useEffect(() => {
        if (rfqDetails) {
            setSelectedQuotes(rfqDetails.cards ? rfqDetails.cards : []);
        }
    }, [rfqDetails]);

    React.useEffect(() => {
        if (rfqSummary) {
            setHeaderName(rfqSummary.title);
        }
    }, [rfqSummary, setHeaderName]);

    const detailsItems = React.useMemo(
        () =>
            rfqDetails?.suppliersDetails
                .filter((details) => !onlyOpen || details.state !== RFQEntryState.Closed)
                .map((details) => ({ ...details, id: details.supplierContactEmail })) || [],
        [rfqDetails, onlyOpen],
    );

    let quotesTableData = React.useMemo<RFQQuoteTableItem[]>(
        () =>
            detailsItems.flatMap((details) =>
                !details.dataTable
                    ? []
                    : details.dataTable
                          .filter((row) => row.rfqNr === rfqSummary.number)
                          .map<RFQQuoteTableItem>((row) => ({
                              ...row,
                              threadId: details.threadId,
                              country: { name: details.country, code: details.countryCode },
                              supplier: details.supplierName || details.supplierDto?.supplierName || '',
                              supplierId: details.supplierId || details.supplierDto?.supplierId || '',
                              state: row.state as RFQQuoteState,
                              type: details.supplierType,
                              supplierReplyForm: details.externalAccessLink,
                              contacts: `${details.supplierContactName || details.supplierDto?.supplierContactName} (${
                                  details.supplierContactEmail || details.supplierDto?.supplierContactEmail
                              })`,
                          })),
            ),
        [detailsItems, rfqSummary],
    );

    React.useEffect(() => {
        if (quotesTableData) {
            setRfqDate(new Date(quotesTableData[0]?.endingDate || ''));
        }
    }, [quotesTableData, setRfqDate]);

    const unitPriceRngObj = getMaxMinAndMeanByField(quotesTableData, 'priceCurrencyToEuro');
    const leadTimeRngObj = getMaxMinAndMeanByField(quotesTableData, 'leadTimeToDeliver');

    quotesTableData = quotesTableData.map((item) => {
        return {
            ...item,
            maxUnitPrice: unitPriceRngObj.max,
            minUnitPrice: unitPriceRngObj.min,
            averageUnitPrice: unitPriceRngObj.mean,
            maxLeadTime: leadTimeRngObj.max,
            minLeadTime: leadTimeRngObj.min,
            averageLeadTime: leadTimeRngObj.mean,
        };
    });

    const changeAssign = React.useCallback((username?: string) => dispatch(changeRFQAssignment(rfqNr, username)), [
        rfqNr,
        dispatch,
    ]);

    const handleItemSelectionChange = React.useCallback(
        (data, selectedNodes, selected: boolean) => {
            if (selected) {
                const index = selectedQuotes.findIndex(
                    (item) => item.id === data.id && item.supplierId === data.supplierId,
                );
                if (index >= 0) return;
                count.current = 1;
                data.availabilityPacsForCard = data.availabilityPacks;
                const dateObj = new Date();
                const month = dateObj.getMonth() + 1;
                const formatMonth = month < 10 ? '0' + month : month;
                const formatHour = dateObj.getHours() < 10 ? `0${dateObj.getHours()}` : dateObj.getHours();
                const formatMinutes = dateObj.getMinutes() < 10 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes();
                data.cardDate = `${dateObj.getDate()}.${formatMonth}.${dateObj.getFullYear()} ${formatHour}:${formatMinutes}`;

                //Creating new array to avoid copy reference
                const additionalCostsList = data.additionalCostList?.map((item: any) => ({
                    label: item.label,
                    value: item.value,
                })) || [{ label: '', value: 0 }];

                // Add default additional costs if they do not exist
                if (additionalCostsList.findIndex((ad: any) => ad.label === 'Supplier Freight') < 0) {
                    additionalCostsList.splice(0, 0, { label: 'Supplier Freight', value: 0 });
                }

                if (additionalCostsList.findIndex((ad: any) => ad.label === 'Client Freight') < 0) {
                    additionalCostsList.splice(0, 0, { label: 'Client Freight', value: 0 });
                }
                data.additionalCostList = [...additionalCostsList];

                if (rfqDetails.cards && selectedQuotes.length === 0) {
                    setSelectedQuotes([...rfqDetails.cards]);
                    return;
                } else if (rfqDetails.cards && selectedQuotes.length) {
                    const uniqueQuotes: RFQQuoteTableItem[] = [];
                    const combinedArray = [...rfqDetails.cards, ...selectedQuotes, { ...data }];

                    combinedArray.forEach((item) => {
                        const index = uniqueQuotes.findIndex((object) => object.id === item.id);
                        if (index < 0) {
                            uniqueQuotes.push(item);
                        }
                    });

                    setSelectedQuotes([...uniqueQuotes]);
                } else {
                    setSelectedQuotes([...selectedQuotes, { ...data }]);
                }
            } else {
                if (selectedNodes.length === 0) {
                    setSelectedQuotes([]);
                    return;
                }
                count.current = 1;
                const quotes = selectedQuotes;
                const updateQuotes = quotes.filter((item) => {
                    if ((item.id === data.id && item.supplierId === data.supplierId) || item.name === data.name) {
                        return false;
                    }
                    return true;
                });
                setSelectedQuotes([...updateQuotes]);
            }
        },
        [selectedQuotes, setSelectedQuotes, rfqDetails],
    );

    const handleDataChange = React.useCallback(
        (data: Item) => {
            let dataArray = [];
            if (data?.expDate && data.expDate.length > 0) {
                const dateChunks = data.expDate.split('/');
                const dateObject = new Date();
                if (dateChunks[0] > 12) {
                    setIsError(true);
                    setErrorMessage(TK.errorMessageInvalidDate);
                    return;
                } else if (dateChunks[1] < dateObject.getFullYear()) {
                    setErrorMessage(TK.errorMessageInvalidYear);
                    setIsError(true);

                    return;
                }
            }

            dataArray.push(data);
            const dataOnlyStrings = dataArray.map((row) =>
                Object.keys(row).reduce(
                    (prev, column) => (typeof row[column] === 'string' ? { ...prev, [column]: row[column] } : prev),
                    {} as RFQQuoteTableItem,
                ),
            );

            const dataByThreadId = dataOnlyStrings.reduce(
                (prev, row) => ({
                    ...prev,
                    [row.threadId]: [...(prev[row.threadId] || []), row],
                }),
                {} as MapOf<RFQQuoteInfo[]>,
            );

            // Propagate PackSize through all cards
            selectedQuotes.forEach((quote) => {
                if (quote.threadId === data.threadId) {
                    // Get first digits
                    quote.packSize = parseNumber(data.packSize) + '';
                }
            });

            count.current = 1;
            setSelectedQuotes([...selectedQuotes]);

            dispatch(changeRFQQuotesData(rfqNr, dataByThreadId));
        },
        [dispatch, rfqNr, setIsError, setErrorMessage, setSelectedQuotes, selectedQuotes],
    );

    const getNamesForColumns = () =>
        tableColsDefinition(myUser!).map((item) => {
            item.headerName = t(item.headerName as TK);

            return item;
        });

    const handleDateChange = React.useCallback(
        (date: Date | null) => {
            setRfqDate(date || new Date());
        },
        [setRfqDate],
    );

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsError(false);
    };

    return (
        <Page
            title={rfqSummary?.title || `${t(TK.loading)}...`}
            goBack={() => dispatch(goToRFQsList())}
            actionPanel={
                <PageActionPanel>
                    <ToggleButton size="small" value={onlyOpen} onChange={() => setOnlyOpen(!onlyOpen)}>
                        {onlyOpen === true ? t(TK.onlyOpen) : t(TK.viewAll)}
                    </ToggleButton>
                    <UserSelector
                        seletedUsername={rfqSummary?.assigneeUsername || ''}
                        disabled={
                            !myUser?.roles?.includes(UserRole.Administrator) &&
                            rfqSummary?.assigneeUsername !== myUser?.username
                        }
                        onChange={changeAssign}
                    />
                </PageActionPanel>
            }
        >
            <Snackbar open={isError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" style={{ fontSize: '20px' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Loading isLoading={isLoading} />

            <Box display="flex" width="100%" justifyContent="right" bgcolor="white">
                <Box marginRight={1}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            disabled={true}
                            margin="normal"
                            id="date-picker-dialog"
                            label={t(TK.dueDate)}
                            format="dd/MM/yyyy"
                            value={rfqDate}
                            onChange={handleDateChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Box>
            </Box>
            {isLoading ? (
                <LinearProgress color="primary" />
            ) : (
                <Table
                    pageSize={1000}
                    captionAnalytics={TK.TotalQuotes}
                    enablePagination={false}
                    isExportable={true}
                    total={quotesTableData.length}
                    stopEditingWhenCellsLoseFocus={false}
                    columnsDefinition={getNamesForColumns() as ColDef[] | ColGroupDef[]}
                    searchColumns={getSearhColumns()}
                    suppressClickEdit={true}
                    deselectRow={isDeSelectItem}
                    onChangeData={handleDataChange}
                    selectedQuotes={selectedQuotes}
                    suppressRowClickSelection={true}
                    rowSelection="multiple"
                    defaultColDef={defaultColDef}
                    rowHeight={40}
                    data={quotesTableData}
                    onRowSelected={(event: RowSelectedEvent) => {
                        const {
                            node: { data },
                        } = event;

                        handleItemSelectionChange(data, event.api.getSelectedNodes(), !!event.node.isSelected());
                    }}
                />
            )}
        </Page>
    );
};

export default RFQDetails;
