import { PaymentTermsState } from './state';
import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import { PaymentTermsLoadedAction } from './types';

const unloadedState: PaymentTermsState = {
    paymentTermsInfo: [],
    isLoading: false,
};

export const persistor = (state: PaymentTermsState): PaymentTermsState => ({
    ...unloadedState,
});

export const reconciler = (stored: PaymentTermsState): PaymentTermsState => ({
    ...stored,
    isLoading: false,
});

const handlePaymentTermsLoading = (state: PaymentTermsState): PaymentTermsState => ({
    ...state,
    isLoading: true,
});

const handlePaymentTermsLoaded = (state: PaymentTermsState, action: PaymentTermsLoadedAction): PaymentTermsState => ({
    ...state,
    isLoading: false,
    paymentTermsInfo: action.result,
});

export const reducer: Reducer<PaymentTermsState> = (
    state: PaymentTermsState = unloadedState,
    action: Action,
): PaymentTermsState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return unloadedState;
        case Actions.paymentTermsLoading:
            return handlePaymentTermsLoading(state);
        case Actions.paymentTermsLoaded:
            return handlePaymentTermsLoaded(state, action as PaymentTermsLoadedAction);
        default:
            return state;
    }
};
