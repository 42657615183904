import { Button, CardContent, CardHeader, Grid, Link } from '@material-ui/core';
import * as React from 'react';
import { RFQQuote } from '../../../models/RFQQuote';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';
import moment from 'moment';
import { RFQQuoteState } from '../../../models/RFQQuoteState';
import { CheckboxParagraph, QuoteBoxCard, UseStyle } from './EditQuoteForm.syles';
import OurInfoForm from '../OurInfoForm/OurInfoForm';
import SupplierInfoForm from '../SupplierInfoForm/SupplierInfoForm';
import { RFQQuoteAvatar } from '../../../components/RFQQuoteAvatar';
import TermsAndConditionsDialog from '../TermsAndConditionsDialog/TermsAndConditionsDialog';
import UserAvatar from '../../../components/UserAvatar';
import FormDialog from '../../../components/FormDialog';
import ConfirmDialog from '../../../components/ConfirmDialog';

export interface QuoteBoxProps {
    quote: RFQQuote;
    responsible?: string;
    receiveEmail?: boolean;
    loading?: boolean;

    onChange: (quote: RFQQuote) => void;
    onChangeReceiveEmail: (expanded: boolean) => void;
    onAddAlternative: () => void;
}

export const getNumberOfPacks = (unitQuantity: string | undefined, packSize: string | undefined) =>
    Math.ceil(unitQuantity && packSize ? parseInt(unitQuantity) / parseInt(packSize) : 0);

const EditQuoteForm: React.FC<QuoteBoxProps> = ({
    quote,
    loading,
    responsible,
    onChange,
    onChangeReceiveEmail,

    onAddAlternative,
}) => {
    const t = useTranslations();
    const [value, setValue] = React.useState(quote);
    const [errors, setErrors] = React.useState<string[]>([]);
    const [termsAndConditionsOpen, setTermsAndConditionsOpen] = React.useState(false);
    const [skipping, setSkipping] = React.useState(false);
    const [isAction, setIsAction] = React.useState('');
    const [showAlert, setShowAlert] = React.useState(false);
    const [showAltDeclineAlert, setShowAltDeclineAlert] = React.useState(false);
    const [showDirectDeclineAlert, setShowDirectDeclineAlert] = React.useState(false);
    const [isAlternativePress, setIsAlternativePress] = React.useState(false);

    React.useEffect(() => setValue(quote), [setValue, quote]);

    const handleSubmitOffer = React.useCallback(() => {
        var errors = [
            value.exwNetPriceEuro?.length ? '' : TK.netPriceEuro,
            value.availabilityPacks ? '' : TK.availabilityPacks,
            value.leadTimeToDeliver?.length ? '' : TK.leadTimeToDeliver,
        ].filter((v) => v.length);

        if (errors.length) {
            setErrors(errors);
        } else {
            setIsAction('editSubmitOffer');
            if (value.isAlternative) {
                setShowAlert(true);
            } else {
                onChangeReceiveEmail(true);
                const numOfPacks = getNumberOfPacks(value.unitQuant, value.packSize);
                onChange({
                    ...value,
                    numOfPacks: numOfPacks.toString(),
                    state: RFQQuoteState.Quoted,
                });
            }
        }
    }, [value, onChangeReceiveEmail, onChange]);

    const handleDeclineOffer = React.useCallback(() => {
        setShowDirectDeclineAlert(true);
    }, []);

    const handleInterest = () => {
        onChange({ ...value, state: RFQQuoteState.ToQuote });
    };

    const handleSkipped = (skipFormResult: { reason: string; duration: string; comments: string }) => {
        const { reason, duration, comments } = skipFormResult;
        const useCommentsAsReason = reason.startsWith('Other');
        let newComments = `Skip reason: ${useCommentsAsReason ? comments : reason}.`;
        if (duration?.length) newComments += ` Duration: ${duration}.`;
        if (!useCommentsAsReason) newComments += ` Comments: ${comments}.`;
        const numOfPacks = getNumberOfPacks(value.unitQuant, value.packSize);
        onChange({
            ...value,
            numOfPacks: numOfPacks.toString(),
            state: RFQQuoteState.Declined,
            comments: newComments,
        });
        if (isAlternativePress) {
            onAddAlternative();
            setIsAlternativePress(false);
        }
    };

    const handleClickNoFunction = () => {
        if (isAction === 'skipped') {
            setSkipping(true);
        }

        if (isAction === 'editSubmitOffer') {
            onChangeReceiveEmail(true);
            const numOfPacks = getNumberOfPacks(value.unitQuant, value.packSize);
            onChange({ ...value, numOfPacks: numOfPacks.toString(), state: RFQQuoteState.Quoted });
        }
        setShowAlert(false);
    };

    const handleDeclineAltClickNoFunction = () => {
        setSkipping(true);
    };

    const handleDeclineAltClickYesFunction = () => {
        const numOfPacks = getNumberOfPacks(value.unitQuant, value.packSize);
        onChange({ ...value, numOfPacks: numOfPacks.toString(), state: RFQQuoteState.Declined });
        onAddAlternative();
    };

    const handleClickYesFunction = () => {
        if (isAction === 'skipped') {
            setSkipping(true);
            setIsAlternativePress(true);
        }

        if (isAction === 'editSubmitOffer') {
            onChangeReceiveEmail(true);
            const numOfPacks = getNumberOfPacks(value.unitQuant, value.packSize);
            onChange({ ...value, numOfPacks: numOfPacks.toString(), state: RFQQuoteState.Quoted });
            onAddAlternative();
        }

        setShowAlert(false);
    };

    const onHandleDeclineYes = React.useCallback(() => {
        if (value.isAlternative) {
            setShowAltDeclineAlert(true);
        } else {
            setShowDirectDeclineAlert(false);
            setSkipping(true);
        }
    }, [value, setShowAltDeclineAlert, setSkipping]);

    const onHandleDeclineNo = React.useCallback(() => {
        setShowDirectDeclineAlert(false);
    }, [setShowDirectDeclineAlert]);

    const classes = UseStyle();

    return (
        <>
            <QuoteBoxCard variant="outlined">
                <CardHeader
                    avatar={responsible && <UserAvatar size="large" username={responsible} />}
                    title={
                        <b>
                            {t(TK.rfqNr)} {value.rfqNr} <RFQQuoteAvatar state={value.state} size="small" />
                        </b>
                    }
                    subheader={
                        value.state !== RFQQuoteState.Closed &&
                        value.endingDate && (
                            <u>
                                {t(TK.expires)} {moment(value.endingDate).fromNow()} (
                                {moment(value.endingDate).format('DD/MM/yyyy')})
                            </u>
                        )
                    }
                    titleTypographyProps={{ variant: 'h6' }}
                    subheaderTypographyProps={{ variant: 'subtitle2' }}
                />

                <CardContent>
                    <OurInfoForm readOnly value={value} setValue={setValue} fieldsWithError={errors} />

                    {value.state !== RFQQuoteState.Declined && (
                        <>
                            <SupplierInfoForm
                                readOnly={
                                    value.state !== RFQQuoteState.ToQuote &&
                                    value.state !== RFQQuoteState.Open &&
                                    value.state !== RFQQuoteState.InProgress
                                }
                                value={value}
                                setValue={setValue}
                                fieldsWithError={errors}
                            />
                        </>
                    )}

                    {(value.state === RFQQuoteState.Open ||
                        value.state === RFQQuoteState.InProgress ||
                        value.state === RFQQuoteState.ToQuote) && (
                        <>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <CheckboxParagraph style={{ marginBottom: '10px' }}>
                                        {'By submitting your offer you are accepting our'}{' '}
                                        <Link onClick={() => setTermsAndConditionsOpen(true)}>
                                            {t(TK.termsAndConditions).toLowerCase()}
                                        </Link>
                                    </CheckboxParagraph>
                                </Grid>
                                <Grid item xs={12} className={classes.centerBox}>
                                    <Button
                                        className={classes.submitButton}
                                        disabled={loading}
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        onClick={handleSubmitOffer}
                                    >
                                        {t(TK.submitOffer)}&emsp;
                                        <svg
                                            className="MuiSvgIcon-root"
                                            viewBox="0 0 24 24"
                                            width="30"
                                            aria-hidden="true"
                                        >
                                            <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z"></path>
                                        </svg>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        className={classes.declinePrimaryButton}
                                        onClick={handleDeclineOffer}
                                    >
                                        {t(TK.decline)}
                                    </Button>
                                </Grid>
                                <Grid item xs={6}></Grid>
                            </Grid>
                        </>
                    )}
                    {value.state === RFQQuoteState.Declined && (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        disabled={loading}
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        onClick={handleInterest}
                                    >
                                        {t(TK.iveChangedMyMindAndImInterested)}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {value.state === RFQQuoteState.Quoted && (
                        <>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Button
                                        disabled={loading}
                                        fullWidth
                                        variant="outlined"
                                        size="large"
                                        color="secondary"
                                        onClick={handleInterest}
                                    >
                                        {t(TK.editQuote)}
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </CardContent>

                <TermsAndConditionsDialog
                    open={termsAndConditionsOpen}
                    onClose={() => setTermsAndConditionsOpen(false)}
                />

                <FormDialog
                    open={skipping}
                    onClose={() => setSkipping(false)}
                    onSubmit={(value) => handleSkipped(value as any)}
                    title={t(TK.decline)}
                    fields={[
                        {
                            key: 'reason',
                            label: t(TK.why),
                            options: [
                                t(TK.quoteLater),
                                t(TK.notInOurRange),
                                t(TK.onShortage),
                                t(TK.notCommercialized),
                                t(TK.authorizationCeased),
                                t(TK.exportBan),
                                t(TK.otherPleaseIndicate),
                            ],
                            validate: (value) => (value ? undefined : t(TK.mandatoryFields)),
                        },
                        {
                            key: 'duration',
                            label: `${t(TK.duration)} (${t(TK.ifApplicable)})`,
                            placeholder: `${t(TK.days)}`,
                        },
                        {
                            key: 'comments',
                            label: t(TK.comments),
                        },
                    ]}
                />
            </QuoteBoxCard>
            <ConfirmDialog
                title={t(TK.offerAlternative)}
                showAlert={showAlert}
                onClickNoFunction={handleClickNoFunction}
                onClickYesFunction={handleClickYesFunction}
            />
            <ConfirmDialog
                title={t(TK.offerAlternativeOnDecline)}
                showAlert={showAltDeclineAlert}
                onClickNoFunction={handleDeclineAltClickNoFunction}
                onClickYesFunction={handleDeclineAltClickYesFunction}
            />

            <ConfirmDialog
                title={t(TK.declineQuote)}
                showAlert={showDirectDeclineAlert}
                onClickNoFunction={onHandleDeclineNo}
                onClickYesFunction={onHandleDeclineYes}
            />
        </>
    );
};

export default EditQuoteForm;
