import { ApplicationState } from '..';
import { Facets } from '../../models/Facets';
import { ProductV2 } from '../../models/ProductV2';

export const getAllProducts = (state: ApplicationState): { [id: string]: ProductV2 } => state.enquireProducts.items || {};
export const getSelectedProducts = (state: ApplicationState): ProductV2[] => state.enquireProducts.selectedProducts || [];
export const getSearchTotalIncludingNotLoaded = (state: ApplicationState): number => state.enquireProducts.searchTotal || 0;
export const getProductsRequestTimeInSeconds = (state: ApplicationState): number => state.enquireProducts.timeInSeconds;
export const isLoadingProducts = (state: ApplicationState): boolean => state.enquireProducts.isLoading;
export const getProductsSearchFacets = (state: ApplicationState): Facets => state.enquireProducts.facets || {};
export const hasCriteriaChanged = (state: ApplicationState): boolean => state.enquireProducts.hasCriteriaChanged;
