import { AppThunkAction } from '..';
import { Action } from 'redux';
import { SearchResult } from '../../models/SearchResult';
import { PlatformV3ProcessingSettings } from '../../models/ProcessingSettings';
import { ProcessingCatSettings } from '../../models/ProcessingCatSettings';
import { ProcessingDciFullSettings } from '../../models/ProcessingDciFullSettings';
import {
    FetchParams,
    PlatformV3ProcessingSettingsLoadedAction,
    PlatformV3ProcessingSettingsNewTermAction,
    PlatformV3ProcessingSettingsUpdatedAction,
} from './types';
import { isLoadingPlatformV3Data } from './selectors';
import { alertGenericError, requestServer, alertSaving, alertDismiss, alertSuccess } from '../Session/actions';
import {
    getPlatformV3ATCsAsync,
    getPlatformV3ActiveIngredientsAsync,
    getPlatformV3ActiveIngredientsFinalAsync,
    getPlatformV3CategoriesListAsync,
    getPlatformV3PharmaceuticalFormsAsync,
    postPlatformV3ActiveIngredientsFinalAsync,
    postPlatformV3CreateCategoryAsync,
    putPlatformV3ActiveIngredientsAsync,
    putPlatformV3PharmaceuticalFormsAsync,
} from '../../fetch/requests';
import { MappingName } from '../../models/ProcessingSettingsV3';
import { ProcessingPformsSettings } from '../../models/ProcessingPformsSettings';
import { getTranslation } from '../Translations/selectors';
import { TK } from '../Translations/translationKeys';

export const Actions = {
    platformV3DataLoading: '@@whichpharma.platformV3.dataLoading',
    platformV3DataLoadError: '@@whichpharma.platformV3.dataLoadError',
    platformV3ProcessingSettingsLoaded: '@@whichpharma.platformV3.mappingsLoaded',
    platformV3ProcessingSettingsUpdated: '@@whichpharma.platformV3.mappingsUpdated',
    platformV3ProcessingSettingsNewTermLoaded: '@@whichpharma.platformV3.newTermLoaded',
};

const platformV3DataLoading = (): Action => ({
    type: Actions.platformV3DataLoading,
});

const platformV3DataLoadError = (): Action => ({
    type: Actions.platformV3DataLoadError,
});

const platformV3ProcessingSettingsLoaded = (
    country: string,
    mappingName: MappingName,
    mappings: SearchResult<PlatformV3ProcessingSettings>,
): PlatformV3ProcessingSettingsLoadedAction => ({
    type: Actions.platformV3ProcessingSettingsLoaded,
    country,
    mappingName,
    mappings,
});

const platformV3ProcessingSettingsUpdated = (
    country: string,
    mappingName: MappingName,
    mappings: ProcessingPformsSettings[],
): PlatformV3ProcessingSettingsUpdatedAction => ({
    type: Actions.platformV3ProcessingSettingsUpdated,
    country,
    mappingName,
    mappings,
});

const platformV3ProcessingSettingsNewTermLoaded = (
    country: string,
    mappingName: MappingName,
    newTerm: ProcessingCatSettings | ProcessingDciFullSettings,
): PlatformV3ProcessingSettingsNewTermAction => ({
    type: Actions.platformV3ProcessingSettingsNewTermLoaded,
    country,
    mappingName,
    newTerm,
});

export const fetchPlatformV3ProcessingSettings = ({
    mappingName,
    origin,
    offset,
    pageSize,
    orderBy,
    filters,
    isNewTerm,
}: FetchParams): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            // TODO NMC: Substitiuir esta lógica por algo que não impeça o segundo pedido
            // if (isLoadingPlatformV3Data(getState())) {
            //     return;
            // }

            dispatch(platformV3DataLoading());

            const result = await dispatch(
                requestServer((token, csrfToken) => {
                    const requestProps = { origin, offset, pageSize, orderBy, filters, token, csrfToken, isNewTerm };
                    switch (mappingName) {
                        case MappingName.PharmaceuticalForms:
                            return getPlatformV3PharmaceuticalFormsAsync(requestProps);
                        case MappingName.CategoriesList:
                            return getPlatformV3CategoriesListAsync(requestProps);
                        case MappingName.ActiveIngredients:
                            return getPlatformV3ActiveIngredientsAsync(requestProps);
                        case MappingName.ActiveIngredientsFinal:
                            return getPlatformV3ActiveIngredientsFinalAsync(requestProps);
                        case MappingName.ATCs:
                            return getPlatformV3ATCsAsync(requestProps);
                        default:
                            return {} as Promise<SearchResult<PlatformV3ProcessingSettings>>;
                    }
                }),
            );
            dispatch(platformV3ProcessingSettingsLoaded(origin, mappingName, result));
        } catch (e) {
            console.log(e);
            dispatch(platformV3DataLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const fetchPlatformV3ProcessingSettingsOneRequest = ({
    mappingName,
    origin,
    offset,
    pageSize,
    orderBy,
    filters,
    isNewTerm,
    pageSizeCat,
}: FetchParams): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            // TODO NMC: Substitiuir esta lógica por algo que não impeça o segundo pedido
            // if (isLoadingPlatformV3Data(getState())) {
            //     return;
            // }

            dispatch(platformV3DataLoading());
            let pforms = {} as SearchResult<PlatformV3ProcessingSettings>;
            let fullList = {} as SearchResult<PlatformV3ProcessingSettings>;
            switch (mappingName) {
                case MappingName.PharmaceuticalForms:
                    pforms = await dispatch(
                        requestServer((token, csrfToken) => {
                            const requestProps = {
                                origin,
                                offset,
                                pageSize,
                                orderBy,
                                filters,
                                token,
                                csrfToken,
                                isNewTerm,
                            };
                            return getPlatformV3PharmaceuticalFormsAsync(requestProps);
                        }),
                    );
                    pageSize = pageSizeCat;
                    fullList = await dispatch(
                        requestServer((token, csrfToken) => {
                            const requestProps = { origin, pageSize, token, csrfToken };
                            return getPlatformV3CategoriesListAsync(requestProps);
                        }),
                    );
                    break;

                case MappingName.ActiveIngredients:
                    pforms = await dispatch(
                        requestServer((token, csrfToken) => {
                            const requestProps = {
                                origin,
                                offset,
                                pageSize,
                                orderBy,
                                filters,
                                token,
                                csrfToken,
                                isNewTerm,
                            };
                            return getPlatformV3ActiveIngredientsAsync(requestProps);
                        }),
                    );
                    pageSize = pageSizeCat;
                    fullList = await dispatch(
                        requestServer((token, csrfToken) => {
                            const requestProps = { origin, pageSize, token, csrfToken };
                            return getPlatformV3ActiveIngredientsFinalAsync(requestProps);
                        }),
                    );
                    break;
                default:
                    break;
            }

            dispatch(platformV3ProcessingSettingsLoaded(origin, mappingName, pforms));
            dispatch(
                platformV3ProcessingSettingsLoaded(
                    origin,
                    mappingName === MappingName.PharmaceuticalForms
                        ? MappingName.CategoriesList
                        : MappingName.ActiveIngredientsFinal,
                    fullList,
                ),
            );
        } catch (e) {
            console.log(e);
            dispatch(platformV3DataLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const savePlatformV3ProcessingSettings = ({
    origin,
    mappingName,
    data,
}: {
    origin: string;
    mappingName: MappingName;
    data: ProcessingPformsSettings[];
}): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingPlatformV3Data(getState())) {
                return;
            }
            dispatch(alertSaving(getTranslation(getState(), TK.saving)));
            await dispatch(
                requestServer((token, csrfToken) => {
                    const requestProps = { data, token, csrfToken };
                    switch (mappingName) {
                        case MappingName.PharmaceuticalForms:
                            return putPlatformV3PharmaceuticalFormsAsync(requestProps);
                        case MappingName.ActiveIngredients:
                            return putPlatformV3ActiveIngredientsAsync(requestProps);
                        default:
                            return Promise.resolve();
                    }
                }),
            );
            dispatch(platformV3ProcessingSettingsUpdated(origin, mappingName, data));
            dispatch(alertDismiss(getState().session.alerts[0].id));
            dispatch(alertSuccess(getTranslation(getState(), TK.savePlatformSettings)));
        } catch (e) {
            console.log(e);
            dispatch(platformV3DataLoadError());
            dispatch(alertGenericError());
        }
    };
};

export const savePlatformV3NewItem = ({
    origin,
    mappingName,
    data,
}: {
    origin: string;
    mappingName: MappingName;
    data: ProcessingCatSettings | { activeIngredient: string; user: string };
}): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            const result = await dispatch(
                requestServer((token, csrfToken) => {
                    switch (mappingName) {
                        case MappingName.CategoriesList:
                            return postPlatformV3CreateCategoryAsync({
                                data: data as ProcessingCatSettings,
                                token,
                                csrfToken,
                            });
                        case MappingName.ActiveIngredientsFinal:
                            return postPlatformV3ActiveIngredientsFinalAsync({
                                data: data as { activeIngredient: string; user: string },
                                token,
                                csrfToken,
                            });
                        default:
                            return Promise.resolve({} as ProcessingCatSettings | ProcessingDciFullSettings);
                    }
                }),
            );
            dispatch(alertSuccess(getTranslation(getState(), TK.pFormCreated)));
            dispatch(platformV3ProcessingSettingsNewTermLoaded(origin, mappingName, result));
        } catch (e) {
            dispatch(platformV3DataLoadError());
            dispatch(alertGenericError());
        }
    };
};
