import * as React from 'react';
import { BoxFlexBetween, Row, useStyles, Text, RowFlexEnd, RadioError } from './styles';
import { useTranslations } from '../../../store/Translations/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
    getQualificationForm,
    getQualificationFormToUpdate,
    getShowValidationErrors,
} from '../../../store/QualificationForm/selectors';
import { Question, YesNoNAOptions } from '../../../models/QualificationForm';
import { qualificationFormLoadError, updateQualificationForm } from '../../../store/QualificationForm/actions';
import { TK } from '../../../store/Translations/translationKeys';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

interface YesNoNAQuestionProps {
    question: Question;
    required?: boolean;
    isBlack?: boolean;
    readonly?: boolean;
    errors: string[];
}

const YesNoNAQuestion: React.FC<YesNoNAQuestionProps> = ({ question, required, isBlack, readonly, errors }) => {
    const t = useTranslations();
    const dispatch = useDispatch();

    const initialValues = useSelector(getQualificationForm);
    const questionsToUpdate = useSelector(getQualificationFormToUpdate);
    const showErrors = useSelector(getShowValidationErrors);
    const classes = useStyles();

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedValue = event.target.value as YesNoNAOptions;

        // Clear the error for this question if the value is updated
        if (errors.includes(question.id)) {
            dispatch(qualificationFormLoadError(errors.filter((id) => id !== question.id)));
        }

        // Check if the question is already in the list, then update or append
        const updatedQuestions = [
            ...questionsToUpdate.filter((q) => q.questionId !== question.id),
            { questionId: question.id, value: updatedValue, section: question.section },
        ];

        dispatch(updateQualificationForm(updatedQuestions));
    };
    return (
        <BoxFlexBetween color={isBlack ? '#fafafc' : '#ffff'} key={question.id} id={question.id}>
            <Row maxWidth={'80%'}>
                <Text>{t(TK[question.id as keyof typeof TK])}</Text>{' '}
            </Row>
            <FormControl
                component="fieldset"
                required={required}
                error={required && showErrors && errors.includes(question.id)}
            >
                <RadioGroup
                    defaultValue={initialValues.find((q) => q.questionId === question.id)?.value || ''}
                    onChange={handleRadioChange}
                    value={questionsToUpdate.find((q) => q.questionId === question.id)?.value || ''}
                    className={required && showErrors && errors.includes(question.id) ? classes.radioGroup : ''}
                >
                    <RowFlexEnd>
                        <FormControlLabel
                            value={YesNoNAOptions.Yes}
                            control={
                                required && showErrors && errors.includes(question.id) ? (
                                    <RadioError color={'primary'} />
                                ) : (
                                    <Radio color={'primary'} />
                                )
                            }
                            label={t(TK.yes)}
                            disabled={readonly}
                        />

                        <FormControlLabel
                            value={YesNoNAOptions.No}
                            control={
                                required && showErrors && errors.includes(question.id) ? (
                                    <RadioError color={'primary'} />
                                ) : (
                                    <Radio color={'primary'} />
                                )
                            }
                            label={t(TK.no)}
                            disabled={readonly}
                        />

                        <FormControlLabel
                            value={YesNoNAOptions.NA}
                            control={
                                required && showErrors && errors.includes(question.id) ? (
                                    <RadioError color={'primary'} />
                                ) : (
                                    <Radio color={'primary'} />
                                )
                            }
                            label={t(TK.na)}
                            disabled={readonly}
                        />
                    </RowFlexEnd>
                </RadioGroup>
            </FormControl>
        </BoxFlexBetween>
    );
};

export default YesNoNAQuestion;
