import appSettings from '../appSettings';

export const routes = {
    // CLOUD

    // Users
    login: () => `${appSettings.users_api_url}/Authenticate`,
    /* 
    postLoginAsync - @src\fetch\requests.ts
        doLogin - @src\store\Session\actions.ts
            Login - @src\pages\Login\Login.tsx
                routes.login
    */
    getAllUsers: () => `${appSettings.users_api_url}/GetAllUsers`,
    /*
    getUsersAsync - @src\fetch\requests.ts
        fetchUsers - @src\store\Users\actions.ts
            Users - @src\pages\Users\Users.tsx
                routes.users
            doLogin - @src\store\Session\actions.ts
                Login - @src\pages\Login\Login.tsx
                    routes.login
    */
    getUser: () => `${appSettings.users_api_url}/GetUser`,
    /* 
    getUserAsync - @src\fetch\requests.ts
        fetchUser - @src\store\Users\actions.ts
            Profile - @src\pages\Profile\Profile.tsx~
                routes.profile
    */
    updateUser: () => `${appSettings.users_api_url}/UpdateUser`,
    /* 
    putUserAsync - @src\fetch\requests.ts
        updateUser - @src\store\Users\actions.ts
            Profile - @src\pages\Profile\Profile.tsx
                routes.profile
            Users - @src\pages\Users\Users.tsx
                routes.users
    */
    createUser: () => `${appSettings.users_api_url}/CreateUser`,

    createAccount: () => `${appSettings.users_api_url}/CreateAccount`,
    /*
    postUserAsync - @src\fetch\requests.ts
        createUser - @src\store\Users\actions.ts
            Users - @src\pages\Users\Users.tsx
                routes.users
    */

    //Health:

    pingProducts: () => `${appSettings.health_api_url}/Ping`,
    pingProducts2: () => `${appSettings.health_api_url}/Ping2`,
    pingProducts3: () => `${appSettings.health_api_tst_url}/Ping`,
    pingProducts4: () => `${appSettings.health_api_tst_url}/Ping2`,

    // Products

    paginatedProducts: () => `${appSettings.products_api_url}/GetProducts`,
    /* 
    getPaginatedProductsAsync - @src\fetch\requests.ts
        fetchPaginatedProducts - @src\store\ProductsV2\actions.ts
            RFQProducts - @src\pages\RFQProducts\RFQProducts.tsx
                routes.rfqProducts
        fetchProducts - @src\store\EnquireProducts\actions.ts
            EnquireProducts - @src\pages\EnquireProducts\EnquireProducts.tsx
                routes.enquiryProducts
    */
    getProductDetails: () => `${appSettings.products_api_url}/GetProductDetails`,
    /*
    getProductDetailsAsync - @src\fetch\requests.ts
        fetchProductDetails - @src\store\ProductsV2\actions.ts
            ProductDetailDialog - @src\components\ProductDialog\ProductDialog.tsx
                ProductsSearchList - @src\components\ProductsSearchList\ProductsSearchList.tsx
                    RFQProducts - @src\pages\RFQProducts\RFQProducts.tsx
                        routes.rfqProducts
                    EnquireProducts - @src\pages\EnquireProducts\EnquireProducts.tsx
                        routes.enquiryProducts
    */

    // Sets
    getCountries: () => `${appSettings.domainInfo_api_url}/GetCountries`,
    /* 
    getCountriesAsync - @src\fetch\requests.ts
        fetchDomainInfo - @src\store\DomainInfo\actions.ts
            doLogin - @src\store\Session\actions.ts
                Login - @src\pages\Login\Login.tsx
                    routes.login
            useDomainInfo - @src\store\DomainInfo\hooks.ts
                useCountries - @src\store\DomainInfo\hooks.ts
                    ProductCard - @src\components\ProductCard\index.tsx
                        RFQDetails - @src\pages\RFQDetails\RFQDetails.tsx
                            routes.rfqDetail(':rfqNrEncoded', false)
                    ProductsFilters - @src\components\ProductsSearchList\ProductsFilters\index.tsx
                        ProductsSearchList - @src\components\ProductsSearchList\ProductsSearchList.tsx
                            RFQProducts - @src\pages\RFQProducts\RFQProducts.tsx
                                routes.rfqProducts
                            EnquireProducts - @src\pages\EnquireProducts\EnquireProducts.tsx
                                routes.enquiryProducts
                    EnquireCreationConfiguration - @src\pages\EnquireCreationConfiguration\EnquireCreationConfiguration.tsx
                        routes.enquiryConfigs
                    ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                        Platform - @src\pages\Platform\Platform.tsx
                            routes.platform
                    SuppliersFilters - @src\pages\SuppliersList\SuppliersFilters\SuppliersFilters.tsx
                        SuppliersListDialog - @src\components\SuppliersDialog\index.tsx
                            SuppliersList - @src\pages\SuppliersList\SuppliersList.tsx
                                routes.rfqSuppliers(':offset?')
    */

    getAllCountries: () => `${appSettings.domainInfo_api_url}/getallcountries`,
    /* 
        getAllCountriesAsync - @src\fetch\requests.ts
            fetchAllCountries - @src\store\CompanyDetails\actions.ts
            CompanyDetails - @src\pages\CompanyDetails\CompanyDetails.tsx
            
    */

    getPaymentTerms: () => `${appSettings.domainInfo_api_url}/v2/getpaymentterms`,
    /* 
        getpaymenttermsAsync - @src\fetch\requests.ts
            fetchPaymentTerms - @src\store\DomainInfo\hooks.ts
            InternalInformation - @src\components\InternalInformation\InternalInformation.tsx
            
    */

    getDeliveryTerms: () => `${appSettings.domainInfo_api_url}/getdeliveryterms`,
    /*
    getDeliveryTermsAsync - @src\fetch\requests.ts
        fetchDeliveryTerms - @src\store\CompanyDetails\actions.ts
        InternalInformation - @src\components\InternalInformation\InternalInformation.tsx
        
    */

    getTaxRates: () => `${appSettings.domainInfo_api_url}/gettaxrate`,
    /*
    getTaxRatesAsync - @src\fetch\requests.ts
        fetchTaxRates - @src\store\CompanyDetails\actions.ts
        InternalInformation - @src\components\InternalInformation\InternalInformation.tsx
        
    */

    getCompanyDetails: () => `${appSettings.suppliers_api_url}/getMyCompanyDetails`,
    /* 
        getCompanyDetailsAsync - @src\fetch\requests.ts
            fetchCompanyDetails - @src\store\CompanyDetails\actions.ts
            CompanyDetails - @src\pages\CompanyDetails\CompanyDetails.tsx
            
    */

    getCompanyTypes: () => `${appSettings.domainInfo_api_url}/v2/GetCompanyType`,
    /*
    getCompanyTypesAsync - @src\fetch\requests.ts
        fetchCompanyTypes - @src\store\CompanyDetails\actions.ts
        CompaniesList - @src\pages\CompaniesList\CompaniesList.tsx
    */

    getCompanies: () => `${appSettings.suppliers_api_url}/GetCompanyList`,
    /*
        getCompaniesAsync - @src\fetch\requests.ts
            fetchCompanies - @src\store\CompaniesList\actions.ts
            CompaniesList - @src\pages\CompaniesList\CompaniesList.tsx
                routes.companies
    */

    getCompanyInfo: (id: number) => `${appSettings.suppliers_api_url}/getinternalcompanyinfo?id=${id}`,
    /*
        getCompanyInfoAsync - @src\fetch\requests.ts
            fetchCompanyInfo - @src\store\CompanyInfo\actions.ts
            CompanyInfo - @src\components\InternalInformation\InternalInformation.tsx
                routes.companyInfo(':id')
    */

    getTransporters: () => `${appSettings.domainInfo_api_url}/GetTransporters`, // TODO: use fetchDomainInfo
    /* 
    getTransportersAsync - @src\fetch\requests.ts
        fetchTransporters - @src\store\Transporters\actions.ts
            RFQEnquire - @src\pages\RFQEnquire\RFQEnquire.tsx
                routes.rfqSimulation(':rfqNrEncoded', false)
    */
    getAdditionalDocuments: () => `${appSettings.domainInfo_api_url}/GetAdditionalDocuments`, // TODO: use fetchDomainInfo
    /* 
    getAdditionalDocumentsAsync - @src\fetch\requests.ts
        fetchAdditionalDocuments - @src\store\AdditionalDocuments\actions.ts
            EnquireCreationConfiguration - @src\pages\EnquireCreationConfiguration\EnquireCreationConfiguration.tsx
                routes.enquiryConfigs
    */

    // Suppliers
    getSuppliers: () => `${appSettings.suppliers_api_url}/GetSuppliers`,
    /* 
    getSuppliersAsync - @src\fetch\requests.ts
        fetchSuppliers - @src\store\Suppliers\actions.ts
            SuppliersList - @src\pages\SuppliersList\v.tsx
                routes.rfqSuppliers(':offset?')
    */

    // Rfqs
    rfqsV3: () => `${appSettings.rfqs_api_url}/GetRFQs`,
    /* 
    getRFQsAsyncPaginated - @src\fetch\requests.ts
        fetchRFQsV3 - @src\store\RFQsV3\actions.ts
            RFQDetails - @src\pages\RFQDetails\RFQDetails.tsx
                routes.rfqDetail(':rfqNrEncoded', false)
            RFQsList - @src\pages\RFQsList\RFQsList.tsx
                routes.rfqList
    */
    rfqsDetailsV3: () => `${appSettings.rfqs_api_url}/GetBidDetails`,
    /* 
    getRFQsDetailsAsyncV3 - @src\fetch\requests.ts
        fetchRFQsDetails - @src\store\RFQsV3\actions.ts
            fetchRFQsV3 - @src\store\RFQsV3\actions.ts
                RFQDetails - @src\pages\RFQDetails\RFQDetails.tsx
                    routes.rfqDetail(':rfqNrEncoded', false)
                RFQsList - @src\pages\RFQsList\RFQsList.tsx
                    routes.rfqList
    */
    rfqDetailsV3: (rfqNumber: string) =>
        `${appSettings.rfqs_api_url}/GetBidDetails?rfqNumber=${encodeURIComponent(rfqNumber)}`, // TODO: delete?
    /* 
    getRFQDetailsAsyncV3 - @src\fetch\requests.ts
        fetchRFQDetails - @src\store\RFQsV3\actions.ts
    */
    rfqsDueDateV3: () => `${appSettings.rfqs_api_url}/PutChangeDueDate`,
    /* 
    putRFQDueReminderRequestAsyncV3 - @src\fetch\requests.ts
        submitRFQDueDateAndReminder - @src\store\RFQsV3\actions.ts
            RFQsList - @src\pages\RFQsList\RFQsList.tsx
                routes.rfqList
    */
    rfqsReasonStateV3: () => `${appSettings.rfqs_api_url}/PutChangeState`,
    /* 
    putRFQStateReasonAsyncV3 - @src\fetch\requests.ts
        submitRFQStateReason - @src\store\RFQsV3\actions.ts
            RFQsList - @src\pages\RFQsList\RFQsList.tsx
                routes.rfqList
    */
    rfqTableDataV3: (rfqNumber: string) =>
        `${appSettings.rfqs_api_url}/PutData?rfqNumber=${encodeURIComponent(rfqNumber)}`,
    /* 
    putRFQDataAsyncV3 - @src\fetch\requests.ts
        changeRFQQuotesData - @src\store\RFQsV3\actions.ts
            RFQDetails - @src\pages\RFQDetails\RFQDetails.tsx
                routes.rfqDetail(':rfqNrEncoded', false)
    */
    rfqCardsDataV3: (rfqNumber: string) =>
        `${appSettings.rfqs_api_url}/PutCard?rfqNumber=${encodeURIComponent(rfqNumber)}`,
    /* 
    putRFQCardsDataAsyncV3 - @src\fetch\requests.ts
        updateRFQQuotesCardsData - @src\store\RFQsV3\actions.ts
            RFQDetails - @src\pages\RFQDetails\RFQDetails.tsx
                routes.rfqDetail(':rfqNrEncoded', false)
    */
    rfqNextNumberV3: () => `${appSettings.rfqs_api_url}/GetNextRFQNumber`, // TODO: delete?
    /* 
    getRFQNextNumberAsyncV3 - @src\fetch\requests.ts
        updateNextRFQNumber - @src\store\RFQsV3\actions.ts
            ProductsRFQsAssociation - @src\pages\CommunicationSetup\ProductsRFQsAssociation\ProductsRFQsAssociation.tsx
                CommunicationSetup - @src\pages\CommunicationSetup\CommunicationSetup.tsx
                    routes.rfqConfigs
            submitRFQs - @src\store\RFQsV3\actions.ts
                CommunicationSetup - @src\pages\CommunicationSetup\CommunicationSetup.tsx
                    routes.rfqConfigs
    */

    getComments: () => `${appSettings.enquiry_need_api_url}/GetComments`,

    postComment: () => `${appSettings.enquiry_need_api_url}/PostComment`,

    postRfqsV3: () => `${appSettings.rfqs_api_url}/PostRFQ`,
    /* 
    postRFQRequestAsyncV3 - @src\fetch\requests.ts
        submitRFQs - @src\store\RFQsV3\actions.ts
            CommunicationSetup - @src\pages\CommunicationSetup\CommunicationSetup.tsx
                routes.rfqConfigs
    */
    rfqEnquire: (rfqNumber: string) =>
        `${appSettings.rfqs_api_url}/GetRFQOffers?rfqNumber=${encodeURIComponent(rfqNumber)}`,
    /* 
    getRFQEnquireAsyncV3 - @src\fetch\requests.ts
        fetchRFQEnquire - @src\store\RFQEnquire\actions.ts
            RFQEnquire - @src\pages\RFQEnquire\RFQEnquire.tsx
                routes.rfqSimulation(':rfqNrEncoded', false)
    */
    rfqAssigneeV3: (rfqNumber: string) => `TODO`, // TODO: delete?
    /* 
    putRFQAssigneeAsyncV3 - @src\fetch\requests.ts
        changeRFQAssignment - @src\store\RFQsV3\actions.ts
            RFQDetails - @src\pages\RFQDetails\RFQDetails.tsx
                routes.rfqDetail(':rfqNrEncoded', false)
    */

    // Enquiry
    getEnquiriesClient: () => `${appSettings.enquiry_api_url}/GetEnquiriesClient`,
    /* 
    getEnquireClientsListAsync - @src\fetch\requests.ts
        fetchEnquires - @src\store\Enquire\actions.ts
            EnquiriesList - @src\pages\EnquiriesListClients\EnquiriesListClients.tsx
                routes.enquiryList
    */
    postEnquiry: () => `${appSettings.enquiry_api_url}/PostEnquiry`,
    /* 
    postEnquiryAsync - @src\fetch\requests.ts
    */
    getEnquiriesPharma: () => `${appSettings.enquiry_api_url}/GetEnquiriesPharma`,

    // Enquiry Need
    getEnquiryNeedDetail: () => `${appSettings.enquiry_need_api_url}/GetDetail`,
    getEnquiryNeedStatusDetail: () => `${appSettings.enquiry_need_api_url}/GetStatusDetail`,
    putEnquiryAproval: (needId: string) =>
        `${appSettings.enquiry_need_api_url}/PutApproval?needId=${encodeURIComponent(needId)}`,

    // External
    getExternalCollaborators: () => `${appSettings.external_api_url}/GetCollaborators`,
    /* 
    getExternalSupplierCollaboratorsAsync - @src\fetch\requests.ts
        SupplierReplyForm - @src\pages\SupplierReplyForm\SupplierReplyForm.tsx
            routes.rfqReply
    */
    getExternalProductsSearch: () => `${appSettings.external_api_url}/GetProductsSearch`,
    /* 
    getExternalSupplierProductsSearchAsync - @src\fetch\requests.ts
        OurInfoWithAutoComplete - @src\pages\SupplierReplyForm\OurInfoForm\OurInfoForm.tsx
            NewQuoteForm - @src\pages\SupplierReplyForm\NewQuoteForm\NewQuoteForm.tsx
                SupplierReplyForm - @src\pages\SupplierReplyForm\SupplierReplyForm.tsx
                    routes.rfqReply
    */
    getExternalResponsibles: () => `${appSettings.external_api_url}/GetResponsibles`,
    /* 
    getExternalSupplierRFQsResposiblesAsync - @src\fetch\requests.ts
        SupplierReplyForm - @src\pages\SupplierReplyForm\SupplierReplyForm.tsx
            routes.rfqReply
    */
    getExternalRfqsData: () => `${appSettings.external_api_url}/GetRfqsData`,
    /* 
    getExternalRFQsSupplierDataAsync - @src\fetch\requests.ts
        SupplierReplyForm - @src\pages\SupplierReplyForm\SupplierReplyForm.tsx
            routes.rfqReply
    */
    putExternalRfqsData: () => `${appSettings.external_api_url}/PutRfqsData`,
    /* 
    putExternalSupplierRFQsDataAsync - @src\fetch\requests.ts
        SupplierReplyForm - @src\pages\SupplierReplyForm\SupplierReplyForm.tsx
            routes.rfqReply
    */

    // Platform
    getPharmaceuticalForms: () => `${appSettings.platform_api_url}/GetPharmaceuticalForms`,
    /* 
    getPlatformV3PharmaceuticalFormsAsync - @src\fetch\requests.ts
        fetchPlatformV3ProcessingSettings - @src\store\PlatformV3\actions.ts
            ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                Platform - @src\pages\Platform\Platform.tsx
                    routes.platform
    */
    putPharmaceuticalForms: () => `${appSettings.platform_api_url}/PutPharmaceuticalForms`,
    /* 
    putPlatformV3PharmaceuticalFormsAsync - @src\fetch\requests.ts
        savePlatformV3ProcessingSettings - @src\store\PlatformV3\actions.ts
            ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                Platform - @src\pages\Platform\Platform.tsx
                    routes.platform
    */
    getCategoriesList: () => `${appSettings.platform_api_url}/GetCategoriesList`,
    /* 
    getPlatformV3CategoriesListAsync - @src\fetch\requests.ts
        fetchPlatformV3ProcessingSettings - @src\store\PlatformV3\actions.ts
            ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                Platform - @src\pages\Platform\Platform.tsx
                    routes.platform
    */
    createCategory: () => `${appSettings.platform_api_url}/CreateCategory`,
    /* 
    postPlatformV3CreateCategoryAsync - @src\fetch\requests.ts
        savePlatformV3NewItem - @src\store\PlatformV3\actions.ts
            ActiveIngredientsTable - @src\pages\Platform\ProcessingSettingsPanel\ActiveIngredientsTable\ActiveIngredientsTable.tsx
                ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                    Platform - @src\pages\Platform\Platform.tsx
                        routes.platform
            PharmaceuticalFormTable - @src\pages\Platform\ProcessingSettingsPanel\PharmaceuticalFormTable\PharmaceuticalFormTable.tsx
                ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                    Platform - @src\pages\Platform\Platform.tsx
                        routes.platform
    */
    getActiveIngredients: () => `${appSettings.platform_api_url}/GetActiveIngredients`,
    /* 
    getPlatformV3ActiveIngredientsAsync - @src\fetch\requests.ts
        fetchPlatformV3ProcessingSettings - @src\store\PlatformV3\actions.ts
            ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                Platform - @src\pages\Platform\Platform.tsx
                    routes.platform
    */
    putActiveIngredients: () => `${appSettings.platform_api_url}/PutActiveIngredients`,
    /* 
    putPlatformV3ActiveIngredientsAsync - @src\fetch\requests.ts
        savePlatformV3ProcessingSettings - @src\store\PlatformV3\actions.ts
            ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                Platform - @src\pages\Platform\Platform.tsx
                    routes.platform
    */
    getActiveIngredientsFinal: () => `${appSettings.platform_api_url}/GetActiveIngredientsFinal`,
    /* 
    getPlatformV3ActiveIngredientsFinalAsync - @src\fetch\requests.ts
        fetchPlatformV3ProcessingSettings - @src\store\PlatformV3\actions.ts
            ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                Platform - @src\pages\Platform\Platform.tsx
                    routes.platform
    */
    postActiveIngredientsFinal: () => `${appSettings.platform_api_url}/PostActiveIngredientsFinal`,
    /* 
    postPlatformV3ActiveIngredientsFinalAsync - @src\fetch\requests.ts
        savePlatformV3NewItem - @src\store\PlatformV3\actions.ts
            ActiveIngredientsTable - @src\pages\Platform\ProcessingSettingsPanel\ActiveIngredientsTable\ActiveIngredientsTable.tsx
                ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                    Platform - @src\pages\Platform\Platform.tsx
                        routes.platform
            PharmaceuticalFormTable - @src\pages\Platform\ProcessingSettingsPanel\PharmaceuticalFormTable\PharmaceuticalFormTable.tsx
                ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                    Platform - @src\pages\Platform\Platform.tsx
                        routes.platform
    */
    getATCs: () => `${appSettings.platform_api_url}/GetATCs`,
    /* 
    getPlatformV3ATCsAsync - @src\fetch\requests.ts
        fetchPlatformV3ProcessingSettings - @src\store\PlatformV3\actions.ts
            ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                Platform - @src\pages\Platform\Platform.tsx
                    routes.platform
    */
    verifyConfirmationCodeAsync: (code: string) =>
        `${appSettings.users_api_url}/verifyconfirmationcode?code=${encodeURIComponent(code)}`,

    verifyResetCodeAsync: (code: string) =>
        `${appSettings.users_api_url}/verifyresetcode?code=${encodeURIComponent(code)}`,
    /* 
    registration - @src\fetch\requests.ts
        verifyconfirmationcode - @src\store\registration\actions.ts
    */

    putAccountActivationAsync: () => `${appSettings.users_api_url}/accountactivation`,

    putRegistCodePasswordAsync: () => `${appSettings.users_api_url}/enableuser`,

    postResetPasswordAsync: () => `${appSettings.users_api_url}/resetpassword`,

    putStartResetPasswordAsync: () => `${appSettings.users_api_url}/startresetpassword`,
    /* 
    putRegistCodePasswordAsync - @src\fetch\requests.ts
        putRegistCodePasswordAsync - @src\store\Registration\actions.ts
            ProcessingSettingsPanel - @src\pages\Platform\ProcessingSettingsPanel\ProcessingSettingsPanel.tsx
                Platform - @src\pages\Platform\Platform.tsx
                    routes.registration
    */

    postCompanyDetailsAsync: () => `${appSettings.suppliers_api_url}/createorupdatecompany`,
    /*
    putSubmitCompanyDetailsAsync - @src\fetch\requests.ts
        putSubmitCompanyDetailsAsync - @src\store\CompanyDetails\actions.ts
            CompanyDetails - @src\pages\CompanyDetails\CompanyDetails.tsx
    */

    putCompanyInfo: () => `${appSettings.suppliers_api_url}/updatecompanyinternalinfo`,
    /*
    putCompanyInfoAsync - @src\fetch\requests.ts
        putCompanyInfoAsync - @src\store\CompanyInfo\actions.ts
            InternalInformation - @src\components\InternalInformation\InternalInformation.tsx
    */

    getProductTypes: () => `${appSettings.domainInfo_api_url}/getproducttypes`,
    /*
            getCompanyTypesAsync - @src\fetch\requests.ts
                fetchCompanyTypes - @src\store\CompanyDetails\actions.ts
                CompaniesList - @src\pages\CompaniesList\CompaniesList.tsx
            */
};
