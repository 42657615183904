import { AppThunkAction } from '..';
import { Action } from 'redux';
import { getRFQEnquireAsyncV3 } from '../../fetch/requests';
import { RFQEnquireLoadedAction } from './types';
import { requestServer } from '../Session/actions';
import { isLoadingRFQEnquire } from './selectors';
import { RFQEnquire } from './state';

export const Actions = {
    rfqEnquireLoading: '@@whichpharma.rfqEnquire.loading',
    rfqEnquireLoaded: '@@whichpharma.rfqEnquire.loaded',
};

const rfqEnquireLoading = (): Action => ({
    type: Actions.rfqEnquireLoading,
});

const rfqEnquireLoaded = (rfqNr: string, rfqEnquire: RFQEnquire): RFQEnquireLoadedAction => {
    return {
        type: Actions.rfqEnquireLoaded,
        rfqNr,
        rfqEnquire,
    };
};

export const fetchRFQEnquire = (rfqNr: string): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingRFQEnquire(getState())) {
                return;
            }
            dispatch(rfqEnquireLoading());
            const result = await dispatch(
                requestServer((token, csrfToken) => getRFQEnquireAsyncV3(rfqNr, token, csrfToken)),
            );
            dispatch(rfqEnquireLoaded(rfqNr, result));
        } catch (e) {
            console.log(e);
        }
    };
};
