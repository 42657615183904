import { AdditionalDocuments } from '../../models/AdditionalDocuments';
import { CompanyType } from '../../models/CompanyType';
import { Country } from '../../models/Country';
import { DeliveryTerm } from '../../models/DeliveryTerm';
import { PaymentTerm } from '../../models/PaymentTerms';
import { ProductType } from '../../models/ProductType';
import { RFQSimulationTransporterDto } from '../../models/RFQSimulationTransporterDto';
import { TaxRate } from '../../models/TaxRate';

export interface DomainInfoData<T> {
    items: T[];
    isUpdating: boolean;
    lastUpdate: Date;
}

export enum DomainInfoName {
    Countries = 'countries',
    Transporters = 'transporters',
    AdditionalDocuments = 'additionalDocuments',
    CompanyTypes = 'companyTypes',
    PaymentTerms = 'paymentTerms',
    DeliveryTerms = 'deliveryTerms',
    TaxRates = 'taxRates',
    ProductTypes = 'productTypes',
}

export interface DomainInfoState {
    [DomainInfoName.Countries]: DomainInfoData<Country>;
    [DomainInfoName.Transporters]: DomainInfoData<RFQSimulationTransporterDto>;
    [DomainInfoName.AdditionalDocuments]: DomainInfoData<AdditionalDocuments>;
    [DomainInfoName.CompanyTypes]: DomainInfoData<CompanyType>;
    [DomainInfoName.PaymentTerms]: DomainInfoData<PaymentTerm>;
    [DomainInfoName.DeliveryTerms]: DomainInfoData<DeliveryTerm>;
    [DomainInfoName.TaxRates]: DomainInfoData<TaxRate>;
    [DomainInfoName.ProductTypes]: DomainInfoData<ProductType>;
}
