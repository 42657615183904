import { RFQEnquireState } from './state';
import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import { RFQEnquireLoadedAction } from './types';

const unloadedState: RFQEnquireState = {
    rfqInformation: { needs: [], rfqDescription: '', rfqNumber: '' },
    isLoading: false,
};

export const persistor = (state: RFQEnquireState): RFQEnquireState => ({
    ...unloadedState,
});

export const reconciler = (stored: RFQEnquireState): RFQEnquireState => ({
    ...stored,
    isLoading: false,
});

const handleRFQEnquireLoading = (state: RFQEnquireState): RFQEnquireState => ({
    ...state,
    isLoading: true,
});

const handleRFQEnquireLoaded = (state: RFQEnquireState, action: RFQEnquireLoadedAction): RFQEnquireState => ({
    ...state,
    isLoading: false,
    rfqInformation: action.rfqEnquire,
});

export const reducer: Reducer<RFQEnquireState> = (
    state: RFQEnquireState = unloadedState,
    action: Action,
): RFQEnquireState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return unloadedState;
        case Actions.rfqEnquireLoading:
            return handleRFQEnquireLoading(state);
        case Actions.rfqEnquireLoaded:
            return handleRFQEnquireLoaded(state, action as RFQEnquireLoadedAction);
        default:
            return state;
    }
};
