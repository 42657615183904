import { IconButton, InputAdornment } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import * as React from 'react';
import { Spinner } from 'reactstrap';

import MultipleSelectInput from '../../../components/inputs/MultipleSelectInput';
import TextInput from '../../../components/inputs/TextInput';
import { Country } from '../../../models/Country';
import { useCountries } from '../../../store/DomainInfo/hooks';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';
import { ifEnter, removeSpaceCharacters } from '../../../utils/utils';
import { FiltersInputsContainer } from './SuppliersFilters.styles';
import { Filters, FiltersKey } from './SuppliersFilters.types';
import { CompanyStatus } from '../../../models/CompaniesList';

export interface SuppliersFiltersProps {
    initialValues: Filters;
    isLoading: boolean;
    onChange: (filters: Filters) => void;
}

const SuppliersFilters: React.FC<SuppliersFiltersProps> = ({ initialValues, isLoading, onChange }) => {
    const t = useTranslations();

    const countries = useCountries();

    const [filters, setFilters] = React.useState(initialValues);
    const [searchInput, setSearchInput] = React.useState<string>('');
    const statuses = Object.keys(CompanyStatus).map((status) => {
        return t(TK[status as keyof typeof CompanyStatus]);
    });

    const updateFilters = React.useCallback(
        (property: string, value: string | string[]) => {
            if (property === FiltersKey.Statuses) {
                typeof value === 'string'
                    ? (value = removeSpaceCharacters(value))
                    : (value = value.map((status) => removeSpaceCharacters(status)));
            }
            const newFilters = { ...filters, [property]: value || undefined };
            setFilters(newFilters);
            onChange(newFilters);
        },
        [filters, onChange],
    );

    const submitPress = React.useCallback((): void => {
        updateFilters(FiltersKey.Name, searchInput);
    }, [searchInput, updateFilters]);

    return (
        <div>
            <FiltersInputsContainer style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                <MultipleSelectInput
                    label={t(TK.country)}
                    placeholder={t(TK.all)}
                    limitTags={10}
                    options={countries}
                    values={countries.filter((country) =>
                        (filters[FiltersKey.Countries] || []).includes(country.alpha2Code),
                    )}
                    onChange={(v) =>
                        updateFilters(
                            FiltersKey.Countries,
                            v.map((country: Country) => country.alpha2Code),
                        )
                    }
                    disabled={isLoading}
                    getOptionLabel={(option: Country) => option?.name || ''}
                    renderOption={(option: Country) => `${option.name} (${option.alpha2Code})`}
                    style={{ width: '450px' }}
                />
                <MultipleSelectInput
                    label={t(TK.status)}
                    placeholder={t(TK.all)}
                    limitTags={10}
                    disabled={isLoading}
                    options={statuses}
                    values={Object.values(CompanyStatus).filter((status) => {
                        const normalizedStatus = removeSpaceCharacters(status);
                        if (typeof filters.statuses === 'string') {
                            return normalizedStatus === filters.statuses;
                        }
                        return filters.statuses?.includes(normalizedStatus);
                    })}
                    onChange={(v): void => updateFilters(FiltersKey.Statuses, v)}
                    style={{ width: '450px' }}
                />
                <TextInput
                    label={t(TK.name)}
                    placeholder={t(TK.all)}
                    disabled={isLoading}
                    value={searchInput}
                    onChange={(v) => setSearchInput(v)}
                    onKeyUp={(keyCode: { keyCode: number }): void => ifEnter(keyCode.keyCode, submitPress)}
                    style={{ width: '450px' }}
                    endAdorment={
                        <InputAdornment position="end">
                            {isLoading ? (
                                <Spinner size="sm" style={{ marginLeft: '5px' }} />
                            ) : (
                                <IconButton
                                    onClick={() => updateFilters(FiltersKey.Name, searchInput)}
                                    style={{ outline: 'none', height: '20px', padding: '0px', marginRight: '-18px' }}
                                    aria-label="free text filter info"
                                    edge="end"
                                >
                                    <PageviewIcon color="primary" style={{ fontSize: '3.7875rem' }} />
                                </IconButton>
                            )}
                        </InputAdornment>
                    }
                />
            </FiltersInputsContainer>
        </div>
    );
};

export default SuppliersFilters;
