import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    getSearchResultSuppliers,
    getSelectedSuppliers,
    getSelectedSuppliersEmails,
    getProductSelectedSuppliers,
    getProductSelectedSupplierSelectedEmails,
} from '../../store/Suppliers/selectors';
import { fetchSuppliers, supplierContactSelectedV3, supplierContactDeselectedV3 } from '../../store/Suppliers/actions';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { columns } from './columns';
import { ProductV2 } from '../../models/ProductV2';
import { Filters } from './SuppliersFilters/SuppliersFilters.types';
import { TK } from '../../store/Translations/translationKeys';
import { useTranslations } from '../../store/Translations/hooks';
import { Button, Checkbox, Typography, Tooltip } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Panel, { PanelButtonsContainer } from '../../components/Panel';
import queryString from 'query-string';
import List from '../../components/List';
import { productsDeselected } from '../../store/RFQProducts/actions';
import { Supplier } from '../../models/Supplier';
import { Contact } from '../../models/Contact';
import StarIcon from '@material-ui/icons/Star';
import RFQProgress, { RFQStep } from '../../modules/RFQProgress/RFQProgress';
import Page from '../../components/Page';
import { goToCommunicationConfig } from '../../store/Router/actions';
import { getOriginsByProductId, getSelectedProducts } from '../../store/RFQProducts/selectors';
import { SuppliersListDialog } from './SuppliersDialog';
import Alert from '@material-ui/lab/Alert/Alert';
import ConfirmDialog from '../../components/ConfirmDialog';
import { PaginationProps } from './SuppliersList.types';
import { getMyRoles } from '../../store/Users/selectors';
import { UserRole } from '../../models/UserRole';
import { CompanyStatus } from '../../models/CompaniesList';
import { removeSpaceCharacters } from '../../utils/utils';

const SuppliersList: React.FC = () => {
    const [verified, setVerified] = React.useState<string[]>([]);
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const [showAlert, setShowAlert] = React.useState<boolean>(false);
    const [removeProd, setRemoveProd] = React.useState<ProductV2[]>([]);
    const [filters, setFilters] = React.useState<Filters | undefined>(undefined);
    const [orderBy, setOrderBy] = React.useState<string | undefined>();
    const [paginationProps, setPaginationProps] = React.useState<PaginationProps>({
        currentPage: 0,
        pageSize: 10,
    });
    const [selectedProduct, setSelectedProduct] = React.useState<ProductV2 | null>(null);
    const [supplierDialogOpen, setSupplierDialogOpen] = React.useState<boolean>(false);

    const location = useLocation();

    const dispatch = useDispatch();
    const t = useTranslations();

    const query2 = queryString.parse(location.search) as Filters;

    const selectedProducts = useSelector(getSelectedProducts) || [];
    const productOrigins = useSelector(getOriginsByProductId) || {};
    const selectedProductSuppliers = useSelector(getProductSelectedSuppliers) || {};
    const selectedProductSuppliersEmails = useSelector(getProductSelectedSupplierSelectedEmails) || {};
    const suppliersResults = useSelector(getSearchResultSuppliers) || [];
    const selectedSuppliers = useSelector(getSelectedSuppliers) || [];
    const selectedSuppliersEmails = useSelector(getSelectedSuppliersEmails) || [];
    const isAdmin = useSelector(getMyRoles).includes(UserRole.Administrator);

    React.useEffect(() => {
        setVerified((prev) => prev.filter((id) => selectedProducts.some((product) => id === product.id)));
    }, [selectedProducts]);

    const suppliersWithoutEmailSelected = React.useMemo(
        () =>
            selectedSuppliers.filter(
                (supplier) =>
                    supplier.contacts.findIndex((contact) =>
                        selectedSuppliersEmails
                            .filter((e) => !e.isCC)
                            .map((e) => e.value)
                            .includes(contact.email),
                    ) === -1,
            ),
        [selectedSuppliers, selectedSuppliersEmails],
    );

    const getSelectedProductSuppliersCount = React.useCallback(() => {
        let count = 0;
        selectedProducts.forEach((product) => {
            if (selectedProductSuppliers[product.id] && selectedProductSuppliers[product.id].length > 0) {
                count++;
            }
        });
        return count;
    }, [selectedProducts, selectedProductSuppliers]);

    const errorMessage = React.useMemo(() => {
        return suppliersWithoutEmailSelected.length > 0
            ? t(TK.pleaseSelectContactEmailForTheSupplier, suppliersWithoutEmailSelected[0].name)
            : selectedSuppliersEmails.findIndex((email) => !email.value?.trim().length) >= 0
            ? t(TK.pleaseMakeSureAllContactsHaveAValidEmail)
            : selectedProducts.length === getSelectedProductSuppliersCount() &&
              verified.length !== selectedProducts.length
            ? t(TK.pleaseMakeSureVerifiedAllProducts)
            : selectedProducts.length === 0
            ? t(TK.pleaseSelectProducts)
            : undefined;
    }, [
        getSelectedProductSuppliersCount,
        selectedSuppliersEmails,
        suppliersWithoutEmailSelected,
        verified,
        selectedProducts,
        t,
    ]);

    React.useEffect(() => {
        if (filters === undefined) {
            return;
        }
        dispatch(
            fetchSuppliers({
                offset: paginationProps.pageSize * paginationProps.currentPage,
                pageSize: paginationProps.pageSize,
                filters,
                orderBy,
            }),
        );
    }, [paginationProps.currentPage, dispatch, orderBy, filters, paginationProps.pageSize]);

    React.useEffect(() => {
        if (filters === undefined) {
            return;
        }
        setPaginationProps((prevProps) => ({ ...prevProps, currentPage: 0 }));
        dispatch(
            fetchSuppliers({
                offset: 0,
                pageSize: paginationProps.pageSize,
                filters,
                orderBy,
            }),
        );
    }, [filters, orderBy, dispatch, paginationProps.pageSize]);

    const handleFiltersChange = (filters: Filters) => setFilters(filters);

    const handleProceedClick = () => dispatch(goToCommunicationConfig({ Version: query2.Version }));

    const onSelectSupplier = (id: any) => {
        const product = selectedProducts.find((product) => id === product.id);
        product && setSelectedProduct(product);
        setSupplierDialogOpen(true);
        setExpanded([id]);
        const updatedQuery = filters || {};
        if (updatedQuery && product) {
            const countries: any = productOrigins[product?.id] || [];
            const localSearchCountries = [product?.countryCode];
            updatedQuery.countries = [...new Set([...countries, ...localSearchCountries])];
            updatedQuery.name = '';
            updatedQuery.statuses = Object.values(CompanyStatus).map((status) => removeSpaceCharacters(status));

            if (Array.isArray(updatedQuery.countries) && updatedQuery.countries.length === 1) {
                updatedQuery.countries = updatedQuery.countries[0];
            }
            setFilters({ ...updatedQuery });
        }
    };

    const getUserAcknowledgement = (isAgree: boolean) => {
        isAgree && dispatch(productsDeselected(removeProd));
        setShowAlert(false);
    };

    const onProductDeleteHandler = (product: ProductV2) => {
        setShowAlert(true);
        setRemoveProd([product]);
    };

    const getSuppliersByProductId = (product: ProductV2) => {
        let productSuppliers: any = selectedProductSuppliers[product.id] || [];

        let suppliersByProducts: Supplier[] = [];
        if (suppliersResults.filter((s) => productSuppliers.includes(s.id)).length > 0) {
            suppliersByProducts = suppliersResults.filter((s) => productSuppliers.includes(s.id));
        } else {
            suppliersByProducts = selectedSuppliers.filter((s) => productSuppliers.includes(s.id));
        }

        return suppliersByProducts;
    };

    const handleVerifyChange = React.useCallback(
        (id: string, checked: boolean) => {
            const newVerified = checked ? [...verified, id] : verified.filter((i) => i !== id);

            setVerified(newVerified);

            setExpanded(checked ? expanded.filter((i) => i !== id) : [...expanded, id]);
        },
        [setVerified, setExpanded, verified, expanded],
    );

    return (
        <Page title={t(TK.createRequestForQuote)} style={{ marginTop: '10rem' }}>
            <RFQProgress activeStep={RFQStep.SelectSuppliers} />
            <ConfirmDialog showAlert={showAlert} getUserAcknowledgement={getUserAcknowledgement} />
            {!!selectedProducts.length && (
                <Panel title={`${t(TK.selectedProducts)} (${selectedProducts.length})`}>
                    {!selectedProducts.length && <p>{t(TK.noProductSelected)}</p>}
                    {selectedProducts.length !== getSelectedProductSuppliersCount() && (
                        <Typography>
                            <Alert severity="info">
                                <strong>{TK.selectSuppliersMessage}</strong>
                            </Alert>
                        </Typography>
                    )}
                    {errorMessage && (
                        <Typography color="error">
                            <Alert severity="error">{errorMessage}</Alert>
                        </Typography>
                    )}
                    <List
                        multiple
                        defaultExpanded={expanded}
                        onExpandedChanged={(expanded) => setExpanded(expanded as string[])}
                        items={selectedProducts.map((p) => ({ ...p, isSelected: true }))}
                        listType="products"
                        selectSupplierButton={true}
                        onClickSupplierButton={(id: any) => onSelectSupplier(id)}
                        renderActions={(item) => {
                            let checkValue = false;
                            const ourSuppliers = getSuppliersByProductId(item);
                            checkValue =
                                ourSuppliers && ourSuppliers.length
                                    ? ourSuppliers.some((s) =>
                                          s.contacts.some((c) =>
                                              selectedProductSuppliersEmails[item.id].find((e) => e.value === c.email),
                                          ),
                                      )
                                    : false;
                            if (checkValue) {
                                return (
                                    <Tooltip title={t(TK.markAsValidated)}>
                                        <Checkbox
                                            checked={verified.includes(item.id)}
                                            onChange={(_, checked) => handleVerifyChange(item.id, checked)}
                                            key={item.id}
                                            icon={<CheckCircleOutlineIcon style={{ fill: 'red' }} />}
                                            checkedIcon={<CheckCircleIcon style={{ fill: 'green' }} />}
                                            name={`checked-${item.id}`}
                                        />
                                    </Tooltip>
                                );
                            } else {
                                return null;
                            }
                        }}
                        renderDetails={(product: ProductV2): React.ReactNode => {
                            return (
                                <div style={{ width: '100%' }}>
                                    <Panel title={t(TK.emailsSelection)}>
                                        {!!!selectedProductSuppliers[product.id] && <p>{t(TK.noSupplierSelected)}</p>}
                                        {!!selectedProductSuppliers[product.id] && (
                                            <List
                                                items={getSuppliersByProductId(product).flatMap((s) =>
                                                    s.contacts.map((c) => ({
                                                        ...c,
                                                        id: c.email,
                                                        productId: product.id,
                                                        supplier: s,
                                                        isSelected: !!selectedProductSuppliersEmails[product.id].find(
                                                            (e) => e.value === c.email,
                                                        ),
                                                        isCc: !!selectedProductSuppliersEmails[product.id].find(
                                                            (e) => e.value === c.email,
                                                        )?.isCC,
                                                    })),
                                                )}
                                                listType="suppliers"
                                                renderName={(p: Contact & { supplier: Supplier }) => (
                                                    <span>
                                                        {columns.country.renderTableCell &&
                                                            columns.country.renderTableCell(p.supplier, t)}{' '}
                                                        - {p.supplier.name} - {p.name}
                                                        {p.isStared && <StarIcon />}
                                                    </span>
                                                )}
                                                renderSummary={(p: Contact) => `(${p.email})`}
                                                renderActions={(p: Contact & { isCc: boolean }) =>
                                                    isAdmin && (
                                                        <span>
                                                            CC:
                                                            <Checkbox
                                                                checked={p.isCc}
                                                                onChange={() => {
                                                                    dispatch(
                                                                        supplierContactSelectedV3(
                                                                            product.id,
                                                                            p.email,
                                                                            !p.isCc,
                                                                        ),
                                                                    );
                                                                }}
                                                            />
                                                        </span>
                                                    )
                                                }
                                                onItemSelected={(p: Contact) => {
                                                    dispatch(supplierContactSelectedV3(product.id, p.email, false));
                                                }}
                                                onItemDeselected={(p: Contact) =>
                                                    dispatch(supplierContactDeselectedV3(product.id, p.email))
                                                }
                                            />
                                        )}
                                    </Panel>
                                </div>
                            );
                        }}
                        renderName={(p: ProductV2): string => p.name}
                        renderSummary={(p: ProductV2): string =>
                            `(${[p.atc, p.pharmaceuticalForm, p.strength, p.package].filter((x) => x).join(' - ')})`
                        }
                        onItemDeselected={(p: ProductV2) => onProductDeleteHandler(p)}
                    />
                </Panel>
            )}

            <PanelButtonsContainer>
                {errorMessage && (
                    <Typography color="error">
                        <Alert severity="error">{errorMessage}</Alert>
                    </Typography>
                )}

                <Button
                    disabled={!!errorMessage || selectedProducts.length !== getSelectedProductSuppliersCount()}
                    variant="contained"
                    color="primary"
                    endIcon={<NavigateNextIcon />}
                    onClick={() => handleProceedClick()}
                >
                    {t(TK.proceedToEmailConfiguration)}
                </Button>
            </PanelButtonsContainer>
            {selectedProduct && (
                <SuppliersListDialog
                    open={supplierDialogOpen}
                    productOpen={selectedProduct}
                    filters={filters || {}}
                    setOrderBy={setOrderBy}
                    handleDialogClose={() => setSupplierDialogOpen(false)}
                    handleFiltersChange={handleFiltersChange}
                    paginationProps={paginationProps}
                    setPaginationProps={setPaginationProps}
                />
            )}
        </Page>
    );
};

export default SuppliersList;
