import { Contact } from './Contact';
import { QualificationForm } from './QualificationForm';

export interface CompanyDetails {
    id: string | null | null;
    name: string | null;
    companyName: string | null;
    bankName: string | null;
    bankAddress: string | null;
    iban: string | null;
    swiftBic: string | null;
    currencyCode: string | null;
    paymentTerms: string | null;
    paymentTermsDescription: string | null;
    vat: string | null;
    type: string | null;
    typeValue: number | null;
    countryCode: string | null;
    isEurope: boolean | null;
    classification: number | null;
    contacts: Contact[] | null;
    state: string | null;
    stateValue: number | null;
    companyAccountType: string | null;
    art23: string | null;
    art23Details: string | null;
    typeDescription: string | null;
    lastVerification: string | null;
    expirationDate: string | null;
    creationDate: string | null;
    warehouseSameAsOffice: string | null;
    deliverySameAs: string | null;
    pharmacovigilanceSameAsResponsibleQm: boolean | null;
    productsType: string[] | null;
    qualificationForm: QualificationForm | null;
}

export enum companyAccountTypeOptions {
    Supplier = 'Supplier',
    Customer = 'Customer',
    Both = 'Both',
}

export enum contactTypeOptions {
    //tab1
    CompanyContactPerson = 'CompanyContactPerson',
    Office = 'Office',
    //tab2
    ResponsibleQMPerson = 'ResponsibleQMPerson',
    WareHouseInfo = 'WareHouseInfo',
    DeliveryInfo = 'DeliveryInfo',
    PharmacovigilanceInfo = 'PharmacovigilanceInfo',
    //tab3
    FinancialContactPerson = 'FinancialContactPerson',
}

export enum ContactOptions {
    name = 'name',
    email = 'email',
    isStared = 'isStared',
    phone = 'phone',
    address = 'address',
    postalCode = 'postalCode',
    contactType = 'contactType',
    website = 'website',
    position = 'position',
    closingHours = 'closingHours',
    id = 'id',
    openingHours = 'openingHours',
    remarks = 'remarks',
}
