import { Box, BoxProps, makeStyles, Radio } from '@material-ui/core';
import styled from 'styled-components';

export const BoxFlexBetween = styled(Box)<BoxProps & { maxWidth?: string; color?: string }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 1%;
    max-width: ${(p) => p.maxWidth || '100%'};
    background-color: ${(p) => p.color};
    color: ${(p) => (p.color === '#ffffff' ? '#000000' : '#000000')};
`;

export const Row = styled(Box)<BoxProps & { maxWidth?: string }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: ${(p) => p.maxWidth || '100%'};
    @media (max-width: 1024px) {
        max-width: 90%; /* Adjust for tablets or medium screens */
    }

    @media (max-width: 768px) {
        max-width: 100%; /* Full width for smaller screens */
    }
`;

export const Text = styled.span`
    margin-right: 0.8rem;
    white-space: normal;
    word-break: break-word;
`;

export const RowFlexEnd = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    flex-wrap: nowrap;
`;

// Style for the radio group when has error
export const useStyles = makeStyles({ radioGroup: { color: 'red' } });

export const RadioError = styled(Radio)`
    color: red;
`;
