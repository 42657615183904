import { Action, Reducer } from 'redux';
import { CompanyInfo } from '../../models/CompanyInfo';
import { CompanyInfoState } from './state';
import { CompanyInfoErrorAction, CompanyInfoLoadedAction } from './types';
import { Actions } from './actions';

const unloadedState: CompanyInfoState = {
    companyInfo: {} as CompanyInfo,
    isLoading: false,
    updating: false,
    companyInfoToUpdate: {} as CompanyInfo,
    // All fields are required, so we default to true
    companyInfoErrors: {
        entityType: true,
        deliveryTerms: true,
        paymentTermsCustomer: true,
        vatTable: true,
        statusChangeReason: true,
        paymentTermsCustomerDescription: true,
    },
    showValidationErrors: false,
};

export const persistor = (state: CompanyInfoState): CompanyInfoState => ({
    ...unloadedState,
});

export const reconciler = (state: CompanyInfoState): CompanyInfoState => ({
    ...state,
    isLoading: false,
});

const handleCompanyInfoLoading = (state: CompanyInfoState): CompanyInfoState => ({
    ...state,
    isLoading: true,
    showValidationErrors: false,
});

const handleCompanyInfoLoadError = (state: CompanyInfoState, action: CompanyInfoErrorAction): CompanyInfoState => ({
    ...state,
    isLoading: false,
    companyInfoErrors: action.fields,
});

const handleCompanyInfoLoaded = (state: CompanyInfoState, action: CompanyInfoLoadedAction): CompanyInfoState => ({
    ...state,
    isLoading: false,
    companyInfo: action.result,
    companyInfoToUpdate: {
        ...action.result,
        reason: '',
        notes: '',
    },
    companyInfoErrors: {
        ...state.companyInfoErrors,
        entityType: action.result.entityType ? false : true,
        deliveryTerms: action.result.deliveryTerms ? false : true,
        paymentTermsCustomer: action.result.paymentTermsCustomer ? false : true,
        vatTable: action.result.vatTable ? false : true,
        statusChangeReason: true,
        paymentTermsCustomerDescription: action.result.paymentTermsCustomerDescription ? false : true,
    },
});

const handleCompanyInfoUpdate = (state: CompanyInfoState, action: CompanyInfoLoadedAction): CompanyInfoState => ({
    ...state,
    companyInfoToUpdate: action.result,
});

const handleShowValidationErrors = (state: CompanyInfoState): CompanyInfoState => ({
    ...state,
    showValidationErrors: true,
});

export const reducer: Reducer<CompanyInfoState> = (
    state: CompanyInfoState = unloadedState,
    action: Action,
): CompanyInfoState => {
    switch (action.type) {
        case Actions.companyInfoLoading:
            return handleCompanyInfoLoading(state);
        case Actions.companyInfoLoadError:
            return handleCompanyInfoLoadError(state, action as CompanyInfoErrorAction);
        case Actions.companyInfoLoaded:
            return handleCompanyInfoLoaded(state, action as CompanyInfoLoadedAction);
        case Actions.companyInfoUpdate:
            return handleCompanyInfoUpdate(state, action as CompanyInfoLoadedAction);
        case Actions.companyInfoShowValidationErrors:
            return handleShowValidationErrors(state);
        default:
            return state;
    }
};
