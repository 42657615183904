import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Box, Button } from '@material-ui/core';
import { goBack } from 'react-router-redux';
import { RowSelectedEvent } from 'ag-grid-community';
import { columnsArray } from './columns';
import { Filters, FiltersKey } from './ProductsFilters/ProductsFilters.types';
import { TK } from '../../store/Translations/translationKeys';
import queryString from 'query-string';
import { ProductV2 } from '../../models/ProductV2';
import Table from '../../components/AGPaginatedTable';
import { PanelButtonsContainer } from '../../components/Panel';
import { ProductDetailDialog } from './ProductDialog/ProductDialog';
import { ProductsSearchFilters, ProductsSearchListProps } from './ProductsSearchList.types';
import ProductsFilters from './ProductsFilters/ProductsFilters';
import SelectedProductsDialog from './SelectedProductsDialog/SelectedProductsDialog';

const ProductsSearchList = ({
    goToAction,
    fetchAction,
    loadedAction,
    selectedAction,
    deselectedAction,
    goToNextAction,
    isLoadingSelector,
    getTotalSelector,
    getSelectedSelector,
    getAllSelector,
    proceedText,
    getFacetsSelector,
    getHasCriteriaChangedSelector,
    changeCriteria,
    resetFacets,
}: ProductsSearchListProps) => {
    const location = useLocation();
    const dispatch = useDispatch();

    const bottomRef = useRef<HTMLDivElement>(null);

    const products = useSelector(getAllSelector);
    const totalRecords = useSelector(getTotalSelector);
    const selectedProducts = useSelector(getSelectedSelector);
    const isLoading = useSelector(isLoadingSelector);
    const facets = useSelector(getFacetsSelector);
    const hasCriteriaChanged = useSelector(getHasCriteriaChangedSelector);

    const [selectedProductsDialogOpen, setSelectedProductsDialogOpen] = React.useState<boolean>(false);
    const [tableFilters, setTableFilters] = React.useState<{ [key: string]: any }>({});
    const [filters, setFilters] = React.useState<ProductsSearchFilters>({
        isMarketed: true,
        page: 0,
        pageSize: 10,
    } as ProductsSearchFilters);

    React.useEffect(() => {
        dispatch(resetFacets());
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        const parsedFilters = queryString.parse(location.search, {
            parseBooleans: true,
            parseNumbers: true,
        }) as ProductsSearchFilters;

        // Set fallback filters
        parsedFilters.page = parsedFilters.page || 0;
        parsedFilters.pageSize = parsedFilters.pageSize || 10;

        parsedFilters.isMarketed =
            Object.keys(parsedFilters).findIndex((key) => key === FiltersKey.IsMarketed) >= 0
                ? parsedFilters.isMarketed
                : true;

        const newFilters = JSON.parse(JSON.stringify(parsedFilters));
        setFilters({ ...newFilters });
    }, [location.search]);

    React.useEffect(() => {
        dispatch(fetchAction(filters, (filters.page || 0) * (filters.pageSize || 10), hasCriteriaChanged));
        // eslint-disable-next-line
    }, [filters]);

    // Table Definition

    const getColsDef = React.useMemo(() => {
        return columnsArray();
    }, []);

    const defaultColDef = React.useMemo(
        () => ({
            flex: 1,
            sortable: true,
            wrapHeaderText: true,
            minWidth: 125,
            resizable: true,
            autoHeaderHeight: true,
            suppressMovable: true,
            filter: true,
            filterParams: {
                textMatcher: () => true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            },
        }),
        [],
    );

    // Page Handlers

    // Filters from the top
    const handleFiltersChange = React.useCallback(
        (newFilters: Filters) => {
            dispatch(changeCriteria(true));
            handleFiltersClear();
            if (Object.keys(newFilters).length === 0) {
                dispatch(deselectedAction([...selectedProducts]));
            } else {
                dispatch(
                    goToAction({
                        countries: newFilters[FiltersKey.Countries] as string[],
                        free: newFilters[FiltersKey.Free] as string,
                        page: 0,
                        refCountries: [],
                    }),
                );
            }
        },
        // eslint-disable-next-line
        [selectedProducts],
    );

    const handleFiltersClear = React.useCallback(() => {
        setTableFilters({});
        dispatch(loadedAction({ items: [], timeInSeconds: 0, total: 0, facets: {} }));
        dispatch(
            goToAction({
                ...filters,
                free: '',
                page: 0,
                pageSize: 10,
                isMarketed: true,
                isEuropean: 0,
                countries: [],
                refCountries: [],
                filters: '',
            }),
        );
    }, [dispatch, filters, setTableFilters, goToAction, loadedAction]);

    const filterItemsByTypes = React.useCallback(
        (isEuropean: number) => {
            dispatch(
                goToAction({
                    ...filters,
                    isEuropean: isEuropean,
                    page: 0,
                }),
            );
        },
        [filters, dispatch, goToAction],
    );

    const filteryByIsCommercial = React.useCallback(
        (isCommercial: boolean) => {
            dispatch(
                goToAction({
                    ...filters,
                    isMarketed: isCommercial,
                    page: 0,
                }),
            );
        },
        [filters, dispatch, goToAction],
    );

    const handleProceedClick = React.useCallback(() => {
        window?.localStorage.setItem('queryString', window?.location.search);
        return goToNextAction && dispatch(goToNextAction());
    }, [dispatch, goToNextAction]);

    // Table Handlers

    const onRowDataUpdated = (params: any) => {
        if (selectedProducts && selectedProducts.length) {
            params.api.forEachNode((node: any) => {
                const isProductPresent = selectedProducts
                    .filter((p) => !!p)
                    .find((product) => product.id === node.data.id);
                if (isProductPresent) {
                    node.setSelected(true);
                }
            });
        }
    };

    const onPageChanged = React.useCallback(
        (page: number) => {
            dispatch(
                goToAction({
                    ...filters,
                    page,
                }),
            );
        },
        [filters, dispatch, goToAction],
    );

    const onPageSizeChanged = React.useCallback(
        (pageSize: number) => {
            dispatch(
                goToAction({
                    page: 0,
                    pageSize: pageSize,
                }),
            );
        },
        [dispatch, goToAction],
    );

    // Filters from Grid
    const onFilterChanged = (query: string, countries: string) => {
        if (!query && !countries) return;
        dispatch(changeCriteria(false));
        if (countries) {
            const newCountries = countries.split(',').map((country) => country.trim().toUpperCase());
            dispatch(
                goToAction({
                    ...filters,
                    filters: query,
                    refCountries: newCountries,
                    page: 0,
                }),
            );
        } else {
            dispatch(
                goToAction({
                    ...filters,
                    filters: query,
                    page: 0,
                }),
            );
        }
    };

    const onSortChanged = React.useCallback(
        (sortField: string, sortOrder: string) => {
            if (sortField && sortOrder) {
                dispatch(
                    goToAction({
                        ...filters,
                        page: 0,
                        orderBy: `${sortField} ${sortOrder}`,
                    }),
                );
                return;
            }

            dispatch(
                goToAction({
                    ...filters,
                    page: 0,
                    orderBy: '',
                }),
            );
        },
        [filters, dispatch, goToAction],
    );

    const onFreeTextChanged = React.useCallback(
        (freeText: string) => {
            dispatch(
                goToAction({
                    ...filters,
                    free: freeText,
                }),
            );
        },
        [filters, dispatch, goToAction],
    );

    const handleProductSelectionChange = React.useCallback(
        (product: ProductV2, selected: boolean) => {
            dispatch(selected ? selectedAction([product]) : deselectedAction([product]));
        },
        [dispatch, deselectedAction, selectedAction],
    );

    // Product Details Handlers

    const productOpen = React.useMemo(() => (location.hash?.length > 1 ? products[location.hash.substring(1)] : null), [
        location,
        products,
    ]);

    const handleDialogClose = React.useCallback(() => {
        dispatch(goBack());
    }, [dispatch]);

    const handleSelectProductOpen = React.useCallback(() => {
        productOpen && handleProductSelectionChange(productOpen, true);
        handleDialogClose();
        setTimeout(() => bottomRef.current?.scrollIntoView({ behavior: 'smooth' }), 500);
    }, [handleDialogClose, productOpen, handleProductSelectionChange]);

    // Selected Products Handlers

    const handleSave = (selectedRows: ProductV2[]) => {
        const deselectedRows = [...selectedProducts].filter(
            (product) => !selectedRows.some((row) => row.id === product.id),
        );
        dispatch(deselectedAction(deselectedRows));
        setSelectedProductsDialogOpen(false);
    };

    return (
        <>
            <ProductDetailDialog
                productOpen={productOpen}
                handleSelectProductOpen={handleSelectProductOpen}
                handleDialogClose={handleDialogClose}
            />
            <ProductsFilters
                filterItemsByTypes={filterItemsByTypes}
                filteryByIsCommercial={filteryByIsCommercial}
                isSwitch={false}
                isLoading={isLoading}
                defaultFilters={filters as Filters}
                onChange={handleFiltersChange}
                onClear={handleFiltersClear}
            />
            {!!selectedProducts.length && (
                <Box
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    position="relative"
                    height={'34px'}
                    marginBottom={'15px'}
                >
                    <Button
                        disabled={!!!goToNextAction}
                        variant="contained"
                        color="primary"
                        endIcon={<NavigateNextIcon />}
                        onClick={handleProceedClick}
                    >
                        {proceedText}
                    </Button>
                    <Button
                        style={{ position: 'absolute', right: 0 }}
                        variant="contained"
                        color="primary"
                        onClick={() => setSelectedProductsDialogOpen(true)}
                    >
                        <ShoppingCartIcon />
                    </Button>
                </Box>
            )}
            <Table
                columnsDefinition={getColsDef}
                isLoading={isLoading}
                pageSize={filters.pageSize!}
                currentPage={+filters.page!}
                totalPages={Math.ceil(totalRecords / filters.pageSize!)}
                totalElements={totalRecords}
                isExportable={false}
                enablePagination={true}
                enableSearch={false}
                captionAnalytics={TK.TotalResult}
                defaultColDef={defaultColDef}
                tableFilters={tableFilters}
                facets={facets}
                data={Object.values(products)}
                onPageChange={onPageChanged}
                onPageSizeChange={onPageSizeChanged}
                onRowDataUpdated={onRowDataUpdated}
                selectedRows={selectedProducts}
                rowSelection="multiple"
                onFilterChanged={onFilterChanged}
                onChangeSorting={onSortChanged}
                onFreeTextChange={onFreeTextChanged}
                onRowSelected={(event: RowSelectedEvent) => {
                    // Check if the click originated from a Link or a specific element
                    const target = event.event?.target as HTMLElement;

                    if (target?.closest('a')) {
                        // If the target is within a link, do nothing
                        event.node.setSelected(!event.node.isSelected());
                        return;
                    }
                    handleProductSelectionChange(event.node.data, !!event.node.isSelected());
                }}
                suppressRowClickSelection={false}
            />
            {!!selectedProducts.length && (
                <>
                    <PanelButtonsContainer>
                        <Button
                            disabled={!!!goToNextAction}
                            variant="contained"
                            color="primary"
                            endIcon={<NavigateNextIcon />}
                            onClick={handleProceedClick}
                        >
                            {proceedText}
                        </Button>
                    </PanelButtonsContainer>
                </>
            )}
            {!!selectedProducts.length && (
                <SelectedProductsDialog
                    selectedProducts={selectedProducts}
                    isDialogOpen={selectedProductsDialogOpen}
                    closeDialog={() => setSelectedProductsDialogOpen(false)}
                    save={handleSave}
                />
            )}
        </>
    );
};

export default ProductsSearchList;
