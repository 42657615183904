import { CompanyDetailsState } from './state';
import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import { CompanyDetailsAction, CompanyDetailsLoadedAction } from './types';
import { CompanyDetails } from '../../models/CompanyDetails';

const unloadedState: CompanyDetailsState = {
    companyDetailsInfo: {} as CompanyDetails,
    isLoading: false,
    updating: false,
};

export const persistor = (state: CompanyDetailsState): CompanyDetailsState => ({
    ...unloadedState,
});

export const reconciler = (stored: CompanyDetailsState): CompanyDetailsState => ({
    ...stored,
    isLoading: false,
});

const handleCompanyDetailsLoading = (state: CompanyDetailsState): CompanyDetailsState => ({
    ...state,
    isLoading: true,
});

const handleCompanyDetailsLoadError = (state: CompanyDetailsState): CompanyDetailsState => ({
    ...state,
    isLoading: false,
});

const handleCompanyDetailsLoaded = (
    state: CompanyDetailsState,
    action: CompanyDetailsLoadedAction,
): CompanyDetailsState => ({
    ...state,
    isLoading: false,
    companyDetailsInfo: action.result,
});

const handleCompanyDetailsUpdate = (state: CompanyDetailsState, action: CompanyDetailsAction): CompanyDetailsState => ({
    ...state,
    companyDetailsInfo: action.companyDetails,
});

export const reducer: Reducer<CompanyDetailsState> = (
    state: CompanyDetailsState = unloadedState,
    action: Action,
): CompanyDetailsState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return unloadedState;
        case Actions.companyDetailsLoading:
            return handleCompanyDetailsLoading(state);
        case Actions.companyDetailsLoadError:
            return handleCompanyDetailsLoadError(state);
        case Actions.companyDetailsLoaded:
            return handleCompanyDetailsLoaded(state, action as CompanyDetailsLoadedAction);
        case Actions.companyDetailsUpdate:
            return handleCompanyDetailsUpdate(state, action as CompanyDetailsAction);
        default:
            return state;
    }
};
