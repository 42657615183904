import * as React from 'react';
import Section from './Sections/Section';
import {
    FreeValueIds,
    QuestionTypeOptions,
    SectionOptions,
    TransportResponsibilityOptions,
} from '../../models/QualificationForm';
import { areEqual } from 'react-window';
import YesNoNAQuestion from './Questions/YesNoNA';
import FreeValueQuestion from './Questions/FreeValue';
interface RowProps {
    data: any;
    index: number;
    style: React.CSSProperties;
}

const Row = React.memo(({ data, index, style }: RowProps) => {
    const { questions, errors, readOnly, listRef } = data;
    // Check if the item exists
    const question = questions[index];
    if (!question) return null;
    const showSection = index === 0 || questions[index - 1]?.section !== question.section;

    const isFreeValueSectionFirstOccurrence =
        question.type === QuestionTypeOptions.FreeValue &&
        ((question.section === SectionOptions.TCQ && question.order === 3) ||
            (question.section === SectionOptions.TR && question.order === 1));

    const isSectionRequired =
        question.section !== SectionOptions.TCQ ||
        !(
            question.section === SectionOptions.TR &&
            question.id === FreeValueIds.TRName &&
            question.value === TransportResponsibilityOptions.supplier
        );

    return (
        <div style={style}>
            {showSection && <Section section={question.section} />}
            {question.type === QuestionTypeOptions.YesNoNA && (
                <YesNoNAQuestion
                    question={question}
                    required={isSectionRequired}
                    isBlack={index % 2 !== 0}
                    readonly={readOnly}
                    errors={errors}
                />
            )}
            {isFreeValueSectionFirstOccurrence && question.type === QuestionTypeOptions.FreeValue && (
                <FreeValueQuestion question={question} readonly={readOnly} errors={errors} listRef={listRef} />
            )}
        </div>
    );
}, areEqual);

export default Row;
