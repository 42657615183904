export interface CompanyInformationError {
    //tab1
    tab1: {
        companyName?: boolean | null;
        vat?: boolean | null;
        countryCode?: boolean | null;
        officeAddress?: boolean | null;
        postalCode?: boolean | null;
        phone?: boolean | null;
        website?: string;
        contactPersonName?: boolean | null;
        contactPersonEmail?: boolean | null;
        contactPersonPhone?: boolean | null;
        contactPersonPosition?: boolean | null;
    };
    tab2: {
        type?: boolean | null;
        typeDescription?: boolean | null;
        productTypes?: boolean | null;
        art23?: boolean | null;
        art23Details?: boolean | null;
        responsibleQMPersonName?: boolean | null;
        responsibleQMPersonPosition?: boolean | null;
        responsibleQMPersonPhone?: boolean | null;
        responsibleQMPersonEmail?: boolean | null;
        deliverySameAs?: boolean | null;
        deliveryAddress?: boolean | null;
        deliveryPersonName?: boolean | null;
        deliveryPersonPhone?: boolean | null;
        deliveryPersonEmail?: boolean | null;
        deliveryPersonOpeningHours?: boolean | null;
        deliveryPersonClosingHours?: boolean | null;
        deliveryPersonRemarks?: boolean | null;
        pharmacovigilanceSameAsResponsibleQm?: boolean | null;
        pharmacovigilancePersonName?: boolean | null;
        pharmacovigilancePersonPosition?: boolean | null;
        pharmacovigilancePersonPhone?: boolean | null;
        pharmacovigilancePersonEmail?: boolean | null;
        warehouseSameAsOffice?: boolean | null;
        warehousePersonName?: boolean | null;
        warehousePersonPhone?: boolean | null;
        warehousePersonEmail?: boolean | null;
        warehousePersonOpeningHours?: boolean | null;
        warehousePersonClosingHours?: boolean | null;
        warehousePersonRemarks?: boolean | null;
        warehouseAddress?: boolean | null;
    };
    tab3: {
        financialContactPersonName?: boolean | null;
        financialContactPersonPosition?: boolean | null;
        financialContactPersonPhone?: boolean | null;
        financialContactPersonEmail?: boolean | null;
        bankName?: boolean | null;
        bankAddress?: boolean | null;
        iban?: boolean | null;
        swiftBic?: boolean | null;
        currency?: boolean | null;
        paymentTerms?: boolean | null;
        paymentTermsDescription?: boolean | null;
    };
}

export enum CompanyInformationFields {
    //tab1
    companyName = 'companyName',
    name = 'name',
    vat = 'vat',
    country = 'countryCode',
    officeAddress = 'officeAddress',
    postalCode = 'postalCode',
    phone = 'phone',
    website = 'website',
    contactPersonName = 'contactPersonName',
    contactPersonEmail = 'contactPersonEmail',
    contactPersonPhone = 'contactPersonPhone',
    contactPersonPosition = 'contactPersonPosition',
    //tab2
    type = 'type',
    typeDescription = 'typeDescription',
    art23 = 'art23',
    art23Details = 'art23Details',
    responsibleQMPersonName = 'responsibleQMPersonName',
    responsibleQMPersonPosition = 'responsibleQMPersonPosition',
    responsibleQMPersonPhone = 'responsibleQMPersonPhone',
    responsibleQMPersonEmail = 'responsibleQMPersonEmail',
    warehouseSameAsOffice = 'warehouseSameAsOffice',
    warehousePersonName = 'warehousePersonName',
    warehousePersonPhone = 'warehousePersonPhone',
    warehousePersonEmail = 'warehousePersonEmail',
    warehousePersonOpeningHours = 'warehousePersonOpeningHours',
    warehousePersonClosingHours = 'warehousePersonClosingHours',
    warehousePersonRemarks = 'warehousePersonRemarks',
    warehouseAddress = 'warehouseAddress',
    pharmacovigilanceSameAsResponsibleQm = 'pharmacovigilanceSameAsResponsibleQm',
    pharmacovigilancePersonName = 'pharmacovigilancePersonName',
    pharmacovigilancePersonPosition = 'pharmacovigilancePersonPosition',
    pharmacovigilancePersonPhone = 'pharmacovigilancePersonPhone',
    pharmacovigilancePersonEmail = 'pharmacovigilancePersonEmail',
    deliverySameAsInvoicing = 'deliverySameAsInvoicing',
    deliveryAddress = 'deliveryAddress',
    deliveryPersonName = 'deliveryPersonName',
    deliveryPersonPhone = 'deliveryPersonPhone',
    deliveryPersonEmail = 'deliveryPersonEmail',
    deliveryPersonOpeningHours = 'deliveryPersonOpeningHours',
    deliveryPersonClosingHours = 'deliveryPersonClosingHours',
    deliveryPersonRemarks = 'deliveryPersonRemarks',

    //tab3
    financialContactPersonName = 'financialContactPersonName',
    financialContactPersonPosition = 'financialContactPersonPosition',
    financialContactPersonPhone = 'financialContactPersonPhone',
    financialContactPersonEmail = 'financialContactPersonEmail',
    bankName = 'bankName',
    bankAddress = 'bankAddress',
    bankIBAN = 'iban',
    bankSwiftCodeBic = 'swiftBic',
    currency = 'currencyCode',
    paymentTerms = 'paymentTerms',
    specifyPaymentTerms = 'paymentTermsDescription',
}
export const CompanyInformationErrorDefault: CompanyInformationError = {
    tab1: {
        companyName: null,
        vat: null,
        contactPersonEmail: null,
        contactPersonName: null,
        contactPersonPhone: null,
        contactPersonPosition: null,
        countryCode: null,
        officeAddress: null,
        phone: null,
        postalCode: null,
    },
    tab2: {
        art23: null,
        art23Details: null,
        deliveryPersonClosingHours: null,
        deliveryPersonEmail: null,
        deliveryPersonName: null,
        deliveryPersonOpeningHours: null,
        deliveryPersonPhone: null,
        deliveryPersonRemarks: null,
        responsibleQMPersonEmail: null,
        responsibleQMPersonName: null,
        responsibleQMPersonPhone: null,
        responsibleQMPersonPosition: null,
        warehousePersonEmail: null,
        warehousePersonName: null,
        warehousePersonPhone: null,
        warehouseAddress: null,
        warehousePersonOpeningHours: null,
        warehousePersonClosingHours: null,
        warehousePersonRemarks: null,
        deliverySameAs: null,
        pharmacovigilanceSameAsResponsibleQm: null,
        pharmacovigilancePersonEmail: null,
        pharmacovigilancePersonName: null,
        pharmacovigilancePersonPhone: null,
        pharmacovigilancePersonPosition: null,
        type: null,
        typeDescription: null,
        warehouseSameAsOffice: null,
    },
    tab3: {
        bankAddress: null,
        iban: null,
        bankName: null,
        swiftBic: null,
        financialContactPersonEmail: null,
        financialContactPersonName: null,
        financialContactPersonPhone: null,
        financialContactPersonPosition: null,
        currency: null,
        paymentTerms: null,
        paymentTermsDescription: null,
    }
};

export type CompanyInformationErrorKeys = keyof CompanyInformationError;
