import { CountriesState } from './state';
import { Action, Reducer } from 'redux';
import { Actions } from './actions';
import { Actions as SessionActions } from '../Session/actions';
import { CountriesLoadedAction } from './types';

const unloadedState: CountriesState = {
    countriesInfo: [],
    isLoading: false,
};

export const persistor = (state: CountriesState): CountriesState => ({
    ...unloadedState,
});

export const reconciler = (stored: CountriesState): CountriesState => ({
    ...stored,
    isLoading: false,
});

const handleTransporterLoading = (state: CountriesState): CountriesState => ({
    ...state,
    isLoading: true,
});

const handleTransporterLoaded = (state: CountriesState, action: CountriesLoadedAction): CountriesState => ({
    ...state,
    isLoading: false,
    countriesInfo: action.result,
});

export const reducer: Reducer<CountriesState> = (
    state: CountriesState = unloadedState,
    action: Action,
): CountriesState => {
    switch (action.type) {
        case SessionActions.loggedOut:
            return unloadedState;
        case Actions.countriesLoading:
            return handleTransporterLoading(state);
        case Actions.countriesLoaded:
            return handleTransporterLoaded(state, action as CountriesLoadedAction);
        default:
            return state;
    }
};
