import * as React from 'react';
import { ATCMappingTableContainer } from './ATCsTable.styles';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { ATCsTableV3Props } from './ATCsTable.types';
import { ProcessingATCSettings } from '../../../../models/ProcessingATCSettings';
import { DEFAULT_PAGE_SIZE } from '../ProcessingSettingsPanel';

const ATCsTable: React.FC<ATCsTableV3Props> = ({ aTCs, setTotalNumberOfPages }) => {
    const [aTCsList, setATCsList] = React.useState<ProcessingATCSettings[]>([]);

    React.useEffect(() => {
        setATCsList(aTCs.items);
        setTotalNumberOfPages(Math.ceil(aTCs.total / DEFAULT_PAGE_SIZE));
    }, [aTCs, setTotalNumberOfPages]);

    return (
        <ATCMappingTableContainer>
            <Table size="small">
                <TableBody>
                    {aTCsList.map((item) => (
                        <TableRow key={item.atcCode}>
                            <TableCell>{item.atcCode}</TableCell>
                            <TableCell width="100%">{item.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </ATCMappingTableContainer>
    );
};

export default ATCsTable;
