import React, { useCallback } from 'react';
import Page from '../../components/Page';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import AddIcon from '@material-ui/icons/AddCircle';
import { Button, IconButton, InputAdornment } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import { useDispatch, useSelector } from 'react-redux';
import {
    getRFQSummaries,
    isLoadingRFQs,
    getRFQDetails,
    getRFQSearchTotal,
    isLoadingRFQsDetails,
} from '../../store/RFQ/selectors';
import { RFQState } from '../../models/RFQState';
import { fetchRFQsV3, submitRFQDueDateAndReminder, submitRFQStateReason } from '../../store/RFQ/actions';
import { goToPaginatedProducts, goToRFQDetails, goToRFQEnquire, goToRFQsList } from '../../store/Router/actions';
import { PageActionPanel } from '../../components/Page/styled';
import { productsDeselectAll } from '../../store/RFQProducts/actions';
import { suppliersDeselectAll } from '../../store/Suppliers/actions';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import TextInput from '../../components/inputs/TextInput';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import { IRfqProps, RfqsFilters } from './RFQsList.types';
import { KanbanFilters } from '../SupplierReplyForm/Filters/Filters';
import { IFilterMembers } from '../../models/RFQQuote';
import { dueDateFilterList } from '../../utils/dueDateFilters';
import { usersFilterList } from '../../utils/usersFilter';
import Table from '../../components/AGPaginatedTable';
import { columnsArray } from './columns';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import { escapeHtmlSpecialChars } from '../../utils/utils';
import ReasonDialog from './ReasonDialog/ReasonDialog';
import DueDateReminder from './DueDateReminder/DueDateReminder';
import { RFQSupplierDetails } from '../../models/RFQSupplierDetails';
import { MapOf } from '../../utils/Types';
import { Spinner } from 'reactstrap';

const RFQsList: React.FC = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const t = useTranslations();

    const { setHeaderName } = React.useContext(AppContext) as AppContextType;

    const [isOpenDialog, setIsOpenDialg] = React.useState(false);
    const [isOpenRDialog, setIsOpenRDialog] = React.useState(false);
    const [members, setMembers] = React.useState<IFilterMembers[]>(usersFilterList);
    const [dueDateFilters, setDueDateFilters] = React.useState(dueDateFilterList);
    const [currentPage, setCurrentPage] = React.useState<number>(0);
    const [currentPageSize, setCurrentPageSize] = React.useState<number>(10);
    const [orderBy, setOrderBy] = React.useState<string>('CreationDate desc');
    const [rfqItem, setRfqItem] = React.useState<IRfqProps>({
        id: '',
        username: '',
        number: '',
        creationDate: '',
        endingDate: '',
        title: '',
        state: RFQState.Open,
        stateChangeDate: '',
        assigneeUsername: '',
    });
    const [isShowFilters, setIsShowFilters] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState<string>('');
    const rfqSummaries = useSelector(getRFQSummaries) || {};
    const rfqsDetails = useSelector(getRFQDetails);
    const rfqsLoading = useSelector(isLoadingRFQs);
    const rfqsDetailsLoading = useSelector(isLoadingRFQsDetails);
    const totalRecords = useSelector(getRFQSearchTotal) || 0;

    const isLoading = rfqsLoading || rfqsDetailsLoading;

    const filters = React.useMemo(
        () => queryString.parse(location.search, { parseBooleans: true, parseNumbers: true }) as RfqsFilters,
        [location.search],
    );

    React.useEffect(() => {
        setHeaderName(t(TK.rfqsList));
    }, [dispatch, setHeaderName, t]);

    React.useEffect(() => {
        dispatch(
            fetchRFQsV3(
                filters.onlyMine === 'true',
                filters.search,
                filters.expiredIn,
                filters.createdBy,
                filters.filters,
                orderBy,
                currentPage * currentPageSize,
                currentPageSize,
            ),
        );
    }, [filters, currentPage, currentPageSize, orderBy, setOrderBy, dispatch]);

    const defaultColDef = React.useMemo(
        () => ({
            flex: 1,
            filter: true,
            sortable: true,
            wrapHeaderText: true,
            minWidth: 125,
            resizable: true,
            autoHeaderHeight: true,
            suppressMovable: true,
            filterParams: {
                textMatcher: () => true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
                suppressAndOrCondition: true,
            },
        }),
        [],
    );

    const onClickStateHandler = (item: IRfqProps) => {
        setRfqItem(item);
        setIsOpenRDialog(true);
    };

    const goToRFQ = React.useCallback(
        (itemId: string) => {
            dispatch(goToRFQDetails(itemId));
        },
        [dispatch],
    );

    const goToRFQInq = React.useCallback(
        (itemId: string) => {
            dispatch(goToRFQEnquire(itemId));
        },
        [dispatch],
    );

    const getColsDef = React.useCallback(() => {
        return columnsArray(goToRFQ, goToRFQInq, onClickStateHandler);
    }, [goToRFQ, goToRFQInq]);

    const onCreateNew = React.useCallback(() => {
        dispatch(productsDeselectAll());
        dispatch(suppliersDeselectAll());
        dispatch(goToPaginatedProducts());
    }, [dispatch]);

    const rfqSummariesItems = React.useMemo(() => {
        return Object.values(rfqSummaries).map((rfq) => {
            const rfqDetail = rfqsDetails && rfqsDetails[rfq.number];
            const byCountry =
                rfqDetail?.suppliersDetails &&
                rfqDetail?.suppliersDetails
                    .filter((supplierDetails) => supplierDetails.country && supplierDetails.countryCode)
                    .reduce<MapOf<RFQSupplierDetails[]>>(
                        (prev, curr) => ({
                            ...prev,
                            [curr.country]: prev[curr.country] ? [...prev[curr.country], curr] : [curr],
                        }),
                        {},
                    );
            return {
                ...rfq,
                id: rfq.number,
                username: rfq.assigneeUsername,
                rfqDetail,
                byCountry,
            };
        });
    }, [rfqSummaries, rfqsDetails]);

    const handleSearch = useCallback(() => {
        setCurrentPage(0);
        dispatch(
            goToRFQsList({
                ...filters,
                offset: 0,
                search: searchInput?.length ? escapeHtmlSpecialChars(searchInput).replace('*', '') : undefined,
            }),
        );
    }, [searchInput, filters, dispatch]);

    const saveRDialogBox = (rfqNumber: string, reason: string, state: RFQState, dueDate: string) => {
        const rfqState = state === RFQState.Open ? RFQState.ClosedWithoutQuote : RFQState.Open;
        dispatch(submitRFQStateReason({ rfqNumber: rfqNumber, reason, state: rfqState, dueDate }));
        setIsOpenRDialog(false);
    };

    const closeRDialogBox = () => {
        setIsOpenRDialog(false);
    };

    const closeDialogBox = () => {
        setIsOpenDialg(false);
    };

    const saveReminder = (rfqNumber: string, reminder: number, dueDate: string) => {
        dispatch(submitRFQDueDateAndReminder(rfqNumber, { reminder, dueDate }));
    };

    /*  
        The Due date filters needs to be test
    */
    const onHandleChangeOverDue = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCurrentPage(0);
            const dueFiltersCopy = [...dueDateFilters];
            const index = dueFiltersCopy.findIndex((item) => item.value === parseInt(event.target.value));
            dueFiltersCopy[index].checked = event.target.checked;
            const activeFilters = dueFiltersCopy.filter((item) => item.checked).map((item) => item.value);
            if (activeFilters.length) {
                dispatch(goToRFQsList({ ...filters, offset: undefined, expiredIn: activeFilters.join(',') }));
            } else {
                dispatch(goToRFQsList({ ...filters, offset: undefined, expiredIn: undefined }));
            }

            setDueDateFilters(dueFiltersCopy);
        },
        [dispatch, filters, dueDateFilters],
    );

    const onHandleMemberFilterChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const copiedMembers = [...members];
            const index = copiedMembers.findIndex((member) => member.key === event.target.value);
            copiedMembers[index].checked = event.target.checked;
            const searchMembers = copiedMembers.filter((member) => member.checked).map((member) => member.key);

            if (searchMembers.length) {
                dispatch(goToRFQsList({ ...filters, offset: undefined, createdBy: searchMembers.join(',') }));
            } else {
                dispatch(goToRFQsList({ ...filters, offset: undefined, createdBy: undefined }));
            }

            setMembers(copiedMembers);
        },
        [filters, dispatch, members],
    );

    const onPageSizeChanged = React.useCallback((pageSize: number) => {
        setCurrentPage(0);
        setCurrentPageSize(pageSize);
    }, []);

    const onPageChanged = React.useCallback((page: number) => {
        setCurrentPage(page);
    }, []);

    const onSortChanged = React.useCallback((sortField: string, sortOrder: string) => {
        if (sortField && sortOrder) {
            setCurrentPage(0);
            setOrderBy(`${sortField} ${sortOrder}`);
            return;
        }

        setOrderBy('');
    }, []);

    const onFilterChanged = React.useCallback(
        (queryFilter: string) => {
            filters.filters = queryFilter;
            dispatch(
                goToRFQsList({
                    ...filters,
                }),
            );
        },
        [dispatch, filters],
    );

    return (
        <Page
            title={t(TK.rfqsList)}
            actionPanel={
                <PageActionPanel>
                    <Button variant="contained" color="primary" endIcon={<AddIcon />} onClick={onCreateNew}>
                        {t(TK.create)}
                    </Button>
                    <TextInput
                        value={searchInput}
                        onChange={(v) => setSearchInput(v)}
                        placeholder={t(TK.search)}
                        endAdorment={
                            <InputAdornment position="end">
                                {isLoading ? (
                                    <Spinner size="sm" style={{ marginLeft: '5px' }} />
                                ) : (
                                    <IconButton
                                        onClick={handleSearch}
                                        style={{
                                            outline: 'none',
                                            height: '20px',
                                            padding: '0px',
                                            marginRight: '-18px',
                                        }}
                                        aria-label="free text filter info"
                                        edge="end"
                                    >
                                        <PageviewIcon color="primary" style={{ fontSize: '3.7875rem' }} />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        }
                    />
                    <Button
                        color="primary"
                        onClick={() => {
                            setIsShowFilters((value) => !value);
                        }}
                        variant="contained"
                    >
                        <svg
                            width="24"
                            height="24"
                            role="presentation"
                            focusable="false"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M4.61799 6C3.87461 6 3.39111 6.78231 3.72356 7.44721L3.99996 8H20L20.2763 7.44721C20.6088 6.78231 20.1253 6 19.3819 6H4.61799ZM10.8618 17.7236C10.9465 17.893 11.1196 18 11.309 18H12.6909C12.8803 18 13.0535 17.893 13.1382 17.7236L14 16H9.99996L10.8618 17.7236ZM17 13H6.99996L5.99996 11H18L17 13Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                        Filters
                    </Button>
                    {isShowFilters && (
                        <KanbanFilters
                            members={members}
                            dueDateFilters={dueDateFilterList}
                            onHandleChangeOverDue={onHandleChangeOverDue}
                            onHandleMemberFilterChange={onHandleMemberFilterChange}
                            setShowFilters={setIsShowFilters}
                            showCreatedBy={false}
                        />
                    )}
                </PageActionPanel>
            }
            style={{ marginTop: '10rem' }}
        >
            <Table
                isLoading={isLoading}
                columnsDefinition={getColsDef() as ColDef[] | ColGroupDef[]}
                pageSize={currentPageSize}
                currentPage={currentPage}
                totalPages={Math.ceil(totalRecords / currentPageSize)}
                totalElements={totalRecords}
                isExportable={false}
                enablePagination={true}
                enableSearch={false}
                captionAnalytics={TK.TotalResult}
                gridType="products"
                defaultColDef={defaultColDef}
                data={rfqSummariesItems}
                onPageChange={onPageChanged}
                onPageSizeChange={onPageSizeChanged}
                onFilterChanged={onFilterChanged}
                onChangeSorting={onSortChanged}
                onFreeTextChange={() => {}}
            />

            <ReasonDialog
                rfqItem={rfqItem}
                isOpenRDialog={isOpenRDialog}
                saveRDialogBox={saveRDialogBox}
                closeRDialogBox={closeRDialogBox}
            />
            <DueDateReminder
                rfqItem={rfqItem}
                saveReminder={saveReminder}
                isOpenDialog={isOpenDialog}
                closeDialogBox={closeDialogBox}
            />
        </Page>
    );
};

export default RFQsList;
