import * as React from 'react';
import { useTranslations } from '../../store/Translations/hooks';
import Page from '../../components/Page';
import { TK } from '../../store/Translations/translationKeys';
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import TextInput from '../../components/inputs/TextInput';
import { RegisterCompany } from '../../models/CreateUser';

import { Label } from '../../components/Styled';
import { alertError } from '../../store/Session/actions';
import { registerCompanyAction } from '../../store/Users/actions';
import { BoxFullWidthWhite, GridCenter } from './Register.styles';
import { getCountries } from '../../store/Countries/selectors';
import { fetchCountries } from '../../store/Countries/actions';
import SingleSelectInput from '../../components/inputs/SingleSelectInput';
import { Country } from '../../models/Country';
import CountryFlag from '../../components/CountryFlag';

const _emailRegexFunction = (value: string) => /^(?=.*[A-Za-z])[A-Za-z\d.\-@]{4,}$/g.test(value);
const _passwordStrengthRegexFunction = (value: string) => /^(?=.*[A-Za-z])(?=.*\d).{4,}$/g.test(value);
const mailError = 'Mail is not in correct format';
const passError = 'Password is not in correct format';
const confirmPassError = 'Password not match';
const companyError = 'Company name empty';
const typeError = 'Acess Type empty';
const countryError = 'Country empty';
const termsError = 'Terms not accepted';

const Register: React.FC = () => {
    const t = useTranslations();
    const dispatch = useDispatch();
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [countryName, setCountryName] = React.useState('');
    const [validMail, setValidMail] = React.useState(false);
    const [isClicked, setIsClicked] = React.useState(false);
    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [validPass, setValidPass] = React.useState(false);
    const [registerCompany, setRegisterCompany] = React.useState({ value: {} as RegisterCompany, valid: false });
    const countries = useSelector(getCountries);
    React.useEffect(() => {
        setHeaderName(t(TK.products));
    }, [setHeaderName,t]);

    React.useEffect(() => {
        dispatch(fetchCountries());
    }, [dispatch]);
    const handleConfirmPassword = React.useCallback(
        (confirmPassword: string) => {
            setConfirmPassword(confirmPassword);
            const valid =
                !!registerCompany.value.password?.length && registerCompany.value.password === confirmPassword;
            setRegisterCompany({ value: registerCompany.value, valid });
        },
        [registerCompany],
    );
    const handleChange = React.useCallback(
        (newValue: RegisterCompany) => {
            const valid = !!newValue.password?.length && newValue.password === confirmPassword;
            setValidMail(_emailRegexFunction(newValue.email ?? ''));
            setValidPass(_passwordStrengthRegexFunction(newValue.password));
            setIsClicked(false);
            setRegisterCompany({ value: newValue, valid });
        },
        [confirmPassword],
    );
    const onClickRegister = React.useCallback(() => {
        const emailValid = _emailRegexFunction(registerCompany.value.email ?? '');
        const passValid = _passwordStrengthRegexFunction(registerCompany.value?.password || '');
        setValidMail(emailValid);
        setValidPass(passValid);
        setIsClicked(true);
        if (!emailValid) {
            dispatch(alertError(mailError));
            return;
        }
        if (!validPass) {
            dispatch(alertError(passError));
            return;
        }
        if (!registerCompany.valid) {
            dispatch(alertError(confirmPassError));
            return;
        }
        if (!registerCompany.value.companyName?.length) {
            dispatch(alertError(companyError));
            return;
        }
        if (!registerCompany.value.acessType) {
            dispatch(alertError(typeError));
            return;
        }
        if (!registerCompany.value.country) {
            dispatch(alertError(countryError));
            return;
        }
        if (!termsAccepted) {
            dispatch(alertError(termsError));
            return;
        }
        dispatch(registerCompanyAction({ ...registerCompany.value }));
        registerCompany.value.password = '';
        setConfirmPassword('');
    }, [registerCompany, termsAccepted, dispatch, validPass]);

    return (
        <Page title={t(TK.welcome)}>
            <Grid container spacing={2} style={{ backgroundColor: 'white' }}>
                <Grid item xs={12}>
                    <Typography variant="h2" style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
                        {t(TK.registration)}
                    </Typography>
                </Grid>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <BoxFullWidthWhite>
                            <TextInput
                                type="email"
                                label={t(TK.email)}
                                value={registerCompany.value.email}
                                required
                                error={isClicked && !validMail ? t(TK.emailEmptyOrNotValid) : ''}
                                onChange={(email) => handleChange({ ...registerCompany.value, email })}
                                fullWidth
                            />
                        </BoxFullWidthWhite>
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <div style={{ display: 'block', width: '100%', backgroundColor: 'white' }}>
                            <TextInput
                                label={t(TK.password)}
                                value={registerCompany.value.password}
                                type="password"
                                required
                                error={isClicked && !validPass ? t(TK.passwordEmptyOrInvalid) : ''}
                                fullWidth
                                onChange={(password) => handleChange({ ...registerCompany.value, password })}
                            />
                        </div>
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <BoxFullWidthWhite>
                            <TextInput
                                label={t(TK.confirmPassword)}
                                value={confirmPassword}
                                type="password"
                                required
                                fullWidth
                                error={
                                    isClicked && registerCompany.value.password !== confirmPassword
                                        ? t(TK.passwordMustMatch)
                                        : ''
                                }
                                onChange={handleConfirmPassword}
                            />
                        </BoxFullWidthWhite>
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <Box width={'100%'}>
                            <Box display={'flex'}>
                                <Label>{t(TK.accessType)}*</Label>
                            </Box>
                            <FormControl fullWidth style={{ alignItems: 'center' }}>
                                <RadioGroup
                                    row
                                    onChange={(i, acessType) => {
                                        handleChange({ ...registerCompany.value, acessType });
                                    }}
                                >
                                    <FormControlLabel
                                        value="Customer"
                                        control={<Radio color="primary" />}
                                        label="Customer"
                                    />
                                    <FormControlLabel
                                        value="Supplier"
                                        control={<Radio color="primary" />}
                                        label="Supplier"
                                    />
                                    <FormControlLabel value="Both" control={<Radio color="primary" />} label="Both" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <BoxFullWidthWhite>
                            <TextInput
                                label={t(TK.companyName)}
                                value={registerCompany.value.companyName}
                                type="text"
                                required
                                error={
                                    isClicked && !registerCompany.value.companyName
                                        ? t(TK.companyNameNeedToBeFilled)
                                        : ''
                                }
                                fullWidth
                                onChange={(companyName) => handleChange({ ...registerCompany.value, companyName })}
                            />
                        </BoxFullWidthWhite>
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6}>
                        <BoxFullWidthWhite>
                            {/* <TextInput
                                label={t(TK.countryV2)}
                                value={registerCompany.value.country}
                                type="text"
                                fullWidth
                                required
                                error={
                                    isClicked && !registerCompany.value.country ? 'Country need to be filled' : ''
                                }
                                onChange={(country) => handleChange({ ...registerCompany.value, country })}
                            /> */}
                            <SingleSelectInput
                                label={t(TK.countryV2)}
                                value={countryName}
                                options={countries}
                                isLoading={!!!countries?.length}
                                isCustomFilter={true}
                                onChange={(country: Country) => {
                                    handleChange({ ...registerCompany.value, country: country?.alpha2Code });
                                    setCountryName(country?.name ?? '');
                                }}
                                error={isClicked && !registerCompany.value.country ? t(TK.countryNeedToBeFilled) : ''}
                                renderOption={(option: Country): React.ReactNode => (
                                    <React.Fragment>
                                        <CountryFlag
                                            countryCode={option.alpha2Code}
                                            showCode={false}
                                            country={`${option.name} (${option.alpha2Code})`}
                                        />
                                    </React.Fragment>
                                )}
                            />
                        </BoxFullWidthWhite>
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={6} style={{ justifyContent: 'start' }}>
                        <Box display={'flex'} alignItems={'center'} flexDirection={'row'}>
                            <Checkbox
                                checked={termsAccepted}
                                onChange={() => setTermsAccepted(!termsAccepted)}
                                color="primary"
                            />
                            <Typography>{t(TK.registerConfirmMessage)}</Typography>
                        </Box>
                    </GridCenter>
                </GridCenter>
                <GridCenter item xs={12}>
                    <GridCenter item xs={2}>
                        <Button
                            disabled={process.env.REACT_APP_ENV === 'PRD'}
                            onClick={onClickRegister}
                            fullWidth
                            color="primary"
                            variant="contained"
                        >
                            {/* TODO: To Uncommnent when becomes available
                            {t(TK.register)} */}
                            {process.env.REACT_APP_ENV === 'PRD' ? t(TK.comingSoon) : t(TK.register)}
                        </Button>
                    </GridCenter>
                </GridCenter>
            </Grid>
        </Page>
    );
};

export default Register;
