import { AppThunkAction } from '..';
import { Action } from 'redux';
import { getCompanyDetailsAsync, postCompanyDetailsAsync } from '../../fetch/requests';
import { CompanyDetailsErrorAction, CompanyDetailsLoadedAction } from './types';
import { alertError, alertSuccess, requestServer } from '../Session/actions';
import { isLoadingCompanyDetails } from './selectors';
import { CompanyDetails, contactTypeOptions } from '../../models/CompanyDetails';
import { Contact } from '../../models/Contact';
import { getTranslation } from '../Translations/selectors';
import { TK } from '../Translations/translationKeys';

export const Actions = {
    companyDetailsLoading: '@@whichpharma.companyDetails.loading',
    companyDetailsLoaded: '@@whichpharma.companyDetails.loaded',
    companyDetailsUpdate: '@@whichpharma.companyDetails.update',
    companyDetailsUpdating: '@@whichpharma.companyDetails.updating',
    companyDetailsLoadError: '@@whichpharma.companyDetails.loadError',
};

const companyDetailsLoading = (): Action => ({
    type: Actions.companyDetailsLoading,
});

const companyDetailsUpdating = (): Action => ({
    type: Actions.companyDetailsUpdating,
});

const companyDetailsLoaded = (result: CompanyDetails): CompanyDetailsLoadedAction => {
    return {
        type: Actions.companyDetailsLoaded,
        result,
    };
};

export const updateCompanyDetails = (newDetails: CompanyDetails) => ({
    type: Actions.companyDetailsUpdate,
    companyDetails: newDetails,
});

const companyDetailsLoadError = (error: string): CompanyDetailsErrorAction => ({
    type: Actions.companyDetailsLoadError,
    error,
});

export const fetchCompanyDetails = (id?: number): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingCompanyDetails(getState())) {
                return;
            }
            dispatch(companyDetailsLoading());
            const result = await dispatch(
                requestServer((token, csrfToken) => getCompanyDetailsAsync(id, token, csrfToken)),
            );
            if (result) {
                let contacts = JSON.parse(JSON.stringify(result.contacts));
                let contactQM = contacts?.find((v: Contact) => v.contactType === contactTypeOptions.Office);
                let contactDelivery = contacts?.find((v: Contact) => v.contactType === contactTypeOptions.DeliveryInfo);

                let contactOffice = contacts?.find((v: Contact) => v.contactType === contactTypeOptions.Office);
                let contactWareHouse = contacts?.find(
                    (v: Contact) => v.contactType === contactTypeOptions.WareHouseInfo,
                );

                if (contactQM && contactDelivery) {
                    const areContactsEqual =
                        contactQM.address === contactDelivery.address &&
                        contactQM.city === contactDelivery.city &&
                        contactQM.country === contactDelivery.country &&
                        contactQM.postalCode === contactDelivery.postalCode;

                    if (areContactsEqual) {
                        result.deliverySameAs = 'sameAsInvoincingAddress';
                    } else {
                        result.deliverySameAs = 'false';
                    }
                }

                if (contactOffice && contactWareHouse) {
                    const areContactsEqual = contactOffice.address === contactWareHouse.address;

                    if (areContactsEqual) {
                        result.warehouseSameAsOffice = 'Yes';
                    } else {
                        result.warehouseSameAsOffice = 'Other';
                    }
                }

                dispatch(companyDetailsLoaded(result));
            } else {
                dispatch(companyDetailsLoadError(getTranslation(getState(), TK.noDataFromServer)));
            }
        } catch (e) {
            console.log(e);
            dispatch(companyDetailsLoadError(getTranslation(getState(), TK.noDataFromServer)));
        }
    };
};

export const updateCompany = (company: CompanyDetails): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState) => {
        try {
            dispatch(companyDetailsUpdating());
            await dispatch(requestServer((token) => postCompanyDetailsAsync(company, token)));
            const message = getTranslation(getState(), TK.submitCompanyDetailsSuccessMessage);
            dispatch(alertSuccess(message));
        } catch (e) {
            dispatch(alertError(getTranslation(getState(), TK.OopsSomethingWentWrong)));
        }
    };
};
