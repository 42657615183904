import { Typography } from '@material-ui/core';
import memoize from 'memoize-one';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { VariableSizeList as List } from 'react-window';
import { CompanyDetails } from '../../models/CompanyDetails';
import {
    FreeValueIds,
    QuestionTypeOptions,
    SectionOptions,
    TransportResponsibilityOptions,
} from '../../models/QualificationForm';
import { getQualificationFormToUpdate } from '../../store/QualificationForm/selectors';
import { TK } from '../../store/Translations/translationKeys';
import Row from './Row';
import { Header } from './styles';

interface QualificationFormProps {
    company: CompanyDetails;
    readOnly?: boolean;
    errors?: string[];
    goToError?: (questionId: string) => void;
    savePressed?: boolean;
}

const createItemData = memoize((questions, errors, readOnly, listRef) => ({
    questions,
    errors,
    readOnly,
    listRef,
}));

const QualificationFormTab: React.FC<QualificationFormProps> = ({
    company,
    readOnly,
    errors,
    goToError,
    savePressed,
}) => {
    let questions = company.qualificationForm?.questions || [];
    const listRef = React.useRef<any>(null);
    const questionToUpdate = useSelector(getQualificationFormToUpdate);

    // Scroll to the first question with an error
    React.useEffect(() => {
        if (window.scrollY === 0) return;
        if (errors && errors.length > 0) {
            const questionId = errors[0];
            const index = questions.findIndex((q) => q.id === questionId);

            if (index !== -1) {
                listRef.current.scrollToItem(index);

                requestAnimationFrame(() => {
                    goToError && goToError(questionId);
                });
            }
        } else if (!errors || errors.length === 0) {
            // Scroll to the top of the page showing the tabs
            listRef.current.scrollToItem(0);
            document.getElementById(TK.qualificationFormTabId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        // eslint-disable-next-line
    }, [savePressed]);

    const listHeight = React.useMemo(() => {
        const headerHeight = 100;
        const viewportHeight = window.innerHeight;
        return viewportHeight - headerHeight;
    }, []);

    const itemSize = (index: number) => {
        const question = questions[index];
        if (question.section === SectionOptions.TCQ && question.type === QuestionTypeOptions.FreeValue) {
            return 40;
        }
        return question && (index === 0 || questions[index - 1]?.section !== question.section) ? 110 : 50;
    };

    const isTRSectionVisible = questionToUpdate.some(
        (q) => q.section === SectionOptions.TR && q.value === TransportResponsibilityOptions.supplier,
    );

    if (!isTRSectionVisible) {
        questions = questions.filter((q) => q.section !== SectionOptions.TR || q.id === FreeValueIds.TRName);
    }

    const itemData = createItemData(questions, errors, readOnly, listRef);
    return (
        <div>
            <Header>
                <Typography variant="h4" color="primary">
                    {company.name}
                </Typography>
            </Header>
            <List
                height={listHeight}
                itemCount={questions.length}
                itemData={itemData}
                itemSize={itemSize}
                width="100%"
                ref={listRef}
            >
                {Row}
            </List>
        </div>
    );
};

export default QualificationFormTab;
