import React from 'react';
import { TK } from '../../store/Translations/translationKeys';
import { useTranslations } from '../../store/Translations/hooks';
import Page from '../../components/Page';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import ProductsSearchList from '../../components/ProductsSearchList/ProductsSearchList';
import { goToPaginatedProducts, goToSuppliers } from '../../store/Router/actions';
import {
    productsDeselected,
    productsLoaded,
    productsSelected,
    fetchPaginatedProducts,
    changeCriteria,
    resetFacets,
} from '../../store/RFQProducts/actions';
import {
    isLoadingProducts,
    getSearchTotalIncludingNotLoaded,
    getSelectedProducts,
    getAllProducts,
    getProductsSearchFacets,
    hasCriteriaChanged,
} from '../../store/RFQProducts/selectors';

const RFQProducts: React.FC = () => {
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const t = useTranslations();

    React.useEffect(() => {
        setHeaderName(t(TK.products));
    }, [setHeaderName, t]);

    return (
        <Page>
            <ProductsSearchList
                fetchAction={fetchPaginatedProducts}
                goToAction={goToPaginatedProducts}
                loadedAction={productsLoaded}
                selectedAction={productsSelected}
                deselectedAction={productsDeselected}
                goToNextAction={goToSuppliers}
                isLoadingSelector={isLoadingProducts}
                getTotalSelector={getSearchTotalIncludingNotLoaded}
                getSelectedSelector={getSelectedProducts}
                getAllSelector={getAllProducts}
                getFacetsSelector={getProductsSearchFacets}
                getHasCriteriaChangedSelector={hasCriteriaChanged}
                changeCriteria={changeCriteria}
                resetFacets={resetFacets}
                proceedText={t(TK.proceedToRFQCreation)}
            />
        </Page>
    );
};

export default RFQProducts;
