import { FilterByField, FilterFieldNames } from './ProcessingSettingsPanel.types';

export const capitalizeFirstLetter = (word: string): string => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getOperator = (type: string): string => {
    let operator = '';
    switch (type) {
        case 'Contains':
            operator = 'in';
            break;
        case 'Not contains':
            operator = 'nin';
            break;
        case 'Equal':
            operator = 'eq';
            break;
        case 'Not equal':
            operator = 'ne';
            break;
        case 'Starts with':
            operator = 'sw';
            break;
        case 'Ends with':
            operator = 'ew';
            break;
    }

    return operator;
};

export const buildFiltersQuery = (
    filtersByField: {
        [key in FilterFieldNames]?: FilterByField;
    },
): string | undefined => {
    let queryArr: string[] = [];

    if (!Object.entries(filtersByField).length) {
        return undefined;
    }

    for (const [key, value] of Object.entries(filtersByField)) {
        if (value?.type && value?.value) {
            queryArr.push(`${capitalizeFirstLetter(key)} ${getOperator(value.type)} '${value.value}'`);
        }
    }

    return queryArr.join(' and ');
};
