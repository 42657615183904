import { Typography } from '@material-ui/core';
import * as React from 'react';
import { DivMargin } from './styles';
import { useTranslations } from '../../../store/Translations/hooks';
import { SectionOptions } from '../../../models/QualificationForm';
import { TK } from '../../../store/Translations/translationKeys';

interface SectionProps {
    section: SectionOptions;
}

/**
 * Component that represents a section in the qualification form
 * A section is a group of questions that are related to a specific topic
 *
 * @param section - the section to render
 */
const Section: React.FC<SectionProps> = ({ section }) => {
    const t = useTranslations();

    return (
        <DivMargin>
            <Typography variant="h6" color="primary">
                {t(TK[section])}
            </Typography>
            <hr color="primary" />
        </DivMargin>
    );
};

export default Section;
