import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export default function AGEditableCommentsRenderer(props: ICellRendererParams) {
    const handleClick = () => {
        props.api.startEditingCell({
            rowIndex: props.rowIndex,
            colKey: props.column!.getId(),
        });
    };

    return (
        <Box display="flex" justifyContent="space-between" height="100%">
            {props.value && props.value.length > 0 ? (
                <>
                    <Tooltip title={<h6>{props.value}</h6>}>
                        <span>{props.value.substr(0, 12) + '...'}</span>
                    </Tooltip>
                </>
            ) : (
                <span style={{ paddingLeft: '4px' }}>{props.value}</span>
            )}
            {props.colDef?.editable ? (
                <IconButton aria-label="edit" size="small" color="primary" onClick={handleClick}>
                    <EditIcon fontSize="small" />
                </IconButton>
            ) : (
                ''
            )}
        </Box>
    );
}
