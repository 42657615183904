import { Box, BoxProps, Chip, ChipProps, TextareaAutosize, TextareaAutosizeProps } from '@material-ui/core';
import styled from 'styled-components';
import { MapOf } from '../../utils/Types';
import { Rating } from '@material-ui/lab';

export const Header = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1%;
    font-size: 1.5rem;
`;

export const BoxFlexStart = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1%;
    max-width: 100%;
`;

export const BoxWidth = styled(Box)<BoxProps & { width: string }>`
    width: ${(p) => p.width};
    padding: 0 1%;
`;

export const Row = styled(Box)`
    display: flex;
    flex-direction: row;
`;

export const BoxFlexBetween = styled(Box)<BoxProps & { maxWidth?: string }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1%;
    max-width: ${(p) => p.maxWidth || '100%'};
`;

export const BoxFlexEvenly = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 1%;
`;

export const Text = styled.span`
    margin-right: 0.8rem;
`;

export const TextArea = styled(TextareaAutosize)<TextareaAutosizeProps & { rowsMin?: number }>`
    rowsmin: ${(p) => p.rowsMin || 1};
    width: 100%;
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.1);
`;

export const RatingStar = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ffbc00', // Filled star color (gold/yellow)
    },
    '& .MuiRating-iconHover': {
        color: '#ff9f00', // Hover star color (slightly darker yellow)
    },
    '& .MuiRating-iconEmpty': {
        color: '#ddd', // Empty star color (white)
    },
    '& .MuiRating-root': {
        fontSize: '2rem', // Adjust the font size of the stars for better visibility
        transition: 'all 0.2s ease', // Smooth transition effect for hover
    },
    '&:hover .MuiRating-iconFilled': {
        color: '#ffcc33', // Slightly different yellow on hover
    },
    '&:focus .MuiRating-iconFilled': {
        color: '#ffbc00', // Keep the initial color on focus
    },
    '&:active .MuiRating-iconFilled': {
        color: '#e6a800', // Active state (clicking the star) color (dark yellow)
    },
});

export const PanelButtonsContainer = styled.div<{ nomargin?: boolean }>`
    margin-top: ${(props) => (props.nomargin ? 0 : props.theme.metrics.space.s)};
    margin-right: ${(props) => (props.nomargin ? 0 : props.theme.metrics.space.s)};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-radius: 12px;
    > * {
        margin-left: ${({ theme }) => theme.metrics.space.s};
    }
`;

const statusColor = {
    Qualified: { backgroundColor: '#40c057', color: 'white' },
    New: { backgroundColor: '#3bc9db', color: 'white' },
    NotQualified: { backgroundColor: '#e03131', color: 'white' },
    ToQualify: { backgroundColor: '#fab005', color: 'white' },
} as MapOf<any>;

export const ChipStatus = styled(Chip)<ChipProps & { status: any }>`
    padding: 0 5px;
    ${(p) => statusColor[p.status]}
`;
