import * as React from 'react';
import Page from '../../components/Page';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../components/AGPaginatedTable';
import { columnsArray } from './columns';
import Panel from '../../components/Panel';

import { EnquiryFiltersInternal } from './EnquiriesListInternal.types';

import {
    addNeedComment,
    fetchEnquiriesRbPharma,
    fetchEnquiryDetail,
    fetchEnquiryDetailUnload,
    fetchEnquiryNeedComments,
    updateApprovalStatus,
} from '../../store/Enquiry/actions';
import {
    getItems as getEnquiries,
    isLoading as isLoadingEnquiries,
    getTotal,
    getComments,
    isLoadingComments,
    getDetail,
} from '../../store/Enquiry/selectors';
import { goToRFQDetails } from '../../store/Router/actions';
import {
    EnquiryNeedDeclineType,
    EnquiryNeedDetail,
    EnquiryNeedStatus,
    OrderStatus,
    ProposalStatus,
    RFQStatus,
} from '../../models/EnquiryNeed';
import SingleSelectInput from '../../components/inputs/SingleSelectInput';
import { Button, Grid } from '@material-ui/core';
import TextInput from '../../components/inputs/TextInput';
import { EnquiryAcceptDeclineDialog } from './EnquiryAcceptDeclineDialog/EnquiryAcceptDeclineDialog';
import { EnquiryCommentsList } from './EnquiryCommentsList/EnquiryCommentsList';
import { MapOf } from '../../utils/Types';
import { EnquiryDetailClientDialog } from '../EnquiriesListClients/EnquiryDetailDialog/EnquiryDetailDialog';

export interface UpdateEnquiryNeedStatus {
    declineType?: string;
    customerComments?: string;
    approvalStatus?: string;
    internalComments?: string;
}

const orderBy = {
    Enquiry: 'EnquiryNumber',
    Status: 'Status',
    Client: 'CreatedBy',
    Need: 'Name',
    'Accept/Decline': 'Status',
    'Last Update': 'UpdatedAt',
} as MapOf<string>;

export interface AddEnquiryNeedComment {
    id?: string;
    comment?: string;
}

const EnquiriesList: React.FC = () => {
    const dispatch = useDispatch();
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const t = useTranslations();
    const [filters, setFilters] = React.useState<EnquiryFiltersInternal>({
        pageSize: 10,
        offset: 0,
    });
    const items = useSelector(getEnquiries);
    const total = useSelector(getTotal);
    const comments = useSelector(getComments);
    const isLoadingComment = useSelector(isLoadingComments);
    const isLoading = useSelector(isLoadingEnquiries);
    const [cPage, setCpage] = React.useState(0);
    const [toAccept, setToAccept] = React.useState(false);
    const [enquiryNeedDetail, setEnquiryNeedDetail] = React.useState<EnquiryNeedDetail>({});
    const [opemCommentsDialog, setOpenCommentsDialog] = React.useState(false);
    const needDetail = useSelector(getDetail);
    const [commentsNeedId, setCommentsNeedId] = React.useState('');
    
    React.useEffect(() => {
        dispatch(fetchEnquiriesRbPharma(filters));
    //eslint-disable-next-line
    }, []); 

    const goToRFQ = (itemId: string) => {
        dispatch(goToRFQDetails(itemId));
    };

    const getNeedDetail = (id: string) => {
        dispatch(fetchEnquiryDetail(id));
    };

    const handleDetailDialogClose = () => {
        dispatch(fetchEnquiryDetailUnload());
    };
    const handleAcceptRejectClick = (item: any, toAccept: boolean) => {
        setToAccept(toAccept);
        setEnquiryNeedDetail({ id: item.id, needName: item.needName });
    };

    const onClickCommentsIcon = (item: any) => {
        dispatch(fetchEnquiryNeedComments(item.id));
        setCommentsNeedId(item.id);
        setOpenCommentsDialog(true);
    };
    const colDef = columnsArray(t, goToRFQ, handleAcceptRejectClick, onClickCommentsIcon, getNeedDetail);

    React.useEffect(() => {
        setHeaderName(t(TK.enquiriesListPharma));
    }, [setHeaderName, t]);

    React.useEffect(() => {
        setCpage(filters.offset! / filters.pageSize);
    }, [filters.offset, filters.pageSize]);

    const updatedStartSearch = React.useCallback(
        (updatedFilters: EnquiryFiltersInternal) => {
            dispatch(fetchEnquiriesRbPharma(updatedFilters));
        },
        [dispatch],
    );
    const startSearch = React.useCallback(() => {
        dispatch(fetchEnquiriesRbPharma(filters));
    }, [dispatch, filters]);

    const handlePageChange = React.useCallback(
        (page: number) => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                offset: page * prevFilters.pageSize,
            }));
            startSearch();
        },
        [startSearch],
    );

    const onSortChanged = (sortField: string, sortOrder: string, prefFilters: EnquiryFiltersInternal) => {
        let newFilter = JSON.parse(JSON.stringify(prefFilters));
        if (sortField && sortOrder) {
            newFilter.orderBy = orderBy[sortField] + ' ' + sortOrder;
            setFilters(newFilter);
            updatedStartSearch(newFilter);
            return;
        }
        newFilter.orderBy = undefined;
        setFilters(newFilter);
        updatedStartSearch(newFilter);
        return;
    };

    const handleAcceptRejectClick2 = (
        id: string,
        toAccept: boolean,
        reason?: EnquiryNeedDeclineType,
        comment?: string,
        internal?: string,
    ) => {
        setEnquiryNeedDetail({});
        dispatch(
            updateApprovalStatus(
                id,
                {
                    approvalStatus: toAccept ? 'Accepted' : 'Declined',
                    customerComments: comment,
                    declineType: reason,
                    internalComments: internal,
                },
                startSearch,
            ),
        );
    };

    const handleAddComments = (add: AddEnquiryNeedComment) => {
        dispatch(addNeedComment(add));
        setOpenCommentsDialog(false);
    };

    const handlePageSizeChange = React.useCallback(
        (pageSize: number) => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                offset: 0,
                pageSize: pageSize,
            }));
            startSearch();
        },
        [startSearch],
    );

    const handleSearch = React.useCallback(() => {
        setFilters((prevFilters) => ({ ...prevFilters, offset: 0 }));
        startSearch();
    }, [startSearch]);

    const handleEnquiryNeedStatus = React.useCallback(
        (status: string) => {
            filters.enquiryStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    const handleOrderStatus = React.useCallback(
        (status: string) => {
            filters.orderStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    const handleProposalStatus = React.useCallback(
        (status: string) => {
            filters.proposalStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    const handleRfqStatus = React.useCallback(
        (status: string) => {
            filters.rfqStatus = status ?? undefined;
            setFilters({ ...filters });
        },
        [filters],
    );

    let filterCleared: EnquiryFiltersInternal = JSON.parse(JSON.stringify(filters));

    const clearFilters = React.useCallback(() => {
        filterCleared.search = undefined;
        filterCleared.enquiryStatus = undefined;
        filterCleared.proposalStatus = undefined;
        filterCleared.orderStatus = undefined;
        filterCleared.rfqStatus = undefined;
        filterCleared.offset = 0;

        setFilters(filterCleared);

        updatedStartSearch(filterCleared);
    }, [filterCleared, updatedStartSearch]);

    return (
        <Page
            title={t(TK.enquiriesListPharma)}
            style={{ marginTop: '10rem' }}
            actionPanel={
                <Panel>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled={isLoading}
                                options={Object.values(EnquiryNeedStatus)}
                                value={filters.enquiryStatus}
                                placeholder={t(TK.enquiryStatus)}
                                onChange={handleEnquiryNeedStatus}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={2}>
                            <TextInput
                                disabled={isLoading}
                                fullWidth={true}
                                value={filters.search}
                                onChange={(v) => {
                                    setFilters((prevFilters) => ({ ...prevFilters, search: v ?? undefined }));
                                }}
                                placeholder={t(TK.search)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled
                                options={Object.values(RFQStatus)}
                                placeholder={t(TK.rfqStatus)}
                                value={filters.rfqStatus}
                                onChange={handleRfqStatus}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled
                                options={Object.values(ProposalStatus)}
                                placeholder={t(TK.proposalStatus)}
                                value={filters.proposalStatus}
                                onChange={handleProposalStatus}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled
                                options={Object.values(OrderStatus)}
                                placeholder={t(TK.orderStatus)}
                                value={filters.orderStatus}
                                onChange={handleOrderStatus}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={1}>
                            <Button
                                disabled={isLoading}
                                variant="contained"
                                color="primary"
                                onClick={handleSearch}
                                fullWidth
                            >
                                {t(TK.search)}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                size="small"
                                style={{ marginTop: '2.5px', outline: 'none' }}
                                variant="outlined"
                                color="secondary"
                                onClick={clearFilters}
                                disabled={isLoading}
                            >
                                {t(TK.clearFilters)}
                            </Button>
                        </Grid>
                    </Grid>
                </Panel>
            }
        >
            <EnquiryDetailClientDialog handleDialogClose={handleDetailDialogClose} needOpen={needDetail} />
            <EnquiryCommentsList
                comments={comments}
                isLoading={isLoadingComment}
                openCommentsDialog={opemCommentsDialog}
                needId={commentsNeedId}
                handleDialogClose={() => setOpenCommentsDialog(false)}
                handleClick={handleAddComments}
            />
            <EnquiryAcceptDeclineDialog
                needOpen={enquiryNeedDetail}
                toAccept={toAccept}
                handleDialogClose={() => {
                    setEnquiryNeedDetail({});
                }}
                handleClick={handleAcceptRejectClick2}
            ></EnquiryAcceptDeclineDialog>
            <Panel>
                <Table
                    columnsDefinition={colDef}
                    data={items}
                    currentPage={cPage}
                    enablePagination={true}
                    pagination={true}
                    pageSize={filters.pageSize}
                    totalElements={total}
                    totalPages={Math.ceil(total / filters.pageSize)}
                    isExportable={false}
                    isLoading={isLoading}
                    onChangeSorting={onSortChanged}
                    onPageChange={handlePageChange}
                    onFilterChanged={() => {}}
                    onPageSizeChange={handlePageSizeChange}
                    onFreeTextChange={() => {}}
                    filters={filters}
                />
            </Panel>
        </Page>
    );
};

export default EnquiriesList;
