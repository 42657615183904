import { Action } from 'redux';
import { CompaniesListLoadedAction, SearchCompaniesListLoadedAction } from './types';
import { SearchResult } from '../../models/SearchResult';
import { AppThunkAction } from '..';
import { alertGenericError, requestServer } from '../Session/actions';
import { getCompaniesAsync } from '../../fetch/requests';
import { CompaniesListFilters } from '../../pages/CompaniesList/CompaniesList.types';
import { CompanyListResult } from '../../models/CompaniesList';

export const Actions = {
    companiesLoading: '@@whichpharma.companiesList.loading',
    companiesLoaded: '@@whichpharma.companiesList.loaded',
    searchCompaniesLoaded: '@@whichpharma.companiesList.searchCompaniesLoaded',
    companiesLoadError: '@@whichpharma.companiesList.loadError',
};

export const companiesLoading = (): Action => ({
    type: Actions.companiesLoading,
});

export const searchCompaniesLoaded = (result: SearchResult<CompanyListResult>): SearchCompaniesListLoadedAction => ({
    type: Actions.searchCompaniesLoaded,
    result,
});

export const companiesLoaded = (result: CompanyListResult): CompaniesListLoadedAction => ({
    type: Actions.companiesLoaded,
    result,
});

export const companiesLoadError = (): Action => ({
    type: Actions.companiesLoadError,
});

export const fetchCompanies = (filters: CompaniesListFilters, offset: number): AppThunkAction<Promise<void>> => {
    return async (dispatch) => {
        dispatch(companiesLoading());
        try {
            const result = await dispatch(
                requestServer((token, csrfToken) =>
                    getCompaniesAsync({
                        token,
                        csrfToken,
                        offset,
                        pageSize: filters.pageSize,
                        orderBy: filters.orderBy,
                        text: filters.text,
                        statuses: filters.statuses,
                        countries: filters.countries,
                        accountType: filters.accountType,
                        companyTypes: filters.companyTypes,
                    }),
                ),
            );
            dispatch(companiesLoaded(result));
        } catch (error) {
            dispatch(companiesLoadError());
            dispatch(alertGenericError());
        }
    };
};
