import { Box, Button, Checkbox, Grid } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import queryString from 'query-string';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../app/App';
import Table from '../../components/AGPaginatedTable';
import CountryFlag from '../../components/CountryFlag';
import MultipleSelectInput from '../../components/inputs/MultipleSelectInput';
import SingleSelectInput from '../../components/inputs/SingleSelectInput';
import TextInput from '../../components/inputs/TextInput';
import Page from '../../components/Page';
import Panel from '../../components/Panel';
import { AppContextType } from '../../context/@types/types';
import { CompanyStatus } from '../../models/CompaniesList';
import { CompanyType } from '../../models/CompanyType';
import { Country } from '../../models/Country';
import { fetchCompanies, searchCompaniesLoaded } from '../../store/CompaniesList/actions';
import { getCompanies, getSearchTotal, isLoadingCompanies } from '../../store/CompaniesList/selectors';
import { useCompanyTypes, useCountries } from '../../store/DomainInfo/hooks';
import { goToCompaniesList, goToCompanyDetails } from '../../store/Router/actions';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import { DEFAULT_PAGE_SIZE, INITIAL_OFFSET } from '../Platform/ProcessingSettingsPanel/ProcessingSettingsPanel';
import { columnsArray } from './columns';
import { CompaniesListFilters } from './CompaniesList.types';
import { removeSpaceCharacters } from '../../utils/utils';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CompaniesList: React.FC = () => {
    const t = useTranslations();
    const location = useLocation();
    const countries = useCountries();
    const companyTypes = useCompanyTypes();
    const dispatch = useDispatch();

    const { setHeaderName } = React.useContext(AppContext) as AppContextType;

    const isLoading = useSelector(isLoadingCompanies);
    const companies = useSelector(getCompanies);
    const totalRecords = useSelector(getSearchTotal);

    const [filters, setFilters] = React.useState<CompaniesListFilters>({
        page: INITIAL_OFFSET,
        pageSize: DEFAULT_PAGE_SIZE,
        statuses: [],
        countries: [],
        companyTypes: [],
    });
    const offset = (filters.page || INITIAL_OFFSET) * (filters.pageSize || DEFAULT_PAGE_SIZE);

    React.useEffect(() => {
        setHeaderName(t(TK.companiesList));
    }, [setHeaderName, t]);

    React.useEffect(() => {
        const parsedFilters = queryString.parse(location.search, {
            parseBooleans: true,
            parseNumbers: true,
        }) as CompaniesListFilters;

        // Set fallback required filters
        parsedFilters.page = parsedFilters.page || INITIAL_OFFSET;
        parsedFilters.pageSize = parsedFilters.pageSize || DEFAULT_PAGE_SIZE;

        const newFilters = JSON.parse(JSON.stringify(parsedFilters));

        setFilters({ ...newFilters });
    }, [location.search]);

    const getColsDef = React.useMemo(() => {
        return columnsArray(t, (id: string) => dispatch(goToCompanyDetails(id)));
    }, [t, dispatch]);

    const defaultColDef = React.useMemo(() => {
        return {
            flex: 1,
            sortable: true,
            wrapHeaderText: true,
            minWidth: 125,
            resizable: true,
            autoHeaderHeight: true,
            suppressMovable: true,
            filter: true,
            filterParams: {
                textMatcher: () => true,
                buttons: ['reset', 'apply'],
                closeOnApply: true,
            },
        };
    }, []);

    // Page Handlers
    const handleOnChangeText = (text: string) => {
        !text && dispatch(goToCompaniesList({ ...filters, text: '' })); // Reset search
        setFilters({ ...filters, text });
    };

    const handleSelectedStatus = (status: string[]) => {
        const normalizedStatus = status.map((status) => removeSpaceCharacters(status));
        dispatch(goToCompaniesList({ ...filters, statuses: normalizedStatus }));
    };

    const handleSelectedCountry = (countries: Country[]) => {
        dispatch(goToCompaniesList({ ...filters, countries: countries.map((country) => country.alpha2Code) }));
    };

    const handleSelectedAccountType = (accountType: string) => {
        dispatch(goToCompaniesList({ ...filters, accountType: accountType }));
    };

    const handleSelectedCompanyType = (companyTypes: string[]) => {
        dispatch(goToCompaniesList({ ...filters, companyTypes: companyTypes }));
    };

    const handleOnSearch = () => {
        dispatch(goToCompaniesList({ ...filters, page: INITIAL_OFFSET }));
        dispatch(fetchCompanies(filters, offset));
    };

    const handleOnClear = () => {
        dispatch(
            searchCompaniesLoaded({
                items: [],
                total: 0,
                timeInSeconds: 0,
                facets: {},
            }),
        );
        dispatch(
            goToCompaniesList({
                ...filters,
                page: INITIAL_OFFSET,
                pageSize: DEFAULT_PAGE_SIZE,
                text: '',
                statuses: [],
                countries: [],
                accountType: '',
                companyTypes: [],
                orderBy: '',
            }),
        );
    };

    // Table handlers
    const onSortingChanged = (sortField: string, sortOrder: string) => {
        if (totalRecords === 0) return;
        if (sortField && sortOrder) {
            dispatch(goToCompaniesList({ ...filters, page: 0, orderBy: `${sortField} ${sortOrder}` }));
            dispatch(fetchCompanies(filters, offset));
            return;
        }
        dispatch(goToCompaniesList({ ...filters, page: 0, orderBy: '' }));
        dispatch(fetchCompanies(filters, offset));
    };

    const onPageChanged = (page: number) => {
        dispatch(goToCompaniesList({ ...filters, page }));
        dispatch(fetchCompanies(filters, offset));
    };

    const onPageSizeChanged = (pageSize: number) => {
        dispatch(goToCompaniesList({ ...filters, pageSize: pageSize, page: 0 }));
        dispatch(fetchCompanies(filters, offset));
    };

    const companyTypesOptions = () => {
        const allCompanyTypes = companyTypes.map((companyType: CompanyType) =>
            companyType.companyTypes.map((company) => company.id),
        );
        // Flatten the array and remove duplicates
        return allCompanyTypes.flat().filter((item, index) => allCompanyTypes.flat().indexOf(item) === index);
    };

    // Onclick enter it will call the onSearch function
    const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleOnSearch();
        }
    };

    return (
        <Page
            title={t(TK.companiesList)}
            style={{ marginTop: '10rem' }}
            actionPanel={
                <Panel>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} lg={2}>
                            <TextInput
                                disabled={isLoading}
                                fullWidth
                                value={filters.text}
                                onChange={handleOnChangeText}
                                onKeyDown={handleKeyPress}
                                placeholder={t(TK.search)}
                                label={t(TK.search)}
                            />
                        </Grid>
                        <Box width={50} />
                        <Grid item xs={12} md={6} lg={2}>
                            <MultipleSelectInput
                                disabled={isLoading}
                                // In options we need to map the enum values to the transalation keys to display the correct values
                                options={Object.keys(CompanyStatus).map((status) => {
                                    return t(TK[status as keyof typeof CompanyStatus]);
                                })}
                                limitTags={2}
                                placeholder={t(TK.status)}
                                values={Object.values(CompanyStatus).filter((status) => {
                                    const normalizedStatus = removeSpaceCharacters(status);
                                    if (typeof filters.statuses === 'string') {
                                        return normalizedStatus === filters.statuses;
                                    }
                                    return filters.statuses?.includes(normalizedStatus);
                                })}
                                onChange={handleSelectedStatus}
                                label={t(TK.status)}
                                getOptionLabel={(option: string): string => option || ''}
                                renderOption={(option: string, { selected }): React.ReactNode => (
                                    <React.Fragment>
                                        <Checkbox icon={icon} style={{ marginRight: 8 }} checked={selected} />
                                        {option}
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <MultipleSelectInput
                                disabled={isLoading}
                                options={countries}
                                limitTags={2}
                                isCustomFilter={true}
                                values={countries.filter((country) => filters.countries?.includes(country.alpha2Code))}
                                placeholder={t(TK.countryV2)}
                                onChange={handleSelectedCountry}
                                label={t(TK.countryV2)}
                                orderBy={countries.sort((c, d) => c.name.localeCompare(d.name))}
                                getOptionLabel={(option: Country): string => option?.name || ''}
                                renderOption={(option: Country, { selected }): React.ReactNode => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        <CountryFlag
                                            countryCode={option.alpha2Code}
                                            showCode={false}
                                            country={`${option.name} (${option.alpha2Code})`}
                                        />
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <SingleSelectInput
                                disabled={isLoading}
                                options={companyTypes.map((companyType) => companyType.companyAccountType)}
                                placeholder={t(TK.accountType)}
                                value={filters.accountType}
                                onChange={handleSelectedAccountType}
                                label={t(TK.accountType)}
                                key={filters.accountType}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <MultipleSelectInput
                                disabled={isLoading}
                                options={companyTypesOptions()}
                                limitTags={1}
                                isCustomFilter={true}
                                placeholder={t(TK.companyType)}
                                values={companyTypesOptions().filter((companyType) =>
                                    filters.companyTypes?.includes(companyType),
                                )}
                                onChange={handleSelectedCompanyType}
                                label={t(TK.companyType)}
                                getOptionLabel={(option: string): string => option || ''}
                                renderOption={(option: string, { selected }): React.ReactNode => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {companyTypesOptions().find((companyType) => companyType === option)}
                                    </React.Fragment>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={1}>
                            <Button
                                disabled={isLoading}
                                variant="contained"
                                color="primary"
                                onClick={handleOnSearch}
                                fullWidth
                            >
                                {t(TK.search)}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Button
                                size="small"
                                style={{ marginTop: '2.5px', outline: 'none' }}
                                variant="outlined"
                                color="secondary"
                                onClick={handleOnClear}
                                disabled={isLoading}
                            >
                                {t(TK.clearFilters)}
                            </Button>
                        </Grid>
                    </Grid>
                </Panel>
            }
        >
            <Panel>
                <Table
                    columnsDefinition={getColsDef}
                    defaultColDef={defaultColDef}
                    data={Object.values(companies)}
                    currentPage={+filters.page!}
                    enablePagination={true}
                    pageSize={filters.pageSize!}
                    totalElements={totalRecords}
                    totalPages={Math.ceil(totalRecords / filters.pageSize!)}
                    isExportable={false}
                    isLoading={isLoading}
                    captionAnalytics={TK.totalCompanies}
                    onChangeSorting={onSortingChanged}
                    onPageChange={onPageChanged}
                    onPageSizeChange={onPageSizeChanged}
                    onFreeTextChange={() => {}}
                    onFilterChanged={() => {}}
                />
            </Panel>
        </Page>
    );
};

export default CompaniesList;
