import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@material-ui/core';
import { EnquiryNeedComment } from '../../../models/EnquiryNeed';
import { CloseIconWrapper, DialogTitleContainer, ColorHighlightOff } from './EnquiryCommentsList.styles';
import { useTranslations } from '../../../store/Translations/hooks';
import { TK } from '../../../store/Translations/translationKeys';
import { Send } from '@material-ui/icons';
import UserAvatar from '../../../components/UserAvatar';
import moment from 'moment';
import { AddEnquiryNeedComment } from '../EnquiriesListInternal';

interface IEnquiryCommentsListDialogProps {
    comments: EnquiryNeedComment[];
    isLoading: boolean;
    openCommentsDialog: boolean;
    needId?: string;
    handleDialogClose: () => void;
    handleClick: (add: AddEnquiryNeedComment) => void;
}

export const EnquiryCommentsList: React.FC<IEnquiryCommentsListDialogProps> = ({
    comments,
    isLoading,
    openCommentsDialog,
    needId,
    handleDialogClose,
    handleClick,
}) => {
    const t = useTranslations();
    const [comment, setComment] = React.useState<string>();
    const onSendButton = React.useCallback(() => {
        handleClick({ id: needId, comment: comment });
        setComment(undefined);
    }, [comment, needId, handleClick]);
    return (
        <Dialog
            style={{ overflow: 'unset' }}
            open={!isLoading && openCommentsDialog}
            onClose={handleDialogClose}
            maxWidth="lg"
        >
            <DialogTitle style={{ padding: 0, overflow: 'unset' }}>
                <DialogTitleContainer>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                            <Typography style={{ marginLeft: '5px', color: 'white' }} variant="h5">
                                {t(TK.comments)}
                            </Typography>
                        </div>
                    </div>
                    <CloseIconWrapper>
                        <IconButton onClick={handleDialogClose} style={{ outline: 'none' }}>
                            <ColorHighlightOff colors="white" fontSize="large" />
                        </IconButton>
                    </CloseIconWrapper>
                </DialogTitleContainer>
                <hr style={{ margin: 0 }} />
            </DialogTitle>
            <DialogContent style={{ width: '100%', margin: '8px 0px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box
                            alignItems={'end'}
                            flexDirection={'column-reverse'}
                            display="flex"
                            style={{ maxHeight: '600px', height: 'auto', overflowY: 'auto' }}
                        >
                            {comments?.map((comment) => {
                                return (
                                    <Box
                                        width={'100%'}
                                        alignItems="center"
                                        height={'auto'}
                                        flexDirection={'row'}
                                        display={'flex'}
                                    >
                                        <div style={{ margin: '1rem' }}>
                                            <UserAvatar username={comment.createdBy || ''} size="large" />
                                        </div>
                                        <Box display={'flex'} flexDirection={'column'}>
                                            <Typography style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                                {comment.value}
                                            </Typography>
                                            <Box display={'flex'} flexDirection={'row'}>
                                                <Typography style={{ fontWeight: 600 }}>{comment.createdBy}</Typography>
                                                <Typography style={{ whiteSpace: 'pre' }} color="secondary">
                                                    {moment(comment.createdAt).format(' YYYY/MM/DD hh:mm:ss')}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Grid>
                    <Box alignItems={'center'} display="flex" style={{ width: '1300px' }}>
                        <Grid item xs={12} md={12} lg={11}>
                            <TextField
                                fullWidth
                                multiline
                                value={comment}
                                onChange={(value) => {
                                    setComment(value.currentTarget.value);
                                }}
                                rows={3}
                                variant="outlined"
                                size="medium"
                            ></TextField>
                        </Grid>
                        <Grid item xs={12} md={12} lg={1}>
                            <IconButton onClick={onSendButton}>
                                <Send color="primary" fontSize="large"></Send>
                            </IconButton>
                        </Grid>
                    </Box>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};
