import React from 'react';
import Page from '../../components/Page';
import { AppContext } from '../../app/App';
import { AppContextType } from '../../context/@types/types';
import { useTranslations } from '../../store/Translations/hooks';
import { TK } from '../../store/Translations/translationKeys';
import ProductsSearchList from '../../components/ProductsSearchList/ProductsSearchList';
import { goToCreateEnquireConfiguration, goToEnquireProducts } from '../../store/Router/actions';
import {
    productsDeselected,
    productsLoaded,
    productsSelected,
    fetchProducts,
    changeCriteria,
    resetFacets,
} from '../../store/EnquiryProducts/actions';
import {
    isLoadingProducts,
    getSearchTotalIncludingNotLoaded,
    getSelectedProducts,
    getAllProducts,
    getProductsSearchFacets,
    hasCriteriaChanged,
} from '../../store/EnquiryProducts/selectors';
import { UserRole } from '../../models/UserRole';
import { useSelector } from 'react-redux';
import { getLoggedUser } from '../../store/Session/selectors';

const EnquireProducts = () => {
    const { setHeaderName } = React.useContext(AppContext) as AppContextType;
    const t = useTranslations();
    const user = useSelector(getLoggedUser);

    React.useEffect(() => {
        setHeaderName(t(TK.products) + (!user?.roles.includes(UserRole.Customer) ? ` (${t(TK.customer)})` : ''));
    }, [setHeaderName, t, user]);

    return (
        <Page>
            <ProductsSearchList
                fetchAction={fetchProducts}
                goToAction={goToEnquireProducts}
                loadedAction={productsLoaded}
                selectedAction={productsSelected}
                deselectedAction={productsDeselected}
                goToNextAction={goToCreateEnquireConfiguration}
                isLoadingSelector={isLoadingProducts}
                getTotalSelector={getSearchTotalIncludingNotLoaded}
                getSelectedSelector={getSelectedProducts}
                getAllSelector={getAllProducts}
                getFacetsSelector={getProductsSearchFacets}
                getHasCriteriaChangedSelector={hasCriteriaChanged}
                changeCriteria={changeCriteria}
                resetFacets={resetFacets}
                proceedText={t(TK.proceedToEnquiryCreation)}
            />
        </Page>
    );
};

export default EnquireProducts;
