import { RFQQuoteState } from '../models/RFQQuoteState';
import { RFQSimulationState } from '../models/RFQSimulationState';
import { TK } from '../store/Translations/translationKeys';

export const StateColors = (val: string) => {
    return val.charAt(0).toUpperCase() + val.slice(1);
};

export const getColor = (state: string): string => {
    switch (state) {
        case RFQQuoteState.Open:
            return '#ffc000';
        case RFQQuoteState.ToQuote:
            return '#ffc000';
        case RFQQuoteState.InProgress:
            return '#ffc000';
        case RFQQuoteState.Declined:
            return '#f00';
        case RFQQuoteState.Quoted:
            return '#92d050';
        case RFQQuoteState.Alternative:
            return '#dc02dcd9';
        case RFQQuoteState.Closed:
            return '#b0b0b0';
        case RFQSimulationState.Approved:
            return '#009655';
        case RFQSimulationState.Draft:
            return '#ffc000';
        case RFQSimulationState.ForApproval:
            return '#ffc000';
        default:
            return '#b0b0b0';
    }
};

export const getTK = (state: string): TK => {
    switch (state) {
        case RFQQuoteState.Open:
            return TK.open;
        case RFQQuoteState.ToQuote:
            return TK.open;
        case RFQQuoteState.InProgress:
            return TK.open;
        case RFQQuoteState.Declined:
            return TK.skipped;
        case RFQQuoteState.Alternative:
            return TK.alternative;
        case RFQQuoteState.Quoted:
            return TK.quoted;
        case RFQQuoteState.Closed:
            return TK.closed;
        case RFQSimulationState.Approved:
            return TK.approved;
        case RFQSimulationState.Draft:
            return TK.draft;
        case RFQSimulationState.ForApproval:
            return TK.forApproval;
        default:
            return TK.unknown;
    }
};
