import * as React from 'react';
import { InputWrapper, InputStyled, Label, Error } from '../Styled/FormElements';
import { Box } from '@material-ui/core';

interface InputProps<T> {
    label?: string;
    name?: string;
    value?: T;
    type?: string;
    placeholder?: string;
    error?: string;
    disabled?: boolean;
    required?: boolean;
    onChange: (value: T) => void;
    onBlur?: (value: T) => void;
    onKeyUp?: (keyCode: number) => void;
}

const Input: React.FC<InputProps<any>> = ({
    label,
    name,
    value,
    type,
    placeholder,
    error,
    disabled,
    required,
    onChange,
    onKeyUp,
    onBlur,
}) => {
    return (
        <div style={{ width: '100%' }}>
            {label && (
                <Box display={'flex'}>
                    <Label>{label}</Label>
                    {required && <Label style={{ float: 'right', color: 'red', fontSize: '2rem' }}>*</Label>}
                </Box>
            )}
            <InputWrapper rounded error={!!error}>
                <InputStyled
                    value={value || ''}
                    name={name || ''}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => onBlur && onBlur(e)}
                    onKeyUp={(e: React.KeyboardEvent) => onKeyUp && onKeyUp(e.keyCode)}
                />
            </InputWrapper>
            {error && <Error>{error}</Error>}
        </div>
    );
};
export default Input;
