import { AppThunkAction } from '..';
import { Action } from 'redux';
import { getPaymentTermsAsync } from '../../fetch/requests';
import { PaymentTermsLoadedAction } from './types';
import { requestServer } from '../Session/actions';
import { isLoadingPaymentTerms } from './selectors';
import { PaymentTerm } from '../../models/PaymentTerms';

export const Actions = {
    paymentTermsLoading: '@@whichpharma.paymentTerms.loading',
    paymentTermsLoaded: '@@whichpharma.paymentTerms.loaded',
};

const paymentTermsLoading = (): Action => ({
    type: Actions.paymentTermsLoading,
});

const paymentTermsLoaded = (result: PaymentTerm[]): PaymentTermsLoadedAction => {
    return {
        type: Actions.paymentTermsLoaded,
        result: result,
    };
};

export const fetchPaymentTerms = (): AppThunkAction<Promise<void>> => {
    return async (dispatch, getState): Promise<void> => {
        try {
            if (isLoadingPaymentTerms(getState())) {
                return;
            }
            dispatch(paymentTermsLoading());
            const result = await dispatch(requestServer((token, csrfToken) => getPaymentTermsAsync(token, csrfToken)));
            dispatch(paymentTermsLoaded(result));
        } catch (e) {
            console.log(e);
        }
    };
};
