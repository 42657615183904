import { Price } from '../models/Price';
import { ProcessingPformsSettings } from '../models/ProcessingPformsSettings';
import { YesNoNAOptions } from '../models/QualificationForm';
import { UserRole } from '../models/UserRole';
import { currencies } from './currencies';

export const findStringComparison = (sourceStr: string | undefined, str: string, filterType: string) => {
    switch (filterType) {
        case 'Contains':
            return sourceStr?.toLocaleLowerCase().includes(str.toLocaleLowerCase());

        case 'Not contains':
            return !sourceStr?.toLocaleLowerCase().includes(str.toLocaleLowerCase());

        case 'Equal':
            return sourceStr?.toLocaleLowerCase() === str.toLocaleLowerCase();

        case 'Not equal':
            return sourceStr?.toLocaleLowerCase() !== str.toLocaleLowerCase();

        case 'Starts with':
            return sourceStr?.toLocaleLowerCase().startsWith(str.toLocaleLowerCase());

        case 'Ends with':
            return sourceStr?.toLocaleLowerCase().endsWith(str.toLocaleLowerCase());

        default:
            return true;
    }
};

export const extractPriceByType = (
    prices: Price[],
    type: string,
    includeVat: boolean,
    currencyOrginal: boolean,
): Price | undefined => {
    return prices.find(
        (I) =>
            I.type === type &&
            I.includeVAT === includeVat &&
            (currencyOrginal ? I.currencyCode !== 'EUR' : I.currencyCode === 'EUR'),
    );
};

export const currencyFormatter = async (value: number, targetCurrency: string = 'EUR'): Promise<string> => {
    const locale =
        targetCurrency === 'EUR'
            ? 'pt-PT'
            : targetCurrency === 'USD'
            ? 'en-US'
            : currencies
                  .filter((country) => country.currency.ISO === targetCurrency)
                  .reduce((_index, item) => {
                      return item.locale;
                  }, '');

    const convertedValue = Number(value.toFixed(2));
    return new Intl.NumberFormat(locale ?? 'pt-PT', {
        style: 'currency',
        currency: targetCurrency,
        useGrouping: true,
    }).format(convertedValue);
};

export const moneyConvert = async (
    value: number,
    originCurrency: string,
    targetCurrency: string = 'EUR',
): Promise<string> => {
    const locale =
        targetCurrency === 'EUR'
            ? 'pt-PT'
            : targetCurrency === 'USD'
            ? 'en-US'
            : currencies
                  .filter((country) => country.currency.ISO === targetCurrency)
                  .reduce((_index, item) => {
                      return item.locale;
                  }, '');
    if (originCurrency === targetCurrency) {
        return Promise.resolve(
            new Intl.NumberFormat('pt-PT', { style: 'currency', currency: targetCurrency }).format(value),
        );
    }
    return await fetch(`https://api.exchangeratesapi.io/latest?symbols=${targetCurrency}&base=${originCurrency}`)
        .then((res) => res.json())
        .then((res) => {
            const rate = res.rates[targetCurrency];
            const convertedValue = Number((value * rate).toFixed(2));
            return new Intl.NumberFormat(locale ? locale : 'pt-PT', {
                style: 'currency',
                currency: targetCurrency,
            }).format(convertedValue);
        });
};

export const ifEnter = (keyCode: number, action: () => void) => {
    if (keyCode === 13) action();
};

export const assignDefined = <T>(target: T, ...sources: any): T => {
    for (const source of sources) {
        for (const key of Object.keys(source)) {
            const val = source[key];
            if (val !== undefined) {
                (target as any)[key] = val;
            }
        }
    }
    return target;
};

export const onlyUnique = <T>(value: T, index: number, self: T[]) => self.indexOf(value) === index;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const ofDefault = (array: any[], defaultValue: any) => (array.length ? array : [defaultValue]);

export const filterByChangedValues = (values: { [key: string]: ProcessingPformsSettings }) => {
    const newValues = {} as any;
    Object.keys(values)
        .filter((key) => values[key].edited)
        .forEach((key) => (newValues[key] = values[key]));

    if (Object.keys(newValues).length === 0) {
        return {};
    }

    return newValues;
};

export const removeEmptyArrayFieldsAndUpdateMetadata = (obj: any, username: string): any =>
    Object.keys(obj)
        .filter((key) => key.length)
        .reduce((prev, key) => {
            const value = obj[key];
            const lastUpdated = new Date();

            // Update metadata
            value['user'] = username;
            value['updated'] = lastUpdated;

            return Object.assign(prev, { [key]: value });
        }, {});

export const parseNumber = (str?: string): number | undefined => {
    var match = str?.match(/\d+/);
    return match?.length ? parseInt(match[0]) : undefined;
};

export const parseEuDecimalToDecimal = (str?: string): number => {
    if (str) {
        return parseFloat(str.replace(',', '.'));
    }
    return 0;
};

export const groupBy = <T>(data: T[], field: string): { [group: string]: T[] } => {
    return data.reduce((acc: any, d: any) => {
        if (Object.keys(acc).includes(d[field])) return acc;

        acc[d[field]] = data.filter((g: any) => g[field] === d[field]);
        return acc;
    }, {});
};

export const reorder = <T extends unknown>(list: T[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const tryGetNameFromOriginal = (originalName: string) => {
    var match = /^[^,;_1234567890]+/.exec(originalName);
    return match?.length ? match[0].trim() : originalName;
};

export const excerptText = (limit: number, text: string) => {
    return text.substr(0, limit);
};

export const getMaxMinAndMeanByField = (quotesTableData: any[], fieldName: string) => {
    let numberArrays: number[] = [];
    let sum = 0;
    switch (fieldName) {
        case 'exwNetPriceEuro':
            quotesTableData.forEach((item) =>
                numberArrays.push(
                    item.exwNetPriceEuro
                        ? (parseEuDecimalToDecimal(item.exwNetPriceEuro) || 0) /
                              (parseNumber(item.packSize || item.package) || 1)
                        : 0,
                ),
            );
            break;
        case 'priceCurrencyToEuro':
            quotesTableData.forEach((item) =>
                numberArrays.push(
                    item.priceCurrencyToEuro
                        ? (parseEuDecimalToDecimal(item.priceCurrencyToEuro) || 0) /
                              (parseNumber(item.packSize || item.package) || 1)
                        : 0,
                ),
            );
            break;
        case 'leadTimeToDeliver':
            quotesTableData.forEach((item) => {
                if (item.leadTimeToDeliver && parseInt(item.leadTimeToDeliver) !== 0) {
                    numberArrays.push(parseInt(item.leadTimeToDeliver));
                }
            });
            break;
    }

    let maxNumber = Math.max(...numberArrays);
    let minNumber = Math.min(...numberArrays.filter((i) => i !== 0));

    for (let j = 0; j < numberArrays.length; j++) {
        sum += numberArrays[j];
    }

    let meanNumber = sum / numberArrays.length;

    return { min: minNumber, max: maxNumber, mean: meanNumber };
};

export const convertUTCDatetoDate = (utcDate: string, format = 'dd-mm-yyyy hh:mm') => {
    let dateObj = new Date(utcDate);
    if (format === 'yyyy-mm-dd h:m') {
        return (
            dateObj.getUTCFullYear() +
            '-' +
            (dateObj.getUTCMonth() + 1) +
            '-' +
            dateObj.getUTCDate() +
            ' ' +
            dateObj.getUTCHours() +
            ':' +
            dateObj.getUTCMinutes()
        );
    }
    return (
        dateObj.getUTCDate() +
        '-' +
        (dateObj.getUTCMonth() + 1) +
        '-' +
        dateObj.getUTCFullYear() +
        ' ' +
        dateObj.getUTCHours() +
        ':' +
        dateObj.getUTCMinutes()
    );
};

export const escapeHtmlSpecialChars = (val: string, allowCharsArray: string[] = []) => {
    // Our finalized string will start out as a copy of the initial string.
    const specialCharsArray = [
        ['&', ''],
        ['<', ''],
        ['>', ''],
        ['"', ''],
        [';', ''],
    ];

    let escapedString = val;

    // For each of the special characters,
    var len = specialCharsArray.length;
    for (var x = 0; x < len; x++) {
        // Replace all instances of the special character with its entity.
        if (allowCharsArray.includes(specialCharsArray[x][0])) {
            continue;
        }
        escapedString = escapedString.replace(new RegExp(specialCharsArray[x][0], 'g'), specialCharsArray[x][1]);
    }

    // Return the escaped string.
    return escapedString;
};

export const escapeString = (val: string) =>
    val
        .replace(/\\/g, '\\\\') // Replace backslash with double backslash
        .replace(/\n/g, '\\n') // Replace newline with \n
        .replace(/\r/g, '\\r') // Replace carriage return with \r
        .replace(/\t/g, '\\t') // Replace tab with \t
        .replace(/'/g, "\\'") // Replace single quote with \'
        .replace(/"/g, '\\"'); // Replace double quote with \"

export const capitalizeFirstLetter = (val: string) => {
    return val.charAt(0).toUpperCase() + val.slice(1);
};

export const isValidDate = (dateString: string) => {
    return dateString !== '0001-01-01T00:00:00.000Z';
};
export const stringIsNullOrEmpty = (t: string): Boolean => {
    return typeof t != 'undefined' && t ? true : false;
};

export const isValidIBAN = (iban: string) => {
    // Remover espaços e converter para letras maiúsculas
    iban = iban.replace(/\s+/g, '').toUpperCase();

    // Verificar o comprimento mínimo (IBAN é geralmente entre 15 e 34 caracteres)
    const ibanLength = iban.length;
    if (ibanLength < 15 || ibanLength > 34) {
        return false; // IBAN inválido se não estiver dentro do comprimento esperado
    }

    // Mover os quatro primeiros caracteres para o final da string
    const rearrangedIBAN = iban.slice(4) + iban.slice(0, 4);

    // Substituir letras por números, onde A=10, B=11,..., Z=35
    const numericIBAN = rearrangedIBAN
        .split('')
        .map((char) => {
            const charCode = char.charCodeAt(0);
            if (charCode >= 48 && charCode <= 57) {
                // É um dígito (0-9)
                return char;
            } else if (charCode >= 65 && charCode <= 90) {
                // É uma letra (A-Z)
                return (charCode - 55).toString(); // Transformar em número
            }
            throw new Error('IBAN inválido: contém caracteres não alfanuméricos.');
        })
        .join('');

    // Calcular o módulo 97 do número resultante
    const remainder = BigInt(numericIBAN) % BigInt(97);

    // Se o resto for igual a 1, o IBAN é válido
    return remainder === BigInt(1);
};

export const removeSpaceCharacters = (val: string) => {
    return val.replace(/\s/g, '');
};

/**
 * Functions that checks if a user has a specific role
 */
export const isUserSupplier = (userRoles: UserRole[]): boolean => {
    return userRoles.includes(UserRole.Supplier);
};

export const isUserCustomer = (userRoles: UserRole[]): boolean => {
    return userRoles.includes(UserRole.Customer);
};

export const isUserAdmin = (userRoles: UserRole[]): boolean => {
    return userRoles.includes(UserRole.Administrator);
};

export const isUserCollaborator = (userRoles: UserRole[]): boolean => {
    return userRoles.includes(UserRole.Collaborator);
};

export const isUserBoth = (userRoles: UserRole[]): boolean => {
    return isUserSupplier(userRoles) && isUserCustomer(userRoles);
};

export const isUserInternal = (userRoles: UserRole[]): boolean => {
    return isUserAdmin(userRoles) || isUserCollaborator(userRoles);
};

export function normalizeYesNoNA(value: string): YesNoNAOptions | undefined {
    const normalizedValue = value?.toUpperCase();
    switch (normalizedValue) {
        case 'YES':
            return YesNoNAOptions.Yes;
        case 'NO':
            return YesNoNAOptions.No;
        case 'NA':
            return YesNoNAOptions.NA;
        default:
            return undefined;
    }
}
