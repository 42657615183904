import styled from 'styled-components';
import { Chip, ChipProps, makeStyles } from '@material-ui/core';

import colors from '../../../theme/colors';
import { MapOf } from '../../../utils/Types';

export const DialogTitleContainer = styled.div`
    display: inline-flex;
    background-color: ${colors.brightGreen};
    padding: 30px 20px 15px;
    font-weight: 200;
    color: ${colors.textRBGreen};
    width: 100%;
`;

export const CloseIconWrapper = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
`;

export const IconStyle = makeStyles({
    IndustryIcon: {
        color: '#156f56',
        width: '1.6em',
        height: '1.4em',
    },
});

const statusColor = {
    Qualified: { backgroundColor: '#40c057', color: 'white' },
    New: { backgroundColor: '#3bc9db', color: 'white' },
    NotQualified: { backgroundColor: '#e03131', color: 'white' },
    ToQualify: { backgroundColor: '#fab005', color: 'white' },
} as MapOf<any>;

export const ChipStatus = styled(Chip)<ChipProps & { status: any }>`
    padding: 0 5px;
    ${(p) => statusColor[p.status]}
`;
