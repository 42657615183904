import * as React from 'react';
import {
    IconButton,
    InputAdornment,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Checkbox,
    Box,
    makeStyles,
    createStyles,
    Switch,
} from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PageviewIcon from '@material-ui/icons/Pageview';

import { TK } from '../../../store/Translations/translationKeys';
import MultipleSelectInput from '../../../components/inputs/MultipleSelectInput';
import { Country } from '../../../models/Country';
import TextInput from '../../../components/inputs/TextInput';
import { useTranslations } from '../../../store/Translations/hooks';
import { useCountries } from '../../../store/DomainInfo/hooks';
import { Filters, FiltersKey } from './ProductsFilters.types';
import SearchContainer from '../../../components/SearchContainer';
import useKeyPress from '../../../utils/hooks/keyPress';
import { Spinner } from 'reactstrap';
import CountryFlag from '../../../components/CountryFlag';
import { AppContext } from '../../../app/App';
import { AppContextType } from '../../../context/@types/types';
import { SearchTags } from '../../../components/SearchTag';
import { useDispatch, useSelector } from 'react-redux';
import { suppliersDeselectAll } from '../../../store/Suppliers/actions';
import { useLocation } from 'react-router';
import { escapeHtmlSpecialChars } from '../../../utils/utils';
import { getMyUser } from '../../../store/Users/selectors';
import { fetchUser } from '../../../store/Users/actions';
import { UserRole } from '../../../models/UserRole';

export interface ProductsFiltersProps {
    defaultFilters: Filters;
    isLoading: boolean;
    isSwitch?: boolean;
    onChange: (filters: Filters) => void;
    onClear: () => void;
    setIsSwitch?: React.Dispatch<React.SetStateAction<boolean>>;

    filterItemsByTypes?: (isEuropean: number) => void;
    filteryByIsCommercial?: (isActive: boolean) => void;
}

const areEqual = (o1: any, o2: any): boolean => {
    if (Array.isArray(o1)) o1 = o1.join(', ');
    if (Array.isArray(o2)) o2 = o2.join(', ');
    return o1 === o2;
};

const areDifferent = (filters1: Filters, filters2: Filters) =>
    !areEqual(filters1[FiltersKey.Free], filters2[FiltersKey.Free]) ||
    !areEqual(filters1[FiltersKey.Countries], filters2[FiltersKey.Countries]) ||
    !areEqual(filters1[FiltersKey.IsMarketed], filters2[FiltersKey.IsMarketed]) ||
    !areEqual(filters1[FiltersKey.IsEuropean], filters2[FiltersKey.IsEuropean]);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(() =>
    createStyles({
        searchIconButton: {
            height: '20px',
            padding: '0px',
            marginRight: '-18px',
        },
        searchIconLarge: {
            fontSize: '3.7875rem',
        },
    }),
);

const ProductsFilters: React.FC<ProductsFiltersProps> = ({
    defaultFilters,
    isLoading,
    isSwitch,
    filterItemsByTypes,
    filteryByIsCommercial,
    setIsSwitch,
    onChange,
    onClear,
}: ProductsFiltersProps) => {
    const t = useTranslations();
    const countries = useCountries();
    const path = useLocation();
    const dispatch = useDispatch();

    const [filters, setFilters] = React.useState(defaultFilters);
    const [freeTextInfoOpen, setFreeTextInfoOpen] = React.useState(false);
    const [isCommercial, setIsCommercial] = React.useState(filters.isMarketed);
    const [isEuropean, setIsEuropean] = React.useState<number>(filters.isEuropean || 0);

    const user = useSelector(getMyUser);
    const hideUE = user?.roles.find((item) => item === UserRole.Customer) && user?.customer?.countryCode !== 'pt';
    const [hasFetchedUser, setHasFetchedUser] = React.useState(false);

    React.useEffect(() => {
        if (user?.username && !hasFetchedUser) {
            dispatch(fetchUser(user.username));
            setHasFetchedUser(true);
        }
    }, [dispatch, user, hasFetchedUser]);

    const { handleDrawerToggle, isOpen } = React.useContext(AppContext) as AppContextType;

    const classes = useStyles();

    React.useEffect(() => {
        if (!path.search) {
            dispatch(suppliersDeselectAll());
        }
    }, [path, dispatch]);

    const handleSwitchChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsCommercial(event.target.checked);
            setIsSwitch && setIsSwitch(true);
            setFilters((prevFilters) => ({ ...prevFilters, isMarketed: event.target.checked }));
            filteryByIsCommercial && filteryByIsCommercial(event.target.checked);
        },
        [setIsSwitch, filteryByIsCommercial],
    );

    const handleEuChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            // 0 -> Both switchs are true
            // 1 -> EU is selected
            // 2 -> NonEU is selected
            let newIsEuropean: number = 0;
            if (isEuropean === 0 || isEuropean === 1) {
                newIsEuropean = 2;
            }

            setFilters((prevFilters) => ({ ...prevFilters, isEuropean: newIsEuropean }));
            setIsEuropean(newIsEuropean);
            filterItemsByTypes && filterItemsByTypes(newIsEuropean);
        },
        [setFilters, isEuropean, setIsEuropean, filterItemsByTypes],
    );

    const handleNonEuChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            // 0 -> Both switchs are true
            // 1 -> EU is selected
            // 2 -> NonEU is selected

            let newIsEuropean: number = 0;
            if (isEuropean === 0 || isEuropean === 2) {
                newIsEuropean = 1;
            }

            setFilters((prevFilters) => ({ ...prevFilters, isEuropean: newIsEuropean }));
            setIsEuropean(newIsEuropean);
            filterItemsByTypes && filterItemsByTypes(newIsEuropean);
        },
        [setFilters, isEuropean, setIsEuropean, filterItemsByTypes],
    );

    const handleSelectedCountry = React.useCallback((v: Country[]) => {
        const arr = v.map((country: Country) => country.alpha2Code);
        setFilters((prevFilters) => ({
            ...prevFilters,
            countries: arr,
        }));
    }, []);

    const updateFilters = React.useCallback(
        (property: string, value: string | string[]): void => {
            if (property === 'free') {
                let tempV = value as string;
                value = tempV.replace('*', '');
                value = escapeHtmlSpecialChars(value, ['"']);
            }
            const newFilters = { ...filters, [property]: value && value.length ? value : undefined };
            setFilters(newFilters);
        },
        [filters, setFilters],
    );

    const updateFiltersAndSearch = React.useCallback(
        (property: string, value: string | string[]): void => {
            const newFilters = { ...filters, [property]: value && value.length ? value : undefined };
            setFilters(newFilters);
            onChange(newFilters);
            if (isOpen) {
                handleDrawerToggle();
            }
        },
        [filters, setFilters, onChange, handleDrawerToggle, isOpen],
    );

    const onSearchClick = React.useCallback(() => {
        onChange(filters);
        if (isOpen) {
            handleDrawerToggle();
        }
    }, [onChange, filters, handleDrawerToggle, isOpen]);

    const clearAll = React.useCallback(() => {
        setIsCommercial(true);
        setIsEuropean(0);
        setFilters({} as Filters);
        onChange({} as Filters);
        dispatch(suppliersDeselectAll());
        onClear();
    }, [setFilters, onChange, onClear, dispatch]);

    const hasFilters = React.useMemo<boolean>(
        () => (filters.countries && filters.countries.length > 0) || !!filters.free,
        [filters],
    );

    const hasChanged = React.useMemo<boolean>(() => areDifferent(filters, defaultFilters), [filters, defaultFilters]);

    useKeyPress('Enter', () => !isLoading && hasChanged && onChange(filters));
    return (
        <SearchContainer showClearButton={hasFilters} onClearAll={clearAll}>
            <Box style={{ width: '63%' }}>
                <Box display="flex">
                    <MultipleSelectInput
                        label={t(TK.country)}
                        placeholder={t(TK.all)}
                        options={countries}
                        limitTags={2}
                        isCustomFilter={true}
                        style={{ width: '176%' }}
                        orderBy={countries.sort((c, d) => c.name.localeCompare(d.name))}
                        values={countries.filter((country) => (filters.countries || []).includes(country.alpha2Code))}
                        onChange={handleSelectedCountry}
                        getOptionLabel={(option: Country): string => option?.name || ''}
                        renderOption={(option: Country, { selected }): React.ReactNode => (
                            <React.Fragment>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                <CountryFlag
                                    countryCode={option.alpha2Code}
                                    showCode={false}
                                    country={`${option.name} (${option.alpha2Code})`}
                                />
                            </React.Fragment>
                        )}
                    />
                    <TextInput
                        placeholder={t(TK.freeTextSearchDescription)}
                        value={filters[FiltersKey.Free] as string}
                        fullWidth={true}
                        formStyle={{ width: '235%', marginLeft: '12px' }}
                        onChange={(v): void => updateFilters(FiltersKey.Free, v)}
                        endAdorment={
                            <InputAdornment position="end">
                                {isLoading ? (
                                    <Spinner size="sm" style={{ marginLeft: '5px' }} />
                                ) : (
                                    <IconButton
                                        onClick={onSearchClick}
                                        className={classes.searchIconButton}
                                        style={{ outline: 'none' }}
                                        aria-label="free text filter info"
                                        edge="end"
                                    >
                                        <PageviewIcon color="primary" className={classes.searchIconLarge} />
                                    </IconButton>
                                )}
                            </InputAdornment>
                        }
                    />

                    <Button
                        size="small"
                        style={{ marginLeft: '10px', outline: 'none' }}
                        color="secondary"
                        variant="outlined"
                        onClick={clearAll}
                    >
                        {t(TK.clearFilters)}
                    </Button>
                </Box>
                <Box
                    display="flex"
                    justifyContent="right"
                    marginTop={'15px'}
                    marginRight={'107px'}
                    marginBottom={'15px'}
                >
                    <Box display="flex" alignItems="center">
                        <Typography
                            style={{
                                fontSize: '14px',
                                marginTop: '-4px',
                                marginRight: '-4px',
                                marginLeft: '5px',
                            }}
                        >
                            {t(TK.marketedProducts)}
                        </Typography>
                        <Switch
                            color="primary"
                            checked={isCommercial}
                            onChange={handleSwitchChange}
                            size="small"
                            name="notCommercial"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={isSwitch}
                        />
                    </Box>
                    {!hideUE && (
                        <>
                            <Box display="flex" alignItems="center">
                                <Typography
                                    style={{
                                        fontSize: '14px',
                                        marginTop: '-3px',
                                        marginRight: '-4px',
                                        marginLeft: '5px',
                                    }}
                                >
                                    {t(TK.euProducts)}
                                </Typography>
                                <Switch
                                    color="primary"
                                    checked={isEuropean === undefined || isEuropean === 0 || isEuropean === 1}
                                    onChange={handleEuChange}
                                    size="small"
                                    name="european"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={isSwitch}
                                />
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Typography
                                    style={{
                                        fontSize: '14px',
                                        marginTop: '-3px',
                                        marginRight: '-4px',
                                        marginLeft: '5px',
                                    }}
                                >
                                    {t(TK.nonEuProducts)}
                                </Typography>
                                <Switch
                                    color="primary"
                                    checked={isEuropean === undefined || isEuropean === 0 || isEuropean === 2}
                                    onChange={handleNonEuChange}
                                    size="small"
                                    name="non-european"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={isSwitch}
                                />
                            </Box>
                        </>
                    )}
                </Box>
                <Box display="flex" justifyContent="center" width={'700px'}>
                    {window.location.search && <SearchTags filters={filters} updateFilters={updateFiltersAndSearch} />}
                </Box>
            </Box>

            <Dialog open={freeTextInfoOpen} onClose={() => setFreeTextInfoOpen(false)}>
                <DialogContent>
                    <Typography variant="h5">{t(TK.freeTextSearch)}</Typography>
                </DialogContent>
                <DialogContent dividers>
                    <Typography variant="h6">{t(TK.defaultBehavior)}</Typography>
                    <Typography gutterBottom>{t(TK.freeTextSearchDefaultBehaviorInfo)}</Typography>
                    <Typography variant="h6">{t(TK.mandatoryWords)}</Typography>
                    <Typography gutterBottom>{t(TK.freeTextSearchMandatoryWordsInfo)}</Typography>
                    <Typography variant="h6">{t(TK.exactPhrase)}</Typography>
                    <Typography gutterBottom>{t(TK.freeTextSearchExactPhraseInfo)}</Typography>
                    <Typography variant="h6">{t(TK.excludeWords)}</Typography>
                    <Typography gutterBottom>{t(TK.freeTextSearchExcludeWordsInfo)}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setFreeTextInfoOpen(false)} color="primary">
                        {t(TK.ok)}
                    </Button>
                </DialogActions>
            </Dialog>
        </SearchContainer>
    );
};

export default ProductsFilters;
