import { Action } from 'redux';
import { QualificationFormErrorAction, QualificationFormLoadedAction, QuestionInfo } from './types';

export const Actions = {
    qualificationFormLoaded: '@@whichpharma.qualificationForm.loaded',
    qualificationFormLoadError: '@@whichpharma.qualificationForm.loadError',
    qualificationFormUpdate: '@@whichpharma.qualificationForm.update',
    qualificationFormLoading: '@@whichpharma.qualificationForm.loading',
    qualificationFormUpdated: '@@whichpharma.qualificationForm.updated',
    qualificationFormShowValidationErrors: '@@whichpharma.qualificationForm.showValidationErrors',
};

export const qualificationFormLoading = (): Action => ({
    type: Actions.qualificationFormLoading,
});

export const qualificationFormLoaded = (result: QuestionInfo[]): QualificationFormLoadedAction => ({
    type: Actions.qualificationFormLoaded,
    result,
});

export const updateQualificationForm = (updatedQuestions: QuestionInfo[]) => ({
    type: Actions.qualificationFormUpdate,
    result: updatedQuestions,
});

export const qualificationFormLoadError = (questions: string[], error?: string): QualificationFormErrorAction => ({
    type: Actions.qualificationFormLoadError,
    error,
    questions,
});

export const qualificationFormShowValidationErrors = (): Action => ({
    type: Actions.qualificationFormShowValidationErrors,
});
