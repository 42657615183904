import { companyAccountTypeOptions } from './CompanyDetails';

export interface CompanyListResult {
    items: CompanyListItem[];
    total: number;
}

export interface CompanyListItem {
    id: number;
    name: string;
    status: string;
    country: string;
    countryCode: string;
    companyAccountType: companyAccountTypeOptions;
    vat: string | null;
    notes: string | null;
    updatedBy: string;
    updatedAt: string;
}

export enum CompanyStatus {
    ToQualify = 'To Qualify',
    New = 'New',
    Qualified = 'Qualified',
    NotQualified = 'Not Qualified',
}
