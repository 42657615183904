import { ColDef } from 'ag-grid-community';
import { TK } from '../../store/Translations/translationKeys';

import * as React from 'react';
import { ITranslate } from '../../store/Translations/hooks';
import { Cell } from '../../components/AGTable/CellFormatter';
import CountryFlag from '../../components/CountryFlag';
import moment from 'moment';
import { DateTimeFormat } from '../../components/Table/DataTypeFormatter';
import { CompanyListItem } from '../../models/CompaniesList';
import { ChipStatus, defaultCellStyle, LinkButton } from './styles';
import { escapeHtmlSpecialChars } from '../../utils/utils';

export function columnsArray(t: ITranslate<TK>, goToAction: (id: string) => void): ColDef<CompanyListItem>[] {
    return [
        {
            colId: TK.name,
            headerName: t(TK.name),
            field: 'Name',
            headerClass: 'agrid-column-country-pr',
            cellStyle: { ...defaultCellStyle, wordBreak: 'break-word' },
            wrapText: true,
            initialWidth: 250,
            menuTabs: [],
            autoHeight: true,
            sortable: true,
            valueGetter: (p: any) => p.data.name,
            cellRenderer: (p: any) => {
                if (!p.data.name) return <>-</>;
                return (
                    // Text color like link and cursor pointer, do not have any other styles,
                    <LinkButton onClick={() => goToAction(p.data.id)}>{p.data.name}</LinkButton>
                );
            },
        },
        {
            colId: TK.status,
            headerName: t(TK.status),
            headerClass: 'agrid-column-name_pl',
            cellStyle: defaultCellStyle,
            wrapText: true,
            menuTabs: [],
            autoHeight: true,
            initialWidth: 120,
            valueGetter: (p: any) => p.data.status,
            cellRenderer: (p: any) => {
                if (!p.data.status) return <>-</>;
                return <ChipStatus status={p.data.status} size="small" label={p.data.status} />;
            },
        },
        {
            colId: TK.countryV2,
            headerName: t(TK.countryV2),
            cellStyle: defaultCellStyle,
            wrapText: true,
            menuTabs: [],
            autoHeight: true,
            initialWidth: 125,
            headerClass: 'agrid-column-name_pl',
            sortable: true,
            valueGetter: (p: any) => p.data.countryCode.toUpperCase(),
            cellRenderer: (p: any) => {
                if (!p.data.country || !p.data.countryCode) return <>-</>;
                return <CountryFlag country={p.data.country} showCode={false} countryCode={p.data.countryCode} />;
            },
        },
        {
            colId: escapeHtmlSpecialChars(TK.accountType),
            headerName: t(TK.accountType),
            headerClass: 'agrid-column-name_pl',
            cellStyle: defaultCellStyle,
            wrapText: true,
            menuTabs: [],
            autoHeight: true,
            valueGetter: (p: any) => p.data.companyAccountType,
            cellRenderer: (p: any) => {
                if (!p.data.companyAccountType) return <>-</>;
                return (
                    <Cell lineHeight="1.2rem" marginTop="0.67rem">
                        {p.data.companyAccountType}
                    </Cell>
                );
            },
        },
        {
            colId: escapeHtmlSpecialChars(TK.companyType),
            headerName: t(TK.companyType),
            field: 'CompanyType',
            headerClass: 'agrid-column-name_pl',
            cellStyle: defaultCellStyle,
            wrapText: true,
            menuTabs: [],
            autoHeight: true,
            valueGetter: (p: any) => p.data.type,
            cellRenderer: (p: any) => {
                if (!p.data.type) return <>-</>;
                return (
                    <Cell lineHeight="1.2rem" marginTop="0.67rem">
                        {p.data.type}
                    </Cell>
                );
            },
        },
        {
            colId: TK.vatNumber,
            headerName: TK.vatNumber,
            headerClass: 'agrid-column-name_pl',
            cellStyle: defaultCellStyle,
            wrapText: true,
            menuTabs: [],
            autoHeight: true,
            valueGetter: (p: any) => p.data.vat,
            cellRenderer: (p: any) => {
                if (!p.data.vat) return <>-</>;
                return (
                    <Cell lineHeight="1.2rem" marginTop="0.67rem">
                        {p.data.vat}
                    </Cell>
                );
            },
        },
        {
            colId: TK.notes,
            headerName: t(TK.notes),
            headerClass: 'agrid-column-name_pl',
            cellStyle: { ...defaultCellStyle, wordBreak: 'break-word' },
            wrapText: true,
            menuTabs: [],
            autoHeight: true,
            initialWidth: 320,
            valueGetter: (p: any) => p.data.notes,
            cellRenderer: (p: any) => {
                if (!p.data.notes) return <>-</>;
                return (
                    <Cell lineHeight="1.2rem" marginTop="0.67rem">
                        {' '}
                        {p.data.notes}
                    </Cell>
                );
            },
        },
        {
            colId: TK.lastUpdate,
            headerName: t(TK.lastUpdate),
            headerClass: 'agrid-column-name_pl',
            cellStyle: defaultCellStyle,
            wrapText: true,
            menuTabs: [],
            autoHeight: true,
            valueGetter: (p: any) => p.data.updatedAt,
            cellRenderer: (p: any) => {
                if (!p.data.updatedAt || !p.data.updatedBy) return <></>;
                return (
                    <Cell lineHeight="1.2rem" marginTop="0.67rem">
                        {moment(p.data.updatedAt).format(DateTimeFormat)}, <br />
                        by {p.data.updatedBy}
                    </Cell>
                );
            },
        },
    ];
}
