import styled from 'styled-components';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';



export const SuccessPanel = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SuccessIcon = styled(CheckCircleOutlineIcon)`
    fill: ${({ theme }) => theme.colors.RBGreen};
    height: 5rem;
    width: 5rem;
`;

export const SuccessParagraph = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${({ theme }) => theme.metrics.space.xl};
    > * + * {
        margin-left: ${({ theme }) => theme.metrics.space.s};
    }
`;
